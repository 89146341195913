import ApiStore from './ApiStore';
import { observable, action, computed } from 'mobx';
import api from 'api.json';

export default class AuthStore extends ApiStore {

    constructor(stores){

        super(stores);

        this.url = api.endpoints.user.auth;
        this.jwt = localStorage.getItem("usersJWT") || "";
        this.id = localStorage.getItem("id") || false;
    }

    @observable jwt = "";
    @observable id = false;

    @action.bound logout() {
        this.setJwt("");
        this.setId(false);
    }

    @action.bound setJwt(jwt) {
        this.jwt = jwt;
        localStorage.setItem("usersJWT", this.jwt);
    }

    @action.bound setId(id) {
        this.id = id;
        localStorage.setItem("id", this.id);
    }

    @computed get isLoggedIn() {

        return (this.jwt === "") ? false : true;
    }

    @action.bound async login(email, password) {

        const res = await this.post(
            {"email": email, "password": password},
            {renew: false}
        );

        if(this.isResponseOk(res)) {
            this.setJwt(res.data.jwt);
            this.setId(res.data.id);
        }

        return res;
    }

    @action.bound async forgot(email) {

        const res = await this.patch(
            {"email": email},
            {renew: false}
        );

        return res;
    }

    @action.bound async renew() {

        const res = await this.put(
            {},
            {renew: false}
        );

        if(this.isResponseOk(res)) {
            this.setJwt(res.data.jwt);
            this.setId(res.data.id);
        } else {
            this.logout();
        }

        return res;
    }

    @action.bound async temporary() {

        const res = await this.put(
            {"lifetime": 60}
        );

        return res;
    }

}
