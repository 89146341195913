export default { 

    typeCodes: {
    
        euroBusinessParcel: 10000,
        euroBusinessFreight: 10013,
        globalBusinessParcel: 10015,
        globalExpressParcel: 10010
    },

    typeNames: {

        10000: 'EuroBusinessParcel',
        10013: 'EuroBusinessFreight',
        10015: 'GlobalBusinessParcel',
        10010: 'GlobalExpressParcel'
    },

    typeShortnames: {

        10000: 'EBP',
        10013: 'EBF',
        10015: 'GBP',
        10010: 'GEP'
    }
}
