import React from 'react';
import styled from 'styled-components';
import theme from 'theme';

const Table = styled.table`

    width: ${props => props.width};
    margin-bottom: ${props => props.marginBottom};

    @media (max-width: ${props => props.breakpoint}){

        display: block;
        width: 100%;
    }
`;

const Tbody = styled.tbody`

    @media (max-width: ${props => props.breakpoint}){

        display: block;
    }
`;

const Tr = styled.tr`

    @media (max-width: ${props => props.breakpoint}){

        display: block;
    }
`;

const Td = styled.td`

    ${props => `

        padding: 0 ${props.theme.size[2]} 0 ${props.theme.size[2]};

        &:first-of-type {

            padding-left: 0;
        }

        &:last-of-type {

            padding-right: 0;
        }

        @media (max-width: ${props.breakpoint}){

            display: block;
            padding: 0 0 ${props.theme.size[1]} 0;

            &:last-of-type {

                padding-bottom: 0;
            }
        }
    `}
`;

class Buttons extends React.Component {

    render(){

        return (

            <Table {...this.props}>

                <Tbody breakpoint={this.props.breakpoint}>
            
                    <Tr breakpoint={this.props.breakpoint}>

                        {this.props.children.map((v, k) => {

                            if(typeof v.type === 'symbol' && v.type.toString() === 'Symbol(react.fragment)'){

                                return v.props.children.map((v, k) => {

                                    if(v !== false){

                                        return <Td key={k} breakpoint={this.props.breakpoint}>{v}</Td>;
                                    }

                                    return false;
                                });
                            }

                            else if(v !== false){

                                return <Td key={k} breakpoint={this.props.breakpoint}>{v}</Td>;
                            }

                            return null;
                        })}

                    </Tr>

                </Tbody>
            
            </Table>
        );
    }

    static defaultProps = {

        width: '100%',
        breakpoint: theme.breakpoint,
        marginBottom: theme.size[1]
    }
}

export {Buttons};
