import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import theme from 'theme';
import Button from 'components/gls-dumb/Button';
import {inject} from 'mobx-react';

const Header = styled.div(props => `

    display: flex;
    justify-content: space-between;
    background: ${props.important === true ? props.theme.color[6] : props.theme.color[4]};
    padding: ${props.theme.size[1]};
`);

const Wrapper = styled.div`

    display: flex;
    padding: ${props => props.theme.size[1]};
    margin-bottom: ${props => props.marginBottom};
    align-items: center;

    & :first-child {

        width: 100%;
    }
`;

const Item = styled.div`

    
`;

const Preview = styled.div`
    
    white-space: pre-line;
`;

@inject('ui')

class News extends React.Component {

    lang = this.props.ui.lang.announcements;

    render(){

        const suffix = '_' + this.props.lang;

        return (

            <React.Fragment>

                {this.props.items.map((v, k) => {

                    return (

                        <React.Fragment key={v.id}>
                        
                            <Header important={v.is_important}>
                                
                                <Item>{v['title' + suffix]}</Item>
                                <Item>{moment(v.created_at, 'X').format('DD.MM.YYYY')}{v.updated_at !== 0 && v.created_at !== v.updated_at && ' (' + this.lang.updated + ': ' + moment(v.updated_at, 'X').format('DD.MM.YYYY') + ')'}</Item>
                            
                            </Header>

                            <Wrapper marginBottom={this.props.items.length !== k + 1 ? theme.size[1]: 0}>

                                <Preview>{v['text' + suffix]}</Preview>
                                {v['url' + suffix] !== '' && <Button width="auto" marginLeft={theme.size[1]} onClick={() => {window.open(v['url' + suffix])}}>{this.lang.open}</Button>}

                            </Wrapper>


                        </React.Fragment>
                    );
                })}

            </React.Fragment>
        );
    }
}

export default News;
