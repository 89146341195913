import React from 'react';
import {inject} from 'mobx-react';
import {Route, Switch, withRouter} from 'react-router-dom';

const withViews = (Component) => {

    return @withRouter @inject('views') class extends React.Component {

        constructor(props){

            super(props);

            this.dependencies = [];

            // Loop trough routes.

            this.props.views.routes.forEach((obj, route) => {

                // If route is the current route add dependencies.
                
                if(this.current.route === route){

                    obj.dependencies.forEach(dependency => {

                        this.dependencies.push(dependency(this.current.params));
                    });
                }
            });
        }

        componentDidUpdate(prev){

            if(this.props.location.pathname !== prev.location.pathname){

                window.scrollTo(0, 0);
            }
        }

        // Match route same kind of way as React Router.

        get current(){

            const path = this.props.location.pathname.split('/');
            const matches = [];

            // Loop trough routes.
        
            this.props.views.routes.forEach((v, route) => {

                const fragments = [];

                route.split('/').forEach(fragment => {

                    // If fragment begins with colon the fragment is parameter.

                    if(fragment.substr(0, 1) === ':'){

                        const param = fragment.substr(1, fragment.length -1);

                        fragments.push ({
                        
                            match: param,
                            param: true
                        });
                    }

                    else {

                        fragments.push ({
                        
                            match: fragment,
                            param: false
                        });
                    }
                });

                // If path has same amount of fragments try to match it.

                if(path.length === fragments.length){

                    let match = true;
                    const params = {};

                    fragments.forEach((fragment, k) => {

                        // If fragment is not parameter it has to match with counterpart of the path.

                        if(fragment.param === false && path[k] !== fragments[k].match){

                            match = false;
                        }

                        // If fragment is parameter save counterpart of the path as parameter.

                        if(fragment.param === true){

                            params[fragment.match] = path[k];
                        }
                    });

                    if(match === true){

                        matches.push({
                        
                            route: route,
                            params: params
                        });
                    }
                }
            });

            // If there are more than one match there is route collision and if less no match was found.

            if(matches.length === 0){

                console.log('No matching route for: ', this.props.location.pathname);

                return {route: undefined, params: undefined};
            }

            if(matches.length !== 1){

                if(matches.lenght > 1){

                    console.log('Route collision!');
                }
            }

            return matches[0];
        }

        render(){

            return (

                <Component dependencies={this.dependencies} current={this.current} {...this.props}>

                    <Switch>

                        {[...this.props.views.routes].map((v, k) => {

                            const route = v[0];
                            const view = v[1].component;
                            
                            return <Route key={k} exact path={route} component={view} />;
                        })}

                    </Switch>

                </Component>
            );
        }
    }
}

export default withViews;
