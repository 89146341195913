// APP

import React from 'react'
import ModalAlerts from 'components/gls-dumb/ModalAlerts';
import ModalConfirm from 'components/gls-dumb/ModalConfirm';
import {inject, observer} from 'mobx-react';
import Loading from 'components/gls-dumb/Loading';
import withViews from 'withViews';
import Footer from 'components/smart/Footer.js'
import Wrapper from 'components/gls-dumb/Wrapper';

@withViews

@inject('auth')
@inject('ui')
@inject('settings')
@inject('parcel')
@inject('import')
@inject('load')

@observer class App extends React.Component {

    constructor(props){

        super(props);

        if(this.props.auth.isLoggedIn){

            this.props.ui.reload = true;

            this.props.settings.load().then(() => {

                if(this.props.current.route === '/' || this.props.current.route === '/login/' || this.props.current.route === '/reset/password.html'){

                    const url = this.props.settings.object.default_view;
                    this.props.history.push(url);
                }

                this.props.ui.loadApp(this.props.dependencies).then(
                
                    resolved => {

                        // If language is not already set do it now

                        if(this.props.ui.selectedLang === null){

                            const lang = this.props.settings.object.language;

                            if(lang === undefined){

                                this.setDefaultLang();
                            }

                            else {
                           
                                localStorage.setItem('lang', lang);
                                this.props.ui.selectedLang = lang;
                            }
                        }

                        this.repopulate(); // Repopulation of form that spreads between pages has to be done here.
                    },
                
                    rejected => {
                    
                        console.log('Reloading failed:', rejected);
                    }
                );
            });
        }

        else {

            this.setDefaultLang();

            // Redirect on refresh.

            if(this.props.current.route !== '/login/' && this.props.current.route !== '/reset/password.html'){

                this.props.history.push('/login/');
            }
        }

        this.initStorageListener(); 
    }

    repopulate = () => {

        switch(this.props.current.route){

            // Export

            case '/parcel/send.html':
            case '/parcel/:id/send.html':
            case '/parcel/measurements.html':
            case '/parcel/:id/measurements.html':
            case '/parcel/proforma.html':
            case '/parcel/:id/proforma.html':

                let firstPageValidation;

                if(this.props.current.params.id !== undefined){
                
                    this.props.parcel.repopulate(this.props.current.params.id);
                    firstPageValidation = this.props.parcel.validate('ParcelSend');
                }

                // Validate first page and go back if errors.

                if(
                
                    this.props.current.route === '/parcel/measurements.html' ||
                    this.props.current.route === '/parcel/:id/measurements.html' ||
                    this.props.current.route === '/parcel/proforma.html' ||
                    this.props.current.route === '/parcel/:id/proforma.html'
                ){
                
                    if(firstPageValidation === undefined || this.props.parcel.errorCount(firstPageValidation) !== 0){

                        if(this.props.current.params.id === undefined){

                            const route = '/parcel/send.html';

                            this.props.load.route(route, url => {
                                 
                                this.props.history.replace(url);
                                this.props.ui.reload = false;
                            });
                        }

                        else {

                            const route = '/parcel/:id/send.html';
                            const params = this.props.current.params;

                            this.props.load(route, params, url => {

                                this.props.history.replace(url);
                                this.props.ui.reload = false;
                            });
                        }
                    }

                    else {

                        this.props.ui.reload = false;
                    }
                }

                else {

                    this.props.ui.reload = false;
                }

                break;

            // Import

                case '/import/:id/details.html':
                case '/import/:id/measurements.html':

                    if(this.props.current.params.id !== undefined){

                        this.props.import.repopulate(this.props.current.params.id);
                    }

                    this.props.ui.reload = false;

                    break;

            default:

                this.props.ui.reload = false;
            
                break;
        }
    }

    initStorageListener = () => {
        
        localStorage.removeItem('customers_id');
        setInterval(this.storageListener, 1000);
    }

    storageListener = () => {

        const local = localStorage.getItem('customers_id')
        const app = this.props.ui.customers.id;

        if(local !== null && app !== undefined && local !== app){

            window.location.reload(true); // If customers id change from a different window app will reload.
        }
    }

    setDefaultLang = () => {

        const defaultLang = 'en';

        if(this.props.ui.selectedLang === null){

            localStorage.setItem('lang', defaultLang);
            this.props.ui.selectedLang = defaultLang;
        }
    }

    componentDidUpdate(){

        if( ! this.props.auth.isLoggedIn){

            if(this.props.current.route !== '/login/' && this.props.current.route !== '/reset/password.html'){

                // Reload if session expires.

                window.location.reload(true);
            }
        }
    }

    reload = () => {

        this.props.ui.reloadErrors = [];
        window.location.reload(true);
    }

    tryAgain = () => {

        this.props.ui.api.error = null;
        this.props.ui.api.retry();
    }

    closeApiErrors = () => {

        this.props.ui.api.error = null;
    }

    render(){

        return (

            <React.Fragment>

                <Loading isOpen={this.props.ui.isLoading} reload={this.props.ui.reload} />

                <ModalAlerts messages={this.props.ui.messages} ok={this.props.ui.clearMessages} />
                <ModalAlerts messages={this.props.ui.reloadErrors} ok={this.reload} text={this.props.ui.lang.app.tryAgainApi} />

                <ModalConfirm isOpen={this.props.ui.api.error !== null ? true : false} cancel={this.closeApiErrors} ok={this.tryAgain} text={{
                
                    cancel: this.props.ui.lang.app.cancelApi,
                    ok: this.props.ui.lang.app.tryAgainApi

                }}>{this.props.ui.api.error}</ModalConfirm>

                {this.props.ui.customConfirm.isOpen === true &&

                    <ModalConfirm
                    
                        isOpen={this.props.ui.customConfirm.isOpen}
                        cancel={this.props.ui.customConfirm.cancelHandler}
                        ok={this.props.ui.customConfirm.okHandler}
                        text={{
                        
                            cancel: this.props.ui.customConfirm.cancelText,
                            ok: this.props.ui.customConfirm.okText
                        }}
                        
                    >{this.props.ui.customConfirm.message}</ModalConfirm>
                }

                {this.props.ui.reload === false && <Wrapper>{this.props.children}</Wrapper>}

                <Footer />

            </React.Fragment>
        );
    }
}

export default App;
