import ApiStore from 'stores/ApiStore';
import Validate from 'Validate';
import api from 'api.json';

class ConsigneeStore extends ApiStore {

    constructor(stores){

        super(stores);

        this.stores = stores;
        this.form = new Map();

        this.settings = {
        
            url: api.endpoints.user.consignees
        }
    }

    validate = () => {

        const lang = this.stores.ui.lang.consigneeForm;
        const fields =  new Validate(this.form);
        const countryCode = this.stores.countries.codeById(this.form.get('countries_id'));

        fields.set('name1').isFilled(lang.fillName);
        // fields.set('name2').isFilled('');
        fields.set('address1').isFilled(lang.fillAddress);
        // fields.set('address2').isFilled(lang.fillAddress2);
        fields.set('city').isFilled(lang.fillCity);
        fields.set('countries_id').isFilled(lang.fillCountry);
        fields.set('postalnumber').isFilled(lang.invalidPostalnumber);

        if(this.form.get('countries_id') !== undefined){

            const country = this.stores.countries.findCountry('id', this.form.get('countries_id'));

            if(typeof country.zipregex === 'string'){
 
                fields.set('postalnumber').regexp(country.zipregex, this.stores.countries.getZiperrortext(country, lang.invalidPostalnumber));
            }
        }

        if(this.form.get('email') !== undefined && this.form.get('email') !== ''){

            fields.set('email').isEmail(lang.invalidEmail);
        }

        // fields.set('contact').isFilled(lang.fillContact);

        if(this.form.get('tel') !== undefined && this.form.get('tel') !== ''){
        
            fields.set('tel').isTel(lang.invalidTel);
        }

        if(this.form.get('mobile') !== undefined && this.form.get('mobile') !== ''){

            fields.set('mobile').isMobile(lang.invalidMobile);
        }

        if(countryCode !== false && countryCode === 'FI'){

            fields.set('recipienttype').isFilled(lang.is_filled_recipienttype);
        }

        // fields.set('search').isFilled(lang.fillNick);
        // fields.set('businessid').isFilled(lang.fillBusinessID);
        // fields.set('description').isFilled(lang.fillInfo);

        return {fields: fields.result()};
    }

    errorCount = (results) => {

        return Validate.size(results);
    }

    messages = (results) => {

        return Validate.parseMessages(results);
    }

    add = async () => {

        const data = this.convertMap(this.form);

        return await this.post(data, this.settings);
    }

    update = async (id) => {

        const data = this.convertMap(this.form);

        this.settings = {
        
            url: api.endpoints.user.consignees + id
        }

        return await this.put(data, this.settings);
    }

    remove = async id => {

        const data = this.convertMap(this.form);

        this.settings = {
        
            url: api.endpoints.user.consignees + id
        }

        return await this.delete(data, this.settings);
    }

    fetchAll = async () => {

        const settings = {
        
            url: api.endpoints.user.consignees
        };

        return await this.get({}, settings);
    }

    fetchSingle = async params => {

        const settings = {
        
            url: api.endpoints.user.consignees + params.id
        };

        return await this.get({}, settings);
    }

    load = async () => {

        return await this.fetchAll();
    }

    repopulate(id){

        const form = new Map();
        const object = this.data.get(id);

        for(let k in object){

            form.set(k, object[k]);
        }

        this.form = form;
    }
}

export default ConsigneeStore;
