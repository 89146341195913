import React from 'react';
import styled from 'styled-components';
import Select from 'components/gls-dumb/Select';
import theme from 'theme';

const Wrapper = styled.div`

    ${props => `
    
        display: flex;
        align-items: center;

        margin-bottom: ${props.theme.size[1]};
    
        @media (min-width: ${props.theme.breakpoint}){

            margin-bottom: 0;
        }
    `}
`;

const Title = styled.div`

    ${props => `
    
        padding-right: ${props.theme.size[1]};
    `}
`;

class Quicklink extends React.Component {

    onChange = v => {

        this.props.options.forEach(option => {

            if(option.value === v.value && option.callback !== undefined){

                option.callback(v.value);
            }
        });
    }

    render(){

        return (
        
            <Wrapper>
            
                <Title>{this.props.title}:</Title>
                <Select name="quicklink" options={this.props.options} onChange={this.onChange} backgroundColor={theme.color[7]} value={this.props.value} width="13em" />
            
            </Wrapper>
        );
    }
}

export default Quicklink;
