import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`

    ${props => `

        opacity: ${props.isEditable === true ? '1.0' : '0.5'};
    `}
`;

const Relative =  styled.div`

    position: relative;
    user-select: none;
`;

const Cover = styled.div`

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
`;

class Editable extends React.Component {

    render(){

        return (

            <Wrapper isEditable={this.props.isEditable}>

                <Relative>

                    {this.props.isEditable !== true &&

                        <Cover />
                    }

                    {this.props.children}

                </Relative>

            </Wrapper>
        );
    }
}

Editable.defaultProps = {

    isEditable: true
}

export default Editable;
