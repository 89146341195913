import api from 'api.json';
import ApiStore from 'stores/ApiStore';
import {computed} from 'mobx';

class CustomersStore extends ApiStore {

    constructor(stores){

        super(stores);
        this.stores = stores;
    }

    async fetchAll(){

        return await this.get({}, {url: api.endpoints.user.customers});
    }

    async load(){

        return await this.fetchAll();
    }

    @computed get current(){

        const data = this.data.get(this.stores.ui.customers.id);

        if( ! data){

            return false;
        }

        return data;
    }
}

export default CustomersStore;
