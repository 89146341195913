import ApiStore from './ApiStore';
import {observable, action} from 'mobx';
import api from 'api.json';

export default class AttachmentStore extends ApiStore {

    constructor(root) {

        super(root);

        this.root = root;
    }

    @observable uploadProgress = 0;
    @observable uploadFilename = "";

    @action.bound async upload(file, url, cancelToken, metadata) {

        if(!file) {
            return false;
        }

        let data = new FormData();
        data.append("file", file);
        data.append("name", file.name);

        if(metadata !== undefined){

            metadata.forEach((v, k) => {

                if(v !== undefined){
            
                    data.append(k, v);
                }
            });
        }

        this.uploadFilename = file.name;
        this.uploadProgress = 0;

        const res = await this.post(data, {
            url: url,
            cancelToken: cancelToken,
            progress: (e) => {
                this.uploadProgress = parseInt(e.loaded/e.total*100, 10);
            }
        });

        this.uploadProgress = 100;

        return res;
    }

    async file(id) {

        let attachment = this.data.get(id);

        if(attachment === false) {
            return false;
        }

        const res = await this.root.auth.temporary();

        if(this.isResponseFailed(res)) {
            return false;
        }

        if(this.isResponseOk(res)) {
            return this.prepareUrl(api.endpoints.user.files + "/" + attachment.files_id + "?jwt=" + encodeURIComponent(res.data.jwt));
        }

        return false;
    }

}
