import React from 'react';
import PropTypes from 'prop-types';
import Input from 'components/gls-dumb/Input';
import Textarea from 'components/gls-dumb/Textarea';
import Button from 'components/gls-dumb/Button';
import styled from 'styled-components';
import {observer} from 'mobx-react';
import {observable, action} from 'mobx';
import Icon from 'components/gls-dumb/Icon';
import Note from 'components/gls-dumb/Note';
import theme from 'theme';
import Select from 'components/gls-dumb/Select';

const Wrapper = styled.div`

    display: flex;
    flex-wrap: ${props => props.labelPosition === 'above' ? 'wrap' : 'nowrap'};
    align-items: center;
    margin-bottom: ${props => props.marginBottom};
`;

const FirstItem = styled.div`

    display: flex;
    align-items: center;
    width: ${props => props.labelPosition === 'above' ? '100%' : 'auto'};
    ${props => props.labelPosition === 'above' && 'padding-bottom: '+props.theme.size[2]};
`;

const Container = styled.div`

    display: flex;
    flex-grow: 1;
`;

const Label = styled.div`

    white-space: nowrap;
    ${props => props.labelPosition === 'left' && 'padding-right: '+props.theme.size[2]+';'}
    ${props => props.withInfo !== false && 'margin-left: 0.5em;'}
    ${props => props.error !== false && 'color: '+props.theme.color[9]+';'}
`;

@observer class Field extends React.Component {

    @observable date = false;
    @observable showInfo = false;
    @observable withInfo = false;

    constructor(props){

        super(props);

        if(props.info !== null){

            this.withInfo = true;
        }
    }

    @action setDate = () => {

        this.props.datePicker(); // Date picking is handled separetly.
    }

    @action toggleInfo = () => {

        if(this.showInfo === false){

            this.showInfo = true;
        }

        else {

            this.showInfo = false;
        }
    }

    render(){

        let label;
        let input;
        let button;
        let info;

        const value = ! this.date ? this.props.value : this.date;

        let props = {

            name: this.props.name,
            onChange: this.props.onChange,
            backgroundColor: this.props.backgroundColor,
            readOnly: this.props.readOnly,
            onClick: this.props.onClick,
            onFocus: this.props.onFocus,
            maxLength: this.props.maxLength,
            autoComplete: this.props.autoComplete,
            onKeyDown: this.props.onKeyDown,
            disabled: this.props.disabled
        }

        // Text input

        if(this.props.type === 'text' || this.props.type === 'password' || this.props.type === 'calendar'){

            props.value = value;

            if(this.props.type === 'text' || this.props.type === 'password'){
            
                props.type = this.props.type;
            }

            input = <Input {...props} />;
        }

        // Textarea

        else if(this.props.type === 'textarea'){

            props.value = value;
            input = <Textarea {...props} />;
        }

        else if(this.props.type === 'select'){

            props.options = this.props.options;
            props.option = this.props.option;
            props.value = this.props.value;

            input = <Select {...props} />;
        }

        // Calendar button

        if(this.props.type === 'calendar'){

            // Extra div because of some width problem with button (dirty fix)

            button = <div><Button type="calendar" marginLeft="0.5em" onClick={this.setDate} /></div>
        }

        // Label

        if(this.props.label !== null){

            const error = this.props.error === undefined ? false : this.props.error;

            label = <Label labelPosition={this.props.labelPosition} withInfo={this.withInfo} error={error}>{this.props.label}:</Label>;
        }

        // Icon & note

        if(this.props.info !== null){

            info = (

                <div>
                
                    <Icon type="info" onClick={this.toggleInfo} cursor="pointer" color={theme.color[1]} hover={theme.color[3]} size="1.5em"></Icon>
                    <Note show={this.showInfo} close={this.toggleInfo}>{this.props.info}</Note>
               
                </div>
            );
        }

        return (

            <Wrapper labelPosition={this.props.labelPosition} marginBottom={this.props.marginBottom}>

                <FirstItem labelPosition={this.props.labelPosition}>

                    {info}
                    {label}

                </FirstItem>

                <Container>

                    {input}
                    {button}

                </Container>

            </Wrapper>
        );
    }
}

Field.propTypes = {

    /** Type of the input.  */
    type: PropTypes.oneOf(['text', 'password', 'textarea', 'calendar', 'select']).isRequired,

    /** Name attribute of the input. */
    name: PropTypes.string,

    /** Label text before input. */
    label: PropTypes.string,

    /** Choose position of the label relative to input. */
    labelPosition: PropTypes.oneOf(['above', 'left']),

    /** Show info icon. */
    info: PropTypes.string,

    /** Options for select. */
    options: PropTypes.array,

    /** Default option of selection box. */
    selected: PropTypes.string,

    /** onChange Event handler. */
    onChange: PropTypes.func,

    /** Default value of input. */
    value: PropTypes.string,

    /** Background color of input. */
    backgroundColor: PropTypes.string,

    /** Maxium length of input. */ 
    maxLength: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

Field.defaultProps = {

    type: 'text',
    label: null,
    labelPosition: 'above',
    info: null,
    marginBottom: theme.size[1]
}

export default Field;
