import React from 'react';
import Field from 'components/gls-dumb/Field';
import Button from 'components/gls-dumb/Button';
import styled from 'styled-components';
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import theme from 'theme';

const Container = styled.div`

    display: flex;
    align-items: flex-end;

    & > :first-child {

        flex-grow: 1;
        padding-right: ${props => props.paddingRight};
    }
    
    margin-bottom: ${props => props.marginBottom};
`;

const Wrapper = styled.div`

    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.5);
`;

const Content = styled.div`

    ${props => `

        background: ${props.theme.color[7]};
        padding: ${props.theme.size[1]} ${props.theme.size[1]} 0 ${props.theme.size[1]};
        border-radius: ${props.theme.size[1]};
        max-height: 80%;
        overflow-y: auto;
    `}
`;

const Item = styled.tr`

    & > :first-child {

        padding-right: ${props => props.theme.size[1]};
        vertical-align: middle;
    }

    & > td {

        padding-bottom: ${props => props.theme.size[1]};
    }
`;

const Message = styled.div`

    ${props => `

        padding-bottom: ${props.theme.size[1]};
    `}
`;

function Items(props){

    if(props.isOpen === false){

        return null;
    }

    return (
    
        <Wrapper onClick={props.close}>

            <Content>

                {props.items.length !== 0 ?

                    <table>

                        <tbody>
 
                            {props.items.map((v, k) =>

                                <Item key={k}>

                                    <td>{v.value}</td>
                                    <td><Button onClick={() => {props.select(v.value, v.key)}}>Valitse</Button></td>

                                </Item>
                            )}

                        </tbody>

                    </table>

                    :

                    <Message>{props.message}</Message>
                }

            </Content>
        
        </Wrapper>
    );
}

@observer class PopUpList extends React.Component {

    @observable isOpen = false;

    @observable selected = {

        key: undefined,
        value: undefined
    };

    constructor(props){

        super(props);

        this.setDefault();
    }

    componentDidUpdate(prevProps){

        if(prevProps.value !== this.props.value){

            this.setDefault();
        }
    }

    setDefault = () => {

        if(this.props.value === '' | this.props.value === undefined){

            this.selected = {

                key: undefined,
                value: undefined
            }
        }

        else {

            this.props.list.forEach((v, k) => {
     
                if(v.key === this.props.value){

                    this.selected = {

                        key: v.key,
                        value: v.value
                    };
                }
            }); 
        }
    }

    open = () => {

        this.isOpen = true;
    }

    close = (e) => {

        if(e.target === e.currentTarget){

            this.isOpen = false;
        }
    }

    select = (v, k) => {

        this.isOpen = false;

        this.selected = {

            key: k,
            value: v.toString(v)
        };
    }

    change = (v) => {
    
        const selected = {
        
            name: this.props.name,
            value: this.selected.key
        }

        this.props.onChange(selected);
    }

    render(){

        return (
        
            <React.Fragment>

                <Items isOpen={this.isOpen} items={this.props.list} close={this.close} select={this.select} message={this.props.message}></Items>
                
                <Container paddingRight={true ? theme.size[1] : 0} marginBottom={this.props.marginBottom}>

                    <Field
                    
                        name={this.props.name}
                        value={this.selected.value}
                        error={this.props.error}
                        label={this.props.label}
                        info={this.props.info}
                        onChange={this.change}
                        backgroundColor={theme.color[7]}
                        readOnly
                        marginBottom="0"
                        onClick={(true || (this.props.list.length === 0 && this.props.message !== undefined)) ? this.open : undefined}
                    /> 

                    {true && <Button type="user-edit" onClick={this.open} />}

                </Container>

            </React.Fragment>
        );
    }
}

PopUpList.defaultProps = {

    marginBottom: theme.size[1]
}

export default PopUpList;
