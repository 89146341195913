import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import theme from 'theme';

const Container = styled.div`

    border: 1px dashed ${props => props.theme.color[4]};
    padding: ${props => props.theme.size[1]};
    font-weight: bold;
    color: ${props => props.theme.color[1]};
    margin-bottom: ${props => props.marginBottom};
`;

class NoticeBox extends React.Component {

    render(){

        return (
        
            <Container {...this.props}>{this.props.children}</Container>
        );
    }
}

NoticeBox.propTypes = {

    /** CSS */
    marginLeft: PropTypes.string
}

NoticeBox.defaultProps = {

    marginBottom: theme.size[1]
}

export {NoticeBox as default, NoticeBox};
