import React from 'react';
import Header from 'components/smart/Header';
import Title from 'components/gls-dumb/Title';
import Columns from 'components/gls-dumb/Columns';
import Pagebreak from 'components/gls-dumb/Pagebreak';
import List from 'components/gls-dumb/List';
import Checkbox from 'components/gls-dumb/Checkbox';
import Field from 'components/gls-dumb/Field';
import Multifields from 'components/gls-dumb/Multifields';
import theme from 'theme';
import Button from 'components/gls-dumb/Button';
import {inject} from 'mobx-react';
import {bool} from 'Helpers';
import {observable, computed, action} from 'mobx';
import {observer} from 'mobx-react';
import ModalAlerts from 'components/gls-dumb/ModalAlerts';
import {withID} from 'Helpers';
import NoticeBox from 'components/gls-dumb/NoticeBox';
import Section from 'components/gls-dumb/Section';

@inject('parcel')
@inject('senders')
@inject('countries')
@inject('ui')
@inject('settings')
@inject('cncodes')
@inject('customunits')

@observer class ProForma extends React.Component {

    @observable validation = {fields: new Map(), multiFields: []};
    @observable messages = [];
    @observable submitted = false;

    lang = this.props.ui.lang.shipmentProforma;
    sender = this.props.senders.data.get(this.props.parcel.form.get('senders_id'))
    redirectUrl = false;

    constructor(props){

        super(props);
        this.setInvoiceCountry();
        this.setDefaultTerms();
        this.setDefaultCurrency();
        this.setDefaultImportReason();
    }

    setDefaultImportReason = () => {

        if(this.get('invoice_reason') === undefined || this.get('invoice_reason') === ''){

            this.set('invoice_reason', 'Commercial');
        }
    }

    /*

    @computed get units(){

        return [
            
            {value: '', option: '- ' + this.lang.selectUnit},
            {value: 'c/k', option: this.lang.ctm},
            {value: '* 100 pcs', option: this.lang.cen},
            {value: '* 1000 pcs', option: this.lang.mil},
            {value: 'pcs', option: this.lang.nar},
            {value: 'kg', option: this.lang.kgm},
            {value: 'pairs', option: this.lang.npr},
            {value: 'liters', option: this.lang.ltr},
            {value: 'm', option: this.lang.mtr},
            {value: 'm²', option: this.lang.mtk},
            {value: 'm³', option: this.lang.mtq},
            {value: 'g', option: this.lang.grm}
        ];
    }

    */

    @computed get unitOptions(){

        const options = [{value: '', option: this.lang.selectUnit}];

        this.props.customunits.data.forEach(v => {

            let option;
        
            if(this.props.ui.selectedLang === 'fi'){

                option = v.fiunit;
            }

            else if(this.props.ui.selectedLang === 'en'){

                option = v.enunit;
            }

            options.push({value: v.intrastat, option: option});
        });

        return options;
    }

    setDefaultCurrency = () => {

        const current = this.get('customs_currency');

        if(current === undefined){

            const default_currency = this.props.settings.object.default_currency;

            if(default_currency !== undefined && default_currency !== ''){

                this.set('customs_currency', default_currency);
            }

            else {
            
                this.set('customs_currency', 'EUR');
            }
        }
    }

    @computed get importReasons(){

        return [

            {value: '', option: '- ' + this.lang.selectReason},
            {value: 'Commercial', option: this.lang.reasonCommercial},
            {value: 'Gift', option: this.lang.reasonGift},
            {value: 'Sample', option: this.lang.reasonSample},
            {value: 'Replacement or spare part, free of charge', option: this.lang.reasonFree}
        ];
    }

    setInvoiceCountry = () => {

        // Set invoice country to same as consignees country.

        this.set('invoice_countries_id', this.get('consignee_countries_id'));
    }

    setDefaultTerms = () => {

        const current = this.get('deliveryterms')

        if(current === undefined){

            const default_terms = this.props.settings.object.default_terms;

            // If default terms are set in profile settings.

            if(default_terms !== undefined && default_terms !== ''){

                this.set('deliveryterms', default_terms);
            }

            // Else use global default.

            else {

                this.set('deliveryterms', 'INCO20');
            }
        }
    }

    cancel = () => {
        
        if(this.get('attachment') !== true){ 
        
            this.props.parcel.form.delete('customs_documenttype'); // Unset selection if ProForma is not attached when returning back.
        }

        this.props.history.push(withID('/parcel/measurements.html', this.props.match.params.id));
    }

    attach = () => {

        this.submitted = true;
        this.validation = this.props.parcel.validate('ProForma');

        if(this.props.parcel.errorCount(this.validation) !== 0){

            this.messages = this.props.parcel.messages(this.validation);
        }

        else {

            this.props.parcel.form.set('attachment', true);
            this.props.history.push(withID('/parcel/measurements.html', this.props.match.params.id));
        }
    }

    get = (k) => {

        return this.props.parcel.form.get(k);
    }

    set = (k, v) => {

        this.props.parcel.form.set(k, v);
    }

    change = (r) => {

        this.props.parcel.form.set(r.name, r.value);

        if(r.name === 'invoice_contents'){

            this.countTotal();
        }

        if(this.submitted === true){

            this.validation = this.props.parcel.validate('ProForma');
        }
    }

    countTotal = () => {

        const contents = this.get('invoice_contents');

        if(contents !== undefined){

            let total = 0;

            contents.forEach((v, k) => {
           
                let totalPrice = v.get('total_price');
 
                if(totalPrice !== undefined){

                    totalPrice = parseFloat(totalPrice.toString().replace(',', '.'));

                    if( ! isNaN(total)){

                        total = total + totalPrice;
                    }
                }
            });

            if( ! isNaN(total)){

                this.set('customs_value', total.toString());
            }
        }
    }

    clearMessages = () => {

        this.messages = [];
    }

    error = (field) => {

        return this.validation.fields.get(field) === undefined ? false : true;
    }

    fieldProps = (field) => {

        return {
        
            name: field,
            value: this.get(field),
            error: this.error(field),
            onChange: this.change
        };
    }

    checkboxProps = (checkbox) => {

        return {

            name: checkbox,
            checked: bool(this.get(checkbox)),
            onChange: this.change
        };
    }

    @action getUnitByCNcode = cncode => {

        let unit = false;

        if(cncode !== undefined && (cncode.length === 8 || cncode.length === 10)){

            cncode = cncode.substr(0, 8);

            this.props.cncodes.data.forEach(v => {

                if(v.cn === cncode){

                    this.props.customunits.data.forEach(v2 => {

                        if(v.su === v2.intrastat){

                            unit =  v2.intrastat;
                        }
                    });
                }
            });
        }

        return unit;
    }

    setCustomsUnit = row => {

        const cncode = row.get('tariff_name');
        const unit = this.getUnitByCNcode(cncode);

        if(unit === false || unit === 'ZZZ'){

            row.set('unit', '');
        }

        else {

            row.set('unit', unit);
        }
    }

    setSupplementalqtyDisabled = (row, index, overrides) => {

        const cncode = row.get('tariff_name');
        const unit = this.getUnitByCNcode(cncode);

        if(unit === false || unit === 'ZZZ'){

            row.set('supplementalqty', undefined);
            overrides.set(index, {'supplementalqty': {disabled: true}});
        }

        else {

            overrides.set(index, {'supplementalqty': {disabled: false}});
        }
    }

    render(){

        const fieldProps = this.fieldProps;
        const checkboxProps = this.checkboxProps;

        let invoice_contents = this.get('invoice_contents');

        if(invoice_contents !== undefined && invoice_contents.length === 0){

            invoice_contents = undefined;
        }

        return (

            <React.Fragment>

                <ModalAlerts messages={this.messages.slice()} ok={this.clearMessages} />

                <Header />

                <Section color="sub">

                    <Title>ProForma</Title>

                </Section>

                <div className="main">
                    
                    <Columns>
                    
                        <Columns.left>
                        
                            <Pagebreak>{this.lang.sender}</Pagebreak>

                            <List marginBottom={theme.size[1]} pairs={[ 

                                    {title: this.lang.name + ':', value: this.sender.name1},
                                    {title: this.lang.address + ':', value: this.sender.address1},
                                    {title: '', value: this.sender.postalnumber + ' ' + this.sender.city + ', ' + this.props.countries.nameByCode(this.sender.countries_id)},
                                    {title: this.lang.contact + ':', value: this.sender.contact},
                                    {title: this.lang.tel + ':', value: this.sender.mobile},
                                    {title: this.lang.mobile + ':', value: this.sender.tel} 
                            ]} />

                        </Columns.left>

                        <Columns.right>
                        
                            <Pagebreak>{this.lang.consignee}</Pagebreak>

                            <List marginBottom={theme.size[1]} pairs={[

                                {title: this.lang.name + ':', value: this.get('consignee_name1')},
                                {title: this.lang.address + ':', value: this.get('consignee_address1')},
                                {title: '', value: this.get('consignee_postalnumber') + ' '+ this.get('consignee_city') +', ' + this.props.countries.nameByCode(this.get('consignee_countries_id'))},
                                {title: this.lang.contact + ':', value: this.get('consignee_contact')},
                                {title: this.lang.tel + ':', value: this.get('consignee_tel')},
                                {title: this.lang.mobile + ':', value: this.get('consignee_mobile')}
                            ]} />

                        </Columns.right>

                    </Columns>

                    <Pagebreak>{this.lang.invoiceinfo}</Pagebreak>

                    <Columns>

                        <Columns.left>

                            <Checkbox {...checkboxProps('different_invoice_address')} value={true} label={this.lang.differentInvoiceAddress} />

                            {this.get('different_invoice_address') === true &&

                                <React.Fragment>

                                    <Field {...fieldProps('invoice_name')} label={this.lang.invoiceName} description="" maxLength="100" />
                                    <Field {...fieldProps('invoice_name2')} label={this.lang.invoiceName2} description="" maxLength="100" />
                                    <Field {...fieldProps('invoice_address')} label={this.lang.invoiceAddress} description="" maxLength="100" />
                                    <Field {...fieldProps('invoice_address2')} label={this.lang.invoiceAddress2} description="" maxLength="100" />
                                    
                                    <Columns gap="small">

                                        <Columns.left width="25%">
                                        
                                            <Field {...fieldProps('invoice_postalnumber')} label={this.lang.invoicePostalnumber} description="" maxLength="10" />

                                        </Columns.left>

                                        <Columns.right width="75%">
                                        
                                            <Field {...fieldProps('invoice_city')} label={this.lang.invoiceCity} description="" maxLength="50" />
                                        
                                        </Columns.right>

                                    </Columns>

                                    <NoticeBox>{this.lang.invoiceCountry}: {this.props.countries.nameByCode(this.get('invoice_countries_id'))}</NoticeBox>
                                    
                                </React.Fragment>
                            }

                        </Columns.left>

                        <Columns.right>

                            <Columns>

                                <Columns.left>

                                    <Field {...fieldProps('invoice_vatnumber')} label={this.lang.vatNumber} maxLength="20" info={this.lang.invoiceVatnumberHelp} />

                                </Columns.left>

                            </Columns>

                            {this.get('different_invoice_address') === true &&

                                <React.Fragment>

                                    <Field {...fieldProps('invoice_contact')} label={this.lang.contact} maxLength="100" />
                                    <Field {...fieldProps('invoice_tel')} label={this.lang.tel} maxLength="16" />
                                    <Field {...fieldProps('invoice_mobile')} label={this.lang.mobile} maxLength="16" />
                                    <Field {...fieldProps('invoice_email')} label={this.lang.email} maxLength="100" />

                                </React.Fragment>
                            }

                        </Columns.right>

                    </Columns>

                    <Pagebreak>{this.lang.contentInfo}</Pagebreak>

                    <Columns>

                        <Columns.left>

                            <Field type="select" {...fieldProps('invoice_reason')} options={this.importReasons} label={this.lang.exportReason} />

                        </Columns.left>

                        <Columns.right>

                            <Checkbox {...checkboxProps('invoice_value')} value={true} label={this.lang.commercialValue} />

                        </Columns.right>

                    </Columns>

                    <Pagebreak>{this.lang.content}</Pagebreak>

                    <Multifields

                        name="invoice_contents"
                        value={invoice_contents}
                        error={this.error('invoice_contents')}
                        validation={this.validation.multiFields}
                        
                        fields={[

                            {name: 'product_code', label: this.lang.product_code + ':', width: '10em', description: this.lang.product_code_descripition},
                            {name: 'amount', label: this.lang.amount + ':', width: '7em', maxlength: 6, description: this.lang.amount_description},
                            {name: 'tariff_name', label: this.lang.tariffName + ':', width: '9em', maxlength: 10, description: this.lang.tariff_name_description},
                            {name: 'supplementalqty', label: this.lang.supplementalqty + ':', width: '11em', description: this.lang.supplementalqty_description, callback: this.setSupplementalqtyDisabled, disabled: true},
                            {name: 'unit', label: this.lang.unit + ':', description: this.lang.unitHelp, width: '7em', maxlength: 50, options: this.unitOptions, disabled: true, callback: this.setCustomsUnit, default: ''},
                            {name: 'netweight', label: this.lang.netweight + ':', width: '7em', maxlength: 50, description: this.lang.netweight_description},
                            {name: 'description', label: this.lang.description + ':', maxlength: 255, description: this.lang.description_description},
                            {name: 'unit_price', label: this.lang.unitPrice + ':', description: this.lang.unitPriceHelp, width: '10em', maxlength: 7, remainderof: ['amount', 'total_price']},
                            {name: 'total_price', label: this.lang.totalPrice + ':', description: this.lang.totalPriceHelp, width: '7em', maxlength: 7, sumof: ['amount', 'unit_price']},
                            {name: 'countries_id', label: this.lang.countryOfOrigin + ':', description: this.lang.originCountryHelp, width: '7em', options: this.props.countries.optionsWithCode(), default: '658b3f9b-0146-4404-88ed-eafd4c0affac'}
                        ]}

                        breakpoint="1100px"
                        onChange={this.change}
                    />

                    <Pagebreak>{this.lang.clearanceInfo}</Pagebreak>

                    <Columns>

                        <Columns.left>
                    
                            <Field type="select" {...fieldProps('deliveryterms')} label={this.lang.deliveryTerm} options={this.props.parcel.getOptionfields('terms', undefined, this.get('consignee_countries_id'))} />

                        </Columns.left>

                        <Columns.right>

                            <Columns gap="small">

                                <Columns.left>
                               
                                    <Field type="text" {...fieldProps('customs_value')} label={this.lang.customsValue} maxLength="7" readOnly={true} info={this.lang.customsValueHelp} />

                                </Columns.left>

                                <Columns.right>
                                
                                    <Field type="select" {...fieldProps('customs_currency')} label={this.lang.customsCurrency} options={this.props.parcel.getOptionfields('currencies')} info={this.lang.customsCurrencyHelp} />
                                
                                </Columns.right>

                            </Columns> 
                        
                        </Columns.right>

                    </Columns>

                    <Pagebreak>{this.lang.invoiceInfo}</Pagebreak>

                    <Field type="textarea" {...fieldProps('invoice_description')} label={this.lang.invoiceDescription} maxLength="255" info={this.lang.invoiceDescriptionHelp} />

                    <Columns>

                        <Columns.left><Button onClick={this.cancel}>{this.lang.cancel}</Button></Columns.left>
                        <Columns.right><Button onClick={this.attach}>{this.lang.attach}</Button></Columns.right>

                    </Columns>

                </div>

            </React.Fragment>
        );
    }
}

export default ProForma;
