import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types';
import Icon from 'components/gls-dumb/Icon';
import theme from 'theme';

const Wrapper = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    ${props => props.type === 'text' && `

        padding-left: ${props.theme.size[1]};
        padding-right: ${props.theme.size[1]};
    `}

    background: ${props => props.backgroundColor ? props.backgroundColor : props.theme.color[6]};
    color: ${props => props.color ? props.color : props.theme.color[8]};
    border-radius: ${props => props.borderRadius};
    height: ${props => props.theme.inputHeight};
    white-space: nowrap;

    width: ${props => props.type !== 'text' ? props.theme.inputSize : '100%'};

    ${props => props.marginLeft && `margin-left: ${props.marginLeft};`}

    &:hover {

        background: ${props => props.theme.color[10]};
        color: ${props => props.theme.color[8]};
    }

    @media (min-width: ${props => props.theme.breakpoint}){

        width: ${props => props.type !== 'text' ? props.theme.inputSize : props.width};
    }

    margin-bottom: ${props => props.marginBottom};
`;

class Button extends React.Component {

    render(){

        let content = false;

        if(this.props.type === 'text'){

            content = this.props.children;
        }

        else {

            content = <Icon type={this.props.type} size={theme.inputSize[1]} />
        }

        return (
        
            <Wrapper onClick={this.props.onClick} {...this.props}>{content}</Wrapper>
        );
    }
}

Button.propTypes = {

    /** Types of the button. */
    type: PropTypes.oneOf(['text', 'search', 'calendar', 'minus', 'plus', 'user-edit']).isRequired,

    /** CSS(margin-left) */
    marginLeft: PropTypes.string,

    /** Action of the click handler. */
    onClick: PropTypes.func,

    /** Optional breakpoint for mobile view. */
    breakpoint: PropTypes.string,

    /** CSS(background-color) */
    backgroundColor: PropTypes.string,

    /** CSS(border-radius) */
    borderRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    /** CSS(width) */
    width: PropTypes.string
}

Button.defaultProps = {

    type: 'text',
    marginLeft: null,
    breakpoint: theme.breakpoint,
    borderRadius: theme.size[2],
    width: '100%',
    marginBottom: 0
}

export default Button;
