import React from 'react';
import Header from 'components/smart/Header';
import Title from 'components/gls-dumb/Title';
import {Buttons} from 'components/gls-dumb/Buttons';
import ButtonB from 'components/gls-dumb/Button/Alternative';
import {observer, inject} from 'mobx-react';
import theme from 'theme';
import {Sortable} from 'components/gls-dumb/Sortable';
import Pagebreak from 'components/gls-dumb/Pagebreak';
import Section from 'components/gls-dumb/Section';

@inject('senders')
@inject('settings')
@inject('consignees')
@inject('parcel')
@inject('ui')
@inject('adminPrivileges')
@inject('load')

@observer class UserSettings extends React.Component {

    lang = this.props.ui.lang.senders;

    send = sendersID => {

        this.props.ui.backFrom.parcel = '/user/settings.html';

        this.props.load.route('/parcel/send.html', url => {

            this.props.parcel.form.clear();
            this.props.parcel.parcelNumbers = [];
            this.props.parcel.defaultSender = sendersID;
            this.props.history.push(url);
        });
    }

    render(){

        const buttonProps = {
        
            width: 'auto',
            borderRadius: 0,
            backgroundColor: theme.color[1]
        };

        const filteredUsers = Array.from(this.props.adminPrivileges.data.values()).filter(v => {
        
            if(v.email.match(/@gls-finland.com/g) !== null){

                return false;
            }

            return true;
        });

        return (

            <React.Fragment>

                <Header />

                <Section color="sub">

                    <Title> 
                        
                        <div>{this.lang.title}</div>

                        <Buttons width="auto" marginBottom="0">

                            <ButtonB {...buttonProps} onClick={() => {this.props.history.push('/senders/add.html')}}>{this.lang.addSender}</ButtonB> 
                            <ButtonB {...buttonProps} onClick={() => {this.props.history.push('/users/add.html')}}>{this.lang.addPrivilege}</ButtonB>

                        </Buttons>
                    
                    </Title>

                </Section>
       
                <div className="main">

                    <Pagebreak>{this.lang.senders}</Pagebreak>
                
                    <Sortable marginBottom="1.5em">
                        
                        <Sortable.thead>
                        
                            <Sortable.tr>

                                <Sortable.td sortable={true} order="asc">{this.lang.name}:</Sortable.td>
                                <Sortable.td colSpan="2"></Sortable.td>

                            </Sortable.tr>
                        
                        </Sortable.thead>

                        <Sortable.tbody>

                            {this.props.senders.data.size !== 0 ? [...this.props.senders.data].map(v => {
                            
                                const id = v[0];
                                const data = v[1];

                                return (
                                
                                    <Sortable.tr key={id}>
                                    
                                        <Sortable.td>{data.name1}</Sortable.td>

                                        <Sortable.td>

                                            <ButtonB {...buttonProps} onClick={() => this.send(id)}>{this.lang.send}</ButtonB>

                                        </Sortable.td>

                                        <Sortable.td>

                                            <ButtonB {...buttonProps} onClick={() => {this.props.history.push('/senders/'+id+'/edit.html')}}>{this.lang.edit}</ButtonB>

                                        </Sortable.td>

                                    </Sortable.tr>
                                );
                            }):

                                <Sortable.tr>

                                    <Sortable.td colSpan="2">{this.lang.noResults}</Sortable.td>

                                </Sortable.tr> 
                            }

                        </Sortable.tbody>

                    </Sortable>

                    <Pagebreak>{this.lang.users}</Pagebreak>

                    <Sortable>
                        
                        <Sortable.thead>
                        
                            <Sortable.tr>

                                <Sortable.td sortable={true} order="asc">{this.lang.name}:</Sortable.td>
                                <Sortable.td></Sortable.td>

                            </Sortable.tr>
                        
                        </Sortable.thead>

                        <Sortable.tbody>

                            {filteredUsers.length !== 0 ? filteredUsers.map(v => { 

                                return (
                                
                                    <Sortable.tr key={v.id}>
                                    
                                        <Sortable.td>{v.email}</Sortable.td>
                                        <Sortable.td></Sortable.td>

                                    </Sortable.tr>
                                );
                            }):

                                <Sortable.tr>

                                    <Sortable.td colSpan="2">{this.lang.noResults}</Sortable.td>

                                </Sortable.tr> 
                            }

                        </Sortable.tbody>

                    </Sortable>                    

                </div>

            </React.Fragment>
        );
    }
}

export default UserSettings;
