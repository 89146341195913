import React from 'react';
import Header from 'components/smart/Header';
import {Sortable} from 'components/gls-dumb/Sortable';
import {inject, observer} from 'mobx-react';
import {observable} from 'mobx';
import theme from 'theme';
import ButtonB from 'components/gls-dumb/Button/Alternative';
import Title from 'components/gls-dumb/Title';
import Center from 'components/gls-dumb/Center';
import styled from 'styled-components';
import Field from 'components/gls-dumb/Field';
import moment from 'moment';
import DateTimePicker from 'components/gls-dumb/DateTimePicker';
import {arrayFromMap} from 'Helpers';
import constants from 'constants.js'
import Quicklink from 'components/gls-dumb/Quicklink';
import Submit from 'components/gls-dumb/Submit';
import Section from 'components/gls-dumb/Section';

const  breakpoint = '800px'; // Global breakpoint (exception)

const Wrapper = styled.div`

    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;

    & > :first-child {

        width: 100%;
    }

    @media (min-width: ${breakpoint}){

        flex-wrap: nowrap;
    }
`;

const QuickLinks = styled.div`

    ${props => `

        margin-top: ${props.theme.size[1]}

        @media (min-width: ${breakpoint}){
       
            margin-top: 0;
            margin-bottom: ${props.theme.size[1]}
        }
    `}
`;

const Link = styled.a`

    text-decoration: underline;
    cursor: pointer;
`;

const Timespan = styled.div`

    display: flex;
    align-items: center;
    padding: ${props => props.theme.size[1]} 0 ${props => props.theme.size[1]} 0;

    @media (min-width: ${breakpoint}){

        padding: 0;
        margin-bottom: ${props => props.theme.size[1]};
    }
`;

const Separator = styled.div`

    padding-left: ${props => props.theme.size[1]};
    padding-right: ${props => props.theme.size[1]};
`;

const Filters = styled.div`

    display: flex;
    flex-wrap: wrap;

    & > * {

        width: 100%;
    }

    & > :first-child {

        padding-bottom: ${props => props.theme.size[1]};
    }

    @media (min-width: ${breakpoint}){

        flex-wrap: nowrap;
        padding-bottom: 0;
        padding-right: ${props => props.theme.size[1]};

        & > * {

            width: auto;
        }

        & > :first-child {

            padding-bottom: 0;
        }
    }
`;

const Filter = styled.div`

    display: flex;

    ${props => ` 

        & > :first-child {

            padding-right: ${props.theme.size[1]};
        }
        
        @media (min-width: ${breakpoint}){
        
            margin-right: ${props.theme.size[1]}
        }
    `}
`;

@inject('ui')
@inject('import')
@inject('senders')
@inject('consignees')
@inject('countries')
@inject('parcel')
@inject('load')

@observer class ImportParcels extends React.Component {

    lang = this.props.ui.lang.importParcels;
    @observable parcels = new Map(this.props.import.data);
    @observable pickerIsOpen = false;
    @observable pickerField = false;
    @observable form = new Map();

    constructor(props){

        super(props);

        this.fieldProps = {

            backgroundColor: theme.color[7],
            marginBottom: 0,
            labelPosition: 'left',
            onChange: this.change
        }
    }

    change = (v) => {

        this.form.set(v.name, v.value);
    }

    open = id => {

        const params = {id: id};

        this.props.load.route('/import/:id/preview.html', params, url => {
       
            this.props.history.push(url);
        });
    }

    openPicker = (field) => {

        this.pickerField = field;
        this.pickerIsOpen = true;
    }

    closePicker = () => {

        this.pickerField = false;
        this.pickerIsOpen = false;
    }

    donePicker = (timestamp) => {

        if(this.pickerField !== false){

            const date = moment(timestamp, 'X').format('DD.MM.YYYY');
        
            this.form.set(this.pickerField, date);
        }

        this.closePicker();
    }

    get filters(){

        return [

            {value: '', option: this.lang.filters_all},
            {value: 'status', option: this.lang.filters_status},
            {value: 'type', option: this.lang.filters_type}
        ];
    }

    useCurrentDate = () => {

        const current = moment().format('DD.MM.YYYY');

        this.form.set('time_from', current);
        this.form.set('time_to', current);

        this.submit();
    }

    useCurrentMonth = () => {

        this.form.set('time_from', moment().startOf('month').format('DD.MM.YYYY'));
        this.form.set('time_to', moment().endOf('month').format('DD.MM.YYYY'));

        this.submit();
    }

    submit = e => {

        if(e !== undefined){

            e.preventDefault();
        }

        let data = arrayFromMap(this.props.import.data);

        // Filter by search term.

        if(this.form.get('search') !== undefined && this.form.get('search') !== ''){

            let fields = [];

            // Compare against selected field.

            if(this.form.get('filter') !== undefined && this.form.get('filter') !== ''){

                fields = [this.form.get('filter')];
            }

            else {

                // Compare agains all (visible) fields.

                fields = [
                
                    'id',
                    'parcels',
                    'parcels_cnt',
                    'total_weight',
                    'type',
                    'from_name1',
                    'from_countries_id',
                    'to_name1',
                    'to_countries_id',
                    'status'
                ];
            }

            const regexp = new RegExp(this.form.get('search'), 'gi');

            data = data.filter(v => {

                let matches = 0;

                fields.forEach(field => {

                    if(field === 'parcels'){

                        const parcelnumber = this.getParcelnumber(v.parcels);

                        if(parcelnumber.toString().match(regexp) !== null){

                            matches++;
                        }
                    }
                
                    else if(field === 'from_countries_id'){

                        const country = this.props.countries.codeById(v.from_countries_id);

                        if(country !== false){

                            if(country.match(regexp) !== null){

                                matches++;
                            }
                        }
                    }

                     else if(field === 'to_countries_id'){

                        const country = this.props.countries.codeById(v.to_countries_id);

                        if(country !== false){

                            if(country.match(regexp) !== null){

                                matches++;
                            }
                        }
                    }

                    else if(field === 'type'){

                        const type = constants.typeNames[v.type];

                        if(type !== undefined){

                            if(type.match(regexp) !== null){

                                matches++;
                            }
                        }
                    }

                    else {
 
                        if(v[field] !== null && v[field].toString().match(regexp) !== null){

                            matches++;
                        }
                    }
                });

                if(matches === 0){

                    return false;
                }

                return true;
            });
        }

        // Filter by date range

        let from = this.form.get('time_from');
        let to = this.form.get('time_to');

        if(from !== undefined && from !== '' && to !== undefined && to !== ''){

            from = moment(from.trim() + ' 00:00:00', 'DD.MM.YYYY HH:mm:ss').unix();
            to = moment(to.trim() + '23:59:59', 'DD.MM.YYYY HH:mm:ss').unix();

            data = data.filter(v => {

                if(v.created_at <= from || v.created_at >= to){

                    return false;
                }

                return true;
            });
        }

        // Replace old data with new sorted and filtered data.

        this.parcels.clear();

        data.forEach((v, k) => {
       
            this.parcels.set(v.id, v);
        });
    }

    openParcels = () => {

        this.props.ui.resolve(this.props.parcel.fetchAll(), this.openParcels, () => {

            this.props.ui.selectedParcels.clear();
            this.props.history.push('/parcels/');
        });
    }

    getParcelnumber = parcels => {

        if(parcels.length === 0 || parcels[0].parcelnumber === undefined || parcels[0].parcelnumber === 0){

            return '-';
        }

        return parcels[0].parcelnumber;
    }

    translateStatus = status => {

        let translation;

        switch(status){

            case 'DRAFT': translation = this.lang.status_draft; break;
            case 'READY': translation = this.lang.status_ready; break;
            case 'DONE': translation = this.lang.status_done; break;
            case 'CANCELED': translation = this.lang.status_canceled; break;

            default: translation = '-';
        }
        
        return translation;
    }

    render(){

        return (

            <React.Fragment>

                <DateTimePicker isOpen={this.pickerIsOpen} onCancel={this.closePicker} onDone={this.donePicker} />

                <Header />

                <Section color="sub">

                    <Title>

                        <Quicklink title={this.lang.title} value="import" options={[
                       
                            {value: 'export', option: this.lang.quick_export, callback: this.openParcels},
                            {value: 'import', option: this.lang.quick_import}
                        ]} />

                        <Wrapper>

                            <Center>

                                <form onSubmit={this.submit}>

                                    <QuickLinks>
                                    
                                        {this.lang.quicklinks}: <Link onClick={this.useCurrentDate}>{this.lang.today}</Link> | <Link onClick={this.useCurrentMonth}>{this.lang.currentMonth}</Link>
                                        
                                    </QuickLinks>

                                    <Timespan>

                                            <Field
                                                
                                                type="calendar"
                                                name="time_from"
                                                value={this.form.get('time_from')}
                                                label={this.lang.timespan}
                                                {...this.fieldProps}
                                                datePicker={() => this.openPicker('time_from')}
                                            />

                                            <Separator>-</Separator>

                                            <Field
                                            
                                                type="calendar"
                                                name="time_to"
                                                value={this.form.get('time_to')}
                                                {...this.fieldProps}
                                                datePicker={() => this.openPicker('time_to')}
                                            />

                                    </Timespan>

                                    <Filters> 

                                        <Filter>

                                            <Field type="select" name="filter" label={this.lang.searchFromField} {...this.fieldProps} options={this.filters} />
                                            <Field type="text" name="search" label={this.lang.searchterm} {...this.fieldProps} />

                                        </Filter>

                                        <Submit width="auto">{this.lang.search}</Submit>
                                        
                                    </Filters>

                                </form>

                            </Center> 

                        </Wrapper>                
                    
                    </Title>

                </Section>

                <div className="main">
                    
                    <Sortable>

                        <Sortable.thead>

                            <Sortable.tr>

                                <Sortable.td sortable={true} order="desc">{this.lang.date}:</Sortable.td>
                                <Sortable.td sortable={true}>{this.lang.pickup_date}</Sortable.td>
                                <Sortable.td sortable={true}>{this.lang.parcelnumber}:</Sortable.td>
                                <Sortable.td sortable={true}>{this.lang.parcels_cnt}:</Sortable.td>
                                <Sortable.td sortable={true}>{this.lang.parcels_weight}:</Sortable.td>
                                <Sortable.td sortable={true}>{this.lang.shipmenttype}:</Sortable.td>
                                <Sortable.td sortable={true}>{this.lang.from_details}:</Sortable.td>
                                <Sortable.td sortable={true}>{this.lang.to_details}:</Sortable.td>
                                <Sortable.td sortable={true}>{this.lang.status}:</Sortable.td>
                                <Sortable.td></Sortable.td>

                            </Sortable.tr>

                        </Sortable.thead>

                        <Sortable.tbody>

                        {Array.from(this.parcels.values()).length !== 0 ?
                        
                            Array.from(this.parcels.values()).map(v => 
                            
                                <Sortable.tr key={v.id}>

                                    <Sortable.td value={v.created_at}>{moment(v.created_at, 'X').format('DD.MM.YYYY')}</Sortable.td>
                                    <Sortable.td value={v.date}>{moment(v.date, 'X').format('DD.MM.YYYY')}</Sortable.td>
                                    <Sortable.td>{this.getParcelnumber(v.parcels)}</Sortable.td>
                                    <Sortable.td>{v.parcels_cnt}</Sortable.td>
                                    <Sortable.td value={v.total_weight}>{v.total_weight} kg</Sortable.td>
                                    <Sortable.td>{constants.typeNames[v.type]}</Sortable.td>
                                    <Sortable.td>{v.from_name1}, {this.props.countries.codeById(v.from_countries_id)}</Sortable.td>
                                    <Sortable.td>{v.to_name1}, {this.props.countries.codeById(v.to_countries_id)}</Sortable.td>
                                    <Sortable.td>{this.translateStatus(v.status)}</Sortable.td>

                                    <Sortable.td>
                                    
                                        <ButtonB onClick={() => {this.open(v.id)}}>{this.lang.open}</ButtonB>
                                    
                                    </Sortable.td>

                                </Sortable.tr>
                            )

                            :

                            <Sortable.tr>
                            
                                <Sortable.td colSpan="10">{this.lang.no_results}</Sortable.td>

                            </Sortable.tr>
                        }
                        
                        </Sortable.tbody>

                    </Sortable>            

                </div>

            </React.Fragment>
        );
    }
}

export default ImportParcels;
