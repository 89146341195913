import api from 'api.json';
import ApiStore from 'stores/ApiStore';
import {computed} from 'mobx';

class PrivilegesStore extends ApiStore { 

    constructor(stores){

        super(stores);

        this.stores = stores;
    }

    async load(){

        return await this.get({}, {url: api.endpoints.user.privileges});
    }

    // Check if logged in user is admin for currently selected customer.

    @computed get isAdmin(){

        const keys = this.stores.settings.data.keys();
        const user = this.stores.settings.data.get(keys.next().value); // Current user.
        const customersID = user.customers_id; // Current customer role.

        let isAdmin;

        this.stores.privileges.data.forEach((v, k) => {

            if(v.customers_id === customersID){

                isAdmin = v.is_admin;
            }
        });

        return isAdmin;
    }
}

export default PrivilegesStore;
