import React from 'react';
import Title from 'components/gls-dumb/Title';
import Columns from 'components/gls-dumb/Columns';
import Field from 'components/gls-dumb/Field';
import Header from 'components/smart/Header';
import Pagebreak from 'components/gls-dumb/Pagebreak';
import Button from 'components/gls-dumb/Button';
import {inject, observer} from 'mobx-react';
import {observable} from 'mobx';
import ModalAlerts from 'components/gls-dumb/ModalAlerts';
import {bool} from 'Helpers';
import Checkbox from 'components/gls-dumb/Checkbox';
import Section from 'components/gls-dumb/Section';

@inject('settings')
@inject('senders')
@inject('ui')
@inject('pickuppoints')
@inject('parcel')

@observer class ProfileSettings extends React.Component {

    lang = this.props.ui.lang.profile;

    constructor(props){

        super(props);

        this.setOptionfields();
        this.props.settings.repopulate();
    }

    setOptionfields = () => {

        this.defaultView = [

            {value: '/home/', option: this.lang.home},
            {value: '/parcels/', option: this.lang.shipments}
        ];

        this.afterSend = [

            {value: '/home/', option: this.lang.home},
            {value: '/parcels/', option: this.lang.moveToShipments}
        ];

        this.language = [

            {value: 'fi', option: this.lang.fi},
            {value: 'en', option: this.lang.en}
        ];

        this.printFooter = [

            {value: '', option: '- ' + this.lang.choosePrintFooter},
            {value: true, option: this.lang.printFooter},
            {value: false, option: this.lang.noFooter}
        ];

        this.printingType = [

            {value: '', option: '- ' + this.lang.choosePrintingType},
            {value: 'a4', option: this.lang.a4},
            {value: 'a4fit', option: this.lang.a4fit},
            {value: 'a5', option: this.lang.a5},
            {value: 'label', option: this.lang.label}
        ];

        this.senders = [{value: '', option: this.lang.chooseSenderOnSending}];

        this.props.senders.data.forEach((v, k) => {
        
            this.senders.push({value: v.id, option: v.name1});
        });

        this.pickuppoints = [{value: '', option: this.lang.choosePickuppointOnSend}];

        this.props.pickuppoints.data.forEach((v, k) => {
        
            this.pickuppoints.push({value: v.id, option: v.name1});
        });
    }

    @observable validation = {fields: new Map()};
    @observable messages = [];

    submit = () => {       

        this.validation = this.props.settings.validate();

        if(this.props.settings.errorCount(this.validation) !== 0){

            this.messages = this.props.settings.messages(this.validation);
        }

        else {

            const promises = [this.props.settings.save()];

            if(this.get('password') !== undefined && this.get('password') !== ''){

                const user = this.props.settings.data.entries();
                const id = user.next().value[0];

                promises.push(this.props.settings.changePassword(id));
            }

            this.props.ui.resolve(promises, this.submit, () => {

                const lang = this.get('language');

                localStorage.setItem('lang', lang);
                this.props.ui.selectedLang = lang;
            
                this.messages = [this.lang.saved];
            });
        }
    }

    change = (v) => {

        this.props.settings.setField(v.name, v.value);
    }

    get = (k) => {

        return this.props.settings.getField(k);
    }

    clearMessages = () => {

        this.messages = [];
    }

    fieldProps = (field) => {

        return {

            name: field,
            value: this.get(field),
            error: this.validation.fields.get(field) === undefined ? false : true,
            onChange: this.change
        };
    }

    checkboxProps = (checkbox) => {

        return {

            name: checkbox,
            checked: bool(this.get(checkbox)),
            onChange: this.change
        }
    }

    componentDidUpdate = () => {
    
        this.lang = this.props.ui.lang.profile;
    }

    render(){

        const fieldProps = this.fieldProps;
        const checkboxProps = this.checkboxProps;

        return(

            <React.Fragment>

                <ModalAlerts messages={this.messages.slice()} ok={this.clearMessages}></ModalAlerts>

                <Header />

                <Section color="sub">

                    <Title>{this.lang.profileTitle} ({this.props.settings.all.email})</Title>

                </Section>

                <div className="main">  
                    
                    <Columns>

                        <Columns.left>

                            <Field type="select" {...fieldProps('default_view')} label={this.lang.defaultView} options={this.defaultView} info={this.lang.defaultViewHelp} />
                            <Field type="select" {...fieldProps('language')} label={this.lang.language} options={this.language} />
                            <Checkbox {...checkboxProps('fds')} value={true} label={this.lang.FDS} info={this.lang.fdsHelp} />
                        
                        </Columns.left>

                        <Columns.right>
                        
                            <Field type="select" {...fieldProps('after_send')} label={this.lang.afterSend} options={this.afterSend} info={this.lang.afterSendHelp} />
                            <Field type="select" {...fieldProps('default_sender')} label={this.lang.defaultSender} options={this.senders} info={this.lang.defaultSenderHelp} />
                            <Checkbox {...checkboxProps('autoprint')} value={true} label={this.lang.autoprint} />
                        
                        </Columns.right>

                    </Columns>

                    <Columns>

                        <Columns.left>

                            <Field
                            
                                type="select"
                                {...fieldProps('default_pickuppoint')}
                                label={this.lang.defaultPickuppoint}
                                options={this.pickuppoints}
                                info={this.lang.pickuppointHelp}
                            />

                            <Field
                            
                                type="select"
                                {...fieldProps('default_terms')}
                                label={this.lang.defaultTerms}
                                options={this.props.parcel.getOptionfields('terms', '')}
                                info={this.lang.defaultTermsHelp}
                            />

                            <Field

                                type="select"
                                {...fieldProps('default_product')}
                                label={this.lang.defaultShipmentType}
                                options={this.props.parcel.getOptionfields('types', this.lang.selectProductOnSend)}
                                info={this.lang.defaultProductHelp}
                            />
                        
                        </Columns.left>

                        <Columns.right>
                        
                            <Field
                            
                                type="select"
                                {...fieldProps('default_currency')}
                                label={this.lang.defaultCurrency}
                                options={this.props.parcel.getOptionfields('currencies')}
                                info={this.lang.defautlCurrencyHelp}
                            />

                            <Field type="text" {...fieldProps('default_content')} label={this.lang.defaultContent} info={this.lang.defaultContentHelp} />
                            <Checkbox {...checkboxProps('preadvice')} value={true} label={this.lang.preadvice} />
                            <Checkbox {...checkboxProps('is_confirmed')} value={true} label={this.lang.confirm_email_default} />
                        
                        </Columns.right>

                    </Columns>

                    <Pagebreak>{this.lang.printingSettings}</Pagebreak>

                    <Columns>

                        <Columns.left>

                            <Field
                            
                                type="select"
                                {...fieldProps('printing_type')}
                                label={this.lang.printingType}
                                options={this.printingType}
                                info={this.lang.printingTypeHelp}
                            />
                        
                        </Columns.left>

                        <Columns.right>
                        
                            {this.props.settings.getField('printing_type') === 'label' &&
                            
                                <Field
                                
                                    type="select"
                                    {...fieldProps('print_footer')}
                                    label={this.lang.footer}
                                    options={this.printFooter}
                                    info={this.lang.printFooterHelp}
                                />
                            }
                        
                        </Columns.right>

                    </Columns>

                    <Pagebreak>{this.lang.changePassword}</Pagebreak>

                    <Columns>

                        <Columns.left>
                        
                            <Field type="password" {...fieldProps('password')} label={this.lang.newPassword} />
                        
                        </Columns.left>

                        <Columns.right>

                            <Field type="password" {...fieldProps('password_verify')} label={this.lang.confirmPassword} />
                        
                        </Columns.right>

                    </Columns>

                    <Button onClick={this.submit}>{this.lang.save}</Button>
                     
                </div>

            </React.Fragment>
        );
    }
}

export default ProfileSettings;
