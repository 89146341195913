import React from 'react';
import styled from 'styled-components';

const Image = styled.img(props => `

    width: 10em;
    height: 10em;
    padding-bottom: ${props.theme.padding[2]};
`);

const Container = styled.div`

    display: flex;
    flex-wrap: wrap;
    margin: -0.5em;
`;

const Item = styled.div`

    text-align: center;
    color: ${props => props.theme.color[7]};
    flex-basis: 100%;

    @media (min-width: ${props => props.theme.breakpoint}){

        flex-basis: 50%;
        flex-grow: 1;
    }
`;

const Content = styled.div`

        padding-top: ${props => props.theme.padding[7]};
        padding-bottom: ${props => props.theme.padding[7]};
        background: ${props => props.theme.color[1]};
        margin: 0.5em;
        cursor: pointer;
`;

const Text = styled.div(props => `

    font-size: ${props.theme.fontSize[0]};
`);

class Tiles extends React.Component {

    static item = (props) => {

        return (
        
            <Item>
            
                <Content onClick={props.onClick}>

                {props.icon !== undefined ?

                    <div>

                        <Image src={props.icon} />
                        <Text>{props.children}</Text>

                    </div>

                    : <Text>{props.children}</Text>
                }

                </Content>
                
            </Item>
        );
    }

    render(){

        return(
        
            <Container>{this.props.children}</Container>
        );
    }
}

export default Tiles;
