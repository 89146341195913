import React from 'react'
import Component from 'Component';
import Header from 'components/smart/Header';
import Title from 'components/gls-dumb/Title';
import {Buttons} from 'components/gls-dumb/Buttons';
import ButtonB from 'components/gls-dumb/Button/Alternative';
import {inject} from 'mobx-react';
import theme from 'theme';
import styled from 'styled-components';
import Columns from 'components/gls-dumb/Columns';
import List from 'components/gls-dumb/List';
import Pagebreak from 'components/gls-dumb/Pagebreak';
import NoticeBox from 'components/gls-dumb/NoticeBox';
import {Sortable} from 'components/gls-dumb/Sortable';
import api from 'api';
import constants from 'constants.js';
import Section from 'components/gls-dumb/Section';

const Text = styled.div`

    ${props => ` 

        margin-bottom: ${props.theme.size[1]};
        
        @media (min-width: ${props.theme.breakpoint}){

            margin-bottom: 0;
        }
    `}
`;

@inject('ui')
@inject('senders')
@inject('consignees')
@inject('import')
@inject('countries')
@inject('api')
@inject('auth')
@inject('parcel')
@inject('settings')
@inject('pickuppoints')
@inject('load')

class ImportPreview extends Component {

    lang = this.props.ui.lang.importPreview;
    store = this.props.import;

    constructor(props){

        super(props);

        this.repopulate();
    }

    repopulate = () => {

        this.props.import.form.clear();
        this.props.import.repopulate(this.props.match.params.id);
    }

    confirmRemove = () => {

        this.props.ui.customConfirm = {

            isOpen: true,
            message: this.lang.confirmRemove,
            cancelHandler: () => this.props.ui.clearCustomConfirm(),
            okHandler: () => this.remove(),
            cancelText: this.lang.cancel,
            okText: this.lang.ok
        };
    }

    remove = () => {

        this.props.ui.clearCustomConfirm();

        this.props.ui.resolve(this.props.import.remove(this.props.match.params.id), this.remove, () => {

            this.props.ui.resolve(this.props.import.fetchAll(), this.props.import.fetchAll, () => {

                this.props.history.push('/imports/');
            });
        });
    }

    open = () => {

        const route = '/import/:id/details.html';
        const params = this.props.match.params;

        this.props.load.route(route, params, url => {
        
            this.props.history.push(url);
        });
    }

    replace = v => {

        if(v === ''){

            return '-';
        }

        return v;
    }

    downloadInvoice = id => {

        const tab = window.open();

        this.props.ui.resolve(this.props.auth.temporary(), () => this.downloadInvoice(id), r => {

            tab.location.replace(this.props.api.prepareUrl(api.endpoints.user.files + id + '?jwt=' + encodeURIComponent(r.data.jwt)));
        });
    }

    prepareParcelnumber = parcelnumber => {

        if(parcelnumber === 0){

            return '-';
        }

        return parcelnumber;
    }

    tracking = () => {

        const lang = this.props.ui.selectedLang;
        const parcelnumbers = [];

        this.get('parcels').forEach((v, k) => {

            parcelnumbers.push(v.get('parcelnumber'));
        });

        let match = '';

        parcelnumbers.forEach((v, k) => {

            if(k > 0){

                match += '%2c ';
            }

            match += v;
        });

         let url;

        if(lang === 'fi'){

            url = 'https://gls-group.eu/FI/fi/laehetysseuranta?match=' + match; 
        }

        else if(lang === 'en'){

            url = 'https://gls-group.eu/FI/en/parcel-tracking?match=' + match;
        }

        window.open(url);
    }

    back = () => {

        const promises = [

            this.props.import.fetchAll(),
            this.props.senders.fetchAll()
        ];

        this.props.ui.resolve(promises, this.back, () => {

            this.props.history.push('/imports/');
        });
    }
 
    copy = () => {

        this.props.import.loadImportDetails(() => {

            const parcel = this.props.import.data.get(this.props.match.params.id);
            this.props.import.copyImportParcel = parcel; 
            this.props.history.push('/import/details.html');
        });
    }

    copyToExport = () => {

        this.props.parcel.importCopy = this.props.import.form;

        this.props.ui.backFrom.parcel = '/home/';

        const promises = [
        
            this.props.senders.fetchAll(),
            this.props.consignees.fetchAll(),
            this.props.settings.load(),
            this.props.pickuppoints.load()
        ];

        this.props.ui.resolve(promises, this.openParcelSend, () => {

            this.props.parcel.form.clear();
            this.props.parcel.parcelNumbers = [];
            this.props.history.push('/parcel/send.html');
        });
    }

    print = () => {

        const parcel = this.props.import.data.get(this.props.match.params.id);
        const tab = window.open();

        this.props.ui.resolve(this.props.auth.temporary(), () => this.print, r => {

            tab.location.replace(this.props.api.prepareUrl(api.endpoints.user.files + parcel.shopreturn_files_id + '?jwt=' + encodeURIComponent(r.data.jwt)));
        });
    }

    render(){

        return (

            <React.Fragment>

                <Header />

                <Section color="sub">

                    <Title>
                    
                        <Text>{this.lang.title}</Text>

                        <Buttons width="auto" marginBottom="0">
                        
                            <ButtonB onClick={this.back}>{this.lang.back}</ButtonB>

                            {this.get('status') === 'DRAFT' &&

                                <React.Fragment>
                            
                                    <ButtonB onClick={this.open}>{this.lang.edit}</ButtonB>
                                    <ButtonB onClick={this.confirmRemove}>{this.lang.remove}</ButtonB>

                                </React.Fragment>
                            }

                            {this.get('status') === 'DONE' && <ButtonB onClick={this.tracking}>{this.lang.tracking}</ButtonB>}
                            {this.get('status') === 'READY' || this.get('status') === 'DONE' && <ButtonB onClick={this.copy}>{this.lang.copy}</ButtonB>}

                            <ButtonB onClick={this.copyToExport}>{this.lang.copy_to_export}</ButtonB>

                            {this.get('service') === 'SHOP' && this.get('status') === 'DONE' && <ButtonB onClick={this.print}>{this.lang.print}</ButtonB>}
                        
                        </Buttons>

                    </Title>

                </Section>
       
                <div className="main">

                    <Columns>

                        <Columns.left>

                            <NoticeBox>{this.lang.creator}: {this.get('email')}</NoticeBox>

                        </Columns.left>

                        <Columns.right>

                            <NoticeBox>

                                <React.Fragment>{this.lang.service}: </React.Fragment>
                                {this.get('service') === 'SHIP' && 'Pick&ShipService'}
                                {this.get('service') === 'RETURN' && 'Pick&ReturnService'}
                                {this.get('service') === 'SHOP' && 'ShopReturnService'}

                            </NoticeBox>

                        </Columns.right>

                    </Columns>

                    <Columns marginBottom={theme.size[1]}>

                        <Columns.left>

                            <Pagebreak>{this.lang.from_title}</Pagebreak>

                            <List pairs={[
                            
                                {title: this.lang.from_name1, value: this.get('from_name1')},
                                {title: this.lang.from_name2, value: this.get('from_name2')},
                                {title: this.lang.from_address1, value: this.get('from_address1')},
                                {title: this.lang.from_address2, value: this.get('from_address2')},
                                {title: this.lang.from_postalnumber, value: this.get('from_postalnumber')},
                                {title: this.lang.from_city, value: this.get('from_city')},
                                {title: this.lang.from_countries_id, value: this.props.countries.nameByCode(this.get('from_countries_id'))},
                                {title: this.lang.from_contact, value: this.get('from_contact')},
                                {title: this.lang.from_tel, value: this.get('from_tel')},
                                {title: this.lang.from_mobile, value: this.get('from_mobile')},
                                {title: this.lang.from_email, value: this.get('from_email')}

                            ]} marginBottom={theme.size[1]} />

                            <NoticeBox>
                            
                                {this.lang.preadvice}: {this.get('from_preadvice') === false ? this.lang.no : this.lang.yes + ' (' + this.get('from_preadvice_email') +')'}
                                
                            </NoticeBox>
                        
                        </Columns.left>

                        <Columns.right>

                            <Pagebreak>{this.lang.to_title}</Pagebreak>

                            <List pairs={[
                            
                                {title: this.lang.to_name1, value: this.get('to_name1')},
                                {title: this.lang.to_name2, value: this.get('to_name2')},
                                {title: this.lang.to_address1, value: this.get('to_address1')},
                                {title: this.lang.to_address2, value: this.get('to_address2')},
                                {title: this.lang.to_postalnumber, value: this.get('to_postalnumber')},
                                {title: this.lang.to_city, value: this.get('to_city')},
                                {title: this.lang.to_countries_id, value: this.props.countries.nameByCode(this.get('to_countries_id'))},
                                {title: this.lang.to_contact, value: this.get('to_contact')},
                                {title: this.lang.to_tel, value: this.get('to_tel')},
                                {title: this.lang.to_mobile, value: this.get('to_mobile')},
                                {title: this.lang.to_email, value: this.get('to_email')}

                            ]} />
                        
                        </Columns.right>
                    
                    </Columns>

                    <Pagebreak>{this.lang.shipment_title}</Pagebreak>

                    <Columns marginBottom={theme.size[1]}>

                        <Columns.left>
                            
                            <List pairs={[
                            
                                {title: this.lang.shipmenttype, value: constants.typeNames[this.get('shipmenttype')]},
                                {title: this.lang.ref, value: this.get('reference')},
                                {title: this.lang.description, value: this.get('info')}

                            ]} />
                        
                        </Columns.left>

                        <Columns.right>
                        
                            <List pairs={[
                            
                                {title: this.lang.contents, value: this.get('contents')},
                                {title: this.lang.date, value: this.get('date')},
                                {title: this.lang.amount_and_weight, value:
                                
                                    this.get('parcels_cnt') +
                                    ' ' +
                                    this.lang.cnt_unit +
                                    ' (' +
                                    this.get('total_weight') +
                                    ' ' +
                                    this.lang.weight_unit +
                                    ')'
                                }

                            ]} />
                        
                        </Columns.right>

                    </Columns>

                    <NoticeBox>AddOnLiabilityService: {this.get('addoninsurance') === false ? this.lang.no : this.lang.yes}</NoticeBox>

                    {(this.get('parcels') !== undefined && this.get('parcels') !== '' && this.get('parcels').length !== 0) &&

                        <React.Fragment>

                            <Pagebreak>{this.lang.parcels_title}</Pagebreak>

                            <Sortable>

                                <Sortable.thead>

                                     <Sortable.tr>

                                        <Sortable.td>{this.lang.parcelnumber}:</Sortable.td>
                                        <Sortable.td>{this.lang.amount}:</Sortable.td>
                                        <Sortable.td>{this.lang.content}:</Sortable.td>
                                        <Sortable.td>{this.lang.weight}:</Sortable.td>
                                        <Sortable.td>{this.lang.length}:</Sortable.td>
                                        <Sortable.td>{this.lang.width}:</Sortable.td>
                                        <Sortable.td>{this.lang.height}</Sortable.td>

                                    </Sortable.tr>
                                
                                </Sortable.thead>

                                <Sortable.tbody>
                            
                                    {this.get('parcels').map((v, k) => {
     
                                        return (

                                            <Sortable.tr key={k}>

                                                <Sortable.td>{this.prepareParcelnumber(v.get('parcelnumber'))}</Sortable.td>
                                                <Sortable.td>1</Sortable.td>
                                                <Sortable.td>{this.replace(v.get('content'))}</Sortable.td>
                                                <Sortable.td>{this.replace(v.get('weight'))}</Sortable.td>
                                                <Sortable.td>{this.replace(v.get('length'))}</Sortable.td>
                                                <Sortable.td>{this.replace(v.get('width'))}</Sortable.td>
                                                <Sortable.td>{this.replace(v.get('height'))}</Sortable.td>

                                            </Sortable.tr>
                                        ); 
                                    })}

                                </Sortable.tbody>

                            </Sortable>

                        </React.Fragment>
                    }

                    {(this.get('shipmenttype') === constants.typeCodes.euroBusinessFreight.toString() && this.get('ontoploading') !== undefined) &&

                        <React.Fragment>
                    
                            <Pagebreak>{this.lang.ontoploading}</Pagebreak> 

                             <Columns gap="small">

                                <Columns.left width="25%">

                                    <NoticeBox>{this.get('ontoploading') === false ? this.lang.denied : this.lang.allowed}</NoticeBox>

                                </Columns.left>

                                <Columns.right width="75%">
                                
                                    <NoticeBox>{this.get('ontoploading') === false ? this.lang.charge_basis_denied : this.lang.charge_basis_allowed}</NoticeBox>

                                </Columns.right>

                            </Columns>

                        </React.Fragment>
                    }

                    {this.props.import.requiresTariff === true &&

                        <React.Fragment>
                    
                            <Pagebreak>{this.lang.tariff_title}</Pagebreak>

                            {this.get('customs_documenttype') === 'FILE' &&
                            
                                <NoticeBox>
                                
                                    <ButtonB onClick={() => this.downloadInvoice(this.get('invoice_files_id'))}>{this.lang.download_invoice}</ButtonB>
                                    
                                </NoticeBox>
                            }

                            {this.get('customs_documenttype') === 'LATER' &&
                            
                                <NoticeBox>{this.lang.documentLater}</NoticeBox>
                            }

                            {(this.get('customs_documenttype') === 'FILE' || this.get('customs_documenttype') === 'LATER') &&

                                <Columns>

                                    <Columns.left>
                            
                                        <List pairs={[{title: this.lang.terms, value: this.get('deliveryterms')}]} />

                                    </Columns.left>

                                    <Columns.right>

                                        <List pairs={[{title: this.lang.value_and_currency, value: this.get('customs_value') + ' ' + this.get('customs_currency')}]} />

                                    </Columns.right>

                                </Columns>
                            }

                        </React.Fragment>
                    }
            
                </div>

            </React.Fragment>
        );
    }
}

export default ImportPreview;
