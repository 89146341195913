import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {observer} from 'mobx-react';
import {observable, action} from 'mobx';
import {bool} from 'Helpers';
import Icon from 'components/gls-dumb/Icon';
import Note from 'components/gls-dumb/Note';
import theme from 'theme';

const Group = styled.div`

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`;

const Item = styled.div`

    ${props => `

        display: flex;
        align-items: center;
        margin-bottom: ${props.marginBottom};
        ${props.width !== undefined && `width: ${props.width};`}
    `}
`;

const OuterCircle = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;
    width: ${props => props.theme.inputSize};
    height: ${props => props.theme.inputSize};
    border-radius: ${props => props.theme.radius[2]};
    background: ${props => props.theme.color[7]};
    border: 2px solid ${props => props.theme.color[11]};
    cursor: pointer;
    margin-right: 0.5em;
`;

const InnerCircle = styled.div`

    width: ${props => props.theme.size[1]};
    height: ${props => props.theme.size[1]};
    border-radius: ${props => props.theme.size[2]};
    background: ${props => props.selected ? props.theme.color[1] : props.theme.color[4]};
`;

const Label = styled.div`

    ${props => props.error === true && `color: ${props.theme.color[9]};`}
`;

const Info = styled.div`

    ${props => `
    
        margin-left:  ${props.theme.size[1]};
    `};
`;

/** Select one item from collection of inputs. */

@observer class Radio extends React.Component {

    @observable items = new Map();
    @observable showInfo = new Map();

    constructor(props){

        super(props);

        this.previousDefault = props.value;

        React.Children.map(props.children, (v, i) => {

            // If item is behind statement child could be null.

            if(v !== null){
        
                this.items.set(i, {
                
                    selected: v.props.value === this.props.value ? true : false,
                    value: v.props.value
                });

                this.showInfo.set(i, false);
            }
        });
    }

    componentDidUpdate(){

        // If default value prop is mutated after mounting set new default

        if(this.previousDefault !== this.props.value){

            React.Children.map(this.props.children, (v, i) => {

                // If item is behind statement child could be null and therefore it is not set as item.

                if(this.items.get(i) !== undefined){
            
                    this.items.set(i, {
                    
                        selected: v.props.value === this.props.value ? true : false,
                        value: v.props.value
                    });
                }
            });

            this.previousDefault = this.props.value;
        }
    }

    @action toggle = (index) => {

        this.items.forEach((v, k) => {

            const item = this.items.get(k);

            this.items.set(k, {
            
                selected: false,
                value: item.value
            });
        });

        const item = this.items.get(index);

        this.items.set(index, {
        
            selected: true,
            value: item.value
        });

        if(this.props.onChange !== undefined){

            this.props.onChange({name: this.props.name, value: item.value});
        }
    }

    @action toggleInfo = i => {

        if(this.showInfo.get(i) === false){

            this.showInfo.set(i, true);
        }

        else {

            this.showInfo.set(i, false);
        }
    }

    static item = (props) => {

        const itemMarginBottom = props.marginBottom === undefined ? theme.size[1] : props.marginBottom;

        return (
        
            <Item marginBottom={itemMarginBottom} width={props.width}>

                <OuterCircle onClick={props.toggle}>
                
                    <InnerCircle selected={props.selected}></InnerCircle>

                </OuterCircle>

                <Label error={bool(props.error)}>
                
                    {props.image !== undefined ? <img src={props.image} alt="" /> : <React.Fragment>{props.label}</React.Fragment>} 
                
                </Label>

                {props.info !== undefined &&
                
                    <Info>

                        <Icon type="info" onClick={props.toggleInfo} cursor="pointer" color={theme.color[1]} hover={theme.color[3]} size={theme.fontSize[0]}></Icon>
                        <Note show={props.showInfo} close={props.toggleInfo}>{props.info}</Note>
                
                    </Info>
                }

            </Item>
        );
    }

    render(){

        const updatedChildren = React.Children.map(this.props.children, (child, index) => {

            const item = this.items.get(index);

            // If item is behind statement child could be null and therefore it is not set as item.

            if(item !== undefined){
       
                return React.cloneElement(child, {

                    toggle: () => this.toggle(index),
                    selected: item.selected,
                    error: this.props.error,
                    showInfo: this.showInfo.get(index),
                    toggleInfo: () => this.toggleInfo(index)
                });
            }
        });
 
        return (

            <div>

                <Group>{updatedChildren}</Group>

            </div>
        );
    }
}

Radio.propTypes = {

    /** Name of the radio input  */
    name: PropTypes.string.isRequired,

    /** Default value of the radio item */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
}

export default Radio;
