import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div(props => `
   
        color: ${props.theme.color[7]};
        padding: ${props.theme.padding[4]} 0 ${props.theme.padding[4]} 0;
        text-align: center;
`);

class Footer extends React.Component {

    render(){

        return (
            
            <Wrapper>&copy; General Logistics Systems Finland Oy v250212A</Wrapper>
        );
    }
}

export default Footer;
