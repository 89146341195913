import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import SmallArrow from 'images/dropdown-small-arrow.png';
import BigArrow from 'images/dropdown-big-arrow.png';
import theme from 'theme';

const Multiple = styled.select`

    ${props => `

        height: ${props.theme.inputHeight};
        width: ${props.width};
        background-image: url(${SmallArrow});
        background-position: 97% center;
        background-repeat: no-repeat;
        background-color: ${props.backgroundColor};
        padding: 0 ${props.theme.size[2]} 0 ${props.theme.size[2]};

        @media (min-width: ${props.theme.breakpoint}){

            background-image: url(${BigArrow});
        }

        ${props.disabled === true && `background-color: ${props.theme.color[5]} !important;`}
    `}
`;

class Select extends React.Component {

    change = (e) => {

        if(this.props.onChange !== undefined){
        
            this.props.onChange({
            
                name: e.target.name,
                value: e.target.value
            });
        }
    }

    render(){

        if(this.props.options === undefined){

            return null;
        }

        else {

            if( ! Array.isArray(this.props.options)){

                return null;
            }

            return (
            
                <Multiple name={this.props.name} width={this.props.width} onChange={this.change} value={this.props.value} backgroundColor={this.props.backgroundColor} disabled={this.props.disabled}>
                
                {this.props.options.map((v, k) => {
                
                    v.value = v.value.toString();
                     
                    return (
                    
                        <option key={k} value={v.value}>{v.option}</option>
                    );

                })}</Multiple>
            );
        }
    }
}

Select.defaultProps = {

    width: '100%',
    backgroundColor: theme.color[7]
}

Select.propTypes = {

    /** Name of input */
    name: PropTypes.string,

    /** Default option */
    value: PropTypes.string,

    /** Options for select */
    options: PropTypes.array,

    /** Event handler */
    onChange: PropTypes.func
}

export default Select;
