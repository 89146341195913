import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`

    display: flex;
    flex-wrap: wrap;
    margin-bottom: ${props => props.marginBottom};
`;

const Item = styled.div`

    width: auto;
    padding-right: ${props => props.theme.size[1]};

    @media (min-width: 1000px){

        width: ${props => props.width};
    }
`;

class Grid extends React.Component {

    static item = (props) => {

        return (
        
            <Item width={props.width}>{props.children}</Item>
        );
    }

    render(){

        const total = this.props.children.length;
        const width = parseInt(this.props.width.substr(0, this.props.width.length - 1), 10);
        const row = Math.round(100 / width);
        const rows = Math.ceil(total / row);
        const lastRow = rows * row -row;
        const extra = total - lastRow;

        const children = React.Children.map(this.props.children, (child, index) => {

            let width = this.props.width;

            if(index + 1 > lastRow && lastRow + row !== total){
                
                width = (100 / extra).toString() + '%';
            }

            const props = {
            
                width: width
            };

            return React.cloneElement(child, props);
        });

        return (

            <Wrapper marginBottom={this.props.marginBottom}>{children}</Wrapper>
        );
    }
}

Grid.defaultProps = {

    width: '25%',
    marginBottom: 0
}

export default Grid;
