import React from 'react';
import {inject, observer} from 'mobx-react';
import {observable} from 'mobx';
import moment from 'moment';
import LightTable from 'components/gls-dumb/LightTable';

@inject('parcel', 'ui', 'countries') @observer class TableStressTest extends React.Component {

    @observable test = 0;

    componentDidMount(){

        this.fetchParcels();
    }

    fetchParcels = () => {

        this.props.ui.hideLoading = true;

        const background = [];

        for(let i = 1; i <= 6; i++){

            this.props.ui.backgroundLoading++;

            const year = moment().subtract(i, 'months').format('YYYY');
            const month = moment().subtract(i, 'months').format('M');

            background.push(this.props.parcel.fetchByMonth(year, month).then(() => {

                this.test++;

                this.props.ui.backgroundLoading--;
 
                if(this.props.ui.backgroundLoading === 0){
                
                    this.props.ui.hideLoading = false;
                }
            }));
        }
    }

    render(){

        return (

            <div className="main">

                <LightTable headers={{

                    id: 'ID',
                    consignee_countries_id: 'Country',
                    date: 'Date',
                    consignee_city: 'Consignee city'
                }}

                data={this.props.parcel.data} benchmark={true} test={this.test} pagination={20} defaultOrder="consignee_city" select={v => {console.log('Selected: ', v);}} lang={{open: 'Avaa'}} countries={this.props.countries.data} />

            </div>
        );
    }
}

export default TableStressTest;
