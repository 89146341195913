import React from 'react';
import styled from 'styled-components';
import Icon from 'components/gls-dumb/Icon';
import theme from 'theme';

const Wrapper = styled.div`

    background: ${props => props.theme.color[4]};
    margin-bottom: ${props => props.marginBottom};
    
    & > :nth-child(even){

        background: ${props => props.theme.color[5]};
    }
`;

const Container = styled.div`

    display: flex;
    justify-content: space-between;
    padding: ${props => props.theme.size[1]};
    align-items: center;
`;

const Right = styled.div`

    display: flex;
    align-items: center;
`;

class List extends React.Component {

    placeholder = v => {

        if(v === null || v === ''){

            return '-';
        }

        return v;
    }

    render(){

        return (
        
            <Wrapper marginBottom={this.props.marginBottom}>
            
                {this.props.pairs.map((v, k) => {

                    return (

                        <Container key={k}>
                    
                            <div>{v.title}</div> 
                            <Right>
                            
                                {this.placeholder(v.value)}
                                {v.icon !== undefined && <Icon type={v.icon.type} color={theme.color[1]} onClick={v.icon.handler} cursor="pointer" hover={theme.color[3]} style={{marginLeft: theme.size[2]}} />}
                                
                            </Right>

                        </Container>
                    );
                })}

            </Wrapper>
        );
    }

    static defaultProps = {

        marginBottom: 0
    }
}

export default List;
