import React from 'react';
import styled from 'styled-components';

const Button = styled.button`

    ${props => `

        width: 100%;
        height: ${props.theme.inputHeight};
        display: flex;
        align-items: center;
        justify-content: center;
        background: ${props.theme.color[6]};
        color: ${props.theme.color[1]};
        border-radius: ${props.theme.size[2]};

        &:hover {

            background: ${props.theme.color[10]}
        }
    `}
`;

class Submit extends React.Component {

    render(){

        return (

            <Button type="submit">{this.props.children}</Button>
        );
    }
}

export default Submit;
