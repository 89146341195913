import React from 'react';
import Title from 'components/gls-dumb/Title';
import Content from 'components/gls-dumb/Content';
import Field from 'components/gls-dumb/Field';
import Button from 'components/gls-dumb/Button';
import {inject, observer} from 'mobx-react';
import {observable} from 'mobx';
import ModalAlert from 'components/gls-dumb/ModalAlert';
import Center from 'components/gls-dumb/Center';
import {Link} from 'react-router-dom';
import Window from 'components/gls-dumb/Window';
import Checkbox from 'components/gls-dumb/Checkbox';

@inject('auth')
@inject('customers')
@inject('settings')
@inject('ui')
@inject('privileges')
@inject('announcements')
@inject('countries')
@inject('parcel')
@inject('load')
@inject('views')

@observer class Login extends React.Component {

    lang = this.props.ui.lang.login;

    @observable data = new Map();
    @observable alertIsOpen = false;
    
    @observable isOpen = {

        confirmWindow: false
    }

    confirmWindow = false; // Show confirm window

    login = () => {

        const user = this.data.get('user');
        const pass = this.data.get('pass');

        this.props.ui.login = true;

        this.props.auth.login(user, pass).then((r) => {
       
            if(r.code !== 200){

                this.props.ui.login = false;
                this.alertIsOpen = true; 
            }

            else {

                this.reloadRoutes();

                const dependencies = [
                
                    this.props.settings.load()
                ];

                this.props.ui.loadApp(dependencies).then(() => {

                    this.setLang(this.props.settings.object.language); // Set lang to user specifig.

                    const url = this.props.settings.object.default_view;
                    const promises = [];

                    if(url === '/parcels/'){

                        promises.push(this.props.parcel.load());
                    }

                    this.props.ui.resolve(promises, this.login, () => {

                        this.props.ui.login = false;

                        if(this.confirmWindow === true){

                            this.isOpen.confirmWindow = true;
                        }

                        else{

                            this.props.history.push(this.props.settings.object.default_view);
                        }
                    });
                });
            }
        });
    }

    reloadRoutes = () => {

        this.props.views.setRoutes();
    }

    setLang = lang => {

        localStorage.setItem('lang', lang);
        this.props.ui.selectedLang = lang;
    }

    change = (v) => {

        if(v.code === 13){

            this.login();
        }

        else {

            this.data.set(v.name, v.value);
        }
    }

    close = () => {

        this.alertIsOpen = false;
    }

    @observable acceptForm = new Map();

    continue = () => {

        if(this.acceptForm.get('accept') === true){

            this.props.history.push(this.props.settings.object.default_view);
        }
    }

    changeAccept = v => {

        this.acceptForm.set(v.name, v.value);
    }

    render(){

        return (
        
            <div className="main">

                <Window isOpen={this.isOpen.confirmWindow}>

                    <p>{this.lang.accept_terms}</p><br />

                    <Center>

                        <Checkbox name="accept" value={true} label={this.lang.accept} onChange={this.changeAccept} />

                    </Center>

                    <Button onClick={this.continue}>{this.lang.continue}</Button>

                </Window>

                <ModalAlert isOpen={this.alertIsOpen} ok={this.close}>{this.lang.failed}</ModalAlert>

                <Title>{this.lang.title}</Title>
            
                <Content>
                
                    <Field name="user" value={this.user} label={this.lang.user} onChange={this.change} autoComplete="on" />
                    <Field type="password" name="pass" value={this.pass} label={this.lang.pass} onChange={this.change} autoComplete="on" />

                    <Button onClick={this.login}>{this.lang.login}</Button><br />

                    <Center><Link to="/reset/password.html">{this.lang.forgot}</Link></Center>
                
                </Content>
            
            </div>
        );
    }
}

export default Login;
