import React from 'react';
import Input from 'components/gls-dumb/Input';
import Button from 'components/gls-dumb/Button';
import Table from 'components/gls-dumb/Table';
import {observer} from 'mobx-react';
import {observable, action} from 'mobx';
import PropTypes from 'prop-types';
import Icon from 'components/gls-dumb/Icon';
import Note from 'components/gls-dumb/Note';
import styled from 'styled-components';
import Select from 'components/gls-dumb/Select';
import theme from 'theme';

const Wrapper = styled.div`

    float: left;
    display: flex;
    height: 2em;
    align-items: center;

    & :first-child {

        margin-right: 0.5rem;
    }
`;

const Block = styled.div`

    float: left;
    display: flex;
    height: 2em;
    align-items: center;
`;

const Label = styled.div`

    ${props => props.color !== undefined && `color: ${props.color};`}
`;

@observer class MultiFields extends React.Component {

    @observable rows = [];
    @observable isOpen = [];

    overrides = new Map();
    editingField = null;

    constructor(props){

        super(props);
       
        this.props.fields.forEach((v, k) => {
        
            this.isOpen[k] = false;
        });

        // Repopulate

        if(this.props.value !== undefined){

            this.rows = [];

            this.props.value.forEach((v, k) => {
            
                this.rows.push(v);
            });
        }

        // Defaults

        else {

            const row = this.newRow();

            this.rows.push(row);
        }
    }

    newRow = () => {

        const row = new Map();

        this.props.fields.forEach(v => {

            if(v.default === undefined){

                row.set(v.name, '');
            }

            else {

                row.set(v.name, v.default);
            } 
        });

        return row;
    }

    @action addRow = () => {

        const row = this.newRow();

        this.rows.push(row);

        if(this.props.validationType === 1.0){

            if(this.props.validation !== undefined && this.props.validation.length !== 0){
                
                this.props.validation.push(new Map());
            }
        }

        this.props.onChange({name: this.props.name, value: this.rows});
    }

    @action change = (name, value, row) => {
	console.log(this.editingField);
	const isRootChange = this.editingField === null || this.editingField === undefined;
    	if (isRootChange) {
	    this.editingField = name;
	}
	console.log(this.editingField);

        this.rows[row].set(name, value);

        // Make automatic modifications after new value is set.

        this.props.fields.forEach(field => {

            // Do not edit same field that user changed.

            if(field.name !==  this.editingField){
        
                 if(field.sumof !== undefined){

                    const result = this.sumOf(this.rows[row], field.sumof);
                    
                    if(result !== false){

                        //this.rows[row].set(field.name, result);
  			this.rows[row].set(field.name, parseFloat(result).toFixed(2).toString().replace('.',','));
                    }
                }

                if(field.remainderof !== undefined){

                    const result = this.remainderOf(this.rows[row], field.remainderof);
		    console.log(result);
		    console.log(parseFloat(result));
		    console.log( parseFloat(result).toFixed(2));

                    if(result !== false){

			this.rows[row].set(field.name, parseFloat(result).toFixed(2).toString().replace('.',','));

                        //this.rows[row].set(field.name, result);
                    }
                }

                // Custom callback

                if(field.callback !== undefined){

                    field.callback(this.rows[row], row, this.overrides);
                }
            }
        });
	Promise.resolve().then(() => {
		if (isRootChange) {
        		this.editingField = null;
		        // Trigger the onChange event after all changes are completed
		        this.props.onChange({ name: this.props.name, value: this.rows });
		}
	});

        //this.props.onChange({name: this.props.name, value: this.rows});
    }

    @action deleteRow = (row) => {

        this.rows.splice(row, 1);

        if(this.props.validationType === 1.0){

            if(this.props.validation !== undefined && this.props.validation.length !== 0){
                
                this.props.validation.splice(row, 1);
            }
        }

        this.overrides.delete(row);

        this.props.onChange({name: this.props.name, value: this.rows});
    }

    @action open = (i) => {

        this.isOpen[i] = true;
    }

    @action close = (i) => {

        this.isOpen[i] = false;
    }

    error = field => {

        if(this.props.validationType === 1.0){

            if(this.props.validation === undefined){

                return false;
            }

            else {

                let errors = 0;

                this.props.validation.forEach((row) => {
     
                    if(row.get(field) !== undefined){

                        errors++;
                    }
                });

                if(errors === 0){

                    return false;
                }

                else {

                    return true;
                }
            }
        }

        else if(this.props.validationType === 2.0){

            return false;
        }
    }

    sumOf = (row, keys, decimals = null) => {

        let multiplier = row.get(keys[0]); // First key is multiplier.
        let multiplicand = row.get(keys[1]); // Second key is multiplicand.

        if(multiplier === undefined || multiplicand === undefined){

            return false;
        }

        multiplier = parseFloat(multiplier.toString().replace(',', '.'));
        multiplicand= parseFloat(multiplicand.toString().replace(',', '.'));

        if(isNaN(multiplier) === true || isNaN(multiplicand) === true){

            return false;
        }

        return multiplier * multiplicand;
    }

    remainderOf = (row, keys) => {

        let divider = row.get(keys[0]);
        let dividend = row.get(keys[1]);

        if(divider === undefined || dividend === undefined){

            return false;
        }

        divider = parseFloat(divider.toString().replace(',', '.')); // First key is divider.
        dividend = parseFloat(dividend.toString().replace(',', '.')); // Second key is dividend.

        if(isNaN(divider) === true || isNaN(dividend) === true){

            return false;
        }

        return dividend / divider;
    }

    runCallbacks(){

        this.rows.forEach((fields, index) => {

            fields.forEach((value, name) => {

                this.props.fields.forEach(params => {

                    if(params.name === name && params.callback !== undefined){

                        params.callback(fields, index, this.overrides);
                    }
                });
            }); 
        });
    }

    componentDidMount(){

        // Send default to parent component

        this.props.onChange({name: this.props.name, value: this.rows});

        this.runCallbacks();
    }

    render(){

        return (
 
            <Table mode="multifields" breakpoint={this.props.breakpoint}>

                <Table.thead>

                    <Table.tr>

                        {this.props.fields.map((v, k) => {

                            return (
                        
                                <Table.td key={k} paddingBottom={theme.size[1]}>
                                
                                    {
                                        v.description !== undefined ?

                                        <Wrapper>
                                        
                                            <Icon type="info" cursor="pointer" color={theme.color[1]} hover={theme.color[3]} onClick={() => this.open(k)} />
                                            <Note show={this.isOpen[k]} close={() => {this.close(k)}}>{v.description}</Note>
                                            <Label color={this.error(v.name) === true ? theme.color[9] : undefined}>{v.label}</Label>

                                        </Wrapper>

                                        : <Block><Label color={this.error(v.name) === true ? theme.color[9] : undefined}>{v.label}</Label></Block>
                                    }

                                </Table.td>
                            );
                        })}

                    </Table.tr>

                </Table.thead>

                <Table.tbody>

                    {this.rows.map((v, row) => {

                        return (

                            <Table.tr key={row}>

                                {this.props.fields.map((v, k) => {

                                    let error = false;

                                    // Validate.js

                                    if(this.props.validationType === 1.0){

                                        if(this.props.validation !== undefined && this.props.validation.length !== 0){
                                        
                                            if(this.props.validation[row].get(v.name) !== undefined){

                                                error = true;
                                            }
                                        }
                                    }

                                    // Form.js

                                    if(this.props.validationType === 2.0){

                                        if(this.props.validation !== undefined){

                                            const field = this.props.validation.get(v.name);

                                            if(field !== undefined){

                                                if(field.get(row) !== undefined){

                                                    error = true;
                                                }
                                            }
                                        }
                                    }

                                    const override = this.overrides.get(row) !== undefined ? this.overrides.get(row) : {};

                                    if(override[v.name] !== undefined){

                                        v = {...v, ...override[v.name]}; // Merge values
                                    }

                                    return (
                                
                                        <Table.td key={k} paddingRight={theme.size[1]} paddingBottom={theme.size[1]} width={v.width !== undefined ? v.width : undefined}>

                                        {v.options === undefined 

                                            ? <Input name={v.name + '[]'} value={this.rows[row].get(v.name)} onChange={(r) => {this.change(v.name, r.value, row)}} borderColor={error === false ? undefined : theme.color[9]} maxLength={v.maxlength} disabled={v.disabled} />
                                            : <Select name={v.name + '[]'} value={this.rows[row].get(v.name)} onChange={(r) => {this.change(v.name, r.value, row)}} options={v.options} disabled={v.disabled} />
                                        }
                                            
                                        </Table.td>
                                    );
                                })}

                                <Table.td>

                                    <Button type={row !== 0 ? 'minus' : 'plus'} onClick={() => {row !== 0 ? this.deleteRow(row) : this.addRow()}} breakpoint={this.props.breakpoint}></Button>

                                </Table.td>

                            </Table.tr>
                        );
                    })}

                </Table.tbody>

            </Table>
        ); 
    }
}

MultiFields.propTypes = {

    /** Name of the collection */
    name: PropTypes.string.isRequired,

    /** Parse return value of Multifields onChange property and repopulate fields. */
    value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),

    /** List of fields.  */
    fields: PropTypes.arrayOf(
    
        PropTypes.shape({

            name: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            description: PropTypes.string,
            width: PropTypes.string,
            maxlength: PropTypes.number,
            sumof: PropTypes.arrayOf(PropTypes.string)
        })
    ),

    /** Optional breakpoint for mobile view. */
    breakpoint: PropTypes.string,
    
    /** Get values of the rows as array of maps. */
    onChange: PropTypes.func
}

MultiFields.defaultProps = {

    breakpoint: theme.breakpoint,
    validationType: 1.0
}

export default MultiFields;
