import ApiStore from 'stores/ApiStore'
import api from 'api.json';

class CustomsUnitStore extends ApiStore {

    fetchAll = async () => {

        const options = {
        
            url: api.endpoints.common.customunits
        };

        return await this.get({}, options);
    }
}

export default CustomsUnitStore;
