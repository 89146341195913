import ApiStore from 'stores/ApiStore';
import api from 'api.json';

class ShipmentsInfo extends ApiStore {

    getPrice = async data => {

        const options = {
        
            url: api.endpoints.user.shipmentsInfo,
            retry: false
        };

        return await this.post(data, options);
    }

    getParcelshops = async data => {

        const options = {
        
            url: api.endpoints.user.parcelshops,
            retry: false
        };

        return await this.post(data, options);
    }
}

export default ShipmentsInfo;
