import ApiStore from 'stores/ApiStore';
import Validate from 'Validate';
import api from 'api.json';
import {computed} from 'mobx';

class SendersStore extends ApiStore {

    constructor(stores){

        super(stores);

        this.stores = stores;
        this.form = new Map();
    }

    validate = () => {

        const lang = this.stores.ui.lang.senderForm;
        const fields =  new Validate(this.form);

        fields.set('name1').isFilled(lang.fillName);
        fields.set('address1').isFilled(lang.fillAddress);
        fields.set('postalnumber').isFilled(lang.fillPostalnumber);

        if(this.form.get('countries_id') !== undefined){

            const country = this.stores.countries.findCountry('id', this.form.get('countries_id'));

            if(typeof country.zipregex === 'string'){
 
                fields.set('postalnumber').regexp(country.zipregex, this.stores.countries.getZiperrortext(country, lang.fillPostalnumber));
            }
        }

        fields.set('city').isFilled(lang.fillCity);
        fields.set('countries_id').isFilled(lang.fillCountry);
        fields.set('businessid').isFilled(lang.fillBusinessID);
        fields.set('email').isFilled(lang.fillEmail).isEmail(lang.invalidEmail);
        fields.set('contact').isFilled(lang.fillContact);
        fields.set('tel').isFilled(lang.fillTel).isTel(lang.invalidTel);
        fields.set('mobile').isFilled(lang.fillMobile).isMobile(lang.invalidMobile);

        return {fields: fields.result()};
    }

    errorCount = (results) => {

        return Validate.size(results);
    }

    messages = (results) => {

        return Validate.parseMessages(results);
    }

    // Map to plain object

    convertMap = (map) => {
    
        const data = {};

        map.forEach((v, k) => {
        
            data[k] = v;
        });

        return data;
    }

    add = async () => {

        const data = this.convertMap(this.form);

        const settings = {
        
            url: api.endpoints.admin.senders
        }

        return await this.post(data, settings);
    }

    update = async id => {

        const data = this.convertMap(this.form);

        const settings = {
        
            url: api.endpoints.admin.senders+id
        }

        return await this.put(data, settings);
    }

    remove = async id => {

        this.settings = {
        
            url: api.endpoints.admin.senders+id
        }

        return await this.delete({}, this.settings);
    }

    fetchAll = async () => {

        return await this.get({}, {
        
            url: api.endpoints.user.senders
        });
    }

    fetchSingle = async params => {

        return await this.get({}, {
        
            url: api.endpoints.user.senders + params.id
        });
    }

    load = async () => {

        return await this.fetchAll();
    }

    repopulate(id){

        const form = new Map();
        const object = this.data.get(id);

        for(let k in object){

            form.set(k, object[k]);
        }

        this.form = form;
    }

    @computed get defaultSender(){

        let defaultSender = false;

        this.data.forEach((v, k) => {

            // If only one sender we use that as default even if it is not set.

            if(defaultSender === false && this.data.size === 1){

                defaultSender = v.id;
            }

            /*

            // Otherwise use sender that is set default.
        
            else if(v.is_default === true){
                
                defaultSender = v.id;

                return false;
            }

            */
        });

        return defaultSender;
    }

    senderExists = (id) => {

        let exists = false;

        this.data.forEach((v, k) => {
        
            if(v.id === id){

                exists = true;

                return false;
            }
        }); 

        return exists;
    }
}

export default SendersStore;
