import React from 'react';
import Header from 'components/smart/Header';
import {inject} from 'mobx-react';
import Title from 'components/gls-dumb/Title';
import {Buttons} from 'components/gls-dumb/Buttons';
import Button from 'components/gls-dumb/Button';
import ButtonB from 'components/gls-dumb/Button/Alternative';
import theme from 'theme';
import Pagebreak from 'components/gls-dumb/Pagebreak';
import List from 'components/gls-dumb/List';
import Columns from 'components/gls-dumb/Columns';
import {Sortable} from 'components/gls-dumb/Sortable';
import {NoticeBox} from 'components/gls-dumb/NoticeBox';
import api from 'api.json';
import {computed} from 'mobx';
import Section from 'components/gls-dumb/Section';

@inject('parcel')
@inject('ui')
@inject('countries')
@inject('auth')
@inject('api')
@inject('import')
@inject('load')
@inject('pickuppoints')
@inject('senders')

class ParcelView extends React.Component {

    lang = this.props.ui.lang.shipmentView;

    constructor(props){

        super(props);

        this.props.parcel.form.clear();
        this.props.parcel.repopulate(this.props.match.params.id);

        this.data = {
        
            consignee: {
            
                left: [
            
                    {title: this.lang.consigneeName + ':', value: this.replace(this.get('consignee_name1'))},
                    {title: this.lang.consigneeName2 + ':', value: this.replace(this.get('consignee_name2'))},
                    {title: this.lang.consigneeAddress + ':', value: this.replace(this.get('consignee_address1'))},
                    {title: this.lang.consigneeAddress2 + ':', value: this.replace(this.get('consignee_address2'))},
                    {title: this.lang.consigneePostalnumber + ':', value: this.replace(this.get('consignee_postalnumber'))},
                    {title: this.lang.consigneeCity + ':', value: this.replace(this.get('consignee_city'))},
                    {title: this.lang.consigneeCountry + ':', value: this.replace(this.get('consignee_countries_id'))}
                ],

                right: [

                    {title: this.lang.consigneeContact + ':', value: this.replace(this.get('consignee_contact'))},
                    {title: this.lang.consigneeTel + ':', value: this.replace(this.get('consignee_tel'))},
                    {title: this.lang.consigneeMobile + ':', value: this.replace(this.get('consignee_mobile'))},
                    {title: this.lang.consigneeEmail + ':', value: this.replace(this.get('consignee_email'))}
                ]
            },

            info: {

                left: [
                
                    {title: this.lang.shipmentType + ':', value: this.replace(this.props.parcel.typeNames[this.get('type')])},
                    {title: this.lang.sendersRef + ':', value: this.replace(this.get('ref'))},
                    {title: this.lang.info + ':', value: this.replace(this.get('description'))}
                ],

                right: [
                
                    {title: this.lang.content + ':', value: this.replace(this.get('contents'))},
                    {title: this.lang.date + ':', value: this.replace(this.get('date'))},
                    {title: this.lang.parcelsCnt + ':', value: this.replace(this.get('parcels_cnt'))},
                    {title: this.lang.totalWeight + ':', value: this.replace(this.get('total_weight'))}
                ]
            },

            tariff: [
            
                {title: this.lang.deliveryterms + ':', value: this.replace(this.get('deliveryterms'))},
                {title: this.lang.customsValue + ':', value: this.replace(this.get('customs_value'))},
                {title: this.lang.customsCurrencty + ':', value: this.replace(this.get('customs_currency'))}
            ]
        }
    }

    edit = () => {

        const route = '/parcel/:id/send.html';
        const params = {id: this.props.match.params.id};

        this.props.ui.backFrom.parcel = {
        
            route: '/parcel/:id/view.html',
            params: params
        };

        this.props.load.route(route, params, url => {
        
            this.props.history.push(url);
        });
    }

    print = () => {

        this.props.parcel.form.set('is_printed', true);
        this.props.parcel.printParcelcards([this.props.match.params.id]);
    }

    copy = () => {

        this.props.parcel.loadParcelSend(() => {

            const parcel = this.props.parcel.data.get(this.props.match.params.id);
        
            this.props.parcel.copy = parcel;

            this.props.ui.backFrom.parcel = {
            
                route: '/parcel/:id/view.html',
                params: {id: this.props.match.params.id}
            };

            this.props.history.push('/parcel/send.html');
        });
    }

    @computed get removable() {
 
        const status = this.props.parcel.form.get('status');
        const isPrinted = this.props.parcel.form.get('is_printed');

        if(status === 'DONE' || isPrinted === true){

            return false;
        }

        return true;
    }

    confirmRemove = () => {

        const c = this.props.ui.customConfirm;

        c.isOpen = true;
        c.message = this.lang.deleteShipment;
        c.cancelHandler = this.props.ui.clearCustomConfirm;
        c.okHandler = this.remove;
        c.cancelText = this.lang.cancelDelete;
        c.okText = this.lang.deleteShipmentOK;
    }

    remove = () => {

        if(this.removable === true){

            this.props.ui.clearCustomConfirm();

            this.props.parcel.remove(this.get('id')).then(() => {
           
                this.props.parcel.data.delete(this.get('id'));
                this.props.history.push('/parcels/');
            });
        }
    }

    get = k => {

        let data = this.props.parcel.form.get(k);

        if(k === 'consignee_countries_id'){

            return this.props.countries.nameByCode(data);
        }

        return data;
    }

    back = () => {

        this.props.ui.backFrom.overview = true;

        this.props.load.route('/parcels/', url => {
        
            this.props.history.push(url);
        });
    }

    replace = (value) => {

        if(value === '' || value === undefined){

            return '-';
        }

        return value;
    }

    downloadAttachment = (file) => {

        const tab = window.open();

        this.props.auth.temporary().then(r => {

            tab.location.replace(this.props.api.prepareUrl(api.endpoints.user.files + file + '?jwt=' + encodeURIComponent(r.data.jwt)));
        });
    }

    downloadProForma = () => {

        this.props.parcel.printProForma(this.props.match.params.id);
    }

    tracking = () => {

        const lang = this.props.ui.selectedLang;
        const parcelnumbers = [];

        this.get('parcels').forEach((v, k) => {

            parcelnumbers.push(v.get('parcelnumber'));
        });

        let match = '';

        parcelnumbers.forEach((v, k) => {

            if(k > 0){

                match += '%2c ';
            }

            match += v;
        });

         let url;

        if(lang === 'fi'){

            url = 'https://gls-group.eu/FI/fi/laehetysseuranta?match=' + match; 
        }

        else if(lang === 'en'){

            url = 'https://gls-group.eu/FI/en/parcel-tracking?match=' + match;
        }

        window.open(url);
    }

    return = () => {

        this.props.import.loadImportDetails(() => {

            const parcel = this.props.parcel.data.get(this.props.match.params.id);
        
            this.props.import.copyExportParcel = parcel;
            this.props.history.push('/import/details.html');
        });
    }

    render(){

        const pickuppoint = this.props.pickuppoints.data.get(this.get('pickuppoints_id'));
        const sender = this.props.senders.data.get(this.get('senders_id'));

        return (

            <React.Fragment> 

                <Header />

                <Section color="sub">

                    <Title>
                    
                        <span>{this.lang.preview}</span>
                        
                        <Buttons width="auto" marginBottom="0">
                        
                            <ButtonB onClick={this.back}>{this.lang.back}</ButtonB>
                            {this.get('status') !== 'DONE' && this.get('is_printed') === false && <ButtonB onClick={this.edit}>{this.lang.edit}</ButtonB>}

                            {(this.get('status') === 'READY' || this.get('status') === 'DONE') &&

                                <React.Fragment>
                            
                                    <ButtonB onClick={this.print}>{this.lang.print}</ButtonB>
                                    <ButtonB onClick={this.tracking}>{this.lang.tracking}</ButtonB>
                                    <ButtonB onClick={this.copy}>{this.lang.copy}</ButtonB>
                                    <ButtonB onClick={this.return}>{this.lang.return}</ButtonB>

                                </React.Fragment>
                            }

                            {this.removable === true && <ButtonB onClick={this.confirmRemove}>{this.lang.remove}</ButtonB>}
                        
                        </Buttons>
                    
                    </Title>

                </Section>
        
                <div className="main">

                    <NoticeBox marginBottom={theme.size[1]}>{this.lang.creator}: {this.get('email')}</NoticeBox>

                    <Pagebreak>{this.lang.sendersInfo}</Pagebreak>

                    <Columns marginBottom={theme.size[1]}>

                        <Columns.left>
                        
                            <List pairs={[

                                {title: this.lang.consigneeName + ':', value: sender.name1},
                                {title: this.lang.consigneeName2 + ':', value: sender.name2},
                                {title: this.lang.consigneeAddress + ':', value: sender.address1},
                                {title: this.lang.consigneeAddress2 + ':', value: sender.address2},
                                {title: this.lang.consigneePostalnumber + ':', value: sender.postalnumber},
                                {title: this.lang.consigneeCity + ':', value: sender.city},
                                {title: this.lang.consigneeCountry + ':', value: this.props.countries.nameByCode(sender.countries_id)}

                            ]} />
                        
                        </Columns.left>

                        <Columns.right>
                        
                            <List pairs={[

                                {title:  this.lang.consigneeContact + ':', value: sender.contact},
                                {title: this.lang.consigneeTel + ':', value: sender.tel},
                                {title: this.lang.consigneeMobile + ':', value: sender.mobile},
                                {title: this.lang.consigneeEmail + ':', value: sender.email}

                            ]} />
                        
                        </Columns.right>

                    </Columns>

                    <Pagebreak>{this.lang.pickuppointInfo}</Pagebreak>

                    <Columns marginBottom={theme.size[1]}>

                        <Columns.left>
                        
                            <List pairs={[

                                {title: this.lang.consigneeName + ':', value: pickuppoint.name1},
                                {title: this.lang.consigneeName2 + ':', value: pickuppoint.name2},
                                {title: this.lang.consigneeAddress + ':', value: pickuppoint.address1},
                                {title: this.lang.consigneeAddress2 + ':', value: pickuppoint.address2},
                                {title: this.lang.consigneePostalnumber + ':', value: pickuppoint.postalnumber},
                                {title: this.lang.consigneeCity + ':', value: pickuppoint.city},
                                {title: this.lang.consigneeCountry + ':', value: this.props.countries.nameByCode(pickuppoint.countries_id)}

                            ]} />
                        
                        </Columns.left>

                        <Columns.right>
                        
                            <List pairs={[

                                {title:  this.lang.consigneeContact + ':', value: pickuppoint.contact},
                                {title: this.lang.consigneeTel + ':', value: pickuppoint.tel},
                                {title: this.lang.consigneeMobile + ':', value: pickuppoint.mobile},
                                {title: this.lang.consigneeEmail + ':', value: pickuppoint.email}

                            ]} />
                        
                        </Columns.right>

                    </Columns>
 
                    <Pagebreak>{this.lang.consigneeInfo}</Pagebreak> 
                
                    <Columns marginBottom={theme.size[1]}>

                        <Columns.left>
                        
                            <List pairs={this.data.consignee.left} />
                        
                        </Columns.left>

                        <Columns.right>
                        
                            <List pairs={this.data.consignee.right} />
                        
                        </Columns.right>

                    </Columns>

                    <Pagebreak>{this.lang.shipmentsInfo}</Pagebreak> 

                    <Columns marginBottom={theme.size[1]}>

                        <Columns.left>
                        
                            <List pairs={this.data.info.left} />
                        
                        </Columns.left>

                        <Columns.right>
                        
                            <List pairs={this.data.info.right} />
                        
                        </Columns.right>

                    </Columns>

                    <Columns gap="small">

                        <Columns.left>
                        
                            <NoticeBox>FlexDeliveryService: {this.get('fds') === false ? this.lang.no : this.lang.yes}</NoticeBox>
                            <NoticeBox>AddOnLiabilityService: {this.get('insurance') === false ? this.lang.no : this.lang.yes}</NoticeBox>
                        
                        </Columns.left>

                        <Columns.right>
                        
                            <NoticeBox>{this.lang.preadvice}: {this.get('preadvice') === false ? this.lang.no : this.lang.yes + ' (' + this.get('preadvice_email') +')'}</NoticeBox>
                        
                        </Columns.right>

                    </Columns>

                    {this.get('parcels') !== undefined &&

                        <React.Fragment>

                            <Pagebreak>{this.lang.weightAndMeasurements}</Pagebreak> 

                            <Sortable>

                                <Sortable.thead>

                                     <Sortable.tr>

                                        <Sortable.td>{this.lang.parcelnumber}:</Sortable.td>
                                        <Sortable.td>{this.lang.amount}:</Sortable.td>
                                        <Sortable.td>{this.lang.content}:</Sortable.td>
                                        <Sortable.td>{this.lang.weight}:</Sortable.td>
                                        <Sortable.td>{this.lang.length}:</Sortable.td>
                                        <Sortable.td>{this.lang.width}:</Sortable.td>
                                        <Sortable.td>{this.lang.height}</Sortable.td>

                                    </Sortable.tr>
                                
                                </Sortable.thead>

                                <Sortable.tbody>
                            
                                    {this.get('parcels').map((v, k) => {
     
                                        return (

                                            <Sortable.tr key={k}>

                                                <Sortable.td>{this.replace(v.get('parcelnumber'))}</Sortable.td>
                                                <Sortable.td>{this.replace(v.get('amount'))}</Sortable.td>
                                                <Sortable.td>{this.replace(v.get('content'))}</Sortable.td>
                                                <Sortable.td>{this.replace(v.get('weight'))}</Sortable.td>
                                                <Sortable.td>{this.replace(v.get('length'))}</Sortable.td>
                                                <Sortable.td>{this.replace(v.get('width'))}</Sortable.td>
                                                <Sortable.td>{this.replace(v.get('height'))}</Sortable.td>

                                            </Sortable.tr>
                                        ); 
                                    })}

                                </Sortable.tbody>

                            </Sortable>

                        </React.Fragment>
                    }

                    {(this.get('ontoploading') !== null && this.get('type') === this.props.parcel.types.euroBusinessFreight) &&

                        <React.Fragment>
                    
                            <Pagebreak>{this.lang.ontoploading}</Pagebreak> 

                             <Columns gap="small">

                                <Columns.left width="25%">

                                    <NoticeBox>{this.get('ontoploading') === false ? this.lang.denied : this.lang.allowed}</NoticeBox>

                                </Columns.left>

                                <Columns.right width="75%">
                                
                                    <NoticeBox>{this.get('ontoploading') === false ? this.lang.charged_by_meters : this.lang.charged_by_space}</NoticeBox>

                                </Columns.right>

                            </Columns>

                        </React.Fragment>
                    }

                    {(this.get('customs_documenttype') !== null && this.props.parcel.require.tariff) &&

                        <React.Fragment>

                            <Pagebreak>{this.lang.clearance}</Pagebreak> 

                            <NoticeBox marginBottom={(this.get('customs_documenttype') === 'FILE' || this.get('customs_documenttype') === 'LATER') ? undefined : '0'}>
                                
                                {this.get('customs_documenttype') === 'FILE' && <Button onClick={() => this.downloadAttachment(this.get('invoice_files_id'))}>{this.lang.downloadInvoice}</Button>}
                                {this.get('customs_documenttype') === 'PROFORMA' && <Button onClick={() => this.downloadProForma()}>{this.lang.downloadProforma}</Button>}
                                {this.get('customs_documenttype') === 'DOCUMENT' && <React.Fragment>{this.lang.documentShipment}</React.Fragment>}
                                {this.get('customs_documenttype') === 'LATER' && <React.Fragment>{this.lang.documentLater}</React.Fragment>}
                                
                            </NoticeBox>

                            {(this.get('customs_documenttype') === 'FILE' || this.get('customs_documenttype') === 'LATER') && <List pairs={this.data.tariff} />}

                        </React.Fragment>
                    }     

                </div>

            </React.Fragment>
        ); 
    }
}

export {ParcelView}
