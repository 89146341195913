import ApiStore from 'stores/ApiStore';
import {observable} from 'mobx';
import Validate from 'Validate';
import api from 'api.json';

class AdminUsersStore extends ApiStore {

    @observable form = new Map();
    @observable validation = new Map();

    validate = () => {
        
        const lang = this.stores.ui.lang.usersAdd;
        const fields = new Validate(this.form);

        fields.set('email').isFilled(lang.fillEmail).isEmail(lang.invalidEmail);
        fields.set('name').isFilled(lang.fillName);
        fields.set('tel').isFilled(lang.fillTel).isTel(lang.invalidTel);

        this.validation = fields.result();

        if(this.validation.size !== 0){
        
            return false;
        }

        return true;
    }

    messages = () => {

        return Validate.parseMessages({fields: this.validation});
    }

    add = async () => {

        return await this.post(this.form, {url: api.endpoints.admin.users});
    }
}

export default AdminUsersStore;
