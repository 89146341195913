import naturalSort from 'javascript-natural-sort';

const bool = v => {

    return v === undefined || v === null ? false : v;
}

const isNumeric = v => {

    if(v === undefined || v === null){

        return false;
    }

    if(typeof v.toString !== "function"){

        return false;
    }

    v = v.toString();

    const numbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    const chars = v.split('');

    let numeric = true;

    for(let i = 0; i < chars.length; i++){

        if(numbers.indexOf(chars[i]) === -1){
        
            numeric = false; break;
        }
    }

    return numeric;
}

// Returns url without id if not set and with id if set.

const withID = (url, id) => {

    const parts = url.split('/');

    if(parts.length === 3 && id !== undefined && id !== false){

        url = '/' + parts[1] + '/' + id + '/' + parts[2];
    }

    return url;
}

const arrayFromMap = map => {

    const array = [];

    map.forEach((v, k) => {

        array.push(v);
    });

    return array;
};

const objectFromMap = map => {

    const object = {};

    map.forEach((v, k) => {
   
        object[k] = v;
    });

    return object;
}

// Sort map that contains objects (column is one of the object keys).

const sortMap = (map, column, order) => {

    // Default order

    if(order === undefined){
    
        order = 'asc';
    }

    // Array from map.

    let data = arrayFromMap(map);

    // Order data by key.

    data.sort((a, b) => {

        if(isNumeric(column)){

            if(order === 'asc'){

                return a[column] - b[column];
            }

            else {

                return b[column] - a[column];
            }
        }

        else {

            if(order === 'asc'){

                return ('' + a[column]).localeCompare(b[column]); 
            }

            else {

                return ('' + b[column]).localeCompare(a[column]);
            }
        }
    });

    // Clear original map.

    map.clear();

    // Repopulate map with sorted data.

    data.forEach((v, k) => {
   
        map.set(v.id, v);
    });

    // Return sorted map.

    return map;
}

// Rearrange insertion order.

const sortObjectByKey = (object, order) => {

    if(order === undefined){

        order = 'asc';
    }

    // Convert object to array of keys.

    let array = [];

    for(let k in object){

        array.push(k);
    }

    // Sort keys

    array.sort(naturalSort);

    if(order === 'desc'){

        array.reverse();
    }

    // Create new object from old object in order of sorted keys.

    const sorted = {};

    array.forEach(v => {
    
        sorted[v] = object[v];
    });

    return sorted;
}

const parseNumber = v => {

    if(typeof v !== 'string' && typeof v !== 'number'){

        return 0;
    }

    const number = parseFloat(v.toString().replace(',', '.'));

    if(isNaN(number)){

        return 0;
    }

    return number;
}

const round = (v, precision) => {

    if(precision === undefined){

        precision = 0;
    }

    let number = '1';

    for(let i = 0; i < precision; i++){

        number += '0';
    }

    number = parseNumber(number);

    return Math.round((parseNumber(v) + Number.EPSILON) * number) / number;
}

export {bool, isNumeric, withID, arrayFromMap, sortMap, objectFromMap, sortObjectByKey, parseNumber, round}
