import React from 'react';
import Component from 'Component';
import {observer, inject} from 'mobx-react';
import Header from 'components/smart/Header';
import Content from 'components/gls-dumb/Content';
import Title from 'components/gls-dumb/Title';
import {computed, observable} from 'mobx';
import Submit from 'components/gls-dumb/Submit';
import ButtonB from 'components/gls-dumb/Button/Alternative';
import theme from 'theme';
import Columns from 'components/gls-dumb/Columns';
import Field from 'components/gls-dumb/Field';
import ModalAlerts from 'components/gls-dumb/ModalAlerts';
import DateTimePicker from 'components/gls-dumb/DateTimePicker';
import moment from 'moment';
import List from 'components/gls-dumb/List';
import Checkbox from 'components/gls-dumb/Checkbox2';
import Editable from 'components/gls-dumb/Editable';

@inject('ui')
@inject('fetch')
@inject('parcel')
@inject('customers')
@inject('settings')

@observer class OrderFetch extends Component {

    lang = this.props.ui.lang.orderFetch;
    store = this.props.fetch;
    selectedDateTime = false;
    customer = this.props.customers.data.get(this.props.ui.customers.id);

    @observable isOpen = {

        dateTimePicker: false
    }

    constructor(props){

        super(props);

        if(this.redirect()){

            this.replace('/parcels/');
            return false;
        }

        this.resetForm();
        this.setDefaultTime();
        this.setDefaultFields();
    }

    setDefaultFields = () => {

        if(this.props.settings.object.is_confirmed === true){

            this.store.form.set('isconfirm', true);
        }
    }

    resetForm = () => {

        this.store.form.clear();
        this.store.validation.clear();
    }

    getUnprinted = () => {

        const unprinted = [];

        this.props.parcel.data.forEach(v => {

            if(v.status !== 'DRAFT' && v.is_printed === false){

                unprinted.push(v.id);
            }
        });

        return unprinted;
    }

    isUnprinted = () => {

        const unprinted = this.getUnprinted();

        if(unprinted.length === 0){

            return false;
        }

        return true;
    }

    printUnprinted = callback => {

        const unprinted = this.getUnprinted();

        this.props.parcel.printParcelcards(unprinted, callback);
    }

    setDefaultTime = () => {

        this.set('date_time_from', moment().format('DD.MM.YYYY HH:mm'));
    }

    redirect = () => {
 
        if(this.props.ui.selectedParcels.size === 0){

            return true;
        }

        return false;
    }

    submit = (e) => {

        if(e !== undefined){
        
            e.preventDefault();
        }

        this.submitted = true;
        this.store.validate();

        if(this.store.validation.size !== 0){

            this.messages = this.store.messages();
        }

        else {

            const shipments = [];

            this.props.ui.selectedParcels.forEach((v, k) => {
            
                shipments.push(k);
            });

            this.props.ui.resolve(this.props.fetch.make(shipments), this.submit,

                r => {

                    let errors = false;

                    // Test if all shipments are ordered.

                    r.data.forEach((shipment, k) => {
                    
                        if(shipments[k] !== shipment){

                            errors = true;
                        }
                    });

                    const messages = [this.props.ui.isCancel ? this.lang.cancelOrdered : this.lang.fetchOrdered];

                    if(errors === true){

                        messages.push(this.lang.allNotOrdered);
                    }

                    this.props.ui.afterClearMessages = this.done;
                    this.props.ui.messages = messages;
                }
            );
        }
    }

    done = () => {

        if(this.store.form.get('print_unprinted') === true){

            this.printUnprinted(this.back);
        }

        else {

            this.back();
        }
    }

    back = () => {

        this.props.ui.resolve(this.props.parcel.fetchAll(), this.done, () => {

            this.props.history.push('/parcels/');
        });
    }

    @computed get selected(){

        let parcelCnt = 0;
        let totalWeight = 0;

        this.props.ui.selectedParcels.forEach((v, id) => {
       
            const selected = this.props.parcel.data.get(id);

            parcelCnt += selected.parcels_cnt;
            totalWeight += selected.total_weight;
        });

        return {
        
            parcelCnt: parcelCnt,
            totalWeight: totalWeight
        };
    }

    setDateTime = date => {

        if(this.selectedDateTime !== false){

            this.change({
            
                name: this.selectedDateTime,
                value: moment(date).format('DD.MM.YYYY HH:mm')
            });
        }

        this.isOpen.dateTimePicker = false;    
    }

    cancelDateTime = () => {

        this.selectedDateTime = false;
        this.isOpen.dateTimePicker = false;    
    }

    openDateTime = name => {

        this.selectedDateTime = name;
        this.isOpen.dateTimePicker = true; 
    }

    render(){

        if(this.redirect()){
        
            return null; 
        }

        return(

            <div className="main">

                <ModalAlerts messages={this.messages} ok={this.clearMessages}></ModalAlerts>

                <DateTimePicker isOpen={this.isOpen.dateTimePicker} onCancel={this.cancelDateTime} onDone={this.setDateTime} timeMode={true} />

                <Header />

                <Title>
                
		    {this.props.ui.isCancel ? this.lang.cancelTitle : this.lang.Ordertitle}
                    <ButtonB onClick={() => {this.go('/parcels/')}}>{this.lang.cancel}</ButtonB>
                    
                </Title>
            
                <Content>

                    <form onSubmit={this.submit}>

                    <Columns marginBottom={theme.size[1]}>

                        <Columns.left>
                        
                            <List pairs={[
                            
                                {title: this.lang.parcelCnt, value: this.selected.parcelCnt + ' ' + this.lang.parcelCntUnit},
                                {title: this.lang.orderer + ':', value: this.props.settings.all.name},

                            ]} />
                        
                        </Columns.left>

                        <Columns.right>
                        
                            <List pairs={[
                            
                                {title: this.lang.totalWeight, value: this.selected.totalWeight + ' ' + this.lang.totalWeightUnit},
                                {title: this.lang.tel + ':', value: this.props.settings.all.tel}

                            ]} />
                        
                        </Columns.right>

                    </Columns>

                        <Columns>

			<Columns.left>
			  {/* Conditionally use visibility: hidden to keep the space occupied */}
			  <div style={{ visibility: this.props.ui.isCancel ? 'hidden' : 'visible' }}>
			    <Field
			      type="calendar"
			      {...this.fieldProps('date_time_from')}
			      label={this.lang.fetchTimeFrom}
			      datePicker={() => this.openDateTime('date_time_from')}
			      maxLength="16"
			    />
			  </div>

			  {/* Add a hidden input field to preserve the value for form submission */}
			  {this.props.ui.isCancel && (
			    <input
			      type="hidden"
			      name="date_time_from"
			      value={this.fieldProps('date_time_from').value}
			    />
			  )}
			</Columns.left>

                            <Columns.right>

                                {/*

                                <Field
                                
                                    type="calendar"
                                    {...this.fieldProps('date_time_to')}
                                    label={this.lang.fetchTimeTo}
                                    datePicker={() => this.openDateTime('date_time_to')}
                                    maxLength="10"
                                />

                                */}

                            </Columns.right>
                    
                        </Columns>
			{/* Textarea Field */}
			{this.props.ui.isCancel ? (
			  // Hidden input with value "PERUTTU" if it's a cancel
			  <input type="hidden" name="info" value="PERUTTU" />
			) : (
			  // Regular textarea field when it's not a cancel
			  <Field {...this.fieldProps('info')} type="textarea" label={this.lang.info} />
			)}

			{/* Checkboxes */}		
			{this.props.ui.isCancel ? null : (
			  // Render checkboxes only if it's not a cancel
			  <Editable isEditable={this.isUnprinted()}>
			  <Checkbox {...this.checkboxProps('print_unprinted')} value={true} label={this.lang.print_unprinted} />
			  <Checkbox {...this.checkboxProps('isconfirm')} value={true} label={this.lang.send_email_confirm} />
			  </Editable>
			)}


                        {/*<Field {...this.fieldProps('info')} type="textarea" label={this.lang.info} />

                        <Editable isEditable={this.isUnprinted()}>

                            <Checkbox {...this.checkboxProps('print_unprinted')} value={true} label={this.lang.print_unprinted} />
                            <Checkbox {...this.checkboxProps('isconfirm')} value={true} label={this.lang.send_email_confirm} />

                        </Editable> */}

                        <Submit>{this.props.ui.isCancel ?  this.lang.submitCancel : this.lang.submitOrder}</Submit>

                    </form>

                </Content> 

            </div>
        );
    }
}

export {OrderFetch};
