import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

const modal = document.getElementById('modal');

const Wrapper = styled.div`

    ${props => `
     
        display: flex;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        background: rgba(0, 0, 0, 0.5);
        z-index: 1000;
    `}
`;

const Container = styled.div`

    ${props => ` 
        
        padding: ${props.theme.size[1]};
        background: ${props.theme.color[7]};
        border-radius: ${props.theme.size[1]};
        max-height: 90%;
        overflow: auto;
    `}
`;

class Window extends React.Component {

    constructor(props){

        super(props);

        this.el = document.createElement('div');
    }

    componentDidMount(){

        modal.appendChild(this.el);
    }

    componentWillUnmount(){

        modal.removeChild(this.el);
    }

    render(){

        if(this.props.isOpen !== true){

            return null;
        }

        const Portal = <Wrapper><Container>{this.props.children}</Container></Wrapper>;

        return ReactDOM.createPortal(Portal, this.el);
    }
}

export default Window;
