import React from 'react';
import Title from 'components/gls-dumb/Title';
import Content from 'components/gls-dumb/Content';
import Field from 'components/gls-dumb/Field';
import {inject, observer} from 'mobx-react';
import {observable} from 'mobx';
import Button from 'components/gls-dumb/Button';
import ModalAlert from 'components/gls-dumb/ModalAlert';
import Validate from 'Validate';
import Header from 'components/smart/Header';

@inject('auth')
@inject('ui')

@observer class ResetPassword extends React.Component {

    lang = this.props.ui.lang.resetPassword;

    @observable data = new Map();
    @observable isOpen = false;
    @observable message;

    change = (v) => {

        this.data.set(v.name, v.value);
    }

    submit = () => {

        const fields = new Validate(this.data);

        fields.set('email').isFilled(this.lang.fillEmail).isEmail(this.lang.invalidEmail);

        const errors = fields.result();

        if(errors.size !== 0){

            this.message = errors.get('email').message;
            this.isOpen = true;
        }

        else {

            this.props.auth.forgot(this.data.get('email')).then((r) => {
           
                if(r.code !== 200){

                    // this.message = this.lang.failed;
                    this.message = this.lang.sent; // Same message regardless of status
                }

                else {

                    this.message = this.lang.sent; 
                }

                this.isOpen = true;

            });

        }
    }

    close = () => {

        this.isOpen = false;
    }

    render(){

        return(

            <div className="main">

                <Header />

                <ModalAlert isOpen={this.isOpen} ok={this.close}>{this.message}</ModalAlert>
        
                <Title>{this.lang.title}</Title>

                <Content>
                
                    <Field name="email" label={this.lang.email} value={this.data.get('email')} onChange={this.change} /> 
                    <Button onClick={this.submit}>{this.lang.reset}</Button>

                </Content>

            </div>
        );
    }
}

export default ResetPassword;
