import React from 'react';
import Component from 'Component';
import Header from 'components/smart/Header';
import Content from 'components/gls-dumb/Content';
import Columns from 'components/gls-dumb/Columns';
import {inject, observer} from 'mobx-react';
import Button from 'components/gls-dumb/Button';
import Submit from 'components/gls-dumb/Submit';
import Multifields from 'components/gls-dumb/Multifields';
import {action} from 'mobx';
import InfoBox from 'components/gls-dumb/InfoBox';
import theme from 'theme';
import {observable} from 'mobx';
import Field from 'components/gls-dumb/Field';
import Pagebreak from 'components/gls-dumb/Pagebreak';
import Radio from 'components/gls-dumb/Radio';
import NoticeBox from 'components/gls-dumb/NoticeBox';
import Title from 'components/gls-dumb/Title';
import ModalConfirm from 'components/gls-dumb/ModalConfirm';
import constants from 'constants.js';
import ImportDraft from 'components/smart/ImportDraft';
import api from 'api';
import styled from 'styled-components';
import Section from 'components/gls-dumb/Section';

const Text = styled.span`

    ${props => `

        margin-bottom: ${props.theme.size[1]};
    
        @media (min-width: ${props.theme.breakpoint}){

            margin-bottom: 0;
        }
    `}
`;

@inject('ui')
@inject('import')
@inject('attachment')
@inject('senders')

@observer class ImportMeasurements extends Component {

    lang = this.props.ui.lang.importMeasurements;
    store = this.props.import;

    @observable countedAmount = 0;
    @observable countedWeight = 0;
    @observable countedCubicMeter = 0;
    @observable attachmentIsOpen = false;
    @observable totalAmount = this.get('parcels_cnt') !== undefined ? this.get('parcels_cnt') : 0;

    constructor(props){

        super(props);

        this.validatePreviousPage();
        this.setDefaultMeasurements();
        this.countMeasurements(this.get('parcels'));
    }

    setDefaultDeliveryterms(){

        if(this.get('customs_documenttype') === undefined){

            this.set('deliveryterms', 'INCO60');
        }
    }

    setDefaultCurrency(){

        if(this.get('customs_currency') === undefined){

            this.set('customs_currency', 'EUR');
        }
    }

    validatePreviousPage(){

        if(this.props.import.validate('details') === false){

            this.props.import.validation.clear();
            this.back();
        }
    }

    change = v => {

        if(v.name === 'customs_documenttype'){

            if(v.value === 'FILE'){

                this.attachmentIsOpen = true;
            }

            if(v.value === 'FILE' || v.value === 'LATER'){

                this.setDefaultDeliveryterms();
                this.setDefaultCurrency();
            }
        }

        this.set(v.name, v.value);

        if(this.submitted === true){

            this.store.validate('measurements');
        }
    }

    setDefaultMeasurements(){

        if(this.get('counted_amount') === undefined){

            this.set('counted_amount', '0');
        }

        if(this.get('counted_weight') === undefined){

            this.set('counted_weight', '0');
        }
    }

    submit = e => {

        e.preventDefault();

        this.submitted = true;

        // If measurements are filled by hand use counted weight as total weight and not weight set on first page.

        if(this.get('fill_measurements') === true){

            this.set('total_weight', this.countedWeight.toString().replace(',', '.'));
        }

        if(this.props.import.validate('measurements') === false){

            this.props.ui.messages = this.props.import.messages;
        }

        else {

            // If import has new file save import as draft first.

            const status = this.get('customs_documenttype') === 'FILE' && this.get('file') !== undefined  ? 'DRAFT' : 'READY';

            this.props.import.save(status, 'measurements', this.props.match.params.id, (consignees, r) => {

                const id = r.data.id;

                if(this.get('customs_documenttype') === 'FILE' && this.get('file') !== undefined){

                    const url = api.endpoints.user.imports + r.data.id + '/invoice/';

                    // Upload file

                    this.props.ui.resolve(this.props.attachment.upload(this.get('file'), url), () => this.props.attachment.upload(this.get('file'), url), () => {

                        // Update import to READY 

                        this.props.import.save('READY', 'measurements', id, (consignees, r) => {

                            this.ready(consignees);
                        });
                    });
                }

                else {
                
                    this.ready(consignees);
                }
            });
        }
    }

    ready = (consignees) => {

        if(consignees === false){

            this.props.ui.afterClearMessages = this.goToImports;
            this.props.ui.messages = [this.lang.save_consignees_failed];
        }

        else {

            this.goToImports();
        }
    }

    goToImports = () => {

        const promises = [
        
            this.props.senders.fetchAll(),
            this.props.import.fetchAll()
        ];

        this.props.ui.resolve(promises, this.goToImports, () => {
       
            this.props.history.push('/imports/');
        });
    }

    back = () => {

        if(this.props.match.params.id === undefined){
        
            this.props.history.push('/import/details.html');
        }

        else {

            this.props.history.push('/import/' + this.props.match.params.id + '/details.html');
        }
    }

    get parcels(){

        return [

            {name: 'amount', label: this.lang.amount + ':', description: this.lang.amountHelp, width: '7em', maxlength: 2},
            {name: 'content', label: this.lang.content + ':', width: '20em', maxlength: 100},
            {name: 'weight', label: this.lang.weight + ':', maxlength: 7},
            {name: 'length', label: this.lang.length + ':', maxlength: 6},
            {name: 'width', label: this.lang.width + ':', maxlength: 6},
            {name: 'height', label: this.lang.height + ':', maxlength: 6}
        ];
    }

    @action countMeasurements = (rows) => {
 
        let totalAmount = 0;
        let totalWeight = 0;
        let totalCubicMeter = 0;

        if(rows !== undefined){

            rows.forEach((v, k) => {

                let amount = this.parseValue(v.get('amount'));

                if(isNaN(amount)){

                    amount = 0;
                }

                totalAmount += parseInt(amount, 10);

                let weight = this.parseValue(v.get('weight'));

                if(isNaN(weight)){

                    weight = 0;
                }

                totalWeight += amount * weight;

                let width = this.parseValue(v.get('width'));
                let height = this.parseValue(v.get('height'));
                let length = this.parseValue(v.get('length'));

                if(isNaN(width) || isNaN(height) || isNaN(length)){

                    width = 0;
                    height = 0;
                    length = 0;
                }

                totalCubicMeter += amount * width * height * length / 1000000;
            });
        }

        this.countedAmount = totalAmount;
        this.countedWeight = totalWeight;
        this.countedCubicMeter = Math.round(totalCubicMeter * 100) / 100;

        this.set('counted_amount', this.countedAmount.toString());
        this.set('counted_weight', this.countedWeight.toString());
    }

    parseValue = (v) => {

        if(v === ''){

            return 0;
        }

        if(typeof v === 'string'){

            v = v.replace(',', '.');
        }

        if(Number.isInteger(v)){

            return v;
        }

        return  parseFloat(v);
    }

    fillError = (k) => {

        if(this.error(k) === true){

            return theme.color[9];
        }

        return undefined;
    }

    error = (k) => {

        return this.props.import.validation.get(k) === undefined ? false : true;
    }

    selectFile = (file) => {

        if(file.name !== undefined){

            this.attachmentIsOpen = false;

            this.change({
            
                name: 'file',
                value: file
            });
        }
    }

    cancelFile = () => {

        this.props.import.form.delete('customs_documenttype');
        this.attachmentIsOpen = false;
    }

    removeFile = () => {

        this.props.import.form.delete('file');
        this.props.import.form.delete('invoice_files_id');
        this.props.import.form.delete('customs_documenttype');
    }

    cancel = () => {

        this.props.ui.customConfirm = {
  
            isOpen: true,
            message: this.lang.confirm_cancel,
            cancelHandler: this.props.ui.clearCustomConfirm,

            okHandler: () => {
            
                this.props.ui.clearCustomConfirm();
                this.props.history.push('/home/')
            },

            cancelText: this.lang.cancel_cancel,
            okText: this.lang.cancel_ok
        }
    }

    render(){
        
        return (

            <React.Fragment>

                <ModalConfirm

                    isOpen={this.attachmentIsOpen}
                    cancel={this.cancelFile}
                    ok={this.selectFile}
                    file={true}
                    text={{cancel: this.lang.cancel_file, ok: this.lang.choose_file}}

                >{this.lang.add_file}</ModalConfirm>

                <Header />

                <Section color="sub">

                    <Title>
                    
                        <Text>{this.lang.title}</Text>
                        <ImportDraft />
                        
                    </Title>

                </Section>
        
                <div className="main">

                    <form onSubmit={this.submit}>

                        {this.get('fill_measurements') && 

                            <React.Fragment>

                                <Pagebreak>{this.lang.measurements}</Pagebreak>

                                <Multifields
                                
                                    name="parcels"
                                    value={this.get('parcels')}
                                    fields={this.parcels}
                                    validation={this.props.import.validation.get('parcels')}
                                    validationType={2.0}
                                    onChange={r => {this.countMeasurements(r.value); this.change(r);}}
                                    breakpoint="800px"
                                />

                                <InfoBox>
                               
                                    <InfoBox.item color={this.fillError('counted_amount')}>
                                    
                                        {this.lang.counted_amount}: {this.get('counted_amount')} / {this.totalAmount} kpl
                                        
                                    </InfoBox.item>

                                    <InfoBox.item color={this.fillError('counted_weight')}>
                                    
                                        {this.lang.counted_weight}: {this.get('counted_weight').toString().replace('.', ',')} kg
                                        
                                    </InfoBox.item>

                                    <InfoBox.item>{this.lang.cubic_meter}: {this.countedCubicMeter} m³</InfoBox.item>

                                </InfoBox>

                            </React.Fragment>
                        }

                        {this.get('shipmenttype') === constants.typeCodes.euroBusinessFreight.toString() &&

                            <React.Fragment>

                                <Pagebreak>{this.lang.ontoploading}</Pagebreak>

                                <Columns>

                                    <Columns.left width="25%">

                                        <Radio {...this.fieldProps('ontoploading')}>

                                            <Radio.item value={true} label={this.lang.allowed} />
                                            <Radio.item value={false} label={this.lang.denied} />

                                        </Radio>

                                    </Columns.left>

                                    <Columns.right width="75%">
                                    
                                        {this.get('ontoploading') !== undefined &&
                                        
                                            <NoticeBox>{this.get('ontoploading') === true ? this.lang.charge : this.lang.noCharge}</NoticeBox>
                                        }

                                    </Columns.right>

                                </Columns>

                            </React.Fragment>
                        }

                        {this.props.import.requiresTariff &&

                            <React.Fragment>

                                <Pagebreak>{this.lang.customsClearance}</Pagebreak>
                            
                                {this.get('file') === undefined && (this.get('invoice_files_id') === undefined || this.get('invoice_files_id') === '') ?

                                    <React.Fragment>

                                        <Radio {...this.fieldProps('customs_documenttype')}>
                                        
                                            <Radio.item value="FILE" label={this.lang.pdf} />
                                            {/* <Radio.item value="LATER" label={this.lang.later} /> */}
                                        
                                        </Radio>

                                        <NoticeBox marginBottom={theme.size[1]}>{this.lang.info}</NoticeBox>

                                    </React.Fragment>

                                    :

                                    <React.Fragment>

                                        <Content>

                                            <Columns gap="small">
                                            
                                                <Columns.left color={this.props.import.validation.get('file') !== undefined ? theme.color[9] : undefined}>
                                                
                                                    {this.lang.selected_file}: {this.get('file') !== undefined ? this.get('file').name : this.get('invoice_files_id')}
                                                
                                                </Columns.left>

                                                <Columns.right>

                                                    <Button
                                                    
                                                        onClick={() => {this.removeFile()}}
                                                        borderRadius={0}
                                                        backgroundColor={theme.color[1]}

                                                    >{this.lang.remove_file}</Button>

                                                </Columns.right>

                                            </Columns>

                                        </Content>

                                        <br />

                                    </React.Fragment>
                                }

                                {(this.get('customs_documenttype') === 'FILE' || this.get('customs_documenttype') === 'LATER') && 

                                    <Columns>

                                        <Columns.left>
                                    
                                            <Field

                                                {...this.fieldProps('deliveryterms')}
                                                type="select"
                                                label={this.lang.deliveryterms}
                                                options={this.props.import.terms}
                                            />

                                        </Columns.left>

                                        <Columns.right>

                                            <Columns gap="small">

                                                <Columns.left>
                                               
                                                    <Field
                                                    
                                                        {...this.fieldProps('customs_value')}
                                                        type="text" 
                                                        label={this.lang.customs_value}
                                                        maxLength="7"
                                                    />

                                                </Columns.left>

                                                <Columns.right>
                                                
                                                    <Field
                                                    
                                                        {...this.fieldProps('customs_currency')}
                                                        type="select"
                                                        label={this.lang.customs_currency}
                                                        options={this.props.import.currencies}
                                                    />
                                                
                                                </Columns.right>

                                            </Columns> 
                                        
                                        </Columns.right>

                                    </Columns>
                                }

                            </React.Fragment>
                        }

                        <Columns gap="small">
                    
                            <Columns.left>

                                <Button onClick={this.back}>{this.lang.back}</Button>
                            
                            </Columns.left>

                            <Columns.right>
                            
                                <Submit>{this.lang.submit}</Submit>
                            
                            </Columns.right>

                        </Columns>

                    </form>                    

                </div>

            </React.Fragment>
        );
    }
}

export default ImportMeasurements;
