import React from 'react';
import styled from 'styled-components';

const Container = styled.div`

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background: ${props => props.theme.color[4]};
    margin-bottom: ${props => props.theme.size[1]};
`;

const Item = styled.div`

    padding: ${props => props.theme.size[1]};
    ${props => props.color !== undefined && 'color: '+props.theme.color[9]+';'}
`;

class InfoBox extends React.Component {

    static item = (props) => {

        return <Item color={props.color}>{props.children}</Item>
    }

    render(){

        return (

            <Container>{this.props.children}</Container>
        );
    }
}

export default InfoBox;
