import React from 'react';
import styled from 'styled-components';

const Container = styled.div(props => `

    background: ${props.theme.color[7]};
`);

function Wrapper(props){

    return <Container>{props.children}</Container>;
}

export default Wrapper;
