import React from 'react';
import styled from 'styled-components';

const Container = styled.div`

    display: flex;
    justify-content: center;
`;

class Center extends React.Component {

    render(){

        return (

            <Container>{this.props.children}</Container>
        );
    }
}

export default Center;
