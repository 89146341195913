import React from 'react';
import 'components/gls-dumb/FileUpload/css/FileUpload.css';

const FileUpload = (props) => {

    let id = props.id;

    if(id === undefined){

        id = 'file';
    }

    let classNames = "FileUpload";

    if(props.className) {
        classNames += ' '+props.className;
    }

    let onChange = (e) => {

        props.onChange(e.target.files[0]);
        document.getElementById(id).reset();
    };

    return (
        <form className={classNames} onSubmit={(e) => {e.preventDefault(); }} id={id}>
            <label>
                {props.children}
                <input onChange={onChange} type="file" name="file"/>
            </label>
        </form>
    );
}

export default FileUpload;
