import React from 'react';
import Pagebreak from 'components/gls-dumb/Pagebreak';
import PopUpList from 'components/gls-dumb/PopUpList';
import Columns from 'components/gls-dumb/Columns';
import Radio from 'components/gls-dumb/Radio';
import Field from 'components/gls-dumb/Field';
import Checkbox from 'components/gls-dumb/Checkbox';
import {observer} from 'mobx-react';
import theme from 'theme';

@observer class PriceToolImport extends React.Component {

    lang = this.props.ui.lang.price_tool;
    fieldProps = this.props.fieldProps;
    checkboxProps = this.props.checkboxProps;


     get pickuppointsOptions(){

        const pickuppoints = [];

        this.props.pickuppoints.data.forEach(v => {

            const details = [];

            if(v.address1 !== ''){

                details.push(v.address1);
            }

            if(v.city !== ''){

                details.push(v.city);
            }

            if(v.postalnumber !== ''){

                details.push(v.postalnumber);
            }

            let pickuppoint = '[' + (v.nickname || v.name1) + '] ' + v.name1 + ' (' + details.join(', ') + ')';

            pickuppoints.push({

                key: v.id,
                value: pickuppoint
            });
        });

        return pickuppoints;
    }


    render(){

        return (

            <React.Fragment>

                <Columns>

                    <Columns.left>

                        <Pagebreak>{this.lang.pickup_title}</Pagebreak>

                        <Columns gap="small">

                            <Columns.left width="25%">

                                <Field {...this.fieldProps('from_postalnumber')} label={this.lang.consignee_postalnumber} />

                            </Columns.left>

                            <Columns.right width="75%">

                                <Field {...this.fieldProps('from_country')} type="select" label={this.lang.consignee_country} options={this.props.countries.optionsWithCode(false, ['10000'], this.props.selectedService('SHOP'))} />



                            </Columns.right>

                        </Columns>

                    </Columns.left>

                    <Columns.right>

                        <Pagebreak>{this.lang.shipment_title}</Pagebreak>

                        <Field {...this.fieldProps('shipment_type')} type="select" label={this.lang.shipment_type} options={this.props.shipmentTypeOptions} />
			<Checkbox {...this.checkboxProps('insurance')} value={true} label={<span><span style={{color: theme.color[1], fontSize: '28px', fontStyle: 'italic'}}>AddOnLiability</span><span style={{color: theme.color[1], fontSize: '28px', fontStyle: 'italic', fontWeight: 'bold'}}>Service</span></span>} info={this.lang.insurance_info} />

                    </Columns.right>

                </Columns>

		<Pagebreak>{this.lang.serviceType}</Pagebreak>


                        <Radio {...this.fieldProps('service')}>

                            <Radio.item value="RETURN" label={<span><span style={{color: theme.color[1], fontSize: '28px', fontStyle: 'italic'}}>Pick&amp;Return</span><span style={{color: theme.color[1], fontSize: '28px', fontStyle: 'italic', fontWeight: 'bold'}}>Service</span></span>} info={this.lang.return_service_info}/>
                            <Radio.item value="SHIP" label={<span><span style={{color: theme.color[1], fontSize: '28px', fontStyle: 'italic'}}>Pick&amp;Ship</span><span style={{color: theme.color[1], fontSize: '28px', fontStyle: 'italic', fontWeight: 'bold'}}>Service</span></span>} info={this.lang.ship_service_info} />
                            <Radio.item value="SHOP" label={<span><span style={{color: theme.color[1], fontSize: '28px', fontStyle: 'italic'}}>ShopReturn</span><span style={{color: theme.color[1], fontSize: '28px', fontStyle: 'italic', fontWeight: 'bold'}}>Service</span></span>} info={this.lang.ship_service_info} />

                        </Radio>


			<Pagebreak>{this.lang.consignee_title}</Pagebreak>
			{!this.props.selectedService('SHIP') ? (
			  <div>
			    <PopUpList
			      {...this.fieldProps('deliver_to')}
			      label={this.lang.deliver_to}
			      list={this.pickuppointsOptions}
			      message={this.lang.pickuppoints_not_found}
			      marginBottom="4em"
			    />
			  </div>
			) : (
			  <div>
		            <Columns>
				<Columns.left>
			            <Field {...this.fieldProps('deliver_to_postalnumber')} label={this.lang.deliver_to_postalnumber} />
			 	 </Columns.left>
			 	 <Columns.right>
			            <Field {...this.fieldProps('deliver_to_country')} type="select" label={this.lang.deliver_to_country} options={this.props.countries.optionsWithCode(false, ['10000'])} />
			 	 </Columns.right>
			    </Columns>
			  </div>
			)}



		{/*    <Columns>

                        <Editable isEditable={this.props.selectedService('RETURN')}>

                            <PopUpList

                                {...this.fieldProps('deliver_to')}
                                label={this.lang.deliver_to}
                                list={this.pickuppointsOptions}
                                message={this.lang.pickuppoints_not_found}
                                marginBottom="4em"
                            />

                        </Editable>

                        <Editable isEditable={this.props.selectedService('SHIP')}>

                            <Columns gap="small">

                                <Columns.left width="25%">

                                    <Field {...this.fieldProps('deliver_to_postalnumber')} label={this.lang.deliver_to_postalnumber} />

                                </Columns.left>

                            </Columns>

                            <Field {...this.fieldProps('deliver_to_country')} type="select" label={this.lang.deliver_to_country} options={this.props.countries.optionsWithCode(false, ['10000'])} />

                        </Editable>


                </Columns>*/}

            </React.Fragment>
        );
    }
}

export default PriceToolImport;
