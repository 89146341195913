import ApiStore from 'stores/ApiStore'
import api from 'api.json';

class CustomsStore extends ApiStore {

    add = async data => {

        const options = {
        
            url: api.endpoints.user.customs
        };

        return await this.post(data, options);
    }
}

export default CustomsStore;
