import Validate from 'Validate';
import {observable} from 'mobx';
import ApiStore from 'stores/ApiStore';
import api from 'api.json';
import moment from 'moment';

class FetchStore extends ApiStore {

    constructor(stores){

        super(stores);
        this.stores = stores;
    }

    @observable form = new Map();
    @observable validation = new Map();

    validate = () => {
        
        const lang = this.stores.ui.lang.orderFetch;
        const fields = new Validate(this.form);

        fields.set('date_time_from').isFilled(lang.fillDateTimeFrom).datetime(lang.invalidDateTime);
        // fields.set('date_time_to').isFilled(lang.fillDateTimeTo).datetime(lang.invalidDateTime);

        this.validation.clear();

        const errors = fields.result();

        errors.forEach((v, k) => {
        
            this.validation.set(k, v);
        });
    }

    messages = () => {

        return Validate.parseMessages({fields: this.validation});
    }

    make = async shipments => {
	const infoValue = this.stores.ui.isCancel ? 'PERUTTU' : this.form.get('info');
        
        const data = {
       
            start_at: moment(this.form.get('date_time_from'), 'DD.MM.YYYY HH:mm').unix(),
            info: infoValue,
            isconfirm: this.form.get('isconfirm')
        }

        const settings = {url: api.endpoints.user.pickup.replace('{shipments}', shipments.join(','))};

        return await this.post(data, settings);
    }
}

export default FetchStore;
