import React from 'react';
import Header from 'components/smart/Header';
import Title from 'components/gls-dumb/Title';
import Pagebreak from 'components/gls-dumb/Pagebreak';
import Field from 'components/gls-dumb/Field';
import Columns from 'components/gls-dumb/Columns';
import Checkbox from 'components/gls-dumb/Checkbox';
import Grid from 'components/gls-dumb/Grid';
import Button from 'components/gls-dumb/Button';
import {inject, observer} from 'mobx-react';
import {observable, computed} from 'mobx';
import {bool} from 'Helpers';
import ModalAlerts from 'components/gls-dumb/ModalAlerts';
import Draft from 'components/smart/Draft';
import PopUpList from 'components/gls-dumb/PopUpList';
import Search from 'components/gls-dumb/Search';
import DateTimePicker from 'components/gls-dumb/DateTimePicker';
import moment from 'moment';
import {withID} from 'Helpers';
import Checkbox2 from 'components/gls-dumb/Checkbox2';
import Quicklink from 'components/gls-dumb/Quicklink';
import Radio from 'components/gls-dumb/Radio';
import Section from 'components/gls-dumb/Section';
import theme from 'theme';

@inject('parcel')
@inject('senders')
@inject('consignees')
@inject('countries')
@inject('settings')
@inject('ui')
@inject('pickuppoints')
@inject('import')
@inject('load')
@inject('shipmentsInfo')

@observer class ParcelSend extends React.Component {

    lang = this.props.ui.lang.shipmentSend;

    @observable validation = {fields: new Map()};
    @observable messages = [];
    @observable nextButtonText = this.lang.save;
    @observable preadviceErrors = [];
    @observable availableProducts = false;
    @observable parcelshops = [];

    @observable isOpen = {

        datepicker: false,
        preadvice: false
    }

    @observable editable = {
    
        parcelshop: false,
        fill_measurements: false,
        fds: false
    };

    country = {

        parcelshop: false,
        fds: false,
        shd: false,
        iseu: false
    }

    submitted = false; // After form is submitted once start validating on every change.

    automatic_preadvice_email = true;

    constructor(props){

        super(props);

        this.setDefaultDate();
        this.filledDefaults(); // Defaults if type and consignee country are already set (returning from second page)
        this.setDefaultSender();
        this.updateButtonText();
        this.setDefaultConsignee(); // Default consignee if user came here from consignees list
        this.setDefaultPickuppoint();
        this.setDefaultContent();
        this.setDefaultType();
        this.setDefaultPreadvice();
        this.setAvailableCountries(this.get('consignee_countries_id'));
        this.copyParcel(); // If copy is set use it as template for parcel.
        this.copyImport();
        this.copyPriceEstimation();
    }

    copyPriceEstimation = () => {

        if(this.props.parcel.priceEstimationCopy !== false){

            const copy = [
           
                ['pickuppoints_id', 'pickuppoints_id'],
                ['consignee_postalnumber', 'consignee_postalnumber'],
                ['consignee_country', 'consignee_countries_id'],
                ['shipment_type', 'type'],
                ['counted_amount', 'parcels_cnt'],
                ['counted_weight', 'total_weight'],
                ['measurements', 'parcels']
            ];

            copy.forEach(v => {

                // Simulates changes made by user from UI.

                this.change({

                    name: v[1],
                    value: this.props.parcel.priceEstimationCopy.get(v[0])
                });
            });

            this.props.parcel.priceEstimationCopy = false;
        }
    }

    copyImport = () => {

        if(this.props.parcel.importCopy !== false){

            const copy = [
            
                ['from_address1', 'consignee_address1'],
                ['from_address2', 'consignee_address2'],
                ['from_city', 'consignee_city'],
                ['from_contact', 'consignee_contact'],
                ['from_countries_id', 'consignee_countries_id'],
                ['from_email', 'consignee_email'],
                ['from_mobile', 'consignee_mobile'],
                ['from_name1', 'consignee_name1'],
                ['from_name2', 'consignee_name2'],
                ['from_postalnumber', 'consignee_postalnumber'],
                ['from_preadvice', 'preadvice'],
                ['from_preadvice_email', 'preadvice_email'],
                ['from_tel', 'consignee_tel']
            ];

            copy.forEach(v => {

                this.props.parcel.form.set(v[1], this.props.parcel.importCopy.get(v[0]));
            });

            this.props.parcel.importCopy = false;
        }
    }

    copyParcel = () => {

        if(this.props.parcel.copy !== false){

            const copy = [
            
                'consignee_address1',
                'consignee_address2',
                'consignee_city',
                'consignee_contact',
                'consignee_countries_id',
                'consignee_email',
                'consignee_mobile',
                'consignee_name1',
                'consignee_name2',
                'consignee_postalnumber',
                'consignee_tel',
                'type'
            ];

            copy.forEach(v => {

                this.props.parcel.form.set(v, this.props.parcel.copy[v]);
            });

            this.updateForm({name: 'consignee_countries_id' , value: this.get('consignee_countries_id')});
            this.updateForm({name: 'type', value: this.get('type')});
            this.props.parcel.copy = false;
        }
    }

    setDefaultType = () => {

        if(this.get('type') === undefined){
        
            const default_product = this.props.settings.object.default_product;

            this.change({name: 'type', value: default_product});
        }
    }

    setDefaultContent = () => {

        if(this.get('contents') === undefined){

            const content = this.props.settings.object.default_content;

            if(

                content !== undefined &&
                content !== ''
            ){

                this.set('contents', content);
            }
        }
    }

    setDefaultPickuppoint = () => {

        if(this.get('pickuppoints_id') === undefined){

            // If only one pickuppoint use tha as default

            if(this.pickuppoints.length === 1){

                this.set('pickuppoints_id', this.pickuppoints[0].key);
            }

            // Else use one on settings if set and pickuppoint exists.

            else {

                const pickuppoint = this.props.settings.object.default_pickuppoint;

                if(
                    pickuppoint !== undefined &&
                    pickuppoint !== '' &&
                    this.props.pickuppoints.exists(pickuppoint)
                ){
                
                    this.set('pickuppoints_id', pickuppoint);
                }
            }
        }
    }

    @computed get parcelTypes(){

        const products = [

            {value: this.props.parcel.types.euroBusinessParcel, option: this.lang.euroBusinessParcel},
            {value: this.props.parcel.types.euroBusinessFreight, option: this.lang.euroBusinessFreight},
            {value: this.props.parcel.types.globalBusinessParcel, option: this.lang.globalBusinessParcel},
            {value: this.props.parcel.types.globalExpressParcel, option: this.lang.globalExpressParcel}
        ];

        const types = [{value: '', option: '-' + this.lang.chooseShipmentType}];

        products.forEach(v => {

            // Show all by default and filtered according of available products.
            
            if(this.availableProducts === false || this.availableProducts.indexOf(v.value) !== -1){ // Remove last condition on production?

                types.push(v);
            }
        });

        return types;
    }

    @computed get parcelshopsIds() {
    const ret = this.parcelshops.map(item => {
        return { 
            value: item.psid,
            option: item.name1 + ", " + item.street1 + ", " + item.zipcode + ", " + item.postaddr };
    });

    if (ret.length === 0) {
        ret.push({ value: '', option: this.lang.noShops });
    }

    return ret;
   }
    @computed get consignees(){

        const consignees = [];

        this.props.consignees.data.forEach((v, k) => {

            const country = this.props.countries.getName(v.countries_id);

            let name = this.lang.placeholderName1;

            if(v.search !== undefined){

                name = v.search;
            }

            let keyword = '[' + name + ']';
            let comma = false;

            [v.name1, v.address1, v.city, country].forEach(v => {
            
                if(v !== false && v !== ''){

                    if(comma === true){

                        keyword += ', ';
                    }

                    else {

                        keyword += ' ';
                        comma = true;
                    }

                    keyword += v;
                }           
            });
        
            consignees.push({
            
                key: k,
                value: keyword
            });
        });

        return consignees;
    }

    setDefaultDate = () => {

        if(this.get('date') === undefined){

            this.set('date', moment().format('DD.MM.YYYY'));
        }
    }

    filledDefaults = () => {

        if(this.get('type') !== undefined && this.get('consignee_countries_id') !== undefined){

            this.updateForm({name: 'consignee_countries_id' , value: this.get('consignee_countries_id')});
            this.updateForm({name: 'type', value: this.get('type')});
        }
    }

    get pickuppoints() {

        const pickuppoints = [];

        this.props.pickuppoints.data.forEach((v, k) => {

            const details = [];

            if(v.address1 !== ''){

                details.push(v.address1);
            }

            if(v.city !== ''){

                details.push(v.city);
            }

            if(v.postalnumber !== ''){

                details.push(v.postalnumber);
            }

            let pickuppoint = '[' + (v.nickname || v.name1) + '] ' + v.name1 + ' (' + details.join(', ') + ')';
  
            pickuppoints.push({
            
                key: v.id,
                value: pickuppoint
            });
        });

        return pickuppoints;
    }

    setDefaultConsignee = () => {

        if(this.props.parcel.defaultConsignee !== false){
        
            this.selectConsignee({value: this.props.parcel.defaultConsignee});
            this.props.parcel.defaultConsignee = false;
        }
    }

    get senders(){

        // Senders list

        const senders = [];
        let senderFound = false;

        this.props.senders.data.forEach((v, k) => {

            if(k === this.get('senders_id')){

                senderFound = true; 
            }

            const details = [];

            if(v.address1 !== ''){

                details.push(v.address1);
            }

            if(v.city !== ''){

                details.push(v.city);
            }

            if(v.postalnumber !== ''){

                details.push(v.postalnumber);
            }

            let sender = v.name1 + ' (' + details.join(', ') + ')';

            senders.push({
            
                key: k,
                value: sender
            });
        });

        if(senderFound === false){

            this.unset('senders_id');
        }

        return senders;
    }

    setDefaultSender = () => {

        if(this.get('senders_id') === undefined){

            if(this.props.parcel.defaultSender !== false){

                // User came here from senders list.

                this.set('senders_id', this.props.parcel.defaultSender);
                this.props.parcel.defaultSender = false;
            }

            else {

                const settings = this.props.settings.object;
                
                let defaultSender = false;

                if(
                    settings['default_sender'] !== undefined &&
                    settings['default_sender'] !== '' &&
                    this.props.senders.senderExists(settings['default_sender'])
                ){

                    // If default is set and it exists.

                    defaultSender = settings['default_sender'];
                }

                if(defaultSender === false){

                    // If default is not set and there is only one sender use it as default.

                    defaultSender = this.props.senders.defaultSender;
                }

                if(defaultSender !== false){

                    this.set('senders_id', defaultSender);
                }
            }
        }
    }

    next = () => {

        if(this.get('total_weight') !== undefined){

            this.set('total_weight', this.get('total_weight').toString().replace(',', '.'));
        }

        this.submitted = true; 
        this.validation = this.props.parcel.validate('ParcelSend');

        if(this.props.parcel.errorCount(this.validation)){

            this.messages = this.props.parcel.messages(this.validation);
        }

        else {

            if(this.get('fill_measurements') === true || this.props.parcel.require.tariff === true){

                this.props.load.route('/parcel/measurements.html', url => {
                
                    this.props.history.push(withID('/parcel/measurements.html', this.props.match.params.id));
                });
            }

            else {

                // Resolver is at store in this case!

                this.props.parcel.add(this.props.match.params.id, (v, consignee) => {

                    if(consignee === false){

                        this.props.ui.messages = [this.lang.save_consignee_failed];

                        this.props.ui.afterClearMessages = () => {
                        
                            this.ready(v)
                        };
                    }

                    else {

                        this.ready(v);
                    }
                });
            }
        }
    }

    ready = v => {

        const afterSend = this.props.settings.object.after_send;
        const autoprint = this.props.settings.object.autoprint;

        const promises = [];

        if(afterSend === '/parcels/'){

            promises.push(this.props.parcel.load());
        }

        this.props.ui.resolve(promises, this.props.parcel.load, () => { 
          
            this.props.history.push(afterSend);
        });

        if(autoprint === true){

            this.props.parcel.printParcelcards([v.data.id]);
        }
    }

    change = v => {

        // Default email for preadvice if not set.

        if(v.name === 'preadvice'){

            if(this.get('preadvice_email') === undefined && this.props.settings.object.preadvice !== true){

                this.set('preadvice_email', this.get('consignee_email'));
            }
        }

        if(v.name === 'consignee_countries_id'){

            this.selectTypeByCountry(v.value); // Default if not set.

            // Dependency between two fields.

            this.updateForm(v);
            this.updateForm({name: 'type', value: this.get('type')});
        }

        if(v.name === 'type'){

            this.updateForm(v);
            this.updateForm({name: 'consignee_countries_id', value: this.get('consignee_countries_id')});

            // Require ontoploading on next page if type is EuroBusinessFreight.

            this.props.parcel.require.ontoploading = v.value === this.props.parcel.types.euroBusinessFreight ? true : false;
        }

        if(v.name === 'save_consignee'){ 

            this.set('edit_consignee', false);
        }

        if(v.name === 'edit_consignee'){ 
            
            this.set('save_consignee', false);
        }

        if(v.name === 'consignee_email' && this.automatic_preadvice_email === true){

            this.set('preadvice_email', v.value);
        }
	if(v.name === 'fds' && v.value === true) {
		this.set('parcelshop', false);
	}

        if(v.name === 'parcelshop') {
            if(v.value === true) {
		this.set('fds', false);
		this.set('shopsearch_postalnumber', this.get('consignee_postalnumber'));
	    	this.updateParcelshops();
            } else {
                this.resetParcelshops();
            }
        }

        this.set(v.name, v.value); // Set new value
        this.updateButtonText(); // After change show proper button text.

         // After first submit validate fields on every change!

        if(this.submitted === true){
 
            this.validation = this.props.parcel.validate('ParcelSend');
        }
    }

    updateButtonText = () => {

        if(this.get('fill_measurements') === true || this.props.parcel.require.tariff === true){

            this.nextButtonText = this.lang.next;
        }

        else { 
        
            this.nextButtonText = this.lang.save;
        }
    }

    // Updates selection based on filled values.

    updateForm = (v) => {

        if(v.name === 'consignee_countries_id'){

            this.country.fds = false; // Is FDS available in current country.
            this.country.shd = false; // Is ShopDelivery available in current country.

            if(v.value !== undefined){

                const country = this.props.countries.findCountry('id', v.value);

                if(country.fds === true){

                    this.country.fds = true;
                }
		 if(country.shd === true){

                    this.country.shd = true;
                }

            }
        }

        else if(v.name === 'consignee_postalnumber'){

        }

        else if(v.name === 'type'){

            if(v.value === '' || v.value === undefined){

                // Default settings after change of type.

                this.unset('parcelshop');
                this.editable.parcelshop = false;

                this.unset('fill_measurements');
                this.editable.fill_measurements = false;
                
                this.unset('fds');
                this.editable.fds = false;
            }

            else if(v.value !== this.props.parcel.types.euroBusinessParcel){

                // Anything else than EuroBusinessParcel

                this.props.parcel.form.delete('parcelshop');
                this.editable.parcelshop = false;

                this.set('fill_measurements', true); // Check this field if user have to fill measurements in next page.
                this.editable.fill_measurements = false;

                this.props.parcel.form.delete('fds');
                this.editable.fds = false;
            }

            else {

                // EuroBusinessParcel

                this.editable.parcelshop = this.country.shd;
                if(this.country.shd === false){

                    this.props.parcel.form.delete('parcelshop');
                }

                this.editable.fill_measurements = true; 

                this.editable.fds = this.country.fds; 
                if(this.country.fds === false){

                    this.props.parcel.form.delete('fds');
                } else {

                    const settings = this.props.settings.object;

                    if(settings.fds !== undefined && settings.fds === true){

                        this.set('fds', true); 
                    }
                }
            }
        }
    }

    set = (k, v) => {

        this.props.parcel.form.set(k, v);
    }

    unset = k => {
    
        this.props.parcel.form.delete(k);
    }

    get = k => {

        return this.props.parcel.form.get(k);
    }

    clearMessages = () => {

        this.messages = [];
    }

    fieldProps = (field) => {

        return {

            name: field,
            value: this.get(field),
            error: this.validation.fields.get(field) === undefined ? false : true,
            onChange: this.change
        };
    }

    checkboxProps = (checkbox) => {

        return {

            name: checkbox,
            checked: bool(this.get(checkbox)),
            onChange: this.change
        }
    }

    selectConsignee = (v) => {

        const consignee = this.props.consignees.data.get(v.value);
        
        const remap = {
        
            address1: 'consignee_address1',
            address2: 'consignee_address2',
            city: 'consignee_city',
            contact: 'consignee_contact',
            countries_id: 'consignee_countries_id',
            email: 'consignee_email',
            mobile: 'consignee_mobile',
            name1: 'consignee_name1',
            name2: 'consignee_name2',
            postalnumber: 'consignee_postalnumber',
            tel: 'consignee_tel',
            businessid: 'invoice_vatnumber',
            recipienttype: 'consignee_recipienttype'
        }

        for(let k in remap){

            if(k === 'countries_id'){

                this.selectTypeByCountry(consignee[k]);
            }

            this.props.parcel.form.set(remap[k], consignee[k]);
        }

        this.updateForm({name: 'consignee_countries_id', value: this.get('consignee_countries_id')});
        this.updateForm({name: 'type', value: this.get('type')});
        this.set('selected_consignee', consignee.id);
    }

    selectTypeByCountry = (countries_id) => {

        this.setAvailableCountries(countries_id);

        if(this.get('consignee_countries_id') !== undefined){

            if(this.availableProducts.indexOf(this.get('consignee_countries_id')) === -1){

                this.set('consignee_countries_id', undefined);
            }
        }

        // Default service when country is initially selected.

        if(this.get('consignee_countries_id') === undefined){

            const country = this.props.countries.findCountry('id', countries_id);
            const default_product = this.props.settings.object.default_product;

            if(default_product !== undefined && this.availableProducts.indexOf(default_product) !== -1){

                this.set('type', default_product);
            }

            else if(country.defaultproduct !== undefined){

                this.set('type', country.defaultproduct);
            }
        }
    }

    setAvailableCountries = countries_id => {

        if(countries_id !== undefined && countries_id !== ''){

            const country = this.props.countries.findCountry('id', countries_id);
            this.availableProducts = country.availableproducts;
        }
    }

    openDatePicker = () => {

        this.isOpen.datepicker = true;
    }
    
    closeDatePicker = () => {

        this.isOpen.datepicker = false;
    }

    doneDatePicker = (selected) => {

        this.change({
        
            name: 'date',
            value: moment(selected).format('DD.MM.YYYY')
        });

        this.isOpen.datepicker = false;
    }

    get preSelectedConsignee(){

        const selected = this.get('selected_consignee'); 

        if(selected !== undefined){

            let consignee;

            this.consignees.forEach(v => {

                if(v.key === selected){

                    consignee = v.value;
                }
            });

            return consignee;
        }
    }

    setDefaultPreadvice(){

        if(this.get('preadvice') === undefined){

            const preadvice = this.props.settings.object.preadvice;

            if(preadvice !== undefined){

                this.set('preadvice', preadvice);
            }
        }
    }

    openImport = () => {

        this.props.import.loadImportDetails(() => {
        
            this.props.history.push('/import/details.html');
        });
    }

    disableAutomaticPreadvice = () => {

        this.automatic_preadvice_email = false;
    }

    resetParcelshops = () => {
        this.parcelshops = [];
    }
    updateParcelshops = () => {
    this.resetParcelshops();

    const postalNumber = this.get('shopsearch_postalnumber')?.trim();
    
    if (!postalNumber) {
      //  this.parcelshops.push({ message: 'Invalid zip' });
       // this.change({ name: 'parcelshop_id', value: '' });
        return;
    }

    this.props.load.api(
        [ this.props.shipmentsInfo.getParcelshops({
            to_countries_id: this.get('consignee_countries_id'),
            to_postalnumber: postalNumber
        }) ],
        this.updateParcelshops,
        v => {
            v.data.forEach(item => {
                this.parcelshops.push(item);
            });
            this.change({ name: 'parcelshop_id', value: this.parcelshops.length > 0 ? this.parcelshops[0].psid : '' });
        }
    );
}
 /*   updateParcelshops = () => {
        this.resetParcelshops();
        this.props.load.api(
            [ this.props.shipmentsInfo.getParcelshops({
                to_countries_id: this.get('consignee_countries_id'),
                to_postalnumber: this.get('consignee_postalnumber'),
                to_address1: this.get('consignee_address1')
            }) ],
            this.updateParcelshops,
            v => {
                v.data.forEach(item => {
                    this.parcelshops.push(item);
                });
                this.change({name: 'parcelshop_id', value: this.parcelshops.length > 0 ? this.parcelshops[0].psid : ''});
            }
        );
    }*/

    render(){

        const fieldProps = this.fieldProps;
        const checkboxProps = this.checkboxProps;

        return (

            <React.Fragment>

                <DateTimePicker isOpen={this.isOpen.datepicker} onCancel={this.closeDatePicker} onDone={this.doneDatePicker} cancel={this.lang.cancel} />
                <ModalAlerts messages={this.messages} ok={this.clearMessages} />

                <Header />

                <Section color="sub">

                    <Title>

                        <Quicklink title={this.lang.title} options={[
                       
                            {value: 'export', option: this.lang.quick_export},
                            {value: 'import', option: this.lang.quick_import, callback: this.openImport}
                        ]} />

                        <Draft />

                    </Title>

                </Section>

                <div className="main">

                    <PopUpList {...fieldProps('senders_id')} label={this.lang.sender} list={this.senders} message={this.lang.senderNotFound} info={this.lang.senderHelp} /> 
                    <PopUpList {...fieldProps('pickuppoints_id')} label={this.lang.pickuppoint} list={this.pickuppoints} message={this.lang.senderNotFound} info={this.lang.pickuppointHelp} />

                    <Pagebreak>{this.lang.consigneeInfo}</Pagebreak>

                    <Columns>

                        <Columns.left>

                            <Search name="nick" onChange={this.selectConsignee} label={this.lang.searchName} from={this.consignees} message={this.lang.searchNameNotFound} info={this.lang.nickHelp} value={this.preSelectedConsignee} /> 
                            <Field type="text" {...fieldProps('consignee_name1')} label={this.lang.name1} maxLength="40" />
                            <Field type="text" {...fieldProps('consignee_name2')} label={this.lang.name2} maxLength="40" />
                            <Field type="text" {...fieldProps('consignee_address1')} label={this.lang.address1} maxLength="40" />
                            <Field type="text" {...fieldProps('consignee_address2')} label={this.lang.address2} maxLength="40" />

                            <Columns gap="small">

                                <Columns.left width="25%">
                            
                                    <Field type="text" {...fieldProps('consignee_postalnumber')} label={this.lang.postalnumber} maxLength="10" />
                                
                                </Columns.left>

                                <Columns.right width="75%">
                                    
                                    <Field type="text" {...fieldProps('consignee_city')} label={this.lang.city} maxLength="40" />
                                
                                </Columns.right>

                            </Columns>

                            <Field type="select" {...fieldProps('consignee_countries_id')} label={this.lang.country} options={this.props.countries.optionsWithCode(false)} />

                        </Columns.left>

                        <Columns.right>
                            
                            <Field type="text" {...fieldProps('consignee_contact')} label={this.lang.contact} maxLength="40" info={this.lang.consigneeContactHelp} />
                            <Field type="text" {...fieldProps('consignee_tel')} label={this.lang.tel} maxLength="40" />
                            <Field type="text" {...fieldProps('consignee_mobile')} label={this.lang.mobile} maxLength="40" />
                            <Field type="text" {...fieldProps('consignee_email')} label={this.lang.email} maxLength="100" />

                            <Checkbox {...checkboxProps('save_consignee')} value={true} label={this.lang.saveConsignee} info={this.lang.saveConsigneeHelp} />
                            {this.get('save_consignee') === true && <Field type="text" {...fieldProps('consignee_search')} label={this.lang.nick} />}
                            {this.get('selected_consignee') !== undefined && <Checkbox {...checkboxProps('edit_consignee')} value={true} label={this.lang.editConsignee} />}

                            <Radio {...this.fieldProps('consignee_recipienttype')}>

                                <Radio.item value="2C" label={this.lang.recipienttype_private_address} info={this.lang.recipienttype_private_address_info} width="50%" />
                                <Radio.item value="2B" label={this.lang.recipienttype_business_address} info={this.lang.recipienttype_business_address_info} width="50%" />

                            </Radio>
                    
                        </Columns.right>

                    </Columns>
                
                    <Pagebreak>{this.lang.shipmentInfo}</Pagebreak>

                    <Columns>

                        <Columns.left>

                            <Field type="select" {...fieldProps('type')} label={this.lang.type} options={this.parcelTypes} info={this.lang.typeHelp} />
                            <Field type="text" {...fieldProps('ref')} label={this.lang.ref} maxLength="40" info={this.lang.refHelp} />
                            <Field type="text" {...fieldProps('description')} label={this.lang.description} maxLength="40" info={this.lang.descriptionHelp} />
                    
                        </Columns.left>

                        <Columns.right>

                            <Field type="text" {...fieldProps('contents')} label={this.lang.content} maxLength="100" info={this.lang.contentHelp} />
                            <Field type="calendar" {...fieldProps('date')} label={this.lang.date} datePicker={this.openDatePicker} maxLength="10" info={this.lang.dateHelp} />

                            <Columns gap="small">

                                <Columns.left><Field type="text" {...fieldProps('parcels_cnt')} label={this.lang.parcelsCnt} maxLength="3"/></Columns.left>
                                <Columns.right>{this.get('fill_measurements') !== true && <Field type="text" {...fieldProps('total_weight')} label={this.lang.totalWeight} maxLength="7" info={this.lang.totalWeightHelp} />}</Columns.right>

                            </Columns>

                        </Columns.right>

                    </Columns>

                    <Grid width="50%">

                        <Grid.item><Checkbox {...checkboxProps('fds')} value={true} editable={this.editable.fds} label={<span><span style={{color: theme.color[1], fontSize: '28px', fontStyle: 'italic'}}>FlexDelivery</span><span style={{color: theme.color[1], fontSize: '28px', fontStyle: 'italic', fontWeight: 'bold'}}>Service</span></span>} info={this.lang.flexHelp} /></Grid.item>
                        <Grid.item><Checkbox {...checkboxProps('insurance')} value={true} label={<span><span style={{color: theme.color[1], fontSize: '28px', fontStyle: 'italic'}}>AddOnLiability</span><span style={{color: theme.color[1], fontSize: '28px', fontStyle: 'italic', fontWeight: 'bold'}}>Service</span></span>} info={this.lang.insuranceHelp} /></Grid.item>
                        <Grid.item><Checkbox {...checkboxProps('parcelshop')} value={true} editable={this.editable.parcelshop} label={<span><span style={{color: theme.color[1], fontSize: '28px', fontStyle: 'italic'}}>ShopDelivery</span><span style={{color: theme.color[1], fontSize: '28px', fontStyle: 'italic', fontWeight: 'bold'}}>Service</span></span>} info={this.lang.shopHelp} />
                    	{this.get('parcelshop') === true && (
				<>
				    <Columns gap="medium">
					<Columns.right width="70%">
	                            	    <Field type="text" {...fieldProps('shopsearch_postalnumber')} label={this.lang.searchpostalnumber} maxLength="10" /> 
					</Columns.right>
					<Columns.left width="25%">
				      	    <Button onClick={() => {this.updateParcelshops()}} style={{ marginLeft: '10px' }}>{this.lang.searchbutton}</Button>
					</Columns.left>
				    </Columns>
				    <Field type="select" {...fieldProps('parcelshop_id')} label={this.lang.shopselect} options={this.parcelshopsIds} />

				</>)
                    	}
			</Grid.item>
                        <Grid.item><Checkbox2 {...checkboxProps('preadvice')} value={true} label={this.lang.preadvice} info={this.lang.preadviceHelp} />
			                    {this.get('preadvice') === true &&

                        <Columns>

                            <Columns.left> 
                                
                                <Field type="text" {...fieldProps('preadvice_email')} label={this.lang.preadviceEmail} maxLength="100" onKeyDown={this.disableAutomaticPreadivce} />
                            
                            </Columns.left>

                        </Columns>
                    }

			</Grid.item>
                        <Grid.item><Checkbox {...checkboxProps('fill_measurements')} value={true} editable={this.editable.fill_measurements} label={this.lang.fillMeasurements} info={this.lang.fillMeasurementsHelp} /></Grid.item>

                    </Grid>


                    <Button onClick={() => {this.next()}}>{this.nextButtonText}</Button>

                </div>

            </React.Fragment>
        );
    }
}

export default ParcelSend;
