import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const I = styled.i`

    color: ${props => props.color};
    font-size: ${props => props.size};
    cursor: ${props => props.cursor};
    
    ${props => props.hover !== false && ' &:hover {color: '+props.hover+'}'}
`;

class Icon extends React.Component {

    render(){

        switch (this.props.type) {

            case 'info': this.className = 'fas fa-info-circle'; break;
            case 'check': this.className = 'fas fa-check'; break;
            case 'search': this.className = 'fas fa-search'; break;
            case 'calendar': this.className = 'fas fa-calendar-alt'; break;
            case 'minus': this.className = 'fas fa-minus'; break;
            case 'plus': this.className = 'fas fa-plus'; break;
            case 'bars': this.className = 'fas fa-bars'; break;
            case 'user-cog': this.className = 'fas fa-user-cog'; break;
            case 'exchange': this.className = 'fas fa-exchange-alt'; break;
            case 'cog': this.className = 'fas fa-cog fa-spin'; break;
            case 'sign-out': this.className = 'fas fa-sign-out-alt'; break;
            case 'user-edit': this.className = 'fas fa-user-edit'; break;
            case 'file-upload': this.className = 'fas fa-file-upload'; break;
            case 'sort-up': this.className = 'fas fa-sort-amount-up'; break;
            case 'sort-down': this.className = 'fas fa-sort-amount-down'; break;
            case 'edit': this.className = 'fas fa-edit'; break;

            default: this.className = false;
        }

        if(this.props.show === false){

            return false;
        }

        else {

            return <I className={this.className} {...this.props} />;
        }
    }
}

Icon.propTypes = {
    
    /** Type of the icon.  */
    type: PropTypes.oneOf([
    
        'info',
        'check',
        'search',
        'calendar',
        'minus',
        'plus',
        'bars',
        'user-cog',
        'exchange',
        'cog',
        'sign-out',
        'user-edit',
        'file-upload',
        'sort-up',
        'sort-down',
        'edit'

    ]).isRequired,

    /** Display component */
    show: PropTypes.bool,

    /** Icon size */
    size: PropTypes.string,

    /** On click event */
    onClick: PropTypes.func,

    /** Color */
    color: PropTypes.string,

    /** Cursor */
    cursor: PropTypes.string,

    /** Hover color */
    hover: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
}

Icon.defaultProps = {

    show: true,
    size: '2em',
    cursor: 'inherited',
    color: 'inherited',
    hover: false
}

export default Icon;
