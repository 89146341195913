import React from 'react';
import styled from 'styled-components';
import ReactDOMServer from 'react-dom/server';
import PropTypes from 'prop-types';
import theme from 'theme';
import Order from 'components/gls-dumb/Order';
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import {isNumeric} from 'Helpers';
import Checkbox from 'components/gls-dumb/Checkbox';

const Wrapper = styled.table`

    ${props => `

        width: 100%;
        ${props.pagination === undefined && `margin-bottom: ${props.marginBottom};`}

        & > thead > tr > td {

            padding: ${props.theme.size[1]};
            background: ${props.theme.color[1]};
            color: ${props.theme.color[7]};
            border: 1px solid ${props.theme.color[1]};
            white-space: nowrap;

            @media (min-width: ${props.breakpoint}){

                border: 2px solid ${props.theme.color[1]};
            }
        }

        & > tbody > tr > td {

            padding: ${props.theme.size[1]};
            border-bottom: 1px solid ${props.theme.color[1]};
            vertical-align: middle;

            @media (min-width: ${props.breakpoint}){

                border: 0;
                border-bottom: 2px solid ${props.theme.color[1]};
            }
        }

        & > tbody > tr > td:first-of-type {
            
            border-top: 1px solid ${props.theme.color[1]};
        }

        & > tbody > tr > td:last-of-type {
            
            border-bottom: 0;

            @media (min-width: ${props.breakpoint}){

                border-bottom: 2px solid ${props.theme.color[1]};
            }
        }

        & > tbody tr:last-of-type td:last-of-type {

            border-bottom: 1px solid ${props.theme.color[1]};

            @media (min-width: ${props.breakpoint}){

                border-bottom: 2px solid ${props.theme.color[1]};
            }
        }
    `}
`;

const Thead = styled.thead`

    display: none;

    @media (min-width: ${props => props.breakpoint}){

        display: table-header-group;
    }
`;

const Tbody = styled.tbody`

    @media (max-width: ${props => props.breakpoint}){

        display: block;
    }
`;

const Tr = styled.tr`

     @media (max-width: ${props => props.breakpoint}){

        display: block;
    }
`;

const Td = styled.td`

    ${props => props.width !== undefined && `width: ${props.width};`}

    ${props => props.paddingRight !== undefined && `
    
        padding-right: ${props.paddingRight}
    `};

    ${props => props.paddingBottom !== undefined && `
    
        padding-bottom: ${props.paddingBottom}
    `};

    ${props => props.padding !== undefined && `padding: ${props.padding};`}

    @media (max-width: ${props => props.breakpoint}){

        width: 100%;
        display: block;
        padding-right: 0;
        padding-bottom: 0;

        ${props => props['data-title'] !== undefined && `

            text-align: right;

            &:before {

                float: left;
                content: '${props['data-title']}';
            }
        `}
    }
`;

const extractText = str => {

    const span = document.createElement('span');
    span.innerHTML = ReactDOMServer.renderToString(str);

    return span.textContent || span.innerText;
}

const removeChildren = props => {

    const newProps = {};

    for(let k in props){

        if(k !== 'children'){

            newProps[k] = props[k];
        }
    }

    return newProps;
}

const Pagination = styled.div`

    ${props => `
    
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${props.theme.color[1]};
        padding: ${props.theme.size[1]};
        margin-bottom: ${props.marginBottom};
    `}
`;

const Browse = styled.button`

    ${props => `
   
        display: flex;
        align-items: center;
        justify-content: center;
        width: ${props.theme.inputSize};
        height: ${props.theme.inputSize};
        background-color: ${props.theme.color[1]};
        color: ${props.theme.color[7]};

        &:hover {

            background-color: ${props.theme.color[10]};
            color: ${props.theme.color[1]};
        }
    `}
`;

const Page = styled.div`

    ${props => `
   
        margin-left: ${props.theme.size[1]};
        margin-right: ${props.theme.size[1]};
    `}
`;

 @observer class Sortable extends React.Component {

    @observable orders = new Map(); // Current value of column keys that have sort options.
    @observable content;
    @observable selected = {};

    constructor(props) {

        super(props);

        this.init(props);
    }

    init = props => {

        this.page = 0;
        this.pages = 1;
        this.content = props.children;
        this.original = this.content;

        if(props.children.length === 2){

            let tbody = props.children[0];
            let tr = tbody.props.children;
            let td = tr.props.children;

            this.titles = [];

            if(Array.isArray(td) === false){

                td = [td];
            }

            td.forEach((v, k) => {

                // Support for conditional rendering.

                if(v !== false){

                    if(v.props.sortable !== undefined && v.props.sortable === true){

                        if(v.props.order !== undefined && (v.props.order === 'asc' || v.props.order === 'desc')){

                            this.orders.set(k, v.props.order);
                        }

                        else {

                            this.orders.set(k, 'false');
                        }
                    }
                    
                    this.titles.push(extractText(v.props.children));
                }
            });
        }

        this.content = this.sort(); // If default order is set.
        this.updated = false;

        return true;
    }

    shouldComponentUpdate(props){

        if(this.props === props){

            return false;
        }

        return this.init(props);
    }

    static thead = (props) => {
 
        return <Thead {...props} />
    }

    static tbody = (props) => {

        return <Tbody {...props} />
    }

    static tr = (props) => {

        return <Tr {...props} />
    }

    static td = (props) => {

        if(props.sortable !== undefined && props.sortable === true){

            return (
            
                <Td {...removeChildren(props)} onClick={props.toggle}>
                
                    <Order type={props.order}>{props.children}</Order>
                    
                </Td>
            );
        }

        return <Td {...props} />
    }

    toggleOrder = column => {

        this.orders.forEach((v, k) => {

            if(k !== column && this.orders.get(k) !== 'false'){
        
                this.orders.set(k, 'false');
            }
        });

        if(this.orders.get(column) === 'asc'){

            this.orders.set(column, 'desc');
        }

        else {

            this.orders.set(column, 'asc');
        }

        this.content = this.sort();
    }

    browse = page => {

        this.page = this.page + page;
        this.content = this.sort();
    }

    sort = () => {

        let column = {};

        this.orders.forEach((v, k) => {
        
            if(v !== 'false'){

                column = {i: k, order: v};
            }
        });

        if(column.order !== undefined || this.props.pagination !== undefined){

            const arranged = React.Children.map(this.original, (group, k) => {

                // First group sould be thead.

                if(k === 0){

                    return React.Children.map(group, tr => tr); // Return thead unchanged.
                }

                // Reorder content of tbody.

                else {

                    let children = [];
     
                    React.Children.map(group.props.children, (tr, i) => {

                        children.push(tr);
                    });

                    // Order

                    if(column.order !== undefined){

                        children.sort((a, b) => {

                            a = a.props.children[column.i].props;
                            b = b.props.children[column.i].props;

                            // If td has value prop order column with value.

                            if(a.value !== undefined && b.value !== undefined){

                                a = a.value;
                                b = b.value;
                            }

                            // Otherwise use children.

                            else {

                                a = a.children;
                                b = b.children;
                            }

                            // Start comparing of a and b contents.

                            if(isNumeric(a) && isNumeric(b)){

                                if(column.order === 'asc'){

                                    return a - b;
                                }

                                else {

                                    return b - a;
                                }
                            }

                            else {

                                if(column.order === 'asc'){

                                    return ('' + a).localeCompare(b); 
                                }

                                else {

                                    return ('' + b).localeCompare(a);
                                }
                            } 
                        });
                    }

                    // Pagination

                    if(this.props.pagination !== undefined){

                        const limit = parseInt(this.props.pagination, 10);
                        const from = this.page * limit;
                        const to = this.page * limit + limit - 1;
                        const total = children.length;

                        this.pages = Math.ceil(total / limit);

                        const filtered = [];

                        children.forEach((v, i) => {

                            if(i >= from && i <= to){

                                filtered.push(v);
                            }                       
                        });

                        children = filtered;
                    }

                    return React.cloneElement(group, {children: children});
                }
            });

            return arranged;
        }

        return this.content;
    }

    select = (v, id, callback) => {

        if(this.select[v.name] === undefined){

            this.select[v.name] = new Map();
        }

        if(v.value === undefined){

            this.select[v.name].delete(id);
        }

        else if(v.value === true){

            this.select[v.name].set(id, true);
        }

        callback(this.select[v.name]);
    }

    selected = (name, id) => {

        if(this.select[name] === undefined){

            return false;
        }

        const selected = this.select[name].get(id);

        if(selected === undefined){

            return false;
        }

        return true;
    }

    render(){

        let children = React.Children.map(this.content, (group, k) => {

            return React.cloneElement(group, {breakpoint: this.props.breakpoint},
            
                React.Children.map(group.props.children, tr => {

                    return React.cloneElement(tr, {breakpoint: this.props.breakpoint},

                        React.Children.map(tr.props.children, (td, i) => {

                            // Support for conditional rendering.

                            if(td !== null){

                                let children;

                                if(td.props.checkbox === undefined){

                                    children = td.props.children;
                                }

                                else {

                                    children = <Checkbox

                                        name={td.props.checkbox.name}
                                        value={true} marginBottom="0"
                                        checked={this.selected(td.props.checkbox.name, td.props.checkbox.id)}
                                        onChange={v => this.select(v, td.props.checkbox.id, td.props.checkbox.callback)}
                                    />;
                                }

                                return React.cloneElement(td, {
                                
                                    'data-title': this.titles[i],
                                    breakpoint: this.props.breakpoint,
                                    toggle: () => this.toggleOrder(i),
                                    order: this.orders.get(i),
                                    children: children
                                });
                            }

                            return false;
                        })
                    )}
                )
            );
        });

        return (

            <React.Fragment>

                <Wrapper breakpoint={this.props.breakpoint} marginBottom={this.props.marginBottom}>{children}</Wrapper>

                {this.pages !== 1 &&
                
                    <Pagination marginBottom={this.props.marginBottom}>
                    
                        {this.page !== 0 && <Browse onClick={() => this.browse(-1)}><i className="fas fa-caret-left" /></Browse>}
                        <Page>{this.page + 1} / {this.pages}</Page>
                        {this.page + 1 !== this.pages && <Browse onClick={() => this.browse(1)}><i className="fas fa-caret-right" /></Browse>}
                        
                    </Pagination>
                }

            </React.Fragment>
        );
    }

    static defaultProps = {

        breakpoint: theme.breakpoint,
        marginBottom: theme.size[1]
    }

    static propTypes = {
    
        /** Sortable.thead, Sortable.tbody, Sortable.tr or Sortable.td */
        children: PropTypes.node
    }
}

export {Sortable};
