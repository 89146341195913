import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Wrapper = styled.div`

    display: flex;
    flex-wrap: wrap;
    margin-bottom: ${props => props.marginBottom};
`;

const Left = styled.div`

    width: 100%;
    margin-bottom: ${props => props.theme.size[1]};

    @media (min-width: ${props => props.theme.breakpoint}){
       
        margin-bottom: 0;
        width: ${props => props.width};
        padding-right: ${props => props.gap};
    }

    ${props => props.color !== undefined && `color: ${props.color};`}
`;

const Right = styled.div`

    width: 100%;

    @media (min-width: ${props => props.theme.breakpoint}){

        width: ${props => props.width};
        padding-left: ${props => props.gap};
    }

    ${props => props.color !== undefined && `color: ${props.color};`}
`;

class Columns extends React.Component {

    static left = (props) => {

        return (
        
            <Left width={props.width} gap={props.gap} color={props.color}>{props.children}</Left>
        );
    }

    static right = (props) => {

        return (

            <Right width={props.width} gap={props.gap} color={props.color}>{props.children}</Right>
        );
    }

    render(){

        let gap = '0em';

        if(this.props.gap === 'normal'){
        
            gap = '1.56em';
        }

        else if(this.props.gap === 'small'){

            gap = '0.78em';
        }

        const children = React.Children.map(this.props.children, (child, index) => {

            const props = {
            
                width: child.props.width !== undefined ? child.props.width : this.props.width,
                gap: gap
            };

            return React.cloneElement(child, props);
        });

        return (
        
            <Wrapper marginBottom={this.props.marginBottom}>{children}</Wrapper>
        );
    }
}

Columns.propTypes = {

    /** Width of the column. */
    width: PropTypes.string,
    /** */
    gap: PropTypes.oneOf(['normal', 'small']),
}

Columns.defaultProps = {

    width: '50%',
    gap: 'normal',
    marginBottom: 0
}

export default Columns;
