import React from 'react'
import Menu from 'components/gls-dumb/Menu';
import logoFile from 'images/logo.png';
import styled from 'styled-components';
import {observer, inject} from 'mobx-react';
import {withRouter} from 'react-router-dom';
import {sortMap} from 'Helpers';
import Button from 'components/gls-dumb/Button';
import theme from 'theme';
import Title from 'components/gls-dumb/Title';
import moment from 'moment';
import {computed} from 'mobx';
import Section from 'components/gls-dumb/Section';

const Container = styled.div`

    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Logo = styled.img`

    ${props => `

        cursor: pointer;
        margin-left: ${props.theme.size[1]};

        @media (min-width: ${props.theme.breakpoint}){
       
            margin-left: 0;
            margin: 0;
        } 
    `}
`;

const Wrapper = styled.div`

    ${props => `
    
        display: flex;
        align-items: center;
    `};
`;

// Consept for using default sizes from theme.

const multiply = size => {

    return '3em';
}

const Info = styled.div`

    ${props => `
    
        padding-left: ${props.theme.size[1]};
        padding-right: ${props.theme.size[1]};
        color: ${props.theme.color[7]}


        @media (min-width: ${props.theme.breakpoint}){
        
            padding-right: ${multiply(props.theme.size[1])};
        }
    `};
`;

const Notify = styled.div(() => `

    background-color: ${theme.color[2]};
`);

const Center = styled.div(() => `

    @media screen and (min-width: 1280px){

        margin: auto;
        width: 1250px;
    }
`);

@withRouter
@inject('ui')
@inject('auth')
@inject('customers')
@inject('privileges')
@inject('settings')
@inject('senders')
@inject('pickuppoints')
@inject('parcel')

@observer class Header extends React.Component {

    lang = this.props.ui.lang.header;

    changeAccount = (v) => {

        this.props.settings.change(v).then(() => {
        
            window.location = window.location.href; // Reload!
        });
    }

    openSettings = () => {

        const promises = [
        
            this.props.settings.load(),
            this.props.senders.load(),
            this.props.pickuppoints.load()
        ];

        this.props.ui.resolve(promises, this.openSettings, () => {

            this.props.history.push('/profile/settings.html');
        });
    }

    constructor(props){

        super(props);

        if(this.props.auth.isLoggedIn){

            const customers = this.props.customers.data.get(this.props.ui.customers.id);

            this.title = customers.name1;

            this.items = [{icon: 'user-cog', name: this.lang.settings, onClick: this.openSettings}];

            if(this.props.privileges.data.size > 1){

                sortMap(this.props.privileges.data, 'name').forEach((v, k) => {
     
                    this.items.push({icon: 'exchange', name: v.customernumber +" - " + v.name, to: '', onClick: (e) => {
                        
                        this.changeAccount(v.id);
                    }});
                });
            }

            this.items.push({icon: 'sign-out', name: this.lang.logout, onClick: () => {
            
                this.props.auth.logout();
                window.location.reload(true);
            }});
        }

        this.fetchParcels();
    }

    // Fetch current and previous month for waiting order info.

    fetchParcels = async () => {
        for (let i = 0; i <= 1; i++) {
            if (this.waitingShipments === true) {
                return;
            }
            const year = moment().subtract(i, 'months').format('YYYY');
            const month = moment().subtract(i, 'months').format('M');

            if(this.alreadyFetched(year, month) === false){

                this.props.ui.backgroundLoading++;
                this.props.ui.hideLoading = true;
                await this.props.parcel.fetchByMonth(year, month, 'shipments').then(() => {

                    this.props.ui.backgroundLoading--;

                    if(this.props.ui.backgroundLoading === 0){
                    
                        this.props.ui.hideLoading = false;
                    }
                });
            }
        }
    }

    alreadyFetched = (year, month) => {

        let fetched = false;

        this.props.parcel.fetched.forEach(v => {
        
            if(v.year === year && v.month === month){

                fetched = true;
            }
        });

        return fetched;
    }

    openParcels = () => {

        this.props.ui.resolve(this.props.parcel.fetchAll(), this.openParcels, () => {

            this.props.ui.selectedParcels.clear();
            this.props.history.push('/parcels/');
        });
    }

    loading = 0;

    @computed get waitingShipments(){

	let shipments = false;
        this.props.parcel.data.forEach(v => {

            if(v.status === 'READY'){
                shipments = true;
		return;
            }
        });
        return shipments;
    }

    showShipments = () => {

        this.props.ui.actions.useDefaultFilter = true;
        this.openParcels();
    }

    // {this.props.settings.all.email}

    render(){

        return (

            <React.Fragment>

                <Section color="main">

                    <Container>

                        <Logo src={logoFile} onClick={() => this.props.history.push('/home/')} />

                        {this.props.auth.isLoggedIn &&

                            <Wrapper>

                                <Menu title={this.title} items={this.items} />
                                <Info>{this.title}</Info>

                            </Wrapper>
                        }

                    </Container>

                </Section>

                {this.waitingShipments === true &&

                    <Notify>

                        <Center>

                            <Title backgroundColor={theme.color[2]} color={theme.color[8]}>
                            
                                {this.lang.shipments_waiting}
                                <Button width="auto" onClick={this.showShipments}>{this.lang.showShipments}</Button>
                                
                            </Title>

                        </Center>

                    </Notify>
                } 
        
            </React.Fragment>
        );
    }
}

export default Header;
