import Form from 'Form';

class FormGLS extends Form {

    sizeOk = (parcels, rules, message) => {

        let error = false;

        if(parcels !== undefined && rules !== undefined){

            let maxTotal = rules.maxTotal;
            let maxBiggest = rules.maxBiggest;
            let maxSecondly = rules.maxSecondly;
            let maxHeight = rules.maxHeight;
            let maxOther = rules.maxOther;

            parcels.forEach(v => {

                if(error === false){

                    const length = parseInt(v.get('length'), 10);
                    const height = parseInt(v.get('height'), 10);
                    const width = parseInt(v.get('width'), 10);

                    if( ! isNaN(length) && ! isNaN(height) && ! isNaN(width)){
                    
                        const measurements = [length, width, height].sort((a, b) => a - b);
                        const biggest = Math.max(...measurements);
                        const median = measurements[1];
                        const smallest = Math.min(...measurements);
                        const total = biggest + (2 * median) + (2 * smallest);

                        if(maxTotal !== undefined && total > maxTotal){

                            // console.log('Max total error: ', total);
                            error = true;
                        }

                        if(maxBiggest !== undefined && biggest > maxBiggest){

                            // console.log('Max biggert error: ', biggest);
                            error = true;
                        }

                        if(maxSecondly !== undefined && median > maxSecondly){

                            // console.log('Max secondly error: ', median);
                            error = true;
                        }

                        if(maxHeight !== undefined && height > maxHeight){

                            // console.log('Max height error: ', height);
                            error = true;
                        }

                        if(maxOther !== undefined){

                            maxOther = maxOther.sort((a, b) => a - b);
                            let other = [length, width].sort((a, b) => a - b);

                            other.forEach((v, k) => {
                            
                                if(other[k] > maxOther[k]){

                                    // console.log('Max other error: ', other[k], maxOther[k]);
                                    error = true;
                                }
                            });
                        }
                    }
                }
            });

            if(error === true){

                this.values.forEach((value, index) => {

                    this.setError(index, 'sizeOk', message);
                });
            }
        }

        return this;
    }
}

export default FormGLS;
