import { Component } from 'react';
import { observable, action, computed, transaction } from 'mobx';
import naturalSort from 'javascript-natural-sort';
import api from 'api.json';
import axios from 'axios';

export default class Apistore extends Component {

    constructor(root) {
        
        super();

        this.root = root;
        this.stores = root;
        this.hosts = [];
        this.url = false;
        this.resumeTime = 200000;

        api.hosts.forEach( (host) => {
            this.hosts.push({"host": host, "resumeTime": 0});
        });

    }

    // All data is saved here
    @observable data = new Map();

    // Url randomizer with working hosts
    prepareUrl = (url) => {

        // Get host randomly 
        const newHost = this.getWorkingHostRandomly();

        // Get all hosts and replace if found
        api.hosts.forEach( (oldHost) => {
            url = url.replace(oldHost, newHost);
        });

        return url.replace(/^\/\//, 'https://');

    }

    // Shuffle host
    @action shuffleHosts = () => {

        function shuffle (array) {
          var i = 0
            , j = 0
            , temp = null

          for (i = array.length - 1; i > 0; i -= 1) {
            j = Math.floor(Math.random() * (i + 1))
            temp = array[i]
            array[i] = array[j]
            array[j] = temp
          }
        }

        shuffle(this.hosts);
    }

    // Get single working host randomly
    getWorkingHostRandomly = () => {

        if(this.hosts.length === 0) {
            return false;
        }

        this.shuffleHosts();

        const time = new Date().getTime();

        for(let i = 0; i < this.hosts.length; i++) {
            if(this.hosts[i].resumeTime < time) {
                return this.hosts[i].host;
            }
        }

        // All hosts are broken! We can still try one..
        return this.hosts[0].host;

    }

    // Number of data elements
    @computed get cnt() {
        return Array.from(this.data).length;
    }

    // List data
    @computed get list() {

        if(this.sort) {

            let data = Array.from(this.data.values()).sort( (a, b) => {
                return naturalSort(a[this.sort], b[this.sort]);
            });

            if(this.desc === true) {
                return data.reverse();
            }

            return data;

        }

        return Array.from(this.data.values());

    }

    // Error when all retries and other means has been tried
    error = () => {
        // alert("Unexpected error has occured or no network connection available.");
        // window.location.reload(true);
        return false;
    }

    // Create cancel token
    cancelToken = () => {

        const cancelToken = axios.CancelToken;
        const source = cancelToken.source();
        return source;

    }

    // API call
    async fetch(data, settings) {

        if(data === undefined) {
            data = {};
        }

        if(settings === undefined) {
            settings = {};
        }

        // Fill url if not set earlier
        if(settings.url === undefined) {

            settings.url = this.url;

            if(data.id) {
                settings.url += "/" + data.id;
            }

        }

        // Fill method
        if(settings.method === undefined) {
            settings.method = "GET";
        }

        // Fill timeout
        if(settings.timeout === undefined) {
            settings.timeout = 5000;
        }

        // Prepare url
        settings.url = this.prepareUrl(settings.url);

        // Set loading status ON
        this.root.ui.setLoading(1);

//console.log("FETCH", settings.url, settings.method, settings.retry);

        try {

            let response = await axios({
                url: settings.url,
                method: settings.method,
                data: data,
                transformResponse: [(response) => (JSON.parse(response))], 
                cancelToken: settings.cancelToken,
                headers: {
                    'Pragma': 'no-cache',
                    'X-Authorization': 'Bearer ' +  this.root.auth.jwt,
                    'Authorization': 'Bearer ' +  this.root.auth.jwt
                },
                onUploadProgress: settings.progress
            });

            // Set loading status OFF
            this.root.ui.setLoading(-1);

//console.log("FETCH OK", settings.url, response.data);

            if(response.data === {}) {
                return this.error();
            }

            return response.data;

        } catch (err) {

//console.log("FETCH ERROR", settings.url, err);

            // Set loading status OFF
            this.root.ui.setLoading(-1);

            if(axios.isCancel(err)) {
                return err;
            }

            // We have response, but its out of range 2xx
            if(err.response) {

//console.log("FETCH ERROR: we did not receive 2xx", settings.url);
            
                // If error is 403, try to renew token
                if(err.response.status === 403 && settings.renew !== false) {

//console.log("FETCH ERROR: try to renew token", settings.url);

                    const res = await this.root.auth.renew();

                    if(this.root.auth.isResponseOk(res)) {
                        settings.renew = false;
                        return this.fetch(data, settings);
                    }

                }

//console.log("FETCH ERROR: this is our final data", settings.url, err.response.data);

                // Return error data
                return err.response.data;

            }

            // We have some other kind of error: maybe JSON parse error, empty response or something else

//console.log("FETCH ERROR: something else than not 2xx failed, maybe invalid response from server", settings.url);

            // Try to retry
            if(settings.retry !== false) {

// console.log("FETCH ERROR: let's try to retry this api call", settings.url);

                let canRetry = false;
                const time = new Date().getTime();

                for(let i = 0; i < this.hosts.length; i++) {

                    if(this.hosts[i].resumeTime < time) {
                        canRetry = true;
                    }

                    if(settings.url.indexOf(this.hosts[i].host) === 0) {
                        this.hosts[i].resumeTime = time + this.resumeTime;
// console.log("FETCH ERROR: we got error from this host, so we put in in freeze for a while", settings.url, this.hosts[i]);
                    }
                }

                if(canRetry === true) {
// console.log("FETCH ERROR: retrying...", settings.url, settings);
                    return this.fetch(data, settings);
                }
            }

// console.log("FETCH ERROR: we can not retry anymore, we must give up!", settings.url);

            return this.error();

        }

    }

    // GET
    @action.bound async get(data, settings) {

        if(settings === undefined) {
            settings = {};
        }

        settings.method = "GET";

        // API call
        const res = await this.fetch(data, settings);

        if(axios.isCancel(res)) {
            return res.message;
        }

        if(res && res.status === true) {

            if(res.data instanceof Array) {

                transaction(() => {

                    if(settings.append !== true){

                        this.data.clear();
                    }

                    res.data.map( (item, k) => {

                        if(item.id !== undefined){
                        
                            this.data.set(item.id, item);
                        }

                        else {

                            this.data.set(k, item);
                        }

                        return true;
                    })
                });

            } else {

                if(res.data.id) {
                    this.data.set(res.data.id, res.data);
                }

            }
        }

        return res;
    }
    
    // PUT
    @action.bound async put(data, settings) {

        if(settings === undefined) {
            settings = {};
        }

        settings.method = "PUT";

        // API call
        const res = await this.fetch(data, settings);

        if(axios.isCancel(res)) {
            return res.message;
        }

        if(res && res.status === true) {

            if(res.data.id) {
                this.data.set(res.data.id, res.data);
            }
        }

        return res;
    }

    // POST
    @action.bound async post(data, settings) {

        if(settings === undefined) {
            settings = {};
        }

        settings.method = "POST";

        // API call
        const res = await this.fetch(data, settings);

        if(axios.isCancel(res)) {
            return res.message;
        }

        if(res && res.status === true) {

            if(res.data.id) {
                this.data.set(res.data.id, res.data);
            }
        }

        return res;
    }

    // DELETE
    @action.bound async delete(data, settings) {

        if(settings === undefined) {
            settings = {};
        }

        settings.method = "DELETE";
        
        // API call
        const res = await this.fetch(data, settings);

        if(axios.isCancel(res)) {
            return res.message;
        }

        if(res && res.status === true) {

            if(res.data.id) {
                this.data.delete(res.data.id);
            }
        }

        return res;
    }

    // PATCH
    @action.bound async patch(data, settings) {

        if(settings === undefined) {
            settings = {};
        }

        settings.method = "PATCH";

        // API call
        const res = await this.fetch(data, settings);

        if(axios.isCancel(res)) {
            return res.message;
        }

        if(res && res.status === true) {

            if(res.data.id) {
                this.data.set(res.data.id, res.data);
            }
        }

        return res;
    }

    /*

    // Alias for POST
    @action.bound add(data, settings) {
        return this.post(data, settings);
    }

    // Alias for PUT
    @action.bound update(data, settings) {
        return this.put(data, settings);
    }

    // Alias for DELETE
    @action.bound remove(data, settings) {
        return this.delete(data, settings);
    }

    */

    // API answer is successfull
    isResponseOk = (res) => {

        if(res === false || res === null) {
            return false;
        }

        if(typeof(res) !== 'object') {
            return false;
        }

        if(!("status" in res)) {
            return false;
        }

        if(res.status === true) {
            return true;
        }

        return false;

    }

    // API answer is failed
    isResponseFailed = (res) => {
        return !this.isResponseOk(res);
    }

    // Map to object

    convertMap = (map) => {
    
        const data = {};

        map.forEach((v, k) => {
        
            data[k] = v;
        });

        return data;
    }

    // Map to array

    @computed get dataArray(){

        const array = [];

        this.data.forEach((v, k) => {
 
            array.push(v);
        });

        return array;
    }
}
