import ParcelSend from 'components/smart/ParcelSend';
import ParcelMeasurements from 'components/smart/ParcelMeasurements';
import ProForma from 'components/smart/ProForma';
import Login from 'components/smart/Login';
import ResetPassword from 'components/smart/ResetPassword';
import Home from 'components/smart/Home';
import ProfileSettings from 'components/smart/ProfileSettings';
import Consignees from 'components/smart/Consignees';
import ConsigneesAdd from 'components/smart/ConsigneesAdd';
import ConsigneesEdit from 'components/smart/ConsigneesEdit';
import UserSettings from 'components/smart/UserSettings';
import SendersAdd from 'components/smart/SendersAdd';
import SendersEdit from 'components/smart/SendersEdit';
import Parcels from 'components/smart/Parcels';
import {OrderFetch} from 'components/smart/OrderFetch';
import {ParcelView} from 'components/smart/ParcelView';
import {Raports} from 'components/smart/Raports';
import UsersAdd from 'components/smart/UsersAdd';
import Import from 'components/smart/Import';
import ImportMeasurements from 'components/smart/ImportMeasurements';
import ImportParcels from 'components/smart/ImportParcels';
import ImportPreview from 'components/smart/ImportPreview';
import PriceTool from 'components/smart/PriceTool';
import TableStressTest from 'components/smart/TableStressTest';
import CustomsDeclaration from 'components/smart/CustomsDeclaration';

class Views {

    routes = new Map();

    constructor(stores){

        this.stores = stores;
        this.setRoutes();
    }

    setRoutes = () => {

        this.routes.set('/login/', {
        
            component: Login, dependencies: []
        });

        this.routes.set('/reset/password.html', {
        
            component: ResetPassword, dependencies: []
        });

        if(this.stores.auth.isLoggedIn === true){

            this.routes.set('/', {
            
                component: Home, dependencies: []
            });

            this.routes.set('/home/', {
            
                component: Home, dependencies: []
            });

            this.routes.set('/parcel/send.html', {
            
                component: ParcelSend, dependencies: [
                
                    this.stores.consignees.fetchAll,
                    this.stores.pickuppoints.fetchAll,
                    this.stores.senders.fetchAll
                ]
            });

            this.routes.set('/parcel/:id/send.html', {
            
                component: ParcelSend, dependencies: [
                
                    this.stores.parcel.fetchSingle,
                    this.stores.consignees.fetchAll,
                    this.stores.pickuppoints.fetchAll,
                    this.stores.senders.fetchAll
                ]
            });

            this.routes.set('/parcel/measurements.html', {
            
                component: ParcelMeasurements, dependencies: [
                
                    this.stores.senders.fetchAll,
                    this.stores.cncodes.fetchAll,
                    this.stores.customunits.fetchAll
                ]
            });

            this.routes.set('/parcel/:id/measurements.html', {
            
                component: ParcelMeasurements, dependencies: [
                
                    this.stores.parcel.fetchSingle,
                    this.stores.senders.fetchAll,
                    this.stores.cncodes.fetchAll,
                    this.stores.customunits.fetchAll
                ]
            });

            this.routes.set('/parcel/proforma.html', {
            
                component: ProForma, dependencies: [

                    this.stores.cncodes.fetchAll,
                    this.stores.customunits.fetchAll
                ]
            });

            this.routes.set('/parcel/:id/proforma.html', {
            
                component: ProForma, dependencies: [
                
                    this.stores.parcel.fetchSingle,
                    this.stores.senders.fetchAll,
                    this.stores.cncodes.fetchAll,
                    this.stores.customunits.fetchAll
                ]
            });

            this.routes.set('/parcels/', {

                component: Parcels, dependencies: [
                
                    this.stores.parcel.fetchAll,
                    this.stores.pickuppoints.fetchAll
                ]
            });

            this.routes.set('/parcel/:id/view.html', {

                component: ParcelView, dependencies: [
                
                    this.stores.parcel.fetchSingle,
                    this.stores.pickuppoints.fetchAll,
                    this.stores.senders.fetchAll
                ]
            });

            this.routes.set('/consignees/list.html', {
            
                component: Consignees, dependencies: [
            
                    this.stores.consignees.fetchAll
                ]
            });

            this.routes.set('/consignees/add.html', {
            
                component: ConsigneesAdd, dependencies: []
            });

            this.routes.set('/consignees/:id/edit.html', {
            
                component: ConsigneesEdit, dependencies: [
                
                    this.stores.consignees.fetchSingle
                ]
            });

            this.routes.set('/raports/', {
            
                component: Raports, dependencies: [
                
                    this.stores.parcel.fetchAll
                ]
            });

            this.routes.set('/user/settings.html', {
            
                component: UserSettings, dependencies: [
                
                    this.stores.senders.fetchAll,
                    this.stores.adminPrivileges.fetchAll
                ]
            });

            this.routes.set('/senders/add.html', {

                component: SendersAdd, dependencies: []
            });

            this.routes.set('/senders/:id/edit.html', {

                component: SendersEdit, dependencies: [
                
                    this.stores.senders.fetchSingle
                ]
            });

            this.routes.set('/profile/settings.html', {

                component: ProfileSettings, dependencies: [
                
                    this.stores.senders.fetchAll,
                    this.stores.pickuppoints.fetchAll
                ]
            });

            this.routes.set('/import/details.html', {

                component: Import, dependencies: [
                
                    this.stores.consignees.fetchAll,
                    this.stores.pickuppoints.fetchAll
                ]
            });

            this.routes.set('/import/:id/details.html', {

                component: Import, dependencies: [
                
                    this.stores.import.fetchSingle,
                    this.stores.consignees.fetchAll,
                    this.stores.pickuppoints.fetchAll
                ]
            });

            this.routes.set('/import/measurements.html', {

                component: ImportMeasurements, dependencies: [
                
                    this.stores.consignees.fetchAll,
                    this.stores.pickuppoints.fetchAll
                ]
            });

            this.routes.set('/import/:id/measurements.html', {

                component: ImportMeasurements, dependencies: [
                
                    this.stores.import.fetchSingle,
                    this.stores.consignees.fetchAll,
                    this.stores.pickuppoints.fetchAll
                ]
            });

            this.routes.set('/imports/', {

                component: ImportParcels, dependencies: [
                
                    this.stores.import.fetchAll,
                    this.stores.senders.fetchAll
                ]
            });

            this.routes.set('/import/:id/preview.html', {

                component: ImportPreview, dependencies: [
                
                    this.stores.import.fetchSingle,
                    this.stores.senders.fetchAll
                ]
            });

            this.routes.set('/users/add.html', {

                component: UsersAdd, dependencies: []
            });

            this.routes.set('/parcels/order-fetch.html', {

                component: OrderFetch, dependencies: [
                
                    this.stores.parcel.fetchAll,
                    this.stores.pickuppoints.fetchAll
                ]
            });

            this.routes.set('/price/', {

                component: PriceTool, dependencies: [

                    this.stores.pickuppoints.fetchAll,
                    this.stores.consignees.fetchAll
                ]
            });

            this.routes.set('/table-stress-test/', {
            
                component: TableStressTest, dependencies: []
            });

            this.routes.set('/customs/declaration.html', {
            
                component: CustomsDeclaration, dependencies: [

                    this.stores.cncodes.fetchAll,
                    this.stores.customunits.fetchAll
                ]
            });
        }
    }
}

export default Views;
