import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Wrapper = styled.div`

    ${props => `

        padding: ${props.theme.size[1]} 0 ${props.theme.size[1]} 0;

        @media (min-width: ${props.theme.breakpoint}){

            padding: ${props.theme.padding[4]} 0 ${props.theme.padding[4]} 0;
        }

        margin-bottom: ${props.marginBottom};
        background: ${props.theme.color[7]};
    `}
`;

class Content extends React.Component {

    render(){

        return (

            <Wrapper marginBottom={this.props.marginBottom}>{this.props.children}</Wrapper>
        );
    }
}

Content.propTypes = {

    /** Text content or components  */
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

Content.props = {

    marginBottom: '0'
}

export default Content;
