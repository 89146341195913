import React from 'react';
import {observer} from 'mobx-react';
import {observable, action} from 'mobx';
import styled from 'styled-components';
import Icon from 'components/gls-dumb/Icon';
import Dropdown from 'components/gls-dumb/Dropdown';
import Close from 'components/gls-dumb/Close';
import profileButtonSRC from 'images/user.svg';

const Button = styled.div`

    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2em;
    height: 2em;
    background: ${props => props.theme.color[1]};
    color: ${props => props.theme.color[7]};
    cursor: pointer;
`;

@observer class Menu extends React.Component {

    @observable isOpen = false;

    @action open = () => {
       
        this.isOpen = true; 
    }

    @action close = () => {

        setTimeout(() => {
        
            this.isOpen = false;
        }, 1);
    }

    render(){

        return (

            <div>

                <Button>

                    <img src={profileButtonSRC} alt="" onClick={this.open} />
                    <Dropdown isOpen={this.isOpen} title={this.props.title} items={this.props.items} onClick={this.close}></Dropdown>

                </Button>

                <Close isOpen={this.isOpen} onClick={this.close}></Close>

            </div>
        );
    }
}

export default Menu;
