import React from 'react';
import sort from 'fast-sort';
import styled from 'styled-components';
import Order from 'components/gls-dumb/Order';
import Checkbox from 'components/gls-dumb/Checkbox';
import theme from 'theme';
import Button from 'components/gls-dumb/Button/Alternative';
import {observer} from 'mobx-react';

const Table = styled.table`

    ${props => `

        width: 100%;

        @media (max-width: ${props.breakpoint}){

            & thead {

                display: none;
            }

            &, & tbody, & tr, & td {

                display: block;
            }
        }
    `}
`;

const Title = styled.td`

    ${props => `

        padding: ${props.theme.size[1]};
        background: ${props.theme.color[1]};
        color: ${props.theme.color[7]};
    `}
`;

const Row = styled.tr`

    ${props => `

        & > td {

            border-bottom: 1px solid ${props.theme.color[1]};

            @media (min-width: ${props.breakpoint}){

                border-bottom: 2px solid ${props.theme.color[1]};
            }
        }
    `}
`;

const Data = styled.td`

    ${props => `

        padding: ${props.theme.size[1]};
        vertical-align: middle;

        @media (max-width: ${props.breakpoint}){

            text-align: right;

            ${props.title !== undefined && `

                &:before {

                    float: left;
                    content: '${props.title}:';
                }
            `}
        }
    `}
`;

const Pagination = styled.div`

    ${props => `
    
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${props.theme.color[1]};
        padding: ${props.theme.size[1]};
    `}
`;

const ChangePage = styled.div`

    ${props => `

        display: flex;
        align-items: center;
        justify-content: center;
        width: ${props.theme.inputSize};
        height: ${props.theme.inputSize};
        background-color: ${props.theme.color[1]};
        color: ${props.theme.color[7]};
        cursor: pointer;

        &:hover {

            background-color: ${props.theme.color[10]};
            color: ${props.theme.color[1]};
        }
    `}
`;

const Sort = styled.div`

    cursor: pointer;
`;

const CurrentPage = styled.span`

    ${props => `
   
        margin-left: ${props.theme.size[1]};
        margin-right: ${props.theme.size[1]};
    `}
`;

@observer class LightTable extends React.Component {

    selected = new Map();

    constructor(props){

        super(props);

        const defaultSort = this.props.defaultSort !== undefined ? this.props.defaultSort : Object.keys(this.props.headers)[0];
        const defaultOrder = this.props.defaultOrder !== undefined ? this.props.defaultOrder : 'asc';

        this.state = {

            page: 0,
            sort: defaultSort,
            order: defaultOrder
        }
    }

    resetBenchmark = () => {

        this.resetTime = Date.now();
    }

    benchmark = () => {

        let difference = Date.now() - this.resetTime;

        console.log('Benchmark: ' + difference + ' ms, Items: ' + this.props.data.length);
    }

    sortBy = key => {

        const order = this.state.sort !== key ? 'asc' : this.state.order === 'asc' ? 'desc' : 'asc';

        this.setState({

            sort: key,
            order: order
        });
    }

    sort = (data, field, order) => {

        const options = {

            comparer: new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' }).compare
        };

        if(order === 'asc'){

            options.asc = v => v[field];
        }

        else if(order === 'desc'){

            options.desc = v => v[field];
        }

        return sort(data).by(options);
    }

    pagination = data => {

        const pages = this.props.pagination;

        if(pages === false){

            return data;
        }

        const from = this.state.page * pages;
        const to = from + pages;

        data = data.filter((v, k) => {

            if(k >= from & k < to){

                return true;
            }

            return false;
        });

        return data;
    }

    // Arrange data in same order than headers.

    arrange = (values) => {

        values = new Map(values);

        const arranged = [];

        for(let key in this.props.headers){

            arranged.push([key, values.get(key)]);
        }

        return arranged;
    }

    changePage = page => {

        this.setState({

            page: this.state.page + page
        });
    }

    get total(){

        return Math.ceil(this.props.data.length / this.props.pagination);
    }

    select = v => {

        if(v.value === true){

            this.selected.set(v.name, v.value);
        }

        else {

            this.selected.delete(v.name);
        }

        if(this.props.select !== undefined){

            this.props.select(this.selected);
        }
    }

    isSelected = key => {
        
        return this.selected.get(key) === true ? true : false;
    }

    modify = (k, v) => {

        if(this.props.modifiers[k] !== undefined){

            return this.props.modifiers[k](v);
        }

        return v;
    }

    render(){

        this.resetBenchmark();

        let data = this.props.data;

        data = this.sort(data, this.state.sort, this.state.order);
        data = this.pagination(data);

        let headers = Array.from(new Map(Object.entries(this.props.headers)));
        let keys = [];

        return (

            <React.Fragment>

                <Table breakpoint={this.props.breakpoint}>

                    <thead>

                        <tr>

                            <Title></Title>

                            {headers.map((header) => {

                                keys.push(header[0]);

                                return (

                                    <Title key={header[0]}>

                                        <Sort onClick={() => this.sortBy(header[0])}>

                                            {this.state.sort === header[0] && <Order type={this.state.order} />}
                                            {header[1]}:

                                        </Sort>

                                    </Title>
                                );
                            })}


                            <Title></Title>

                        </tr>

                    </thead>

                    <tbody>

                        {data.map((v, i) => {

                            let values = Array.from(new Map(Object.entries(v))).filter(v => {

                                // Filter out fields that are not included in headers.

                                if(keys.indexOf(v[0]) === -1){

                                    return false;
                                }

                                return true;
                            });

                            // Use id as key if it is set.

                            const key = v.id !== undefined ? v.id : i;

                            return (

                                <Row key={key} breakpoint={this.props.breakpoint}>

                                    <Data breakpoint={this.props.breakpoint}>

                                        {v.status !== 'DRAFT' &&  <Checkbox name={key} value={true} checked={this.isSelected(key)} onChange={v => this.select(v)} marginBottom="0" />}

                                    </Data>

                                    {this.arrange(values).map((v) => {

                                        return <Data key={v[0]} title={this.props.headers[v[0]]} breakpoint={this.props.breakpoint}>{this.modify(v[0], v[1])}</Data>;
                                    })}

                                    <Data breakpoint={this.props.breakpoint}><Button onClick={() => this.props.open(key)}>{this.props.lang.open}</Button></Data>
                               
                                </Row>
                            );
                        })}

                    </tbody>

                    {this.props.benchmark === true && [0].map(() => this.benchmark())}

                </Table>

                {(this.props.pagination !== false && this.props.data.length > this.props.pagination) &&

                    <Pagination>

                        {this.state.page > 0 && <ChangePage onClick={() => this.changePage(-1)}><i className="fas fa-caret-left" /></ChangePage>}
                        <CurrentPage>{this.state.page + 1} / {this.total}</CurrentPage>
                        {this.state.page + 1 < this.total && <ChangePage onClick={() => this.changePage(1)}><i className="fas fa-caret-right" /></ChangePage>}

                    </Pagination>
                }

            </React.Fragment>
        );
    }
}

LightTable.defaultProps = {

    benchmark: false,
    pagination: false,
    breakpoint: theme.breakpoint
}

export default LightTable;
