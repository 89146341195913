import React from 'react';
import PopUpList from 'components/gls-dumb/PopUpList';
import Pagebreak from 'components/gls-dumb/Pagebreak';
import Columns from 'components/gls-dumb/Columns';
import Field from 'components/gls-dumb/Field';
import Radio from 'components/gls-dumb/Radio';
import Checkbox from 'components/gls-dumb/Checkbox';
import theme from 'theme';
import {observable, computed} from 'mobx';
import {inject, observer} from 'mobx-react';


@observer class PriceToolExport extends React.Component {

    lang = this.props.ui.lang.price_tool;
    fieldProps = this.props.fieldProps;
    checkboxProps = this.props.checkboxProps;


    get pickuppointsOptions(){

        const pickuppoints = [];

        this.props.pickuppoints.data.forEach(v => {

            const details = [];

            if(v.address1 !== ''){

                details.push(v.address1);
            }

            if(v.city !== ''){

                details.push(v.city);
            }

            if(v.postalnumber !== ''){

                details.push(v.postalnumber);
            }

            let pickuppoint = '[' + (v.nickname || v.name1) + '] ' + v.name1 + ' (' + details.join(', ') + ')';

            pickuppoints.push({

                key: v.id,
                value: pickuppoint
            });
        });

        return pickuppoints;
    }

    render(){
	const {editable} = this.props;

        return (

            <React.Fragment>

                <Pagebreak>{this.lang.pickup_title}</Pagebreak>

                <PopUpList

                    {...this.fieldProps('pickuppoints_id')}
                    label={this.lang.pickuppoint}
                    list={this.pickuppointsOptions}
                    message={this.lang.pickuppoints_not_found}
                />

                <Columns>

                    <Columns.left>

                        <Pagebreak>{this.lang.consignee_title}</Pagebreak>

                        <Columns gap="small">

                            <Columns.left width="25%">

                                <Field {...this.fieldProps('consignee_postalnumber')} label={this.lang.consignee_postalnumber} />

                            </Columns.left>

                            <Columns.right width="75%">

                                <Field {...this.fieldProps('consignee_country')} type="select" label={this.lang.consignee_country} options={this.props.countries.optionsWithCode(false)} />

                            </Columns.right>

                        </Columns>

                        <Radio {...this.fieldProps('consignee_recipienttype')}>

                            <Radio.item value="2C" label={this.lang.recipienttype_private_address} info={this.lang.recipienttype_private_address_info} width="50%" />
                            <Radio.item value="2B" label={this.lang.recipienttype_business_address} info={this.lang.recipienttype_business_address_info} width="50%" />

                        </Radio>

                    </Columns.left>

                    <Columns.right>

                        <Pagebreak>{this.lang.shipment_title}</Pagebreak>

                        <Field {...this.fieldProps('shipment_type')} type="select" label={this.lang.shipment_type} options={this.props.shipmentTypeOptions} />

                        <Checkbox {...this.checkboxProps('insurance')} value={true} label={<span><span style={{color: theme.color[1], fontSize: '28px', fontStyle: 'italic'}}>AddOnLiability</span><span style={{color: theme.color[1], fontSize: '28px', fontStyle: 'italic', fontWeight: 'bold'}}>Service</span></span>} info={this.lang.insurance_info} />
                        <Checkbox {...this.checkboxProps('parcelshop')} value={true} editable={editable.parcelshop} label={<span><span style={{color: theme.color[1], fontSize: '28px', fontStyle: 'italic'}}>ShopDelivery</span><span style={{color: theme.color[1], fontSize: '28px', fontStyle: 'italic', fontWeight: 'bold'}}>Service</span></span>} info={this.lang.shopHelp} />

                    </Columns.right>

                </Columns>

            </React.Fragment>
        );
    }
}

export default PriceToolExport;
