import React from 'react';
import Header from 'components/smart/Header';
import moment from 'moment';
import Button from 'components/gls-dumb/Button';
import Title from 'components/gls-dumb/Title';
import Field from 'components/gls-dumb/Field';
import theme from 'theme';
import styled from 'styled-components';
import {observer, inject} from 'mobx-react';
import {observable, action} from 'mobx';
import {arrayFromMap} from 'Helpers';
import DateTimePicker from 'components/gls-dumb/DateTimePicker';
import Checkbox from 'components/gls-dumb/Checkbox';
import {Sortable} from 'components/gls-dumb/Sortable';
import {Buttons} from 'components/gls-dumb/Buttons';
import ModalAlerts from 'components/gls-dumb/ModalAlerts';
import Center from 'components/gls-dumb/Center';
import api from 'api.json';
import {bool} from 'Helpers';
import Section from 'components/gls-dumb/Section';

const  breakpoint = '800px'; // Global breakpoint (exception)

const Wrapper = styled.div`

    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;

    & > :first-child {

        width: 100%;
    }

    @media (min-width: ${breakpoint}){

        flex-wrap: nowrap;
    }
`;

const Search = styled.div`

    display: flex;
    flex-wrap: wrap;
    
    @media (min-width: ${breakpoint}){
    
        padding: 0 ${props => props.theme.size[1]} 0 ${props => props.theme.size[1]};
    }
`;

const Timespan = styled.div`

    display: flex;
    align-items: center;
    padding: ${props => props.theme.size[1]} 0 ${props => props.theme.size[1]} 0;

    @media (min-width: ${breakpoint}){

        padding: 0;
    }
`;

const Separator = styled.div`

    padding-left: ${props => props.theme.size[1]};
    padding-right: ${props => props.theme.size[1]};
`;

@inject('parcel')
@inject('senders')
@inject('consignees')
@inject('ui')
@inject('countries')
@inject('auth')

@observer class Raports extends React.Component {

    lang = this.props.ui.lang.raports;

    @observable form = new Map();
    @observable pickerIsOpen = false;
    @observable pickerField = false;
    @observable actionsIsOpen = false;
    @observable messages = [];
    @observable parcels = new Map(this.props.parcel.data);

    start = Date.now();

    constructor(props){

        super(props);

        this.fieldProps = {

            backgroundColor: theme.color[7],
            marginBottom: 0,
            labelPosition: 'left',
            onChange: this.change
        }

        this.form.set('time_from', moment().startOf('month').format('DD.MM.YYYY'));
        this.form.set('time_to', moment().endOf('month').format('DD.MM.YYYY'));
        this.fetchOldParcels();
        this.submit();
    }

    @action fetchOldParcels = () => {

        this.props.ui.hideLoading = true;

        const background = [];

        for(let i = 1; i <= 5; i++){

            this.props.ui.backgroundLoading++;

            const year = moment().subtract(i, 'months').format('YYYY');
            const month = moment().subtract(i, 'months').format('M');

            background.push(this.props.parcel.fetchByMonth(year, month, 'report').then(() => {

                this.props.ui.backgroundLoading--;

                if(this.props.ui.backgroundLoading === 0){
                
                    this.props.ui.hideLoading = false;
                }

            }));
        }

        Promise.all(background).then(() => {

            this.submit();
        });
    }

    open = id => {

        const promises = [
        
            this.props.senders.fetchAll(),
            this.props.consignees.fetchAll(),
            this.props.parcel.single(id)
        ];

        this.props.ui.resolve(promises, () => this.open(id), () => {

                this.props.history.push('/parcel/'+id+'/view.html');
            }
        );
    }

    change = (v) => {

        this.form.set(v.name, v.value);

        if(v.code === 13){

            this.submit();
        }
    }

    @action submit = () => {

        let data = arrayFromMap(this.props.parcel.data);

        // Filter by date range and status

        let from = this.form.get('time_from').trim();
        let to = this.form.get('time_to').trim();

        if(from !== undefined && from !== '' && to !== undefined && to !== ''){

            from = moment(from + ' 00:00:00', 'DD.MM.YYYY HH:mm:ss').unix();
            to = moment(to + ' 23:59:59', 'DD.MM.YYYY HH:mm:ss').unix();

            data = data.filter(v => {

                if(v.date < from || v.date > to){

                    return false;
                }

                else if(this.form.get('show_ready') !== true && v.status !== 'DONE'){

                    return false;
                }

                else if(this.form.get('show_ready') === true && v.status !== 'DONE' && v.status !== 'READY'){

                    return false;
                }

                return true;
            });
        }

        // Replace old data with new sorted and filtered data.

        this.parcels.clear();

        data.forEach((v, k) => {
       
            this.parcels.set(v.id, v);
        });
    }

    openPicker = (e) => {

        this.pickerField = e.target.name;
        this.pickerIsOpen = true;
    }

    closePicker = () => {

        this.pickerField = false;
        this.pickerIsOpen = false;
    }

    donePicker = (timestamp) => {

        if(this.pickerField !== false){

            const date = moment(timestamp, 'X').format('DD.MM.YYYY');
        
            this.form.set(this.pickerField, date);
        }

        this.closePicker();
    }

    clearMessages = () => {

        this.messages = [];
    }

    parseParcelnumber = parcels => {
 
        if(parcels[0] === undefined || parcels[0].parcelnumber === undefined){

            return '-';
        }

        return parcels[0].parcelnumber;
    }

    useCurrentDate = () => {

        const current = moment().format('DD.MM.YYYY');

        this.form.set('time_from', current);
        this.form.set('time_to', current);
    }

    download = format => {

        this.props.ui.resolve(this.props.auth.temporary(), () => this.download(format), r => {

            const status = this.form.get('show_ready') === true ? 'READY,DONE' : 'DONE';

            const url = api.endpoints.user.raport
                .replace('{format}', format)
                .replace('{accesstoken}', encodeURIComponent(r.data.jwt))
                .replace('{startTime}', moment(this.form.get('time_from') + ' 00:00:00', 'DD.MM.YYYY HH:mm:ss').unix())
                .replace('{endTime}', moment(this.form.get('time_to') + '23:59:59', 'DD.MM.YYYY HH:mm:ss').unix())
                .replace('{status}', status);

            window.open(url);
        });
    }

    checkboxProps = (checkbox) => {

        return {

            name: checkbox,
            checked: bool(this.form.get(checkbox)),
            onChange: this.change,
            backgroundColor: theme.color[7],
            marginBottom: '0',
            marginLeft: theme.size[1]
        }
    }

    render(){

        const data = arrayFromMap(this.parcels);
 
        return (

            <React.Fragment>

                <DateTimePicker isOpen={this.pickerIsOpen} onCancel={this.closePicker} onDone={this.donePicker} />
                <ModalAlerts messages={this.messages} ok={this.clearMessages} />
                
                <Header />

                <Section color="sub">

                    <Title breakpoint={breakpoint}>
                    
                        <span>{this.lang.raportsTitle}:</span>

                        <Wrapper>

                            <Center>

                                <Search>

                                    <div>

                                        <Timespan>

                                                <Field
                                                    
                                                    type="text"
                                                    name="time_from"
                                                    value={this.form.get('time_from')}
                                                    label={this.lang.timespan}
                                                    {...this.fieldProps}
                                                    onFocus={this.openPicker}
                                                />

                                                <Separator>-</Separator>

                                                <Field type="text" name="time_to" value={this.form.get('time_to')} {...this.fieldProps} onFocus={this.openPicker} />
                                                <Button onClick={this.useCurrentDate} width="auto" marginLeft="1em">{this.lang.today}</Button>
                                                <Checkbox {...this.checkboxProps('show_ready')} value={true} label={this.lang.showWaiting} />


                                        </Timespan>

                                    </div>

                                </Search>

                            </Center>

                            <Button onClick={this.submit} width="auto">{this.lang.search}</Button>

                        </Wrapper>
                        
                    </Title>

                </Section>
            
                <div className="main">                    

                    <Buttons breakpoint="1050px">
                        
                        <Button onClick={() => this.download('csv')}>{this.lang.downloadCSV}</Button>
                        <Button onClick={() => this.download('pdf')}>{this.lang.downloadPDF}</Button>

                    </Buttons>

                    {true && 

                        <Sortable breakpoint="1050px" pagination={100}>
                        
                            <Sortable.thead>
     
                                <Sortable.tr>
                                
                                    <Sortable.td sortable={true}>{this.lang.parcelnumber}:</Sortable.td>
                                    <Sortable.td sortable={true}>{this.lang.amount}</Sortable.td>
                                    <Sortable.td sortable={true}>{this.lang.weight}</Sortable.td>
                                    <Sortable.td sortable={true}>{this.lang.type}:</Sortable.td>
                                    <Sortable.td sortable={true}>{this.lang.consignee}:</Sortable.td>
                                    <Sortable.td sortable={true}>{this.lang.country}:</Sortable.td>
                                    <Sortable.td sortable={true}>{this.lang.printed}:</Sortable.td>
                                    <Sortable.td sortable={true}>{this.lang.status}:</Sortable.td>
                                    <Sortable.td sortable={true} order="desc">{this.lang.date}</Sortable.td>
                                
                                </Sortable.tr>

                            </Sortable.thead>

                            <Sortable.tbody>

                                {data.length !== 0 ? data.map((v, k) =>

                                    <Sortable.tr key={k}>

                                        <Sortable.td>{this.parseParcelnumber(v.parcels)}</Sortable.td>
                                        <Sortable.td>{v.parcels_cnt} {this.lang.cntUnit}</Sortable.td>
                                        <Sortable.td>{v.total_weight} kg</Sortable.td>
                                        <Sortable.td>{this.props.parcel.typeNames[v.type]}</Sortable.td>
                                        <Sortable.td>{v.consignee_name1}</Sortable.td>
                                        <Sortable.td>{this.props.countries.nameByCode(v.consignee_countries_id)}</Sortable.td>
                                        <Sortable.td>{v.is_printed === false ? this.lang.no : this.lang.yes}</Sortable.td>
                                        <Sortable.td>{this.lang[v.status]}</Sortable.td>
                                        <Sortable.td value={v.date}>{moment(v.date, 'X').format('DD.MM.YYYY')}</Sortable.td>

                                    </Sortable.tr>
                                ) :
                                
                                    <Sortable.tr>

                                        <Sortable.td colSpan="10" style={{textAlign: 'center'}}>{this.lang.noResults}</Sortable.td>
                                        
                                    </Sortable.tr>
                                } 

                            </Sortable.tbody>
     
                        </Sortable>
                    }
                     
                </div>

            </React.Fragment>
        );
    }
}

export {Raports};
