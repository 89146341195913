import React, { Component } from 'react';
import moment from 'moment';
import { observer, inject } from 'mobx-react';
import { observable, action, computed} from 'mobx';
import 'components/gls-dumb/DateTimePicker/css/DateTimePicker.css';
import 'moment/locale/fi';
import {PropTypes} from 'prop-types';

@inject('ui')

@observer class DateTimePicker extends Component {

    lang = this.props.ui.lang.dateTimePicker;

    @observable mode;
    @observable dateTime = moment();
    @observable isOpen = false;

    // Ok
    ok = () => {
        this.props.onDone(this.dateTime);    
    }

    // Cancel 
    cancel = () => {
        this.props.onCancel(this.dateTime);
    }

    // Switch to year mode
    @action setModeYear = () => {

        if(this.props.yearMode) {
            if(this.props.minDateTime.year() !== this.props.maxDateTime.year()) {
                this.mode = "year";
            }
        }
    }

    // Switch to time mode
    @action setModeTime = () => {

        if(this.props.timeMode) {
            this.mode = "time";
        }
    }

    // Switch to date mode
    @action setModeDate = () => {
        this.mode = "date";
    }

    // Set date and time
    @action setDateTime = (dateTime) => {
   
        if(dateTime < this.props.minDateTime) {
            dateTime = this.props.minDateTime;
        }

        if(dateTime > this.props.maxDateTime) {
            dateTime = this.props.maxDateTime;
        }

        // Steps for minutes and hours
        let minute = Math.ceil(dateTime.minute() / this.props.minuteStep) * this.props.minuteStep;
        let hour = Math.ceil(dateTime.hour() / this.props.hourStep) * this.props.hourStep;
        dateTime.minute(minute).hour(hour);

        // Min hour
        if(dateTime.hour() < this.props.minHour) {
            dateTime.hour(this.props.minHour);
        }

        // Max hour
        if(dateTime.hour() > this.props.maxHour) {
            dateTime.hour(this.props.maxHour);
        }

        // Min minute
        if(dateTime.minute() < this.props.minMinute) {
            dateTime.minute(this.props.minMinute);
        }

        // Max minute
        if(dateTime.minute() > this.props.maxMinute) {
            dateTime.minute(this.props.maxMinute);
        }

        // Change datetime
        this.dateTime = dateTime;

        // Fire onchange
        this.props.onChange(this.dateTime);
    }

    // Set year
    @action setYear = (year) => {
        this.setDateTime(moment(this.dateTime).year(year)); 
        this.setModeDate();
    }

    // Set minute
    @action setMinute = (e) => {
        this.setDateTime(moment(this.dateTime).minute(e.target.value));
    }

    // Set hour
    @action setHour = (e) => {
        this.setDateTime(moment(this.dateTime).hour(e.target.value));
    }

    // Set day
    @action setDay = (day) => {
        this.setDateTime(moment(this.dateTime).date(day));
    }

    // Next month
    @action nextMonth = () => {
        this.setDateTime(moment(this.dateTime).add(1, "months"));
    }

    // Previous month
    @action prevMonth = () => {
        this.setDateTime(moment(this.dateTime).subtract(1, "months"));
    }

    // Get year
    @computed get year() {
        return this.dateTime.format(this.props.yearFormat);
    }

    // Get month
    @computed get month() {
        return this.dateTime.format(this.props.monthFormat);
    }

    // Get date
    @computed get date() {
        return this.dateTime.format(this.props.dateFormat);
    }

    // Get time
    @computed get time() {
        return this.dateTime.format(this.props.timeFormat);
    }

    // Get hour
    @computed get hour() {
        return this.dateTime.format("H");
    }

    // Get minute
    @computed get minute() {
        return this.dateTime.format("m");
    }

    // See if we can go to prev month
    @computed get hasPrevMonth() {
        return moment(this.dateTime).subtract(1, "months").endOf("month") >= this.props.minDateTime;
    }    

    // See if we can go to next month
    @computed get hasNextMonth() {
        return moment(this.dateTime).add(1, "months").startOf("month") <= this.props.maxDateTime;
    }

    // Mount 
    componentDidMount() {

        // Set initial datetime
        this.setDateTime(this.props.dateTime);

        // Set initial mode
        switch(this.props.mode) {

            case "year":
            this.setModeYear();
            break;

            case "time":
            this.setModeTime();
            break;
        
            default:
            this.setModeDate();
            break;

        }

    }

    // Render
    render() {

        if(this.props.isOpen === false){

            return null;
        }

        // Mode
        let mode = this.mode;

        // Top
        let year = <div className={"year" + (mode === "year" ? " on" : "")} onClick={this.setModeYear}>{this.year}</div>;
        let date = <div className={"date" + (mode === "date" ? " on" : "")} onClick={this.setModeDate}>{this.date}</div>;
        let time = this.props.timeMode && <div className={"time" + (mode === "time" ? " on" : "")} onClick={this.setModeTime}>{this.time}</div>;
        let datetime = <div className="datetime">{date}{time}</div>;
        let top = <div className="top">{year}{datetime}</div>;

        // Content
        let content;

        switch(mode) {

            // Year selection
            case "year":

            let years = [];
            let year = this.props.minDateTime.year();
            while(year <= this.props.maxDateTime.year()) {

                const y = year;
                let cls = (parseInt(this.year, 10) === year ? "y selected" :  "y");

                years.push(<div key={year} onClick={() => {this.setYear(y)}} className={cls}>{year}</div>);

                year++;
            }

            content = <div className="mode-year">{years}</div>;

            break;

            // Time selection
            case "time":

            let hour = this.props.timeModeHour && <fieldset className="hour"><label>{this.lang.hours}</label><input value={this.hour} name="h" type="range" min={this.props.minHour} max={this.props.maxHour} step={this.props.hourStep} onChange={this.setHour}/></fieldset>;
            let minute = this.props.timeModeMinute && <fieldset className="minute"><label>{this.lang.minutes}</label><input value={this.minute} name="m" type="range" min={this.props.minMinute} max={this.props.maxMinute} step={this.props.minuteStep} onChange={this.setMinute}/></fieldset>;
            let form = <form>{hour}{minute}</form>

            content = <div className="mode-time">{form}</div>
            break;   

            // Date selection
            case "date":
            default:

            // Navigation
            let prev = this.hasPrevMonth ? <div className="prev" onClick={this.prevMonth}>{this.lang.previous}</div> : <div className="prev"></div>;
            let current = <div className="current">{this.month} {this.year}</div>;
            let next = this.hasNextMonth ? <div className="next" onClick={this.nextMonth}>{this.lang.next}</div> : <div className="next"></div>;
            let month = <div className="month">{prev}{current}{next}</div>;

            // Weekdays
            let weekdays =  (<div className="weekdays week">
                            <div className="day">ma</div>
                            <div className="day">ti</div>
                            <div className="day">ke</div>
                            <div className="day">to</div>
                            <div className="day">pe</div>
                            <div className="day">la</div>
                            <div className="day">su</div>
                        </div>);

            // Prepare weeks
            let weeks = [];
            let w = 0;
            weeks[w] = [];

            // First and last day of month
            const last = moment(this.dateTime).endOf("month");
            let cur = moment(this.dateTime).startOf("month");

            // Fill start of month
            for(let a = 1; a < cur.format("E"); a++) {
                weeks[w].push(<div key={a} className="day"/>);
            }

            // Fill days in month
            let today = moment();
            while(cur <= last) {

                let day = cur.format("D");
                let cls = "day";

                // Currently selected date
                if(this.dateTime.format("YYYY-MM-DD") === cur.format("YYYY-MM-DD")) {
                    cls += ' selected';
                }

                // Today
                if(today.format("YYYY-MM-DD") === cur.format("YYYY-MM-DD")) {
                    cls += ' today';
                }

                // Outside range
                if(cur < this.props.minDateTime.startOf("date") || cur > this.props.maxDateTime.endOf("date")) {

                    cls += ' disabled';
                    weeks[w].push(<div key={cur.format("E")} className={cls}><span>{day}</span></div>);

                } else {
    
                    cls += ' active';
                    weeks[w].push(<div onClick={() => this.setDay(day)} key={cur.format("E")} className={cls}><span>{day}</span></div>);
                }

                // Add one day to counter
                cur = moment(cur).add(1, "days");

                // Add another week
                if(weeks[w].length === 7) {
                    w++;
                    weeks[w] = [];
                }
            }

            // Fill end of month
            if(weeks[w].length > 0) {
                for(let a = weeks[w].length; a < 7; a++) {
                    weeks[w].push(<div key={a+1} className="day"/>);
                }
            }

            // Create calendar
            let calendar = weeks.map( (week, k) => {
                return <div key={k} className="week">{week}</div>;
            });

            content = <div className="mode-date">{month}{weekdays}{calendar}</div>;

            break;
 
        }

        // Actions
        let ok = <div onClick={this.ok} className="ok">{this.lang.ok}</div>;
        let cancel = <div onClick={this.cancel} className="cancel">{this.lang.cancel}</div>;
        let actions = <div className="actions">{cancel}{ok}</div>

        // Picker
        let picker = <div className="container">{top}{content}{actions}</div>

        return <div className="DateTimePicker">{picker}</div>;
    }

}

DateTimePicker.propTypes = {

    dateTime: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    minDateTime: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    maxDateTime: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    hourStep: PropTypes.number,
    minuteStep: PropTypes.number,
    minHour: PropTypes.number,
    maxHour: PropTypes.number,
    minMinute: PropTypes.number,
    maxMinute: PropTypes.number,
    /** Text of ok button. */
    ok: PropTypes.string,
    /** Text of cancel button. */
    cancel: PropTypes.string,
    hours: PropTypes.string,
    minutes: PropTypes.string,
    dateFormat: PropTypes.string,
    timeFormat: PropTypes.string,
    yearFormat: PropTypes.string,
    monthFormat: PropTypes.string,
    weekdayFormat: PropTypes.string,
    yearMode: PropTypes.bool,
    timeMode: PropTypes.bool, 
    timeModeMinute: PropTypes.bool,
    timeModeHour: PropTypes.bool,
    mode: PropTypes.string,
    onChange: PropTypes.func,
    onDone: PropTypes.func,
    onCancel: PropTypes.func
}

// Default props
DateTimePicker.defaultProps = {

    dateTime: moment(),
    minDateTime: moment().subtract(5, "years").startOf("year"),
    maxDateTime: moment().add(6, "years").endOf("year"),
    hourStep: 1,
    minuteStep: 1,
    minHour: 0,
    maxHour: 23,
    minMinute: 0,
    maxMinute: 59,
    // ok: "OK",
    // cancel: "Cancel",
    // hours: "Hours",
    // minutes: "Minutes",
    dateFormat: "dd, DD. MMM",
    timeFormat: "HH:mm",
    yearFormat: "Y",
    monthFormat: "MMM",
    weekdayFormat: "DD",
    yearMode: false,
    timeMode: false, 
    timeModeMinute: true,
    timeModeHour: true,
    mode: "date",
    onChange: function() {},
    onDone: function() {},
    onCancel: function() {}
}

export default DateTimePicker;
