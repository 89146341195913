import React from 'react';
import styled from 'styled-components';
import Icon from 'components/gls-dumb/Icon';

const Wrapper = styled.div`

    display: inline;
    cursor: pointer;
`;

class Order extends React.Component {

    render(){

        let icon;

        if(this.props.type !== 'false'){

            if(this.props.type === 'asc'){

                icon = <Icon type="sort-down" size="1em" />
            }

            else { 

                icon = <Icon type="sort-up" size="1em" />
            }
        }

        return (

            <Wrapper onClick={this.props.onClick}>{icon} {this.props.children}</Wrapper>
        );
    }
}

export default Order;
