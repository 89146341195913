import React from 'react';
import Header from 'components/smart/Header';
import Title from 'components/gls-dumb/Title';
import PriceToolExport from 'components/smart/PriceToolExport';
import PriceToolImport from 'components/smart/PriceToolImport';
import {inject, observer} from 'mobx-react';
import {action, observable, computed} from 'mobx';
import Quicklink from 'components/gls-dumb/Quicklink';
import Pagebreak from 'components/gls-dumb/Pagebreak';
import Multifields from 'components/gls-dumb/Multifields';
import InfoBox from 'components/gls-dumb/InfoBox';
import Columns from 'components/gls-dumb/Columns';
import theme from 'theme';
import styled from 'styled-components';
import Button from 'components/gls-dumb/Button';
import ButtonB from 'components/gls-dumb/Button/Alternative';
import Form from 'FormGLS';
import NoticeBox from 'components/gls-dumb/NoticeBox';
import Radio from 'components/gls-dumb/Radio';
import Section from 'components/gls-dumb/Section';
import {bool} from 'Helpers';
import Field from 'components/gls-dumb/Field';

const Small = styled.span`

    ${props => `
    
        font-size: 0.8em;
    `}
`;

@inject('ui')
@inject('parcel')
@inject('countries')
@inject('pickuppoints')
@inject('shipmentsInfo')
@inject('load')
@inject('consignees')
@inject('import')
@inject('settings')

@observer class PriceTool extends React.Component {

    @observable mode = 'export';
    @observable form = new Map();
    @observable validation = new Map();
    @observable estimatedPrice = false;
    editable = observable.object({
        parcelshop: true,
    });

    lang = this.props.ui.lang.price_tool;

    constructor(props){

        super(props);
        this.setDefaultPickuppoint();
	this.setDefaultDeliverTo();
    }

    get sharedProps(){

        return {

            change: this.change,
            set: this.set,
            get: this.get,
            unset: this.unset,
            validation: this.validation,
            countryOptions: this.countryOptions,
            shipmentTypeOptions: this.shipmentTypeOptions,
            fieldProps: this.fieldProps,
            selectedService: this.selectedService,
            checkboxProps: this.checkboxProps
        };
    }

    fieldProps = (field) => {

        return {

            name: field,
            value: this.get(field),
            error: this.validation.get(field) === undefined ? false : true,
            onChange: this.change
        };
    }

    checkboxProps = (checkbox) => {

        return {

            name: checkbox,
            checked: bool(this.get(checkbox)),
            onChange: this.change
        }
    }

    get modeOptions(){

        return [

            {value: 'export', option: this.lang.export, callback: this.changeMode},
            {value: 'import', option: this.lang.import, callback: this.changeMode} // Temporarily hided.
        ];
    }

    changeMode = (v) => {

        this.mode = v;

        if(this.isShipmentTypeAvailable(this.get('shipment_type') === false)){
        
            this.unset('shipment_type');
        }

        if(this.realtimeValidation === true){

            this.validate();
        }
    }

    set = (k, v) => {

        this.form.set(k, v);
    }

    get = k => {

        return this.form.get(k);
    }

    unset = k => {

        this.form.set(k, '');
    }

    fillError = (k) => {

        if(this.error(k) === true){

            return theme.color[9];
        }

        return undefined;
    }

    error = (k) => {

        return this.validation.get(k) === undefined ? false : true;
    }

    @observable countedAmount = 0;
    @observable countedWeight = 0;
    @observable countedCubicMeter = 0;



    @action countMeasurements = (rows) => {
 
        let totalAmount = 0;
        let totalWeight = 0;
        let totalCubicMeter = 0;

        if(rows !== undefined){

            rows.forEach((v, k) => {

                let amount = this.parseValue(v.get('amount'));

                if(isNaN(amount)){

                    amount = 0;
                }

                totalAmount += parseInt(amount, 10);

                let weight = this.parseValue(v.get('weight'));

                if(isNaN(weight)){

                    weight = 0;
                }

                totalWeight += amount * weight;

                let width = this.parseValue(v.get('width'));
                let height = this.parseValue(v.get('height'));
                let length = this.parseValue(v.get('length'));

                if(isNaN(width) || isNaN(height) || isNaN(length)){

                    width = 0;
                    height = 0;
                    length = 0;
                }

                totalCubicMeter += amount * width * height * length / 1000000;
            });
        }

        this.countedAmount = totalAmount;
        this.countedWeight = totalWeight;
        this.countedCubicMeter = Math.round(totalCubicMeter * 100) / 100;

        this.set('counted_amount', this.countedAmount.toString());
        this.set('counted_weight', this.countedWeight.toString());
    }

    get measurements(){

        return [

            {name: 'amount', label: this.lang.measurements_pieces},
            {name: 'weight', label: this.lang.measurements_weight},
            {name: 'length', label: this.lang.measurements_length},
            {name: 'width', label: this.lang.measurements_width},
            {name: 'height', label: this.lang.measurements_height}
        ];
    }
     @action
     updateEditable(key, value) {
	      this.editable[key] = value; // Update observable
      }

    parseValue = (v) => {

        if(v === ''){

            return 0;
        }

        if(typeof v === 'string'){

            v = v.replace(',', '.');
        }

        if(Number.isInteger(v)){

            return v;
        }

        return  parseFloat(v);
    }

    updateAvailableProducts = () => {
    
        const selected = this.props.countries.findCountry('id', this.get('consignee_country'));

        this.availableProducts = selected !== false ? selected.availableproducts : undefined;
    };

    change = v => {

        if(v.name === 'service' && this.get('service') !== v.value){

            this.unsetService();
        }

        this.set(v.name, v.value);

        // After value is set:
        const selected = this.props.countries.findCountry('id', this.get('consignee_country'));

        if(v.name === 'consignee_country'){

            this.isValidShipmentType();
	    if (selected.shd === false) {
		    this.editable.parcelshop = false;
		    this.set('parcelshop', false);
            } else if (this.get('shipment_type') === undefined || this.get('shipment_type') === this.props.parcel.types.euroBusinessParcel) {
		    this.editable.parcelshop = true;
	    } else {
		    this.editable.parcelshop = false;
		    this.set('parcelshop', false);
            }

        }
	if(v.name === 'shipment_type'){
		 if(this.get('shipment_type') !== undefined && this.get('shipment_type') !== this.props.parcel.types.euroBusinessParcel) {
			 this.editable.parcelshop = false;
			 this.set('parcelshop', false);
	          } else if (this.get('consignee_country') !== undefined) {
			 if(selected.shd) {
			 	this.editable.parcelshop = true;
	                 } else {
			 this.editable.parcelshop = false;
			 this.parcelshop = false;
			 }
	          }

	}

        if(v.name === 'deliver_to'){

            this.copyDeliverInfo(v.value);
        }

        if(this.realtimeValidation === true){

            this.validate();
        }
    }

    isValidShipmentType = (v) => {

        this.updateAvailableProducts();

        if(this.availableProducts !== undefined && this.availableProducts.indexOf(this.get('shipment_type')) === -1){

            this.set('shipment_type', undefined);
        }
    }

    getPriceEstimate = () => {

        this.realtimeValidation = true;

        if(this.validate() === false){

            this.props.ui.messages = Form.parseMessages(this.validation);
        }

        else {

            let from_countries_id;
            let from_postalnumber;
            let to_countries_id;
            let to_postalnumber;
            let to_recipienttype;
            let insurance;
            let deliveryterms;
            let importtype;
	    let parcelshop;

            if(this.mode === 'export'){

                const pickuppoint = this.props.pickuppoints.data.get(this.get('pickuppoints_id'));
		if(pickuppoint !== false) {
	                from_countries_id = pickuppoint.countries_id;
        	        from_postalnumber = pickuppoint.postalnumber;
		}
                to_countries_id = this.get('consignee_country');
                to_postalnumber = this.get('consignee_postalnumber');
                to_recipienttype = this.get('consignee_recipienttype');
                insurance = this.get('insurance') === true ? true : false;
                parcelshop = this.get('parcelshop') === true ? true : false;
                deliveryterms = this.get('delivery_terms');
            }

            else if(this.mode === 'import'){

                from_countries_id = this.get('from_country');
                from_postalnumber = this.get('from_postalnumber');
		importtype = this.get('service');
                insurance = this.get('insurance') === true ? true : false;
                deliveryterms = this.get('delivery_terms');

                if(this.get('service') === 'RETURN' || this.get('service') === 'SHOP'){

                    const pickuppoint = this.props.pickuppoints.data.get(this.get('deliver_to'));

                    to_countries_id = pickuppoint.countries_id;
                    to_postalnumber = pickuppoint.postalnumber;
                }

                else if(this.get('service') === 'SHIP'){

                    to_countries_id = this.get('deliver_to_country');
                    to_postalnumber = this.get('deliver_to_postalnumber');
                }
            }

            const data = {
            
                from_countries_id: from_countries_id,
                from_postalnumber: from_postalnumber,
                to_countries_id: to_countries_id,
                to_postalnumber: to_postalnumber,
                to_recipienttype: to_recipienttype,
                type: this.get('shipment_type'),
                deliveryterms: deliveryterms,
                insurance: insurance,
                parcelshop: parcelshop,
		importtype: importtype,
                parcels: this.get('measurements'),
                ontoploading: this.get('ontoploading')
            };

            const requests = [
            
                this.props.shipmentsInfo.getPrice(data)
            ];

            const retry = this.getPriceEstimate;

            this.props.load.api(requests, retry, v => {

                this.estimatedPrice = v.data;
            });
        }
    }

    validate = () => {

        this.estimatedPrice = false;

        const form = new Form(this.form);

        if(this.mode === 'export'){

            form.set('pickuppoints_id').isFilled(this.lang.picukppoints_id_is_filled);
            form.set('consignee_postalnumber');
            form.set('consignee_country').isFilled(this.lang.consignee_country_is_filled);

            if(form.get('consignee_country') !== undefined && form.get('consignee_country') !== ''){

                const country = this.props.countries.findCountry('id', form.get('consignee_country'));

                form.set('consignee_postalnumber').regexp(country.zipregex, this.props.countries.getZiperrortext(country, this.lang.invalid_postnumber));

                if(country.code === 'FI' || form.get('shipment_type') === this.props.parcel.types.euroBusinessFreight){

                    form.set('consignee_recipienttype').isFilled(this.lang.recipienttype_is_filled);
                }
            }
        }

        else if(this.mode === 'import'){

            form.set('from_postalnumber').isFilled(this.lang.from_postalnumber_is_filled);

            if(form.get('from_country') !== undefined){

                const country = this.props.countries.findCountry('id', form.get('from_country'));

                form.set('from_postalnumber').regexp(country.zipregex, this.props.countries.getZiperrortext(country, this.lang.invalid_postnumber));
            }

            const finland = this.props.countries.findCountry('code', 'FI');
            const finland_countries_id = finland !== false ? finland.id : undefined;

            form.set('from_country').isFilled(this.lang.from_country_is_filled).isDifferent(finland_countries_id, this.lang.from_country_not_finland);

            if(this.get('service') === undefined){

                form.set('service').isFilled(this.lang.service_is_filled);
            }

            else if(this.get('service') === 'RETURN' || this.get('service') === 'SHOP'){

                const pickuppoint = this.props.pickuppoints.data.get(form.get('deliver_to'));
                const deliver_to_country = pickuppoint !== undefined ? pickuppoint.countries_id : undefined;

                form.set('from_country').isDifferent(deliver_to_country, this.lang.from_country_different);
                form.set('deliver_to').isFilled(this.lang.deliver_to_is_filled);
            }

            else if(this.get('service') === 'SHIP'){

                form.set('from_country').isDifferent(this.form.get('deliver_to_country'), this.lang.from_country_different);
                form.set('deliver_to_postalnumber').isFilled(this.lang.deliver_to_postalnumber_is_filled);

                if(form.get('deliver_to_country') !== undefined){

                    const country = this.props.countries.findCountry('id', form.get('deliver_to_country'));

                    form.set('deliver_to_postalnumber').regexp(country.zipregex, this.props.countries.getZiperrortext(country, this.lang.invalid_postnumber));
                }

                form.set('deliver_to_country').isFilled(this.lang.deliver_to_country_is_filled);
            }
        }


        form.set('shipment_type').isFilled(this.lang.shipment_type_is_filled);

        form.set('measurements', 'amount').isFilled(this.lang.is_filled_measurements_pieces).isInt(this.lang.is_int_measurements_pieces);
        form.set('measurements', 'weight').isFilled(this.lang.is_filled_measurements_weight).isDecimal(this.lang.is_numeric_measurements_weight);

        let sizeValidation;

        const types = this.props.parcel.types;

        if(this.form.get('shipment_type') === types.euroBusinessParcel){
		if(this.form.get('parcelshop')) {
	            form.set('measurements', 'weight').isLess(20, this.lang.invalid_weight_shop);
		} else {
	            form.set('measurements', 'weight').isLess(35, this.lang.too_much_weight);
		}


            sizeValidation = {

                maxBiggest: 200,
                maxSecondly: 80,
                maxTotal: 300
            };
        }
       
        else if(this.form.get('shipment_type') === types.euroBusinessFreight){

            form.set('measurements', 'weight').isLess(1500, this.lang.too_much_weight);

            sizeValidation = {

                maxHeight: 240,
                maxOther: [240, 180]
            };
        }

        else if(this.form.get('shipment_type') === types.globalBusinessParcel || this.form.get('shipment_type') === types.globalExpressParcel){

            form.set('measurements', 'weight').isLess(30, this.lang.too_much_weight);

            sizeValidation = {

                maxBiggest: 270,
                maxTotal: 300
            };
        }

        let fill_dimensions = false;

        // Fill dimesions if EuroBusinessParcel and one of the dimensions is set.

        if(form.get('shipment_type') === types.euroBusinessParcel){

            let measurements = form.get('measurements');

            if(measurements !== undefined){

                measurements.forEach(v => {
                
                    if(
                    
                        (v.get('width') !== undefined && v.get('width') !== '') ||
                        (v.get('height') !== undefined && v.get('height') !== '') ||
                        (v.get('length') !== undefined && v.get('length') !== '')
                    ){

                        fill_dimensions = true;
                    }
                });
            }
        }

        if(form.get('shipment_type') === types.globalBusinessParcel || form.get('shipment_type') === types.globalExpressParcel || fill_dimensions === true){

            form.set('measurements', 'width').isFilled(this.lang.is_filled_width);
            form.set('measurements', 'height').isFilled(this.lang.is_filled_height);
            form.set('measurements', 'length').isFilled(this.lang.is_filled_length);
        }

        form.set('measurements', 'width')
        .isInt(this.lang.is_int_measurements_width)
        .sizeOk(this.form.get('measurements'), sizeValidation, this.lang.measurements_invalid_size);

        form.set('measurements', 'height')
        .isInt(this.lang.is_int_measurements_height)
        .sizeOk(this.form.get('measurements'), sizeValidation, this.lang.measurements_invalid_size);

        form.set('measurements', 'length')
        .isInt(this.lang.is_int_measurements_length)
        .sizeOk(this.form.get('measurements'), sizeValidation, this.lang.measurements_invalid_size);

        if(form.get('shipment_type') === types.euroBusinessFreight){

            form.set('ontoploading').isFilled(this.lang.is_filled_ontoploading);
        }

        this.validation = form.result();

        if(this.validation.size !== 0){

            return false;
        }

        return true;
    }

    get countryOptions(){

        const countries = [{

            value: '',
            option: this.lang.select_deliver_to_country
        }];

        this.props.countries.data.forEach(v => {

            countries.push({

                value: v.id,
                option: v.name
            });
        });

        return countries;
    }

    availableProduts;

    @computed get shipmentTypeOptions(){

        const types = this.props.parcel.types;
        const names = this.props.parcel.typeNames;

        this.updateAvailableProducts();

        const options = [

            {value: '', option: this.lang.select_shipment_type}
        ];

        if(this.availableProducts === undefined || this.availableProducts.indexOf(types.euroBusinessParcel) !== -1){
        
            options.push({value: types.euroBusinessParcel, option: names[types.euroBusinessParcel]});
        }

        // Show Euro Business Freight only to GLS

        if(this.validEmail() === true){

        }

        if(this.mode === 'export'){

            if(this.availableProducts === undefined || this.availableProducts.indexOf(types.euroBusinessFreight) !== -1){

                options.push({value: types.euroBusinessFreight, option: names[types.euroBusinessFreight]});
            }

            if(this.availableProducts === undefined || this.availableProducts.indexOf(types.globalBusinessParcel) !== -1){

                options.push({value: types.globalBusinessParcel, option: names[types.globalBusinessParcel]});
            }

            if(this.availableProducts === undefined || this.availableProducts.indexOf(types.globalExpressParcel) !== -1){

                options.push({value: types.globalExpressParcel, option: names[types.globalExpressParcel]});
            }
        }

        return options;
    }

    validEmail(){

        return true;

        /*

        const email = this.props.settings.all.email;
        const gls = new RegExp(/.*@gls-finland.com/, 'i');
        const tinyfee = new RegExp(/.*@tinyfee.fi/, 'i');

        if(email.match(gls) === null && email.match(tinyfee) === null){

            return false;
        }

        return true;

        */
    }

    isShipmentTypeAvailable(shipment){

        let available = false;

        this.shipmentTypeOptions.forEach(v => {

            if(v.value === shipment){

                available = true;
            }
        });

        return available;
    }

    matchLang = () => {

        let lang = 'fi-FI';

        if(this.props.ui.selectedLang === 'en'){

            lang = 'en-US';
        }

        return lang;
    }

    createShipment = () => {

        if(this.mode === 'export'){

            this.props.load.route('/parcel/send.html', url => {

                this.props.parcel.priceEstimationCopy = this.form;
                this.props.history.push(url);
            });
        }

        else if(this.mode === 'import'){

            this.props.load.route('/import/details.html', url => {

                this.props.import.priceEstimationCopy = this.form;
                this.props.history.push(url);
            });
        }
    }

    unsetService = () => {

        this.unset('deliver_to');
    }

    selectedService = service => {

        if(this.get('service') !== service){

            return false;
        }

        return true;
    }

    copyDeliverInfo = pickuppointId => {

        if(pickuppointId !== undefined){

            const pickuppoint = this.props.pickuppoints.data.get(pickuppointId);

            if(this.get('consignee_postalnumber') === undefined){
            
                this.set('consignee_postalnumber', pickuppoint.postalnumber);
            }

            if(this.get('consignee_country') === undefined){

                this.set('consignee_country', pickuppoint.countries_id);
            }
        }
    }
     setDefaultDeliverTo = () => {

        if(this.get('deliver_to') === undefined){

            // If only one pickuppoint use tha as default

            if(this.props.pickuppoints.data.size === 1){

                this.set('deliver_to', this.props.pickuppoints.data.entries().next().value[0]);
            }

            // Else use one on settings if set and pickuppoint exists.

            else {

                const pickuppoint = this.props.settings.object.default_pickuppoint;

                if(pickuppoint !== undefined && pickuppoint !== '' && this.props.pickuppoints.exists(pickuppoint)){

                    this.set('deliver_to', pickuppoint);
                }
            }
        }
    }


    setDefaultPickuppoint = () => {

        if(this.get('pickuppoints_id') === undefined){

            // If only one pickuppoint use tha as default

            if(this.props.pickuppoints.data.size === 1){

                this.set('pickuppoints_id', this.props.pickuppoints.data.entries().next().value[0]);
            }

            // Else use one on settings if set and pickuppoint exists.

            else {

                const pickuppoint = this.props.settings.object.default_pickuppoint;

                if(pickuppoint !== undefined && pickuppoint !== '' && this.props.pickuppoints.exists(pickuppoint)){
                
                    this.set('pickuppoints_id', pickuppoint);
                }
            }
        }
    }

    showCustomsHandling = () => {
	let country_from;
	let country_to;
	let postalnumber_from;
	let postalnumber_to;
	if(this.mode === 'export') {
		const pickuppoint = this.props.pickuppoints.data.get(this.get('pickuppoints_id'));
		if(pickuppoint) {
      			country_from = this.props.countries.findCountry('id', pickuppoint.countries_id);
                	postalnumber_from = pickuppoint.postalnumber;
		} else {
			country_from = false;
		}
	       	country_to = this.props.countries.findCountry('id', this.form.get('consignee_country'));
                postalnumber_to = this.form.get('consignee_postalnumber'); 

	} else {
		country_from = this.props.countries.findCountry('id', this.get('from_country'));
                postalnumber_from = this.get('from_postalnumber');

		if(this.get('service') === 'SHIP'){
			country_to = this.props.countries.findCountry('id', this.form.get('deliver_to_country'));
			postalnumber_to = this.form.get('consignee_postalnumber'); 
		} else if(this.get('service') === 'RETURN' || this.get('service') === 'SHOP' ) {

                    const pickuppoint = this.props.pickuppoints.data.get(this.get('deliver_to'));

		    if(!pickuppoint) {
			    country_to = false;
		    } else {
	                   const to_countries_id = pickuppoint.countries_id;
			   country_to = this.props.countries.findCountry('id', to_countries_id);
                	   postalnumber_to = pickuppoint.postalnumber;
		   }
                } else {
			country_to = false;
		}


	}

        if(country_to !== false) {
		if(this.props.countries.isEU(country_to, postalnumber_to) === false) {
			return true;
		}
	}
	if (country_from !== false) { 
	 	if(this.props.countries.isEU(country_from, postalnumber_from) === false) {
                       	return true;
               	}
	}
        return false;
    }

    render(){

        return (

            <React.Fragment>

                <Header />

                <Section color="sub">

                    <Title>

                        <Quicklink title={this.lang.title} options={this.modeOptions} value={this.mode} />

                    </Title>

                </Section>

                <div className="main">

                    {this.mode === 'export' && <PriceToolExport editable={this.editable}  updateEditable={this.updateEditable.bind(this)} {...this.props} {...this.sharedProps} />}
                    {this.mode === 'import' && <PriceToolImport {...this.props} {...this.sharedProps} />}

                    <Pagebreak>{this.lang.measurements_title}</Pagebreak>

                    <Multifields 

                        name="measurements"
                        fields={this.measurements}

                        onChange={v => {

                            this.countMeasurements(v.value);
                            this.change(v);
                        }}

                        validation={this.validation.get('measurements')}
                        validationType={2.0}
                    />

                    <InfoBox>
                   
                        <InfoBox.item color={this.fillError('counted_amount')}>{this.lang.counted_amount}: {this.countedAmount} kpl</InfoBox.item>
                        <InfoBox.item color={this.fillError('counted_weight')}>{this.lang.counted_weight}: {this.countedWeight.toString().replace('.', ',')} kg</InfoBox.item>
                        <InfoBox.item>{this.lang.counted_cubic_meters}: {this.countedCubicMeter} m³</InfoBox.item>

                    </InfoBox>

                    {this.showCustomsHandling() === true &&

                        <React.Fragment>
                            
                            <Pagebreak>{this.lang.customs_handling}</Pagebreak>

                            <Columns>

                                <Columns.left>

                                    <Field type="select" {...this.fieldProps('delivery_terms')} label={this.lang.delivery_terms} options={this.props.parcel.getOptionfields('terms', undefined, this.form.get('consignee_country'), this.mode, this.form.get('shipment_type'))} />

                                </Columns.left>

                                <Columns.right></Columns.right>

                            </Columns>

                        </React.Fragment>
                    }

                    {this.form.get('shipment_type') === this.props.parcel.types.euroBusinessFreight &&

                        <React.Fragment>

                            <Pagebreak>{this.lang.ontoploading}</Pagebreak>

                            <Columns>

                                <Columns.left width="25%">

                                    <Radio {...this.fieldProps('ontoploading')}>

                                        <Radio.item value={true} label={this.lang.allowed} />
                                        <Radio.item value={false} label={this.lang.denied} />

                                    </Radio>

                                </Columns.left>

                                <Columns.right width="75%">
                                
                                    {this.get('ontoploading') !== undefined &&
                                    
                                        <NoticeBox>{this.get('ontoploading') === true ? this.lang.charge : this.lang.noCharge}</NoticeBox>
                                    }

                                </Columns.right>

                            </Columns>

                        </React.Fragment>
                    }

                    <Pagebreak>{this.lang.get_price_title}</Pagebreak>

                    {this.estimatedPrice !== false &&

                        <Columns gap="small" marginBottom={theme.size[1]}>

                            <Columns.left>

                                <table>

                                    <tbody>
                                
                                        {this.estimatedPrice.rows.map((v, k) =>

                                            <tr key={k}>

                                                <td style={{paddingRight: theme.size[1]}}>{v[this.matchLang()]}:</td>
                                                <td style={{paddingRight: theme.size[1]}}>{v.value.replace('.', ',')} €</td>

                                            </tr>
                                        )}

                                    </tbody>
                                
                                </table>
                            
                            </Columns.left>

                            <Columns.right>{this.estimatedPrice.message[this.matchLang()]}</Columns.right>

                        </Columns>
                    }

                    <Columns gap="small" marginBottom={this.estimatedPrice !== false ? theme.size[1] : 0}>

                        <Columns.left>{this.estimatedPrice !== false && <Small>{this.lang.vat_not_included}</Small>}</Columns.left>

                        <Columns.right>

                                <Columns gap="small">

                                    <Columns.left>{this.estimatedPrice !== false && <Small>{this.lang.only_estimation}</Small>}</Columns.left>

                                    <Columns.right>

                                        <ButtonB onClick={this.getPriceEstimate} style={{marginBottom: theme.size[1]}}>{this.lang.get_price}</ButtonB>

                                    </Columns.right>

                                </Columns>
                        
                        </Columns.right>

                    </Columns>

                    {this.estimatedPrice !== false &&

                        <Button onClick={this.createShipment}>{this.lang.create_shipment}</Button>
                    }

                </div>

            </React.Fragment>
        );
    }
}

export default PriceTool;
