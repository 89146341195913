import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {observer} from 'mobx-react';
import {observable, action} from 'mobx';
import Icon from 'components/gls-dumb/Icon';
import theme from 'theme';
import Note from 'components/gls-dumb/Note';

const Wrapper = styled.div`

    ${props => `

        display: flex;
        align-items: center;
        margin-bottom: ${props.marginBottom};
        margin-left: ${props.marginLeft};
        {${props.editable === false && `opacity: 0.5;`}}
    `};
`;

const Box = styled.div`

    ${props => `

        display: flex;
        align-items: center;
        justify-content: center;
        width: ${props.theme.inputSize};
        min-width: ${props.theme.inputSize};
        height: ${props.theme.inputSize};
        background: ${props.backgroundColor};
        border-radius: ${props.theme.size[2]};
        border: 2px solid ${props.theme.color[11]};
        {${props.editable === true && `cursor: pointer;`}}
        margin-right: ${props.theme.size[1]};
    `};
`;

const Info = styled.div`

    ${props => `
    
        margin-left:  ${props.theme.size[1]};
    `};
`;

@observer class Checkbox extends React.Component {

    @observable value;
    @observable checked;
    @observable showInfo = false;

    constructor(props){

        super(props);

        if(this.props.checked === true){

            this.checked = true;
            this.value = this.props.value;
        }

        else {

            this.checked = false;
            this.value = undefined;
        }
    }

    componentDidUpdate(prevProps){

        if(prevProps !== this.props){

            if(this.props.checked === true){

                this.checked = true;
                this.value = this.props.value;
            }

            else {

                this.checked = false;
                this.value = undefined;
            }
        }
    }

    @action toggle = () => {

        if(this.checked === false){

            this.checked = true;
            this.value = this.props.value;
        }

        else {
        
            this.checked = false;
            this.value = undefined
        }

        if(this.props.onChange !== undefined){

            this.props.onChange({
            
                name: this.props.name,
                value: this.value
            });
        }

        if(this.props.onClick !== undefined){
        
            this.props.onClick();
        }
    }

    @action toggleInfo = () => {

        if(this.showInfo === false){

            this.showInfo = true;
        }

        else {

            this.showInfo = false;
        }
    }

    render(){

        const toggle = this.props.editable === true ? this.toggle : undefined;
        
        return (

            <Wrapper marginBottom={this.props.marginBottom} marginLeft={this.props.marginLeft} editable={this.props.editable}>

                <Box onClick={toggle} editable={this.props.editable} backgroundColor={this.props.backgroundColor}>
                
                    <Icon type="check" show={this.checked} size="1.5em" />
                
                </Box>

                {this.props.label !== undefined && <div>{this.props.label}</div>}
                {this.props.image !== undefined && <div><img src={this.props.image} alt="" /></div>}

                {this.props.info !== undefined &&
                
                    <Info>

                        <Icon type="info" onClick={this.toggleInfo} cursor="pointer" color={theme.color[1]} hover={theme.color[3]} size={theme.fontSize[0]}></Icon>
                        <Note show={this.showInfo} close={this.toggleInfo}>{this.props.info}</Note>
                
                    </Info>
                }

            </Wrapper>
        );
    }
}

Checkbox.propTypes = {

    /** Name for the input. */
    name: PropTypes.string.isRequired,

    /** Value for input. */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,

    /** Default status */
    checked: PropTypes.bool,

    /** Event handler. */
    onChange: PropTypes.func,

    /** CSS bottom margin. */
    marginBottom: PropTypes.string,

    /** Is checkbox editable by user. */
    editable: PropTypes.bool
}

Checkbox.defaultProps = {

    marginBottom: theme.size[1],
    marginLeft: 0,
    editable: true,
    backgroundColor: theme.color[7]
}

export default Checkbox;
