import ApiStore from 'stores/ApiStore';
import api from 'api.json';

class AnnouncementsStore extends ApiStore {

    fetchAll = async () => {

        const settings = {

            url: api.endpoints.user.announcements
        };

        return await this.get({}, settings);
    }

    load = async () => {
   
        return await this.fetchAll();
    }
}

export default AnnouncementsStore;
