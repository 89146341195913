import React from 'react';
import ReactDOM from 'react-dom';
import App from 'components/smart/App';
import {ThemeProvider} from 'styled-components';
import {BrowserRouter} from 'react-router-dom';
import theme from './theme';
import 'css/reset.css';
import 'css/common.css';
import 'css/fontawesome-all.min.css';
import Stores from 'stores/Stores';
import {Provider} from 'mobx-react'; 

const stores = new Stores();

ReactDOM.render(

    <BrowserRouter basename="/app/">

        <Provider {...stores}>

            <ThemeProvider theme={theme}>

                <App />

            </ThemeProvider>

        </Provider>

    </BrowserRouter>
        
    , document.getElementById('root')
);
