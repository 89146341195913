import React from 'react';
import Component from 'Component';
import Header from 'components/smart/Header';
import Title from 'components/gls-dumb/Title';
import Content from 'components/gls-dumb/Content';
import {inject, observer} from 'mobx-react';
import Field from 'components/gls-dumb/Field';
import Columns from 'components/gls-dumb/Columns';
import Submit from 'components/gls-dumb/Submit';
import ButtonB from 'components/gls-dumb/Button/Alternative';
import theme from 'theme';

@inject('ui')
@inject('adminUsers')
@inject('senders')
@inject('adminPrivileges')

@observer class UsersAdd extends Component {

    lang = this.props.ui.lang.usersAdd;
    store =  this.props.adminUsers;

    constructor(props){

        super(props);

        this.store.form.clear();
        this.setDefaults();
    }

    setDefaults = () => {

        this.store.form.set('settings', {
            
            after_send: '/parcels/',
            default_view: '/home/',
            fds: false,
            language: 'fi',
            print_footer: 'true',
            printing_type: 'a4'
        });

        this.store.form.set('welcome', true);
    }

    submit = e => {

        e.preventDefault();

        if( ! this.store.validate()){

            this.props.ui.messages = this.store.messages();
        }

        else {

            this.props.ui.resolve(this.store.add(), () => {this.submit(e)}, () => {

                this.props.ui.messages = [this.lang.saved];
                this.props.ui.afterClearMessages = this.back;
            });
        }
    }

    back = () => {

        this.props.ui.route('/user/settings.html', url => {
        
            this.props.history.push(url);
        });
    }

    render(){

        return (
        
            <div className="main">
            
                <Header />

                <Title>
                
                    <div>{this.lang.title}</div>
                    <ButtonB onClick={this.back} width="auto" borderRadius="0" backgroundColor={theme.color[1]}>{this.lang.back}</ButtonB>
                    
                </Title>

                <Content>

                    <form onSubmit={this.submit}>

                        <Columns>

                            <Columns.left>

                                <Field {...this.fieldProps('email')} label={this.lang.email} />
                                <Field {...this.fieldProps('name')} label={this.lang.name} />

                            </Columns.left>

                            <Columns.right>

                                <Field {...this.fieldProps('tel')} label={this.lang.tel} />

                            </Columns.right>

                        </Columns>

                        <Submit>{this.lang.submit}</Submit>

                    </form>

                </Content>
            
            </div>
        );
    }
}

export default UsersAdd;
