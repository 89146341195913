import ApiStore from 'stores/ApiStore';
import Validate from 'Validate';
import api from 'api.json';
import {observable, computed} from 'mobx';

class SettingsStore extends ApiStore {

    @observable form = new Map();
    url = api.endpoints.user.users;

    async load(){

        return await this.get({}, {url: this.url});
    }

    async change(account){
    
        return await this.patch({privileges_id: account}, {url: this.url}); 
    }

    save = async () => {

        const settings = {};

        this.form.forEach((v, k) => {

            if(k !== 'password' && k !== 'password_verify'){
        
                settings[k] = v;
            }
        });

        return await this.put({settings: settings}, {

            url: this.url

        });
    }

    changePassword = async id => {

        return await this.patch({password: this.form.get('password')}, {url: api.endpoints.user.users + id + '/password/'});
    }

    repopulate(){

        const keys = this.data.keys();
        const data = this.data.get(keys.next().value);

        if(data.settings !== undefined){

            for(var k in data.settings){

                this.form.set(k, data.settings[k]);
            }
        }
    }

    // Get user settings.

    @computed get object(){

        const keys = this.data.keys();
        const data = this.data.get(keys.next().value);

        if(data === undefined || data.settings === undefined){
       
            return false;
        }

        const fallback = {
        
            after_send: data.settings.after_send,
            default_view: data.settings.default_view
        };

        for(let k in fallback){

            let v = fallback[k];

            if(v === undefined || v === null || v === false || v === ''){

                v = '/home/';
            }

            data.settings[k] = v;
        }

        return data.settings;
    }

    // Get all data from first (and only) item.

    @computed get all(){

        const keys = this.data.keys();
        const data = this.data.get(keys.next().value);

        return data;
    }

    validate(){

        const lang = this.stores.ui.lang.profile;
        const fields = new Validate(this.form);

        fields.set('default_view').isFilled(lang.fillDefaultView);
        fields.set('after_send').isFilled(lang.fillAfterSend);
        fields.set('language').isFilled(lang.fillLanguage);
        fields.set('printing_type').isFilled(lang.fillPrintingType);
        fields.set('print_footer').isFilled(lang.fillPrintFooter);

        if(
            (this.form.get('password') !== '' && this.form.get('password') !== undefined) ||
            (this.form.get('password_verify') !== '' && this.form.get('password_verify') !== undefined)
        ){

            fields.set('password').isFilled(lang.fillPassword).minSize(6, lang.tooShortPassword);
            fields.set('password_verify').isFilled(lang.fillPasswordVerify).equalTo(this.form.get('password'), lang.invalidPasswordVerify).minSize(6, lang.tooShortPassword);
        }

        return {fields: fields.result()};
    }

    setField = (k, v) => {

        if(k === 'printing_type' && v !== 'label'){

            this.form.set('print_footer', 'true');
        }

        this.form.set(k, v);
    }

    getField = (k) => {

        return this.form.get(k);
    } 

    deleteField = (k) => {

        this.form.delete(k);
    }

    errorCount = (results) => {

        return Validate.size(results);
    }

    messages = (results) => {

        return Validate.parseMessages(results);
    }
}

export default SettingsStore;
