import ParcelStore from 'stores/ParcelStore';
import AuthStore from 'stores/AuthStore';
import ApiStore from 'stores/ApiStore';
import UIStore from 'stores/UIStore';
import CustomersStore from 'stores/CustomersStore';
import SettingsStore from 'stores/SettingsStore';
import PrivilegesStore from 'stores/PrivilegesStore';
import ConsigneesStore from 'stores/ConsigneesStore';
import SendersStore from 'stores/SendersStore';
import AnnouncementsStore from 'stores/AnnouncementsStore';
import AttachmentStore from 'stores/AttachmentStore';
import CountriesStore from 'stores/CountriesStore';
import FetchStore from 'stores/FetchStore';
import PickupStore from 'stores/PickupStore';
import AdminPrivilegesStore from 'stores/AdminPrivilegesStore'; 
import Load from 'stores/Load';
import AdminUsersStore from 'stores/AdminUsersStore';
import ImportStore from 'stores/ImportStore';
import Views from 'stores/Views';
import ShipmentsInfo from 'stores/ShipmentsInfo';
import CNcodesStore from 'stores/CNcodesStore';
import CustomUnitsStore from 'stores/CustomUnitsStore';
import CustomsStore from 'stores/CustomsStore';

class Stores {

    constructor(){

        this.ui = new UIStore(this);
        this.auth = new AuthStore(this);
        this.api = new ApiStore(this);
        this.parcel = new ParcelStore(this);
        this.customers = new CustomersStore(this);
        this.settings = new SettingsStore(this);
        this.privileges = new PrivilegesStore(this);
        this.consignees = new ConsigneesStore(this);
        this.senders = new SendersStore(this);
        this.announcements = new AnnouncementsStore(this);
        this.attachment = new AttachmentStore(this);
        this.countries = new CountriesStore(this);
        this.fetch = new FetchStore(this);
        this.pickuppoints = new PickupStore(this);
        this.cncodes = new CNcodesStore(this);
        this.customunits = new CustomUnitsStore(this);
        this.customs = new CustomsStore(this);

        const translations = {

            ONLINE_USER_CONSIGNEES_DUPLICATE_CONSIGNEE_ALREADY_EXISTS: this.ui.lang.consigneeForm.exists,
            ONLINE_ADMIN_USERS_EMAIL_IS_ALREADY_RESERVED: this.ui.lang.usersAdd.exists
        }

        this.load = new Load(this, this.ui.lang.api, translations);
        this.adminPrivileges = new AdminPrivilegesStore(this);
        this.adminUsers = new AdminUsersStore(this);
        this.import = new ImportStore(this);
        this.views = new Views(this);
        this.shipmentsInfo = new ShipmentsInfo(this);
    }
}

export default Stores;
