import React from 'react';
import Header from 'components/smart/Header';
import Title from 'components/gls-dumb/Title';
import Button from 'components/gls-dumb/Button';
import ButtonB from 'components/gls-dumb/Button/Alternative';
import {observer, inject} from 'mobx-react';
import theme from 'theme';
import {observable} from 'mobx';
import Columns from 'components/gls-dumb/Columns';
import {Sortable} from 'components/gls-dumb/Sortable';
import Field from 'components/gls-dumb/Field';
import Submit from 'components/gls-dumb/Submit';
import {arrayFromMap} from 'Helpers';
import {Buttons} from 'components/gls-dumb/Buttons';
import FileUpload from 'components/gls-dumb/FileUpload';
import api from 'api.json';
import Validate from 'Validate';
import Window from 'components/gls-dumb/Window';
import Form from 'Form';
import Checkbox from 'components/gls-dumb/Checkbox';
import Section from 'components/gls-dumb/Section';

@inject('senders')
@inject('settings')
@inject('consignees')
@inject('parcel')
@inject('ui')
@inject('countries')
@inject('attachment')
@inject('load')

@observer class Consignees extends React.Component {

    lang = this.props.ui.lang.consignees;
    form = new Map();

    @observable keyword = false;
    @observable isOpen = {

        window: false
    }

    send = consigneesID => {

        this.props.parcel.loadParcelSend(() => {
        
            this.props.parcel.defaultConsignee = consigneesID;
            this.props.ui.backFrom.parcel = '/consignees/list.html';
            this.props.history.push('/parcel/send.html');
        });
    }

    change = v => {

        this.set(v.name, v.value);
    }

    set = (k, v) => {

        this.props.parcel.form.set(k, v);
    }

    get = k => {

        return this.props.parcel.form.get(k);
    }

    fieldProps = (field) => {

        return {

            name: field,
            value: this.get(field),
            error: false,
            onChange: this.change
        };
    }

    fieldProps2 = (field) => {

        return {

            name: field,
            value: this.form.get(field),
            error: false,
            onChange: this.change2
        };
    }

    submit = e => {

        e.preventDefault(); 

        this.keyword = this.get('keyword');
    }

    uploadCSV = file => {

        if(this.validate() === true){

            const form = new Map([['file', file]]);
            const validation = new Validate(form).set('file').fileOfType(['CSV'], this.lang.invalidFile).result();
            const errors = Validate.parseMessages({result: validation});

            if(errors.length !== 0){

                this.props.ui.messages = errors;
            }

            else {

                this.props.ui.resolve(this.props.attachment.upload(file, api.endpoints.user.consigneesCSV, undefined, this.form), () => this.uploadCSV(file), r => {

                    // Update list if new consignees.

                    if(r.data.length !== 0){

                        this.props.ui.resolve(this.props.consignees.fetchAll(), this.props.consignees.fetchAll, () => { 
                           
                            this.ready([this.lang.imported]);
                        });
                    }

                    else {

                        this.ready([this.lang.imported]);
                    }

                }, r => {

                    const messages = [];


                    if(r.data.length !== undefined){

                        r.data.forEach(v => {
			    if (messages.length < 5) {
                                messages.push(this.lang.errorOnLine + ' ' + v.line + ': ' + v.errors[0]);
			    }
                        });
    			if (messages.length < r.data.length) {
			        messages.push('+' + (r.data.length - messages.length) + ' ' + this.lang.errorOthers);
   			 }
                    }
		    

                    if(r.errors.length !== undefined){

                        r.errors.forEach(v => {
				if (v === 'ONLINE_USER_CONSIGNEES_INVALID_DATA_ENCODING') {
			        	messages.push(this.lang.errorEncoding);
				} else if (v === 'ONLINE_USER_CONSIGNEES_INVALID_CSV_FILE') {
			        	messages.push(this.lang.errorCsvFile);
				} else if (v === 'ONLINE_USER_CONSIGNEES_CSV_FILE_CONTAINS_NO_CONSIGNEE_DATA'){
                                	messages.push(this.lang.no_consignee_data);
                            	} else if ( messages.length === 0) {
					messages.push(v);
				}

                        });
                    }

                    if(messages.length !== 0){

                        this.props.ui.messages = messages;
                    }
                });
            }
        }
    }

    ready = messages => {

        this.props.ui.afterClearMessages = () => {
        
            this.closeWindow();
        };

        this.props.ui.messages = messages;
    }

    openWindow = () => {

        this.isOpen.window = true;
    }

    closeWindow = () => {

        this.isOpen.window = false;
        this.form.clear();
    }

    get charsets(){

        return [
            
            {value: '', option: '- ' + this.lang.selectCharset},
            {value: 'UTF-8', option: 'UTF-8'},
            {value: 'ISO-8859-1', option: 'ANSI / ISO-8859-1'}
        ];
    }

    get delimiters(){

        return [
        
            {value: '', option: '- ' + this.lang.selectDelimeter},
            {value: ',', option: this.lang.comma},
            {value: ';', option: this.lang.semicolon}
        ];
    }

    validate = () => {

        const form = new Form(this.form);

        form.set('encoding').isFilled(this.lang.fill_charset);
        form.set('delimiter').isFilled(this.lang.fill_delimiter);

        const errors = Form.parseMessages(form.result());

        if(errors.length !== 0){

            this.props.ui.messages = errors; 

            return false;
        }

        return true;
    }

    change2 = v => {

        this.form.set(v.name, v.value);
    }

    edit = id => {

        const params = {id: id};

        this.props.load.route('/consignees/:id/edit.html', params, url => {
        
            this.props.history.push(url);
        });
    }

    render(){

        const fields = ['search', 'name1', 'city', 'countries_id'];

        this.consignees = arrayFromMap(this.props.consignees.data).filter(v => {

            if(this.keyword !== false && this.keyword !== ''){

                let matches = 0;

                fields.forEach(field => {

                    const regexp = new RegExp(this.keyword, 'gi');

                    if(field === 'countries_id'){

                        console.log('Country: ', v[field]);
                    }

                    const str = field === 'countries_id' ? this.props.countries.getName(v[field]) : v[field];

                    if(str !== null && str.match(regexp) !== null){

                        matches++;
                    }
                });

                if(matches === 0){ 

                    return false;
                } 
            }

            return true;
        });

        return (

            <React.Fragment>

                <Window isOpen={this.isOpen.window}>

                    <Field type="select" {...this.fieldProps2('encoding')} label={this.lang.charset} options={this.charsets} labelPosition="left" />
                    <Field type="select" {...this.fieldProps2('delimiter')} label={this.lang.delimiter} options={this.delimiters} labelPosition="left" />

                    <Checkbox
                    
                        name="skip_errors"
                        checked={this.form.get('skip_errors')}
                        onChange={v => this.change2(v)}
                        label={this.lang.skip_errors}
                        value={true}
                    />

                    <Buttons marginBottom="0">

                        <Button onClick={this.closeWindow}>{this.lang.cancel}</Button>

                        <FileUpload onChange={this.uploadCSV}>
                        
                            <Button>{this.lang.csv}</Button>
                            
                        </FileUpload>

                    </Buttons>
                
                </Window>

                <Header />

                <Section color="sub">

                    <Title>
                        
                        <div>{this.lang.title}</div>

                        <Buttons width="auto" marginBottom="0">

                            <ButtonB onClick={this.openWindow}>{this.lang.csv}</ButtonB> 
                            <ButtonB onClick={() => {this.props.history.push('/consignees/add.html')}}>{this.lang.add}</ButtonB>

                        </Buttons>
                    
                    </Title>

                </Section>
       
                <div className="main">

                    <form onSubmit={this.submit}>

                        <Columns gap="small" marginBottom={theme.size[1]}>

                            <Columns.left width="75%">
                                
                                <Field {...this.fieldProps('keyword')} label={this.lang.keyword} labelPosition="left" />

                            </Columns.left>

                            <Columns.right width="25%">

                                <Submit>{this.lang.searchButton}</Submit>

                            </Columns.right>

                        </Columns>

                    </form>
                
                    <Sortable>
                        
                        <Sortable.thead>
                        
                            <Sortable.tr>

                                <Sortable.td sortable={true} order="asc">{this.lang.search}:</Sortable.td>
                                <Sortable.td sortable={true}>{this.lang.name1}:</Sortable.td>
                                <Sortable.td sortable={true}>{this.lang.city}:</Sortable.td>
                                <Sortable.td sortable={true}>{this.lang.country}:</Sortable.td>
                                <Sortable.td colSpan="2"></Sortable.td>

                            </Sortable.tr>
                        
                        </Sortable.thead>

                        <Sortable.tbody>

                            {this.consignees.length !== 0 ? this.consignees.map(v => 
                                
                                <Sortable.tr key={v.id}>
                                
                                    <Sortable.td>{v.search}</Sortable.td>
                                    <Sortable.td>{v.name1}</Sortable.td>
                                    <Sortable.td>{v.city}</Sortable.td>
                                    <Sortable.td>{this.props.countries.getName(v.countries_id)}</Sortable.td>

                                    <Sortable.td>
                                    
                                        <ButtonB onClick={() => this.send(v.id)}>{this.lang.send}</ButtonB>

                                    </Sortable.td>

                                    <Sortable.td>

                                        <ButtonB onClick={() => this.edit(v.id)}>{this.lang.edit}</ButtonB>

                                    </Sortable.td>

                                </Sortable.tr>
                            ):
                            
                                <Sortable.tr>

                                    <Sortable.td colSpan="5">{this.lang.noResults}</Sortable.td>

                                </Sortable.tr> 
                            }

                        </Sortable.tbody>

                    </Sortable>

                </div>

            </React.Fragment>
        );
    }
}

export default Consignees;
