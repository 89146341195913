import React from 'react';
import styled from 'styled-components';
import ReactDOMServer from 'react-dom/server';
import PropTypes from 'prop-types';
import Theme from 'theme';

const Wrapper = styled.table`

    width: 100%;

    ${props => props.mode === 'gls' && `

        & > thead > tr > td {

            padding: ${props.theme.size[1]};
            background: ${props.theme.color[4]};
            color: ${props.theme.color[1]};
            border: 1px solid ${props.theme.color[4]};

            @media (min-width: ${props.breakpoint}){

                border: 2px solid ${props.theme.color[4]};
            }
        }

        & > tbody > tr > td {

            padding: ${props.theme.size[1]};
            border-left: 1px solid ${props.theme.color[4]};
            border-right: 1px solid ${props.theme.color[4]};
            border-bottom: 1px solid ${props.theme.color[4]};
            vertical-align: middle;

            @media (min-width: ${props.breakpoint}){

                border: 0;
                border-bottom: 2px solid ${props.theme.color[4]};
            }
        }

        & > tbody > tr > td:first-of-type {
            
            border-left: 1px solid ${props.theme.color[4]};
            border-top: 1px solid ${props.theme.color[4]};

            @media (min-width: ${props.breakpoint}){

                border-left: 2px solid ${props.theme.color[4]};
            }
        }

        & > tbody > tr > td:last-of-type {
            
            border-right: 1px solid ${props.theme.color[4]};
            border-bottom: 0;

            @media (min-width: ${props.breakpoint}){

                border-right: 2px solid ${props.theme.color[4]};
                border-bottom: 2px solid ${props.theme.color[4]};
            }
        }

        & > tbody tr:last-of-type td:last-of-type {

            border-bottom: 1px solid ${props.theme.color[4]};

            @media (min-width: ${props.breakpoint}){

                border-bottom: 2px solid ${props.theme.color[4]};
            }
        }
    `}

    ${props => props.mode === 'multifields' && `
   
        & td {

            padding-bottom: ${props.theme.size[1]};
        }
    `}
`;

const Thead = styled.thead`

    display: none;

    @media (min-width: ${props => props.breakpoint}){

        display: table-header-group;
    }
`;

const Tbody = styled.tbody`

    @media (max-width: ${props => props.breakpoint}){

        display: block;
    }
`;

const Tr = styled.tr`

     @media (max-width: ${props => props.breakpoint}){

        display: block;
    }
`;

const Td = styled.td`

    ${props => props.width !== undefined && `width: ${props.width};`}

    ${props => props.paddingRight !== undefined && `
    
        padding-right: ${props.paddingRight}
    `};

    ${props => props.paddingBottom !== undefined && `
    
        padding-bottom: ${props.paddingBottom}
    `};

    ${props => props.padding !== undefined && `padding: ${props.padding};`}

    @media (max-width: ${props => props.breakpoint}){

        width: 100%;
        display: block;
        padding-right: 0;
        padding-bottom: 0;

        ${props => props.title !== undefined && `

            text-align: right;

            &:before {

                float: left;
                content: '${props.title}';
                ${props.mode === 'multifields' && `padding-bottom: ${props.theme.size[1]};`}
            }
        `}
    }
`;

const extractText = str => {

    const span = document.createElement('span');
    span.innerHTML = ReactDOMServer.renderToString(str);

    return span.textContent || span.innerText;
}

class Table extends React.Component {

    constructor(props) {

        super(props);

        if(props.children.length === 2){

            let tbody = props.children[0];
            let tr = tbody.props.children;
            let td = tr.props.children;

            this.titles = [];

            td.forEach(v => {
            
                this.titles.push(extractText(v.props.children));
            });
        }
    }

    static thead = (props) => {
 
        return <Thead {...props} />
    }

    static tbody = (props) => {

        return <Tbody {...props} />
    }

    static tr = (props) => {

        return <Tr {...props} />
    }

    static td = (props) => {

        return <Td {...props} />
    }

    render(){

        let content = this.props.children;

        content = React.Children.map(content, (group, k) => {

            return React.cloneElement(group, {breakpoint: this.props.breakpoint},
            
                React.Children.map(group.props.children, tr =>

                    React.cloneElement(tr, {breakpoint: this.props.breakpoint},

                        React.Children.map(tr.props.children, (td, i) => {

                            return React.cloneElement(td, {title: this.titles[i], mode: this.props.mode, breakpoint: this.props.breakpoint});
                        })
                    )
                )
            );
        });

        return (

            <Wrapper mode={this.props.mode} breakpoint={this.props.breakpoint}>{content}</Wrapper>
        );
    }
}

Table.defaultProps = {

    breakpoint: Theme.breakpoint
}

Table.propTypes = {

    mode: PropTypes.oneOf(['gls', 'multifields'])
}

export default Table;
