import React from 'react';
import styled from 'styled-components';
import Icon from 'components/gls-dumb/Icon';
import theme from 'theme';

const Wrapper = styled.div`

    position: absolute;
    right: 0;
    top: 0;
    background: ${props => props.theme.color[5]};
    padding: 1.93em;
    color: ${props => props.theme.color[1]};
    z-index: 10;
    cursor: default;
    overflow-y: auto;
    min-width: 1000%;
    max-height: 1400%;
    max-width: 1200%;

    @media (min-width: ${props => props.theme.breakpoint}){

        position: absolute;
        max-width: 1500%;
    }
`;

const Header = styled.div`

    display: flex;
    justify-content: space-between;
`;

const Title = styled.div`

    font-size: 2em;
`;

const Item = styled.div`

    float: left;
    clear: left;
    display: flex;
    align-items: center;
    white-space: nowrap;
    min-width: 15em;
    max-width: 30em;
    cursor: pointer;
    margin-top: 1.5rem;

    & > :first-child {

        margin-right: 1rem;
    }

    &:hover {

        color: ${props => props.theme.color[3]};
    }
`;

class Dropdown extends React.Component {

    render(){

        if(this.props.isOpen === false){

            return null;
        }

        else {

            return (
            
                <Wrapper>

                    <Header>

                        <Title>{this.props.title}</Title>
                        <Icon type="bars" onClick={this.props.onClick} cursor="pointer" hover={theme.color[3]} />

                    </Header>

                    {this.props.items.map((v, k) => 
                    
                        <Item key={k} onClick={v.onClick}>
                       
                            <Icon type={v.icon} /> {v.name}
                            
                        </Item>
                    )}

                </Wrapper>
            );
        }
    }
}

export default Dropdown;
