import React from 'react';
import Header from 'components/smart/Header';
import Title from 'components/gls-dumb/Title';
import Content from 'components/gls-dumb/Content';
import Columns from 'components/gls-dumb/Columns';
import Field from 'components/gls-dumb/Field';
import {observable} from 'mobx';
import {observer, inject} from 'mobx-react';
import Button from 'components/gls-dumb/Button';
import ModalAlerts from 'components/gls-dumb/ModalAlerts';
import Pagebreak from 'components/gls-dumb/Pagebreak';
import theme from 'theme';
import Radio from 'components/gls-dumb/Radio';

@inject('consignees')
@inject('countries')
@inject('ui')
@inject('load')

@observer class ConsigneesAdd extends React.Component {

    lang = this.props.ui.lang.consigneeForm;

    @observable validation = {fields: new Map()};
    @observable messages = [];
    @observable move = false; // If true move back to listing after showing messages.

    constructor(props){

        super(props);

        this.props.consignees.form.clear();
    }

    fieldProps = (field) => {

        return {
        
            name: field,
            value: this.props.consignees.form.get(field),
            error: this.validation.fields.get(field) === undefined ? false : true,
            onChange: this.change
        };
    }

    change = (v) => {

        this.props.consignees.form.set(v.name, v.value);
    }

    submit = () => {

        this.validation = this.props.consignees.validate();

        if(this.props.consignees.errorCount(this.validation) !== 0){

            this.messages = this.props.consignees.messages(this.validation);
        }

        else {

            this.props.ui.resolve(this.props.consignees.add(), this.submit, r => {

                this.move = true;
                this.messages = [this.lang.added];
            });
        }
    }
    
    clearMessages = () => {

        if(this.move === false){ 

            this.messages = [];
        }

        else {

            this.props.consignees.form.clear();
            this.back();
        }
    }

    back = () => {

        this.props.load.route('/consignees/list.html', url => {
        
            this.props.history.push(url);
        });
    }

    render(){

        const fieldProps = this.fieldProps;

        return(
        
            <div className="main">

                <ModalAlerts messages={this.messages} ok={this.clearMessages} />
            
                <Header />

                <Title>
                
                    <div>{this.lang.addTitle}</div>
                    <Button onClick={this.back} width="auto" borderRadius="0" backgroundColor={theme.color[1]}>{this.lang.cancel}</Button>

                    </Title>

                <Content>
                    
                    <Columns>

                        <Columns.left>

                            <Field type="text" label={this.lang.name} {...fieldProps('name1')} /> 
                            <Field type="text" label={this.lang.name2} {...fieldProps('name2')} /> 
                            <Field type="text" label={this.lang.address} {...fieldProps('address1')} /> 
                            <Field type="text" label={this.lang.address2} {...fieldProps('address2')} /> 
                            <Field type="text" label={this.lang.contact} {...fieldProps('contact')} /> 

                            <Columns gap="small">

                                <Columns.left width="25%">
                            
                                    <Field type="text" label={this.lang.postalnumber} {...fieldProps('postalnumber')} />
                                
                                </Columns.left>

                                <Columns.right width="75%">
                                    
                                    <Field type="text" label={this.lang.city} {...fieldProps('city')} />
                                
                                </Columns.right>

                            </Columns>

                            <Radio {...this.fieldProps('recipienttype')}>

                                <Radio.item value="2C" label={this.lang.recipienttype_private_address} info={this.lang.recipienttype_private_address_info} width="50%" />
                                <Radio.item value="2B" label={this.lang.recipienttype_business_address} info={this.lang.recipienttype_business_address_info} width="50%" />

                            </Radio>

                        </Columns.left>

                        <Columns.right>

                            <Field type="select" label={this.lang.country} options={this.props.countries.optionsWithCode()} {...fieldProps('countries_id')} /> 
                            <Field type="text" label={this.lang.businessID} {...fieldProps('businessid')} /> 
                            <Field type="text" label={this.lang.email} {...fieldProps('email')} /> 
                            <Field type="text" label={this.lang.mobile} {...fieldProps('mobile')} /> 
                            <Field type="text" label={this.lang.tel} {...fieldProps('tel')} /> 
                            <Field type="text" label={this.lang.nick} {...fieldProps('search')} info={this.lang.searchHelp} /> 

                        </Columns.right>

                    </Columns>

                    <Pagebreak>{this.lang.info}</Pagebreak>

                    <Field type="textarea" {...fieldProps('description')} />

                    <Button onClick={this.submit}>{this.lang.save}</Button>

                </Content>
            
            </div> 
        );
    }
}

export default ConsigneesAdd;
