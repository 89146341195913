import React from 'react'
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import styled from 'styled-components';

const Textarea = styled.textarea(props => `

    background-color: ${props.theme.color[7]};
    border-width: 1px;

    @media (min-width: ${props.theme.breakpoint}){

        border-width: 2px;
    }
`);

@observer class Component extends React.Component {

    @observable value;

    constructor(props){

        super(props);

        if(this.props.value !== undefined){

            this.value = this.props.value;
        }
    }

    change = e => {

        this.value = e.target.value;

        this.props.onChange({
        
            name: this.props.name,
            value: this.value
        });
    }

    render(){
    
        return (

            <Textarea name={this.props.name} onChange={this.change} value={this.value} />
        );
    }
}

Component.propTypes = {

    /** Name attribute of textarea  */
    name: PropTypes.string.isRequired,

    /** Content of the textarea */
    value: PropTypes.string
}

export default Component;
