import React from 'react';
import ButtonB from 'components/gls-dumb/Button/Alternative';
import styled from 'styled-components';
import {inject} from 'mobx-react';
import {withRouter} from 'react-router-dom';
import api from 'api.json';

const Container = styled.div`

    ${props => `

        display: flex;
        align-items: center; 
        
        & :nth-child(1){

            flex-grow: 1;
        }

        & :first-child {

            margin-right: ${props.theme.size[1]};
        }
    `}
`;

@withRouter
@inject('parcel')
@inject('attachment')
@inject('ui')
@inject('load')

class Draft extends React.Component {

    lang = this.props.ui.lang.draft;

    cancel = () => {

        this.props.ui.clearCustomConfirm();
        let back = this.props.ui.backFrom.parcel;

        if(back === false){

            back = {
            
                route: '/home/',
                params: {}
            };
        }

        this.props.load.route(back.route, back.params, url => {

            this.props.history.push(url);
        });
    }

    doNotCancel = () => {

        this.props.ui.clearCustomConfirm();
    }

    cancelPrompt = () => {

        this.props.ui.customConfirm = {

            isOpen: true,
            message: this.lang.confirm,
            cancelHandler: this.doNotCancel,
            okHandler: this.cancel,
            cancelText: this.lang.cancelConfirm,
            okText: this.lang.okConfirm
        }
    }

    save = () => {

        const saved = () => {

            this.props.ui.messages = [this.lang.saved];
        }

        this.props.ui.resolve(this.props.parcel.saveDraft(this.props.match.params.id), this.save, v => {

            // START

            // Upload file in separate request (required by API)
            // Save SHIPMENT doesn't use this and it has own method to upload file.

            const file = this.props.parcel.form.get('file');
            const existing = this.props.match.params.id;
            const id = existing !== undefined ? existing : v.data.id;

            if(this.props.parcel.form.get('customs_documenttype') === 'FILE' && file !== undefined){

                if(id !== undefined){

                    const url = api.endpoints.user.shipments + id + '/invoice/';

                    this.props.attachment.upload(file, url).then(() => {
                    
                        saved();
                    });
                }

                else {
                
                    console.log('Shipment ID is undefined!');
                };
            }

            // END / START

            // Combine selected file in a separate request (required by API)
            // Save DRAFT doesn't use this and it has own method to combine file.

            else if(this.props.parcel.form.get('customs_documenttype') === 'COMBINE'){

                const from = this.props.parcel.form.get('customs_shipments_id');
                const to = id;

                this.props.ui.resolve(this.props.parcel.patchInvoice(from, to), () => this.props.parcel.patchInvoice(from, to), () => {

                    saved();
                });
            }

            // END

            else {
        
                saved();
            }
        });
    }

    render(){

        const status = this.props.parcel.form.get('status');

        return (
        
            <Container>
           
                <ButtonB onClick={this.cancelPrompt}>{this.lang.cancelDraft}</ButtonB>

                {(status === undefined || status === 'DRAFT') && <ButtonB onClick={this.save}>{this.lang.saveDraft}</ButtonB>}
            
            </Container>
        );
    }
}

export default Draft;
