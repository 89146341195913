import React from 'react';
import Component from 'Component';
import Header from 'components/smart/Header';
import Title from 'components/gls-dumb/Title';
import {observer, inject} from 'mobx-react';
import {observable, computed} from 'mobx';
import PopUpList from 'components/gls-dumb/PopUpList';
import Pagebreak from 'components/gls-dumb/Pagebreak';
import Search from 'components/gls-dumb/Search';
import Columns from 'components/gls-dumb/Columns';
import Field from 'components/gls-dumb/Field';
import Checkbox from 'components/gls-dumb/Checkbox2';
import Radio from 'components/gls-dumb/Radio';
import Submit from 'components/gls-dumb/Submit';
import DateTimePicker from 'components/gls-dumb/DateTimePicker';
import moment from 'moment';
import Grid from 'components/gls-dumb/Grid';
import constants from 'constants.js';
import ImportDraft from 'components/smart/ImportDraft';
import styled from 'styled-components';
import NoticeBox from 'components/gls-dumb/NoticeBox';
import Quicklink from 'components/gls-dumb/Quicklink';
import Section from 'components/gls-dumb/Section';
import theme from 'theme';

const Black = styled.span`

    ${props => `
    
        color: ${props.theme.color[8]};
        font-weight: normal;
    `}
`;

@inject('ui')
@inject('import')
@inject('countries')
@inject('senders')
@inject('consignees')
@inject('customers')
@inject('settings')
@inject('pickuppoints')
@inject('parcel')
@inject('load')

@observer class Import extends Component {

    lang = this.props.ui.lang.import;
    store = this.props.import;

    @observable isOpen = {

        datepicker: false
    }

    @observable editable = {

        fill_measurements: true,
        parcels_cnt: true
    }

    constructor(props){

        super(props);

        // Set defaults if not repopulated.

        if(this.get('status') === undefined){

            this.setDefaultService();
            this.setDefaultReturnTo(); // From senders list.
            this.setDefaultShipmenttype();
            this.setDefaultDate();
            this.copyExportParcel(); // If set (not false) use as template.
            this.copyImportParcel(); // If set (not false) use as template.
            this.priceEstimationCopy(); // If set (not false) use as template.
        }

        this.triggerChanges();
    }

    priceEstimationCopy = () => {

        if(this.props.import.priceEstimationCopy !== false){

            let copy = [

                ['shipment_type', 'shipmenttype'],
                ['from_postalnumber', 'from_postalnumber'],
                ['from_country', 'from_countries_id'],
                ['service', 'service'],
                ['counted_amount', 'parcels_cnt'],
                ['counted_weight', 'total_weight'],
                ['measurements', 'parcels']
            ];

            if(this.props.import.priceEstimationCopy.get('service') === 'SHIP'){

                copy.push(['deliver_to_postalnumber', 'to_postalnumber']);
                copy.push(['deliver_to_city', 'to_city']);
                copy.push(['deliver_to_country', 'to_countries_id']);
            }

            else if(this.props.import.priceEstimationCopy.get('service') === 'RETURN'){

                copy.push(['deliver_to', 'return_to']);
            }

            copy.forEach(v => {

                // Simulates changes made by user from UI.

                this.change({

                    name: v[1],
                    value: this.props.import.priceEstimationCopy.get(v[0])
                });
            });

            this.props.parcel.priceEstimationCopy = false;
        }
    }

    copyExportParcel = () => {

        if(this.props.import.copyExportParcel !== false){

            const remap = {
            
                from_name1: 'consignee_name1',
                from_name2: 'consignee_name2',
                from_address1: 'consignee_address1',
                from_address2: 'consignee_address2',
                from_postalnumber: 'consignee_postalnumber',
                from_city: 'consignee_city',
                from_countries_id: 'consignee_countries_id',
                from_contact: 'consignee_contact',
                from_tel: 'consignee_tel',
                from_mobile: 'consignee_mobile',
                from_email: 'consignee_email'
            };

            for(var k in remap){

                this.store.form.set(k, this.props.import.copyExportParcel[remap[k]]);
            }

            this.props.import.copyExportParcel = false;
        }
    }

    copyImportParcel = () => {

        if(this.props.import.copyImportParcel !== false){

            const keys = [

                'from_name1',
                'from_name2',
                'from_address1',
                'from_address2',
                'from_postalnumber',
                'from_city',
                'from_countries_id',
                'from_contact',
                'from_tel',
                'from_mobile',
                'from_email',
                'to_name1',
                'to_name2',
                'to_address1',
                'to_address2',
                'to_postalnumber',
                'to_city',
                'to_countries_id',
                'to_contact',
                'to_tel',
                'to_mobile',
                'to_email',
                'shipmenttype'
            ];

            keys.forEach(v => {

                const key = this.props.import.keys[v];
            
                this.store.form.set(key, this.props.import.copyImportParcel[key]);
            });

            this.props.import.copyImportParcel = false;
        }
    }

    triggerChanges = () => {

        // Preserve some value before making changes.

        const fill_measurements = this.get('fill_measurements');

        this.makeChange('shipmenttype', this.get('shipmenttype'));

        // And set them back.

        this.set('fill_measurements', fill_measurements);

        // Set service again to reset editable

        this.makeChange('service', this.get('service'));
    }

    change = v => {

        this.updateEditable(v);
        this.uncheckRecipientOptions(v);

        this.set(v.name, v.value);
        this.updateShipmenttype();

        if(this.submitted === true){

            this.store.validate('details');
        }
    }
   
    updateShipmenttype = () => {

        // Unset shipmenttype if already selected and from or to country changes to one that hasn't selected shipmenttype available.

        if(this.get('shipmenttype') !== undefined){

            let isAvailable = false;

            this.shipmenttypes.map(v => {
            
                if(v.value.toString() === this.get('shipmenttype')){

                    isAvailable = true;
                }

                return v;
            });

            if(isAvailable === false){

                this.unset('shipmenttype');
            }
        }
    }

    uncheckRecipientOptions(v){

        if(v.name === 'from_save_recipient'){

            this.set('from_edit_recipient', false);
        }

        else if(v.name === 'from_edit_recipient'){

            this.set('from_save_recipient', false);
        }

        else if(v.name === 'to_save_recipient'){

            this.set('to_edit_recipient', false);
        }

        else if(v.name === 'to_edit_recipient'){

            this.set('to_save_recipient', false);
        }
    }

    updateEditable(v){

        if(v.name === 'shipmenttype'){

            if(v.value !== constants.typeCodes.euroBusinessParcel.toString()){

                this.set('fill_measurements', true);
                this.editable.fill_measurements = false;
            }

            else {

                this.store.form.delete('fill_measurements');
                this.editable.fill_measurements = true;
            }
        }

        if(v.name === 'service'){

            if(v.value === 'SHOP'){

                this.store.form.set('parcels_cnt', '1');
                this.editable.parcels_cnt = false;
                this.editable.fill_measurements = false;
                this.store.form.set('fill_measurements', false);
                this.store.form.set('from_preadvice', false);
                this.store.form.set('from_preadvice_email', '');
            }

            else {

                this.editable.parcels_cnt = true;
                this.store.form.delete('parcels_cnt');
                this.editable.fill_measurements = true;
            }
        }
    }

    makeChange(k, v){
   
        this.change({name: k, value: v}); // Change event triggered by code.
    }

    setDefaultDate(){

        if(this.get('date') === undefined){

            let date;

            for(let i = 1; this.isBusinessDay(date) !== true; i++){

                date = moment().add(i, 'd').format('DD.MM.YYYY');
            }

            this.makeChange('date', date);
        }
    }

    isBusinessDay = date => {

        const day = moment(date, 'DD.MM.YYYY').day();

        if(date === undefined || isNaN(day)){

            return undefined;
        }

        if(day === 6 || day === 0){

            return false;
        }

        return true;
    }

    setDefaultShipmenttype(){

        if(this.get('shipmenttype') === undefined){

            this.makeChange('shipmenttype', constants.typeCodes.euroBusinessParcel.toString());
        }
    }

    setDefaultReturnTo(){

        if(this.get('return_to') === undefined){

            if(this.requesters.length === 1){
                
                this.makeChange('return_to', this.requesters[0].key);
            }
        }
    }

    setDefaultService = () => {

        if(this.get('service') === undefined){

            this.makeChange('service', 'SHIP');
        }
    }

    get requesters(){

        const options = [];

        this.props.pickuppoints.data.forEach((v, k) => {

            const details = [];

            if(v.address1 !== ''){

                details.push(v.address1);
            }

            if(v.city !== ''){

                details.push(v.city);
            }

            if(v.postalnumber !== ''){

                details.push(v.postalnumber);
            }

            let sender = '[' + (v.nickname || v.name1) + '] ' + v.name1 + ' (' + details.join(', ') + ')';
        
            options.push({key: v.id, value: sender});
        });

        return options;
    }

    @computed get recipients(){

        const options = [];

        this.props.consignees.data.forEach((v, k) => {

            const country = this.props.countries.getName(v.countries_id);

            let name = this.lang.placeholderName;

            if(v.search !== undefined && v.search !== ''){

                name = v.search;
            }

            let keyword = '[' + name + ']';
            let comma = false;

            [v.name1, v.address1, v.city, country].forEach(v => {
            
                if(v !== false && v !== ''){

                    if(comma === true){

                        keyword += ', ';
                    }

                    else {

                        keyword += ' ';
                        comma = true;
                    }

                    keyword += v;
                }           
            });
        
            options.push({
            
                key: k,
                value: keyword
            });
        });

        return options;
    }

    autofillFromFields = v => {

        const consignee = this.props.consignees.data.get(v.value);
        
        const remap = {
        
            address1: 'from_address1',
            address2: 'from_address2',
            city: 'from_city',
            contact: 'from_contact',
            countries_id: 'from_countries_id',
            email: 'from_email',
            mobile: 'from_mobile',
            name1: 'from_name1',
            name2: 'from_name2',
            postalnumber: 'from_postalnumber',
            tel: 'from_tel'
        }

        for(let k in remap){

            this.change({name: remap[k], value: consignee[k]});
        }

        this.set('selected_from_recipient', consignee.id);
    }

    autofillToFields = v => {

        const consignee = this.props.consignees.data.get(v.value);
        
        const remap = {
        
            address1: 'to_address1',
            address2: 'to_address2',
            city: 'to_city',
            contact: 'to_contact',
            countries_id: 'to_countries_id',
            email: 'to_email',
            mobile: 'to_mobile',
            name1: 'to_name1',
            name2: 'to_name2',
            postalnumber: 'to_postalnumber',
            tel: 'to_tel'
        }

        for(let k in remap){

            this.change({name: remap[k], value: consignee[k]});
        }

        this.set('selected_to_recipient', consignee.id);
    }

    get shipmenttypes(){

        const ebp = constants.typeCodes.euroBusinessParcel;
        const ebf = constants.typeCodes.euroBusinessFreight;

        const from = this.props.countries.findCountry('id', this.get('from_countries_id'));
        const to = this.props.countries.findCountry('id', this.get('to_countries_id'));

        const available = {
        
            from: from.availableproducts,
            to: to.availableproducts
        };

        const shipmenttypes = [
       
            {value: '', option: '- ' + this.lang.select_shipmenttype},
        ];

        if(this.isProductAvailable(ebp, available)){

            shipmenttypes.push({value: ebp, option: constants.typeNames[ebp]});
        }
        
        if(this.get('service') !== 'SHOP' && this.isProductAvailable(ebf, available)){

            shipmenttypes.push({value: ebf, option: constants.typeNames[ebf]});
        }

        return shipmenttypes;
    }

    isProductAvailable(product, available){

        product = product.toString();

        let verified = 0;
        let requiredVerification = 0;

        if(available.from !== undefined){

            requiredVerification++;

            if(available.from.indexOf(product) !== -1){

                verified++;
            }
        }

        if(available.to !== undefined){

            requiredVerification++;

            if(available.to.indexOf(product) !== -1){

                verified++;
            }
        }

        if(verified < requiredVerification){

            return false;
        }

        return true;
    }

    openDatePicker = () => {

        this.isOpen.datepicker = true;
    }
    
    closeDatePicker = () => {

        this.isOpen.datepicker = false;
    }

    doneDatePicker = selected => {

        this.change({
        
            name: 'date',
            value: moment(selected).format('DD.MM.YYYY')
        });

        this.isOpen.datepicker = false;
    }

    submit = e => {

        e.preventDefault();

        this.submitted = true;

        if(this.get('total_weight') !== undefined){

            this.set('total_weight', this.get('total_weight').toString().replace(',', '.'));
        }

        if(this.props.import.validate('details') === false){

            this.props.ui.messages = this.props.import.messages;
        }

        else {


            if(this.nextPageRequired === true){

                if(this.props.match.params.id === undefined){

                    this.props.history.push('/import/measurements.html');
                }

                else {
        
                    this.props.history.push('/import/' + this.props.match.params.id + '/measurements.html');
                }
            }


            else {

                // Resolver is at store!

                this.props.import.save('READY', 'details', this.props.match.params.id, (consignees, response) => {

                    if(consignees === false){

                        this.props.ui.afterClearMessages = this.goToImports;
                        this.props.ui.messages = [this.lang.save_consignees_failed];
                    }

                    else {

                        if(response.data.service === 'SHOP'){

                            const params = {

                                id: response.data.id
                            }

                            this.props.load.route('/import/:id/preview.html', params, url => {

                                this.props.history.push(url);
                            });
                        }

                        else {

                            this.goToImports();
                        }
                    }

                });
            }
        }
    }

    goToImports = () => {

        const promises = [
        
            this.props.senders.fetchAll(),
            this.props.import.fetchAll()
        ];

        this.props.ui.resolve(promises, this.goToImports, () => {
       
            this.props.history.push('/imports/');
        });
    }

    get nextPageRequired(){

        if(
        
            this.get('fill_measurements') !== true &&
            this.props.import.requiresTariff !== true &&
            this.get('shipmenttype') !== constants.typeCodes.euroBusinessFreight.toString()
        ){

            return false;
        }

        return true;
    }

    get submitText(){

        if(this.nextPageRequired === false){
        
            return this.lang.submit;
        }

        return this.lang.next;
    }

    preselectRecipient = id => {

        if(id === undefined){

            return null;
        }

        let recipient = null;

        this.recipients.forEach(v => {
        
            if(v.key === id){

                recipient = v.value; 
            }
        });

        return recipient;
    }

    get requester(){

        const customers = this.props.customers.data.get(this.props.ui.customers.id);

        return {
            
            name: customers.name1,
            email: this.props.settings.all.email
        };
    }

    openParcelSend = () => {

        this.props.ui.backFrom.parcel = '/home/';

        const promises = [
        
            this.props.senders.fetchAll(),
            this.props.consignees.fetchAll(),
            this.props.settings.load(),
            this.props.pickuppoints.load()
        ];

        this.props.ui.resolve(promises, this.openParcelSend, () => {

            this.props.parcel.form.clear();
            this.props.parcel.parcelNumbers = [];
            this.props.history.push('/parcel/send.html');
        });
    }

    render(){

        return(

            <React.Fragment>

                <DateTimePicker isOpen={this.isOpen.datepicker} onCancel={this.closeDatePicker} onDone={this.doneDatePicker} cancel={this.lang.cancel} />
                    
                <Header />

                <Section color="sub">

                    <Title>                    

                        <Quicklink title={this.lang.title} value="import" options={[
                       
                            {value: 'export', option: this.lang.quick_export, callback: this.openParcelSend},
                            {value: 'import', option: this.lang.quick_import}
                        ]} />

                        <ImportDraft />
                        
                    </Title>

                </Section>

                <div className="main">

                    <form onSubmit={this.submit}>                    

                        <NoticeBox>{this.lang.requester}: <Black>{this.requester.name} ({this.requester.email})</Black></NoticeBox>

                        <Pagebreak>{this.get('service') !== 'SHOP' ? this.lang.pickUpFrom : this.lang.sender}</Pagebreak>

                        <Columns>

                            <Columns.left>

                                <Search
                                
                                    name="from_recipient"
                                    onChange={this.autofillFromFields}
                                    label={this.lang.recipient}
                                    from={this.recipients}
                                    message={this.lang.noRecipients}
                                    value={this.preselectRecipient(this.get('selected_from_recipient'))}
                                    info={this.lang.from_recipient_info}
                                />
                                
                                <Field {...this.fieldProps('from_name1')} label={this.lang.from_name1} maxLength="40"/>
                                <Field {...this.fieldProps('from_name2')} label={this.lang.from_name2} maxLength="40" />
                                <Field {...this.fieldProps('from_address1')} label={this.lang.from_address1} maxLength="40" />
                                <Field {...this.fieldProps('from_address2')} label={this.lang.from_address2} maxLength="40" />

                                <Columns gap="small">

                                    <Columns.left width="25%">

                                        <Field {...this.fieldProps('from_postalnumber')} label={this.lang.from_postalnumber} />
                                    
                                    </Columns.left>

                                    <Columns.right width="75%">
                                    
                                        <Field {...this.fieldProps('from_city')} label={this.lang.from_city} />
                                    
                                    </Columns.right>

                                </Columns>

                                <Field

                                    {...this.fieldProps('from_countries_id')}
                                    type="select"
                                    label={this.lang.from_country}
                                    options={this.props.countries.optionsWithCode(false, ['10000', '10013'], this.get('service') === 'SHOP' ? true : false)}
                                />

                            </Columns.left>

                            <Columns.right>

                                <Field {...this.fieldProps('from_contact')} label={this.lang.from_contact} info={this.lang.from_contact_info} />
                                <Field {...this.fieldProps('from_tel')} label={this.lang.from_tel} />
                                <Field {...this.fieldProps('from_mobile')} label={this.lang.from_mobile} />
                                <Field {...this.fieldProps('from_email')} label={this.lang.from_email} />
                                <Checkbox
                                
                                    {...this.checkboxProps('from_save_recipient')}
                                    label={this.lang.from_saveRecipient}
                                    value={true}
                                    info={this.lang.from_save_recipient_info}
                                />

                                {this.get('from_save_recipient') === true &&
                                
                                    <Field type="text" {...this.fieldProps('from_search')} label={this.lang.from_search} />
                                }

                                {this.get('selected_from_recipient') !== undefined &&
                                
                                    <Checkbox {...this.checkboxProps('from_edit_recipient')} value={true} label={this.lang.from_edit_recipient} />
                                }

                                {this.get('service') !== 'SHOP' && <Checkbox {...this.checkboxProps('from_preadvice')} label={this.lang.from_preadvice} value={true} info={this.lang.from_preadvice_info} />}

                                {this.get('from_preadvice') === true &&
                                
                                    <Field {...this.fieldProps('from_preadvice_email')} label={this.lang.from_preadvice_email} />
                                }

                                <Radio {...this.fieldProps('from_recipienttype')}>

                                    <Radio.item value="2C" label={this.lang.recipienttype_private_address} info={this.lang.recipienttype_private_address_info} width="50%" />
                                    <Radio.item value="2B" label={this.lang.recipienttype_business_address} info={this.lang.recipienttype_business_address_info} width="50%" />

                                </Radio>

                            </Columns.right>

                        </Columns>

                        <Pagebreak>{this.lang.serviceType}</Pagebreak>

                        <Radio {...this.fieldProps('service')}>

                            <Radio.item value="RETURN" label={<span><span style={{color: theme.color[1], fontSize: '28px', fontStyle: 'italic'}}>Pick&amp;Return</span><span style={{color: theme.color[1], fontSize: '28px', fontStyle: 'italic', fontWeight: 'bold'}}>Service</span></span>} info={this.lang.return_service_info} />
                            <Radio.item value="SHIP" label={<span><span style={{color: theme.color[1], fontSize: '28px', fontStyle: 'italic'}}>Pick&amp;Ship</span><span style={{color: theme.color[1], fontSize: '28px', fontStyle: 'italic', fontWeight: 'bold'}}>Service</span></span>} info={this.lang.ship_service_info} />
                            <Radio.item value="SHOP" label={<span><span style={{color: theme.color[1], fontSize: '28px', fontStyle: 'italic'}}>ShopReturn</span><span style={{color: theme.color[1], fontSize: '28px', fontStyle: 'italic', fontWeight: 'bold'}}>Service</span></span>} info={this.lang.shop_service_info} />

                        </Radio>

                        <Pagebreak>{this.lang.deliverTo}</Pagebreak>

                        {this.get('service') === 'SHIP' ?

                            <Columns>

                                <Columns.left>

                                    <Search
                                    
                                        name="to_recipient"
                                        onChange={this.autofillToFields}
                                        label={this.lang.recipient}
                                        from={this.recipients}
                                        message={this.lang.noRecipients}
                                        value={this.preselectRecipient(this.get('selected_to_recipient'))}
                                        info={this.lang.to_recipient_info}
                                    />
                                    
                                    <Field {...this.fieldProps('to_name1')} label={this.lang.to_name1} maxLength="40" />
                                    <Field {...this.fieldProps('to_name2')} label={this.lang.to_name2} maxLength="40" />
                                    <Field {...this.fieldProps('to_address1')} label={this.lang.to_address1} maxLength="40" />
                                    <Field {...this.fieldProps('to_address2')} label={this.lang.to_address2} maxLength="40" />

                                    <Columns gap="small">

                                        <Columns.left width="25%">

                                            <Field {...this.fieldProps('to_postalnumber')} label={this.lang.to_postalnumber} />
                                        
                                        </Columns.left>

                                        <Columns.right width="75%">
                                        
                                            <Field {...this.fieldProps('to_city')} label={this.lang.to_city} />
                                        
                                        </Columns.right>

                                    </Columns>

                                    <Field

                                        {...this.fieldProps('to_countries_id')}
                                        type="select"
                                        label={this.lang.to_country}
                                        options={this.props.countries.optionsWithCode(false, ['10000', '10013'])}
                                    />

                                </Columns.left>

                                <Columns.right>

                                    <Field {...this.fieldProps('to_contact')} label={this.lang.to_contact} info={this.lang.to_contact_info} maxLength="40" />
                                    <Field {...this.fieldProps('to_tel')} label={this.lang.to_tel}  maxLength="40" />
                                    <Field {...this.fieldProps('to_mobile')} label={this.lang.to_mobile}  maxLength="40" />
                                    <Field {...this.fieldProps('to_email')} label={this.lang.to_email}  maxLength="100" />

                                    <Checkbox
                                    
                                        {...this.checkboxProps('to_save_recipient')}
                                        label={this.lang.to_saveRecipient}
                                        value={true}
                                        info={this.lang.to_save_recipient_info}
                                    />

                                    {this.get('to_save_recipient') === true &&
                                    
                                        <Field type="text" {...this.fieldProps('to_search')} label={this.lang.to_search}  maxLength="40"/>
                                    }

                                    {this.get('selected_to_recipient') !== undefined &&
                                    
                                        <Checkbox {...this.checkboxProps('to_edit_recipient')} value={true} label={this.lang.to_edit_recipient} />
                                    }

                                    <Radio {...this.fieldProps('to_recipienttype')}>

                                        <Radio.item value="2C" label={this.lang.recipienttype_private_address} info={this.lang.recipienttype_private_address_info} width="50%" />
                                        <Radio.item value="2B" label={this.lang.recipienttype_business_address} info={this.lang.recipienttype_business_address_info} width="50%" />

                                    </Radio>

                                </Columns.right>

                            </Columns>

                        :

                            <PopUpList {...this.fieldProps('return_to')} list={this.requesters} label={this.lang.deliver_to} />
                        }

                        <Pagebreak>{this.lang.shipment}</Pagebreak>

                        <Columns>

                            <Columns.left>

                                <Field
                                
                                    type="select"
                                    {...this.fieldProps('shipmenttype')}
                                    label={this.lang.shipmenttype}
                                    options={this.shipmenttypes}
                                    info={this.lang.shipmenttype_info}
                                />

                                <Field {...this.fieldProps('reference')} label={this.lang.reference} info={this.lang.reference_info}  maxLength="40"/>
                                <Field {...this.fieldProps('info')} label={this.lang.info} info={this.lang.additional_info}  maxLength="40"/>

                            </Columns.left>

                            <Columns.right>

                                <Field {...this.fieldProps('contents')} label={this.lang.contents} maxLength="40"/>

                                    {this.get('service') !== 'SHOP' && <Field type="calendar" {...this.fieldProps('date')} label={this.lang.date} datePicker={this.openDatePicker} info={this.lang.date_info} />}

                                <Columns gap="small">

                                    <Columns.left>

                                        <Field {...this.fieldProps('parcels_cnt')} label={this.lang.pieces} disabled={this.editable.parcels_cnt === true ? false : true} />
                                    
                                    </Columns.left>

                                    <Columns.right>

                                        {this.get('fill_measurements') !== true &&

                                            <Field {...this.fieldProps('total_weight')} label={this.lang.total_weight} />
                                        }
                                    
                                    </Columns.right>

                                </Columns>

                            </Columns.right>

                        </Columns>

                        <Grid width="50%">

                            <Grid.item>
                            
                                <Checkbox {...this.checkboxProps('addoninsurance')} label={<span><span style={{color: theme.color[1], fontSize: '28px', fontStyle: 'italic'}}>AddOnLiability</span><span style={{color: theme.color[1], fontSize: '28px', fontStyle: 'italic', fontWeight: 'bold'}}>Service</span></span>} value={true} info={this.lang.addoninsurance_info} />
                                
                            </Grid.item> 

                            <Grid.item>
                            
                                <Checkbox

                                    {...this.checkboxProps('fill_measurements')}
                                    label={this.lang.fill_measurements}
                                    value={true}
                                    editable={this.editable.fill_measurements}
                                    info={this.lang.fill_measurements_info}
                                />

                            </Grid.item>

                        </Grid>

                        <Submit>{this.submitText}</Submit>

                    </form>

                </div>

            </React.Fragment>
        );
    }
}

export default Import;
