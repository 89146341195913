import React from 'react';
import styled from 'styled-components';
import Button from 'components/gls-dumb/Button';
import {observer} from 'mobx-react';
import {observable, action} from 'mobx';
import theme from 'theme';
import Input from 'components/gls-dumb/Input';
import Icon from 'components/gls-dumb/Icon';
import Note from 'components/gls-dumb/Note';

const Title = styled.div`

    ${props => `
    
        display: flex;
        align-items: center;
        margin-bottom: ${props.theme.size[2]};

        & :first-child {

            padding-right: ${props.theme.size[2]};
        }
    `}
`;

const Container = styled.div`

    ${props => `

        display: flex;
        align-items: flex-end;

        & > :first-child {

            flex-grow: 1;
            margin-right: ${props.theme.size[1]};
        }

        margin-bottom: ${props.theme.size[1]};
    `}
`;

const Wrapper = styled.div`

    position: relative;
    z-index: 3;
`;

const Suggestions = styled.div`

    position: absolute;
    width: 100%;
    background: ${props => props.theme.color[7]};
    margin-top: -3px;
    border: 1px solid ${props => props.theme.color[4]};
    border-radius: 0 0 ${props => props.theme.size[2]} ${props => props.theme.size[2]};

    & :last-child {
    
        border-radius: 0 0 0.4em 0.4em;
    }

    @media (min-width: ${props => props.theme.breakpoint}){

        margin-top: -4px;
        border: 2px solid ${props => props.theme.color[4]};
    }
`;

const Result = styled.div`

    padding: ${props => props.theme.size[1]};
    cursor: pointer;

    &:hover {
   
        color: ${props => props.theme.color[7]};
        background: ${props => props.theme.color[3]};
    }
`;

const Outside = styled.div`

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

const Message = styled.div`

    padding: ${props => props.theme.size[1]};
`;

@observer class Search extends React.Component {

    @observable value = '';
    @observable isOpen = false;
    @observable results = [];
    @observable showInfo = false;

    constructor(props){

        super(props);

        if(this.props.value !== undefined){

            this.value = this.props.value;
        }

        this.selected = false;
    }

    static suggestions = (props) => {

        if(props.isOpen === false){

            return null;
        }

        return (
        
            <Suggestions>{props.children}</Suggestions>
        );
    }

    static outside = (props) => {

        if(props.isOpen === false){

            return null;
        }

        return (
        
            <Outside onClick={props.onClick} />
        );
    }



    search = (v) => {

        this.value = v.value;

        const filtered = this.props.from.filter(v => { 
        
            const regexp = new RegExp(this.value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'gi');
        
            if(v.value.match(regexp) === null || this.value === ''){

                return false;
            }

            return true;
        });

        this.results = filtered;

        // Do not open suggestions after selection

        if(this.selected === false){

            this.open();
        }

        // Selection is true (white background on input) as long as user does not change value with keyboard.

        if(v.code !== undefined){
        
            this.selected = false;

            // If only one result and it matches exactly with user input select item automaticly.

            if(this.results.length === 1){

                if(this.value === this.results[0].value){

                    this.select({
                    
                        key: this.results[0].key,
                        value: this.results[0].value
                    });
                }
            }
        }
    }

    open = () => {
   
        this.isOpen = true;
    }

    close = () => {

        this.isOpen = false;
    }

    select = (v) => {

        this.value = v.value;
   
        this.props.onChange({
        
            name: this.props.name,
            value: v.key
        });

        this.close();
        this.selected = true;
    }

    @action toggleInfo = () => {

        if(this.showInfo === false){

            this.showInfo = true;
        }

        else {

            this.showInfo = false;
        }
    }

    render(){

        return (

            <React.Fragment>

                <Title>

                    {this.props.info !== undefined &&

                        <React.Fragment>

                            <Icon type="info" onClick={this.toggleInfo} cursor="pointer" color={theme.color[1]} hover={theme.color[3]} size="1.5em"></Icon>
                            <Note show={this.showInfo} close={this.toggleInfo}>{this.props.info}</Note>

                        </React.Fragment>
                    }

                    {this.props.label}:

                </Title>

                <Container>
                
                    <Wrapper>

                        {/*<Field
                        
                            name={this.props.name}
                            value={this.value}
                            label={this.props.label}
                            info={this.props.info}
                            marginBottom="0"
                            onChange={this.search}
                            backgroundColor={this.selected === true ? theme.color[7] : undefined}
                        />*/}

                        <Input
                        
                            name={this.props.name}
                            value={(this.value !== undefined && this.value !== null) ? this.value : ''}
                            onChange={this.search}
                            backgroundColor={this.selected === true ? theme.color[7] : undefined}
                        />

                        <Search.outside isOpen={this.isOpen} onClick={this.close} />

                        <Search.suggestions isOpen={this.isOpen}> 

                            {this.results.length !== 0 ? this.results.map(v => 
                            
                                <Result key={v.key} onClick={() => {this.select(v)}}>{v.value}</Result>

                            ) : <Message>{this.props.message}</Message>}
                        
                        </Search.suggestions>

                    </Wrapper>

                    <Button type="search" onClick={this.open} />

                </Container>
            </React.Fragment>
        );
    }
}

export default Search;
