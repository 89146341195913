import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Button from 'components/gls-dumb/Button';

const Container = styled.div`

    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
`;

const Item = styled.div`

    ${props => `

        padding: ${props.theme.size[1]};
        background: #fff;
        border-radius: 0.5em;
    `}
`;

const Message = styled.div`

    ${props => `

        padding: ${props.theme.size[1]};
    `}
`;

class ModalAlert extends React.Component {

    ok = (e) => {

        if(e.target === e.currentTarget){

            this.props.ok();
        }
    }

    render(){

        if(this.props.isOpen !== true){

            return null;
        }

        else {

            return (

                <Container onClick={this.ok}>
                
                    <Item>
                    
                        <Message>{this.props.children}</Message>
                        <Button onClick={this.props.ok}>OK</Button>
                        
                    </Item>
                    
                </Container>
            );
        }
    }
}

ModalAlert.propTypes = {

    /** Toggle status. */
    isOpen: PropTypes.bool.isRequired,

    /** Ok event. */
    ok: PropTypes.func.isRequired,

    /** Button text. */
    text: PropTypes.shape({
    
        ok: PropTypes.string

    }).isRequired
}

ModalAlert.defaultProps = {

    text: {

       ok: 'OK'
    }
}

export default ModalAlert;
