import ApiStore from 'stores/ApiStore';
import api from 'api.json';
import {computed} from 'mobx';

class PickupStore extends ApiStore {

    constructor(stores){

        super(stores);
        this.stores = stores;
    }

    load = async () => {

        return await this.fetchAll();
    }

    fetchAll = async () => {

        return await this.get({}, {
        
            url: api.endpoints.user.pickuppoints
        });
    }

    @computed get options(){

        const options = [{value: '', option: this.stores.ui.lang.OrderFetch.selectPickuppoint}];

        this.data.forEach((v, k) => {
        
            options.push({
 
                value: v.id,
                option: v.name1
            });
        });

        return options;
    }

    exists = pickuppoint => {

        if(this.data.get(pickuppoint) === undefined){

            return false;
        }

        return true;
    }
}

export default PickupStore;
