import ApiStore from 'stores/ApiStore';
import api from 'api.json';

class CountriesStore extends ApiStore {

    load = async () => {

        return await this.fetchAll();
    }

    fetchAll = async () => {

        return await this.get({}, {url: api.endpoints.common.countries});
    }

    /*

    Filters:

        all = true: Show all
        all = false: Do not show countries where notinuse is true

        type = '10000': Show countries where availableproducts has 10000
    */

    optionsWithCode(all, types, psr){

        if(all === undefined){

            all = true;
        }

        if(psr === undefined){

            psr = false;
        }

        const countries = [{
        
            value: '',
            option: '- ' + this.stores.ui.lang.countries.selectCountry
        }];

        this.sort = 'name';

        this.list.forEach(v => {

            if(v.id !== null && v.name !== null){
 
                if(all === true || (all === false && v.notinuse === false)){

                    // Check types

                    if(types === undefined || (types !== undefined && v.availableproducts !== undefined && this.checkAvailable(v.availableproducts, types) === true)){

                        // Check PSR

                        if(psr === false || (psr === true && v.psr === 1)){

                            countries.push({
                            
                                value: v.id,
                                option: v.name.trim()
                            });
                        }
                    }
                }
            }

            else {

                console.log('Invalid country: ', v);
            }
        });

        return countries;
    }

    checkAvailable = (products, types) => {

        let available = false;

        types.forEach(type => {
        
            if(products.indexOf(type) !== -1){

                available = true;
            }
        });

        return available;
    }

    // Find country by property.

    findCountry(property, value){

        let country = false;

        if(property !== undefined && property !== null && value !== undefined && value !== null){

            this.data.forEach((v) => {

                if(v[property] !== null && value !== null){

                    if(v[property].toString() === value.toString()){

                        country = v;
                    }
                }
            });
        }

        return country;
    }

    nameByCode(code){

        const country = this.data.get(code);

        if(country === undefined){

            return false;
        }

        return country.name.trim();
    }

    // Alias for nameByCode (originally name was retrived with code)

    getName(id){

        return this.nameByCode(id);
    }

    codeById(id){

        const country = this.findCountry('id', id);

        if(country === false){

            return false;
        }

        return country.code.trim();
    }

    //  country is return data from findCountry method

    getZiperrortext = (country, fallbacktext) => {

        if(fallbacktext === undefined){

            fallbacktext = '-';
        }

        let messages = JSON.parse(country.ziperrortext);
        let error;

        for(let lang in messages){

            let regexp = new RegExp(this.stores.ui.selectedLang, 'gi');

            if(lang.match(regexp) !== null){

                error = messages[lang];
            }
        }

        if(error === undefined){

            error = fallbacktext;
        }

        function replace(text){

            return text.replace(/#/g, '<span class="font-fix">#</span>');
        }

        return replace(error);
    }

    isEU(country, postalnumber){

        if(postalnumber === undefined){

            return country.iseu;
        }

        const isEuNegative = (country.iseunegate === '' || postalnumber.match(new RegExp(country.iseunegate, 'i')) === null) ? false : true;

        let result;

        if(isEuNegative === true){

            result = country.iseu === true ? false : true;
        }

        else {

            result = country.iseu;
        }

        return result;
    }
}

export default CountriesStore;
