import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {observer} from 'mobx-react';
import {observable, action} from 'mobx';
import theme from 'theme';

const InputClass = styled.input`

    height: ${props => props.theme.inputHeight};
    width: ${props => props.width};    
    padding: 0 ${props => props.theme.size[2]} 0 ${props => props.theme.size[2]};

    ${props => props.borderColor !== undefined && `
    
        border-color: ${props.theme.color[9]} !important;

        &:focus {
            
            box-shadow: 0 0 0.3em ${props.theme.color[9]} !important;
        }
    `}

    ${props => props.backgroundColor !== undefined && `background-color: ${props.backgroundColor} !important;`}
    ${props => props.disabled === true && `background-color: ${props.theme.color[5]} !important;`}
`;

@observer class Input extends React.Component {

    @observable value;

    constructor(props){

        super(props);

        this.value = this.props.value;
        this.originalProps = this.value;
    }

    componentDidUpdate(){

        // If value from props will change after mounting use new prop value.

        if(this.originalProps !== this.props.value){

            this.value = this.props.value;
            this.originalProps = this.value;

            this.props.onChange({
            
                name: this.props.name,
                value: this.value
            });
        }
    }

    @action change = e => {

        this.value = e.target.value;

        if(this.props.onChange !== undefined){

            this.props.onChange({
           
                name: this.props.name,
                value: this.value,
                code: e.keyCode
            });
        }
    }

    render(){

        let autoComplete = this.props.autoComplete;

        if(autoComplete === undefined){

            autoComplete = 'new-password';
        }

        return (
        
            <InputClass {...this.props} value={this.value} onChange={this.change} onKeyUp={this.change} autoComplete={autoComplete} onKeyDown={this.props.onKeyDown} disabled={this.props.disabled} />
        );
    }
}

Input.propTypes = {

    /** Name attribute of input tag. */
    name: PropTypes.string.isRequired,

    /** Default value of input */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    /** Width (CSS) */
    width: PropTypes.string,

    /** Type */
    type: PropTypes.oneOf(['text', 'password']),

    /** Event handler */
    onChange: PropTypes.func
}

Input.defaultProps = {

    type: 'text',
    width: '100%',
    value: '',
    backgroundColor: theme.color[7],
    onKeyDown: () => {}
}

export default Input;
