import React from 'react';
import Header from 'components/smart/Header';
import Content from 'components/gls-dumb/Content';
import Tiles from 'components/gls-dumb/Tiles';
import {inject} from 'mobx-react';
import News from 'components/gls-dumb/News';
import newShipmentSVG from 'images/new-shipment.svg';
import parcelsSVG from 'images/parcels.svg';
import addressBookSVG from 'images/address-book.svg';
import settingsSVG from 'images/settings.svg';
import raportsSVG from 'images/raports.svg';
import pricingSVG from 'images/pricing.svg';
import sort from 'fast-sort';
import moment from 'moment';

@inject('consignees')
@inject('senders')
@inject('parcel')
@inject('announcements')
@inject('settings')
@inject('ui')
@inject('privileges')
@inject('pickuppoints')
@inject('adminPrivileges')
@inject('import')
@inject('countries')

class Home extends React.Component {

    lang = this.props.ui.lang.home;

    openConsignees = () => {

        this.props.ui.route('/consignees/list.html', url => {
        
            this.props.history.push(url);
        });
    }

    openUserSettings = () => {

        this.props.ui.route('/user/settings.html', url => {
        
            this.props.history.push(url);
        });
    }

    openParcelSend = () => {

        this.props.ui.backFrom.parcel = {
        
            route: '/home/',
            params: {}
        };

        this.props.ui.route('/parcel/send.html', url => {

            this.props.parcel.form.clear();
            this.props.parcel.parcelNumbers = [];
            this.props.history.push(url);
        });
    }

    openParcels = () => {

        this.props.ui.route('/parcels/', url => {
        
            this.props.ui.selectedParcels.clear();
            this.props.history.push(url);
        });
    }

    openRaports = () => {

        this.props.ui.route('/raports/', url => {
        
            this.props.history.push(url);
        });
    }

    openPriceTool = () => {

        this.props.ui.route('/price/', url => {

            this.props.history.push(url);
        });
    }

    openCustomsDeclaration = () => {

        this.props.ui.route('/customs/declaration.html', url => {

            this.props.history.push(url);
        });
    }

    filterAnnouncementsByLang(announcements){

        const currentTime = parseInt(moment().endOf('day').format('X'), 10);

        announcements = announcements.filter(v => {

            if(v.is_active === false){

                return false;
            }

            if(v.visible_from_at !== 0 && v.visible_from_at > currentTime){

                return false;
            }

            if(v.visible_until_at !== 0 && v.visible_until_at < currentTime){

                return false;
            }

            return true;
        });

        return sort(announcements).desc(v => v.created_at);
    }

    render(){

        const announcements = this.filterAnnouncementsByLang(this.props.announcements.dataArray);

        return (

            <React.Fragment>
                
                <Header />

                <div className="main">

                    <Content>
                    
                        <Tiles>
                        
                            <Tiles.item onClick={this.openParcelSend} icon={newShipmentSVG}>{this.props.ui.lang.home.send}</Tiles.item>
                            <Tiles.item onClick={this.openParcels} icon={parcelsSVG}>{this.props.ui.lang.home.shipments}</Tiles.item>
                            <Tiles.item onClick={this.openPriceTool} icon={pricingSVG}>{this.props.ui.lang.home.price_tool}</Tiles.item>
                            <Tiles.item onClick={this.openConsignees} icon={addressBookSVG}>{this.props.ui.lang.home.consignees}</Tiles.item>
                            {this.props.privileges.isAdmin && <Tiles.item onClick={this.openUserSettings} icon={settingsSVG}>{this.props.ui.lang.home.settings}</Tiles.item>}
                            <Tiles.item onClick={this.openRaports} icon={raportsSVG}>{this.lang.raports}</Tiles.item>
                            {/*<Tiles.item onClick={this.openCustomsDeclaration}>{this.lang.customs_declaration}</Tiles.item>*/}
                        
                        </Tiles>
     
                    </Content>

                    {announcements.length !== 0 && 

                        <Content>
                        
                            <News items={announcements} lang={this.props.ui.selectedLang} />
                        
                        </Content>
                    }

                </div>

            </React.Fragment>
        );
    }
}

export default Home;
