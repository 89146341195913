import React from 'react';
import styled from 'styled-components';
import Icon from 'components/gls-dumb/Icon';
import theme from 'theme';

const Container = styled.div`

    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${props => props.reload === false ? `rgba(0, 0, 0, 0.5)` : props.theme.color[1]};
    z-index: 1000;
`;

class Loading extends React.Component {

    render(){

        if(this.props.isOpen === false){

            return null;
        }

        else {
    
            return (
            
                <Container reload={this.props.reload}>
                
                    <Icon type="cog" color={theme.color[7]} size="3em" />
                
                </Container>
            );
        }
    }
}

export default Loading;
