import ApiStore from 'stores/ApiStore';
import api from 'api.json';

class AdminPrivilegesStore extends ApiStore {

    load = async () => {

        return await this.fetchAll();
    }

    fetchAll = async () => {

        return await this.get({}, {url: api.endpoints.admin.privileges}); 
    }
}

export default AdminPrivilegesStore;
