import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {Buttons} from 'components/gls-dumb/Buttons';
import Button from 'components/gls-dumb/Button';
import FileUpload from 'components/gls-dumb/FileUpload';

const Container = styled.div`

    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
`;

const Item = styled.div`

    ${props => `

        padding: ${props.theme.size[1]};
        background: #fff;
        border-radius: 0.5em;
    `}
`;

const Message = styled.div`

    text-align: center;

    ${props => `
    
        padding-bottom: ${props.theme.size[1]};
    `}
`;

class ModalConfirm extends React.Component {

    cancel = (e) => {

        if(e.target === e.currentTarget){

            this.props.cancel();
        }
    }

    ok = (v) => {

        this.props.ok(v);
    }

    render(){

        if(this.props.isOpen !== true){

            return null;
        }

        else {
            
            let button = <Button onClick={this.ok}>{this.props.text.ok}</Button>;

            if(this.props.file === true){

                button = <FileUpload onChange={this.ok}>{button}</FileUpload>;
            }

            return (

                <Container onClick={this.close}>
                
                    <Item>
                    
                        <Message>{this.props.children}</Message>

                        <Buttons marginBottom="0">

                            <Button onClick={this.cancel}>{this.props.text.cancel}</Button>
                            {button}

                        </Buttons>
                        
                    </Item>
                    
                </Container>
            );
        }
    }
}

ModalConfirm.propTypes = {

    /** Toggle status.  */
    isOpen: PropTypes.bool.isRequired,

    /** Cancel event. */
    cancel: PropTypes.func.isRequired,

    /** Ok event. */
    ok: PropTypes.func.isRequired,

    /** Button texts. */
    text: PropTypes.shape({
    
        cancel: PropTypes.string,
        ok: PropTypes.string

    }).isRequired
}

ModalConfirm.defaultProps = {

    text: {

        cancel: 'Peruuta',
        ok: 'OK'
    }
}

export default ModalConfirm;
