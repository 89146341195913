import {observable, computed} from 'mobx';
import fi from 'lang/fi';
import en from 'lang/en';

class UIStore {

    selectedParcels = new Map();

    isCancel = false;

    @observable loading = 0;
    @observable customers = {}; // Customerdata (logged in customer id)
    @observable reload = false;
    @observable login = false;
    @observable selectedLang = localStorage.getItem('lang');
    @observable messages = [];
    @observable reloadErrors = [];

    memory = {
        
        parcels: null
    };

    @observable actions = {
    
        useDefaultFilter: false
    };

    @observable api = {
        
        error: null,
        retry: undefined,
        callback: undefined
    }

    @observable customConfirm = {
  
        isOpen: false,
        message: undefined,
        cancelHandler: undefined,
        okHandler: undefined,
        cancelText: undefined,
        okText: undefined
    }

    backgroundLoading = 0;

    backFrom = {
    
        parcel: false,
        overview: false
    }

    constructor(stores){

        this.stores = stores;
    }

    @computed get lang(){

        const languages = {
        
            fi: fi,
            en: en
        };

        if(this.selectedLang === null){

            this.selectedLang = 'en';
        }

        return languages[this.selectedLang];
    }

    setLoading = (v) => {

        this.loading += v;
    }

    @observable hideLoading = false;

    @computed get isLoading(){

        if(this.hideLoading === true){

            return false;
        }

        return this.loading === 0 ? false : true;
    }

    clearCustomConfirm = () => {

        this.customConfirm = {
  
            isOpen: false,
            message: undefined,
            cancelHandler: undefined,
            okHandler: undefined,
            cancelText: undefined,
            okText: undefined
        }
    }

    afterClearMessages = false; // False or function.

    clearMessages = () => {

        this.messages = [];

        if(this.afterClearMessages !== false){

            this.afterClearMessages();
            this.afterClearMessages = false;
        }
    }

    resolve = async (promises, retry, callback, callback2) => {

        return await this.stores.load.api(promises, retry, callback, callback2);
    }

    route = (route, params, callback) => {

        this.stores.load.route(route, params, callback);
    }

    // Load all on app refresh and on login.

    loadApp = dependencies => {

        const promises = [
        
            this.stores.load.request(this.stores.customers.load()), // Customers has to be first promise in promises array!
            this.stores.load.request(this.stores.privileges.load()),
            this.stores.load.request(this.stores.announcements.load()),
            this.stores.load.request(this.stores.countries.load())
        ];

        dependencies.forEach(dependency => {

            promises.push(this.stores.load.request(dependency));
        });

        return Promise.all(promises).then(
        
            resolved => {

                // Set current customers id for later usage.

                this.stores.ui.customers.id = resolved[0].data.id; 
                localStorage.setItem('customers_id', this.stores.ui.customers.id);

                return Promise.resolve(resolved);
            },
        
            rejected => {

                this.stores.ui.reloadErrors = ['Reload error: check log'];

                console.log('Reload error: ', rejected);

                return Promise.reject(rejected);
            }
        );
    }
}

export default UIStore;
