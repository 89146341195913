export default { 

    size: {

        1: '0.6em',
        2: '0.3em'
    },

    breakpoint: '600px', // Default brakepoint from mobile to desktop
    white: '#fff', // Old color for white
    black: '#000', // Old color for black

    color: {

        1: '#061ab1', // Blue (NEW)
        2: '#f3c8d4', // Pink (NEW)
        3: '#34903e', // Green
        4: '#c8e7f2', // Light blue (NEW)
        5: '#e7e8ee', // Light grey
        6: '#ffd100', // Yellow (NEW)
        7: '#fff', // White
        8: '#000', // black
        9: '#ff1d38', // Red
        10: '#f1b62a', // Dark yellow
        11: '#d1d2dd' // Gray
    },

    padding: {

        2: '1.5em',
        4: '2em', // Content padding
        7: '5.6em', // Tiles inner padding
    },

    radius: {

        2: '1.375em', // Outer circle (radio button)
        3: '0.6875em' // Inner circle (radio button)
    },

    fontSize: {

        0: '1.5em' // Font size of tiles
    },

    inputHeight: '2em',
    inputSize: '2em'
}
