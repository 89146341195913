class Load {

    // Expections are rejected without modal to user.

    exceptions = [
    
        '^.*_TOKEN_VERIFICATION_FAILED$'
    ];

    constructor(stores, lang, translations){

        this.stores = stores;
        this.lang = lang;
        this.translations = translations;

        if(translations === undefined){

            this.translations = {};
        }

        if(this.lang === undefined){

            this.lang = lang;
        }
    }

    // Handle response from API (resolve and reject)

    request = async (promise) => {

        return await promise.then(
        
            resolved => {

                if(resolved.code !== 200){

                    let messages = []; // Messages are showed to user without retry modal (e-mail is in use etc).
                    let errors = []; // Errors are showed to user with retry modal (network errors etc).

                    if(resolved.errors !== undefined && resolved.errors.length !== 0){

                        resolved.errors.forEach((v, k) => {

                            if(this.isTranslation(v.code)){

                                messages.push(this.translations[v.code]);
                            }

                            else if( ! this.isException(v.code)){

                                errors.push(v.code);
                            }
                        });
                    }

                    else {

                        errors.push(this.lang.networkError);
                    }

                    return Promise.reject({
                    
                        messages: messages,
                        errors: errors,
                        data: resolved.data
                    });
                }

                return Promise.resolve(resolved);
            },
            
            rejected => {
            
                console.log('ApiStore itself should not ever reject: ', rejected);

                return rejected;
            }
        );
    }

    isTranslation = code => {

        let result = false;

        for(let property in this.translations){

            if(property === code){

                result = true;
            }
        }

        return result;
    }

    isException = code => {

        let result = false;

        this.exceptions.forEach(v => {

            const rexexp = new RegExp(code);
        
            if(v.match(rexexp) !== null){

                result = true;
            }
        });

        return result;
    }

    // Make request which can retry and perform callback or show message to user if fatal.

    api = async (promises, retry, callback, callback2) => {

        if(Array.isArray(promises) === false){

            promises = [promises]; 
        }   

        promises.forEach((v, k) => {

            promises[k] = this.request(v); 
        });

        return await Promise.all(promises).then(

            resolved => {

                if(callback){

                    callback(resolved[0]); // Return only data of first promise (backwards compatible for single promises)
                }
            },

            rejected => {

                // If callback for rejection is not set show errors from API.

                if( ! callback2){

                    if(rejected.errors.length !== 0){

                        this.stores.ui.api = {

                            error: 'API error: ' + rejected.errors.join(', '),
                            retry: retry,
                            callback: callback
                        };
                    }

                    else if(rejected.messages.length !== 0){

                        this.stores.ui.messages = rejected.messages;
                    }

                    else {

                        console.log('Unexpected response: ', rejected); // If unknown response log it to console.
                    }
                }

                else {

                    callback2(rejected);
                }
            }
        );
    }

    route = (destination, params, callback) => {

        if(typeof params === 'function'){

            callback = params;
            params = {};
        }

        this.stores.ui.hideLoading = false; // App doesn't show loading cog when loading in background, but if route is loaded during background loading show cog.
    
        // Loop trough routes.

        let found = false;
 
        this.stores.views.routes.forEach((data, route) => {

            if(destination === route){

                found = true;

                // Load depedendencies by matched route.

                const promises = [];
                
                data.dependencies.forEach(dependency => {

                    promises.push(dependency(params));
                });

                // Replace matching route params with passed param values.

                for(let key in params){

                    const regex = new RegExp(':' + key, 'gi');

                    destination = destination.replace(regex, params[key]);
                }

                if(promises.length !== 0){

                    this.api(
                    
                        promises,
                        
                        () => {

                            // Retry
                        
                            this.route(route, params, callback);
                        },

                        () => {
                            
                            callback(destination);
                        }
                    );
                }

                else {

                    // If no promises return just converted destination.
                
                    callback(destination);
                }
            }
        });

        if(found === false){

            console.log('Route not found for: ', destination);
        }
    }
}

export default Load;
