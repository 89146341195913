import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import theme from 'theme';

const Wrapper = styled.div`

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: ${props => props.theme.size[1]};
    background: ${props => props.backgroundColor};
    color: ${props => props.color};
    font-size: 1.125em;
    margin-bottom: ${props => props.marginBottom};

    & > :first-child {
    
        white-space: nowrap;
    }

    @media (min-width: ${props => props.breakpoint}){

        flex-wrap: nowrap;
    }
`;

class Title extends React.Component {

    render(){

        return (
        
            <Wrapper {...this.props}>{this.props.children}</Wrapper>
        ); 
    }

    static propTypes = {

        children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired
    }

    static defaultProps = {

        backgroundColor: theme.color[4],
        color: theme.color[1],
        breakpoint: theme.breakpoint,
        marginBottom: 0
    }
}

export default Title;
