import React from 'react';
import Header from 'components/smart/Header';
import Title from 'components/gls-dumb/Title';
import Content from 'components/gls-dumb/Content';
import Pagebreak from 'components/gls-dumb/Pagebreak';
import Radio from 'components/gls-dumb/Radio';
import Columns from 'components/gls-dumb/Columns';
import Field from 'components/gls-dumb/Field';
import Button from 'components/gls-dumb/Button';
import ButtonB from 'components/gls-dumb/Button/Alternative';
import theme from 'theme';
import Multifields from 'components/gls-dumb/Multifields';
import InfoBox from 'components/gls-dumb/InfoBox';
import {observer, inject} from 'mobx-react';
import {observable, action, computed} from 'mobx';
import NoticeBox from 'components/gls-dumb/NoticeBox';
import ModalConfirm from 'components/gls-dumb/ModalConfirm';
import ModalAlerts from 'components/gls-dumb/ModalAlerts';
import Draft from 'components/smart/Draft';
import api from 'api.json';
import Window from 'components/gls-dumb/Window';
import moment from 'moment';
import {Sortable} from 'components/gls-dumb/Sortable';
import styled from 'styled-components';
import Checkbox2 from 'components/gls-dumb/Checkbox2';
import {bool} from 'Helpers';
import Section from 'components/gls-dumb/Section';

const Small = styled.span`

    ${props => `
    
        font-size: 0.8em;
    `}
`;

@inject('parcel')
@inject('attachment')
@inject('countries')
@inject('ui')
@inject('settings')
@inject('load')
@inject('shipmentsInfo')
@inject('senders')
@inject('customunits')
@inject('cncodes')

@observer class ParcelMeasurements extends React.Component {

    lang = this.props.ui.lang.shipmentMeasurements;

    @observable countedAmount = 0;
    @observable totalAmount = this.get('parcels_cnt') !== undefined ? this.get('parcels_cnt') : 0;
    @observable countedWeight = 0;
    @observable totalWeight = this.get('total_weight') !== undefined ? this.get('total_weight') : 0;
    @observable countedCubicMeter = 0;
    @observable validation = {fields: new Map(), multiFields: [], multiCustoms: []};
    @observable proFormaIsOpen = false;
    @observable messages = [];
    @observable attachmentIsOpen = false;
    @observable combineIsOpen = false;

    submitted = false;

    constructor(props){

        super(props);

        this.setOptionfields();

        this.set('counted_amount', '0');
        this.set('counted_weight', '0');

        this.setDefaultTerms();
        this.setDefaultCurrency();
    }

    setDefaultCurrency = () => {

        const current = this.get('customs_currency');

        if(current === undefined){

            const default_currency = this.props.settings.object.default_currency;

            if(default_currency !== undefined && default_currency !== ''){

                this.set('customs_currency', default_currency);
            }

            else {
            
                this.set('customs_currency', 'EUR');
            }
        }
    }

    setDefaultTerms = () => {

        if(this.get('customs_documenttype') === 'FILE' || this.get('customs_documenttype') === 'LATER'){

            if(this.get('deliveryterms') === undefined){

                const default_terms = this.props.settings.object.default_terms;

                if(default_terms !== undefined && default_terms !== ''){

                    this.set('deliveryterms', default_terms);
                }

                else {

                    this.set('deliveryterms', 'INCO20');
                }
            }
        }
    }

    setOptionfields = () => {

        this.parcels = [

            {name: 'amount', label: this.lang.amount + ':', description: this.lang.amountHelp, width: '7em', maxlength: 2},
            {name: 'content', label: this.lang.content + ':', width: '20em', maxlength: 100},
            {name: 'weight', label: this.lang.weight + ':', maxlength: 7},
            {name: 'length', label: this.lang.length + ':', maxlength: 6},
            {name: 'width', label: this.lang.width + ':', maxlength: 6},
            {name: 'height', label: this.lang.height + ':', maxlength: 6},
        ];
    }

    @action countMeasurements = (rows) => {
 
        let totalAmount = 0;
        let totalWeight = 0;
        let totalCubicMeter = 0;

        if(rows !== undefined){

            rows.forEach((v, k) => {

                let amount = this.parseValue(v.get('amount'));

                if(isNaN(amount)){

                    amount = 0;
                }

                totalAmount += parseInt(amount, 10);

                let weight = this.parseValue(v.get('weight'));

                if(isNaN(weight)){

                    weight = 0;
                }

                totalWeight += amount * weight;

                let width = this.parseValue(v.get('width'));
                let height = this.parseValue(v.get('height'));
                let length = this.parseValue(v.get('length'));

                if(isNaN(width) || isNaN(height) || isNaN(length)){

                    width = 0;
                    height = 0;
                    length = 0;
                }

                totalCubicMeter += amount * width * height * length / 1000000;
            });
        }

        this.countedAmount = totalAmount;
        this.countedWeight = totalWeight;
        this.countedCubicMeter = Math.round(totalCubicMeter * 100) / 100;

        this.set('counted_volume', this.countedCubicMeter);
        this.set('counted_amount', this.countedAmount.toString());
        this.set('counted_weight', this.countedWeight.toString());
    }

    parseValue = (v) => {

        if(v === ''){

            return 0;
        }

        if(typeof v === 'string'){

            v = v.replace(',', '.');
        }

        if(Number.isInteger(v)){

            return v;
        }

        return  parseFloat(v);
    }

    previous = () => {

        const params = {id: this.props.match.params.id};

        if(params.id !== undefined){

            this.props.load.route('/parcel/:id/send.html', params, url => {
            
                this.props.history.push(url);
            });
        }

        else {

            this.props.load.route('/parcel/send.html', url => {
            
                this.props.history.push(url);
            });
        }
    }

    set = (k, v) => {

        this.props.parcel.form.set(k, v);
    }

    change = (v) => {

        this.set(v.name, v.value);

        if(v.name === 'customs_documenttype'){

            if(v.value === 'PROFORMA'){

                this.goToProForma();
            }

            else if(v.value === 'FILE'){

                this.attachmentIsOpen = true;
            }

            else if(v.value === 'COMBINE'){

                this.openCombine();
            }

            this.setDefaultTerms();
        }

        if(this.submitted === true){
        
            this.validate();
        }
    }

    goToProForma = () => {

        if(this.props.match.params.id !== undefined){

            const params = {id: this.props.match.params.id};

            this.props.load.route('/parcel/:id/proforma.html', params, url => {

                this.props.history.push(url);
            });
        }

        else {

            this.props.load.route('/parcel/proforma.html', {}, url => {

                this.props.history.push(url);
            });
        }
    }

    validate = mod => {

        this.submitted = true;

        // If measurements are filled by hand use counted weight as total weight and not weight set on first page.

        if(this.get('fill_measurements') === true){

            this.set('total_weight', this.countedWeight.toString().replace(',', '.'));
        }

        this.validation = this.props.parcel.validate('ParcelMeasurements', mod);
    }

    get = (k) => {

        const v = this.props.parcel.form.get(k);

        if(k === 'parcels' && v !== undefined){

            this.countMeasurements(v);
        }

        return v;
    }

    save = () => {        

        this.validate();

        if(this.props.parcel.errorCount(this.validation) !== 0){

            this.messages = this.props.parcel.messages(this.validation);
        }

        else {

            // Resolver is at store in this case!

            this.props.parcel.add(this.props.match.params.id, (v, consignee) => {

                if(consignee === false){

                    this.props.ui.messages = [this.lang.save_consignee_failed];

                    this.props.ui.afterClearMessages = () => {
                    
                        this.ready(v)
                    };
                }

                else {
                
                    this.ready(v);
                }
            });
        }
    }

    ready = v => {

        const file = this.props.parcel.form.get('file');
        const promises = [];
        const existing = this.props.match.params.id;
        const id = existing !== undefined ? existing : v.data.id;

        // START

        // Upload file in separate request (required by API)
        // Save DRAFT doesn't use this and it has own method to upload file.

        if(this.props.parcel.form.get('customs_documenttype') === 'FILE' && file !== undefined){

            if(id !== undefined){

                const url = api.endpoints.user.shipments + id + '/invoice/';
                promises.push(this.props.attachment.upload(file, url));
            }

            else {
            
                console.log('Shipment ID is undefined!'); // This shouldn't occur.
            };
        }

        // END / START

        // Combine selected file in a separate request (required by API)
        // Save DRAFT doesn't use this and it has own method to combine file.

        if(this.get('customs_shipments_id') !== '' && this.get('customs_shipments_id') !== undefined){

            promises.push(this.props.parcel.patchInvoice(this.get('customs_shipments_id'), id));
        }

        // END

        const afterSend = this.props.settings.object.after_send;
        const autoprint = this.props.settings.object.autoprint;

        if(afterSend === '/parcels/'){

            promises.push(this.props.parcel.load());
        }

        this.props.ui.resolve(promises, () => {this.ready(v)}, () => {

            this.props.history.push(afterSend);

            if(autoprint === true){

                this.props.parcel.printParcelcards([id]);
            }
        });
    }

    error = (k) => {

        return this.validation.fields.get(k) === undefined ? false : true;
    }

    fillError = (k) => {

        if(this.error(k) === true){

            return theme.color[9];
        }

        return undefined;
    }

    clearMessages = () => {

        this.messages = [];
    }

    fieldProps = (field) => {

        return {

            name: field,
            value: this.get(field),
            onChange: this.change,
            error: this.error(field)
        }
    }

    unattachProForma = () => {

        this.props.parcel.form.delete('customs_documenttype');
        this.set('attachment', false);
    }

    cancelAttachment = () => {

        this.props.parcel.form.delete('customs_documenttype');
        this.attachmentIsOpen = false;
    }

    selectFile = (file) => {

        if(file.name !== undefined){

            this.attachmentIsOpen = false;

            this.change({
            
                name: 'file',
                value: file
            });
        }
    }

    removeFile = () => {

        this.props.parcel.form.delete('file'); // File input
        this.props.parcel.form.delete('invoice_files_id') // From API
        this.props.parcel.form.delete('customs_documenttype');
    }

    showDocumentOption = () => {

        if(
        
            this.props.parcel.form.get('type') === this.props.parcel.types.euroBusinessParcel ||
            this.props.parcel.form.get('type') === this.props.parcel.types.euroBusinessFreight
        ){

            return false;
        }

        return true;
    }

    openCombine = () => {

        const promises = [];

        // Fetch current and couple of previous months.

        for(let i = 0; i <= 2; i++){

            this.loading++;

            const year = moment().subtract(i, 'months').format('YYYY');
            const month = moment().subtract(i, 'months').format('MM');

            promises.push(this.props.parcel.fetchByMonth(year, month));
        }

        this.props.ui.resolve(promises, this.openCombine, () => {

            this.combineIsOpen = true;
        });
    }

    cancelCombine = () => {

        this.combineIsOpen = false;
        this.set('customs_shipments_id', '');
        this.props.parcel.form.delete('customs_documenttype');
        this.props.parcel.form.delete('invoice_files_id');
    }

    @computed get combinableParcels(){

        const parcels = [];

        this.props.parcel.data.forEach(v => {

            if(
                v.status === 'READY' &&
                v.consignee_countries_id === this.get('consignee_countries_id') &&
                v.invoice_files_id !== null &&
                v.id !== this.props.match.params.id
            ){
       
                parcels.push(v);
            }
        });

        return parcels;
    }

    selectCombinable = shipment => {

        this.combineIsOpen = false;
        this.set('customs_shipments_id', shipment);
    }

    parseParcelnumber = parcels => {
 
        if(parcels.length === 0 || parcels[0] === undefined || parcels[0].parcelnumber === undefined){

            return '-';
        }

        return parcels[0].parcelnumber;
    }

    get validUser(){

        const valid = [

            'emma.hagsberg@gls-finland.com',
            'tarja.ruohonen@gls-finland.com',
            'suvi.immonen@reima.com',
            'jenni.lokki@reima.com',
            'ella.pelli@gls-finland.com',
            'sari.pihlajaniemi@reima.com',
            'annika.ronnlund@reima.com',
            'martti.niinimaa@gls-finland.com',
            'merja.pohjola@gls-finland.com',
            'maija.rutanen@reima.com',
            'niina.vertanen@reima.com',
            'saara.hakala@gls-finland.com',
            'janina.koski@logistikas.fi',
            'jani.hirvonen@tinyfee.fi'
        ];

        if(valid.indexOf(this.props.settings.all.email) === -1){

            return false;
        }

        return true;
    }

    get fromGLS(){

        return true;

        /*

        const email = this.props.settings.all.email;
        const gls = new RegExp(/@gls-finland.com/, 'i');
        const tinyfee = new RegExp(/@tinyfee.fi/, 'i');

        if(email.match(gls) === null && email.match(tinyfee) === null){

            return false;
        }

        return true;

        */
    }

    @observable estimatedPrice = false;

    getPrice = () => {

        this.validate('getPrice');

        if(this.props.parcel.errorCount(this.validation) !== 0){

            this.messages = this.props.parcel.messages(this.validation);
        }

        else {

            const sendersID = this.get('senders_id');
            const from = this.props.senders.data.get(sendersID);

            const data = {
            
                from_countries_id: from.countries_id,
                from_postalnumber: from.postalnumber,
                to_countries_id: this.get('consignee_countries_id'),
                to_postalnumber: this.get('consignee_postalnumber'),
                to_recipienttype: this.get('consignee_recipienttype'),
                type: this.get('type'),
                deliveryterms: this.get('deliveryterms'),
                insurance: this.get('insurance') === true ? true : false,
                parcelshop: this.get('parcelshop') === true ? true : false,
                parcels: this.get('parcels'),
                ontoploading: this.get('ontoploading'),
            };

            const requests = [
            
                this.props.shipmentsInfo.getPrice(data)
            ];

            const retry = this.getPrice;

            this.props.load.api(requests, retry, v => {

                this.estimatedPrice = v.data;
            });
        }
    }

    matchLang = () => {

        let lang = 'fi-FI';

        if(this.props.ui.selectedLang === 'en'){

            lang = 'en-US';
        }

        return lang;
    }

    @computed get unitOptions(){

        const options = [{value: '', option: this.lang.unit_select}];

        this.props.customunits.data.forEach(v => {

            let option;
        
            if(this.props.ui.selectedLang === 'fi'){

                option = v.fiunit;
            }

            else if(this.props.ui.selectedLang === 'en'){

                option = v.enunit;
            }

            options.push({value: v.intrastat, option: option});
        });

        return options;
    }

    @action getUnitByCNcode = cncode => {

        let unit = false;

        if(cncode !== undefined && (cncode.length === 8 || cncode.length === 10)){

            cncode = cncode.substr(0, 8);

            this.props.cncodes.data.forEach(v => {

                if(v.cn === cncode){

                    this.props.customunits.data.forEach(v2 => {

                        if(v.su === v2.intrastat){

                            unit =  v2.intrastat;
                        }
                    });
                }
            });
        }

        return unit;
    }

    setCustomsUnit = row => {

        const cncode = row.get('tariff_name');
        const unit = this.getUnitByCNcode(cncode);

        if(unit === false || unit === 'ZZZ'){

            row.set('unit', '');
        }

        else {

            row.set('unit', unit);
        }
    }

    setSupplementalqtyDisabled = (row, index, overrides) => {

        const cncode = row.get('tariff_name');
        const unit = this.getUnitByCNcode(cncode);

        if(unit === false || unit === 'ZZZ'){

            row.set('supplementalqty', undefined);
            overrides.set(index, {'supplementalqty': {disabled: true}});
        }

        else {

            overrides.set(index, {'supplementalqty': {disabled: false}});
        }
    }

    render(){

        const fieldProps = this.fieldProps;
        const radioProps = this.fieldProps;
        const invoice_files_id = this.get('invoice_files_id');
        const file = (invoice_files_id !== undefined && invoice_files_id !== '') ? invoice_files_id : this.props.parcel.form.get('file');

        let invoice_contents = this.get('invoice_contents');

        if(invoice_contents !== undefined && invoice_contents.length === 0){

            invoice_contents = undefined;
        }

        let parcels = this.get('parcels');

        if(parcels !== undefined){

            if(parcels.length === 0){

                parcels = undefined;
            }
        }

        return (

            <React.Fragment>

                <Window isOpen={this.combineIsOpen}>

                    <Sortable marginBottom={theme.size[1]} pagination="10">

                        <Sortable.thead>
                    
                            <Sortable.tr>

                                <Sortable.td sortable={true} order="desc">{this.lang.combine_date}:</Sortable.td>
                                <Sortable.td sortable={true}>{this.lang.combine_parcelnumber}:</Sortable.td>
                                <Sortable.td sortable={true}>{this.lang.combine_consignee}:</Sortable.td>
                                <Sortable.td></Sortable.td>

                            </Sortable.tr>

                        </Sortable.thead>

                        <Sortable.tbody>

                            {this.combinableParcels.map(v => {

                                return (

                                    <Sortable.tr key={v.id}>

                                        <Sortable.td>{moment(v.date, 'X').format('DD.MM.YYYY')}</Sortable.td>
                                        <Sortable.td>{this.parseParcelnumber(v.parcels)}</Sortable.td>
                                        <Sortable.td>{v.consignee_name1}</Sortable.td>
                                        <Sortable.td><Button borderRadius={0} backgroundColor={theme.color[1]} onClick={() => this.selectCombinable(v.id)}>{this.lang.combine_select}</Button></Sortable.td>

                                    </Sortable.tr>
                                );
                            })}

                        </Sortable.tbody>
                    
                    </Sortable>

                    <Button onClick={this.cancelCombine}>{this.lang.cancel_combine}</Button>
                
                </Window>

                <ModalAlerts messages={this.messages.slice()} ok={this.clearMessages} />
 
                <ModalConfirm

                    isOpen={this.attachmentIsOpen}
                    cancel={this.cancelAttachment}
                    ok={this.selectFile}
                    file={true}
                    text={{cancel: this.lang.cancel, ok: this.lang.choose}}

                >{this.lang.fileAdd}:</ModalConfirm>

                <Header />

                <Section color="sub">

                    <Title>
                    
                        {this.lang.additionalInfo} <Draft />
                    
                    </Title>

                </Section>
        
                <div className="main"> 

                    {this.get('fill_measurements') === true &&

                        <React.Fragment>

                            <Pagebreak>{this.lang.measurements}</Pagebreak>

                            <Multifields
                            
                                name="parcels"
                                value={parcels}
                                fields={this.parcels}
                                validation={this.validation.multiFields}
                                onChange={(r) => {this.countMeasurements(r.value); this.change(r);}}
                                breakpoint="800px"
                            />

                            <InfoBox>
                           
                                <InfoBox.item color={this.fillError('counted_amount')}>{this.lang.countedAmount}: {this.countedAmount} / {this.totalAmount} kpl</InfoBox.item>
                                <InfoBox.item color={this.fillError('counted_weight')}>{this.lang.countedWeight}: {this.countedWeight.toString().replace('.', ',')} kg</InfoBox.item>
                                <InfoBox.item color={this.fillError('counted_volume')}>{this.lang.volume}: {this.countedCubicMeter} m³</InfoBox.item>

                            </InfoBox>

                        </React.Fragment>
                    }

                    {this.props.parcel.require.ontoploading === true &&

                        <React.Fragment>

                            <Pagebreak>{this.lang.ontoploading}</Pagebreak>

                            <Columns>

                                <Columns.left width="25%">

                                    <Radio {...radioProps('ontoploading')}>

                                        <Radio.item value={true} label={this.lang.allowed} />
                                        <Radio.item value={false} label={this.lang.denied} />

                                    </Radio>

                                </Columns.left>

                                <Columns.right width="75%">
                                
                                    {this.get('ontoploading') !== undefined &&
                                    
                                        <NoticeBox>{this.get('ontoploading') === true ? this.lang.charge : this.lang.noCharge}</NoticeBox>
                                    }

                                </Columns.right>

                            </Columns>

                        </React.Fragment>
                    }

                    {this.props.parcel.require.tariff === true &&

                        <React.Fragment>

                            <Pagebreak>{this.lang.customsClearance}</Pagebreak>
                        
                            {(this.props.parcel.form.get('attachment') !== true && file === undefined && (this.get('customs_shipments_id') === '' || this.get('customs_shipments_id') === undefined)) &&

                                <React.Fragment>

                                    <Radio {...radioProps('customs_documenttype')}>
                                    
                                        <Radio.item value="FILE" label={this.lang.pdf} />
                                        {(this.get('type') === this.props.parcel.types.euroBusinessParcel && this.validUser === true) && <Radio.item value="COMBINE" label={this.lang.combine_customs_consignment} />}
                                        <Radio.item value="PROFORMA" label={this.lang.proforma} />

                                        {this.showDocumentOption() === true && <Radio.item value="DOCUMENT" label={this.lang.document} info={this.lang.documentshipment_info} />}

                                        {/*<Radio.item value="LATER" label={this.lang.later} />*/}
                                    
                                    </Radio>

                                    <NoticeBox marginBottom={theme.size[1]}>{this.lang.info}</NoticeBox>

                                </React.Fragment>
                            }

                            {(this.get('customs_shipments_id') !== '' && this.get('customs_shipments_id') !== undefined) &&

                                 <React.Fragment>

                                    <Content>

                                        <Button borderRadius={0} backgroundColor={theme.color[1]} onClick={this.cancelCombine}>{this.lang.cancel_combine}</Button>

                                    </Content>

                                    <br />

                                </React.Fragment>                           
                            }

                            {this.props.parcel.form.get('attachment') === true &&

                                <React.Fragment>

                                    <Content>

                                        <Columns gap="small">
                                        
                                            <Columns.left>
                                            
                                                <ButtonB onClick={() => {this.unattachProForma()}}>{this.lang.removeProForma}</ButtonB>
                                                
                                            </Columns.left>

                                            <Columns.right>

                                                <ButtonB onClick={() => {this.goToProForma()}}>{this.lang.editProForma}</ButtonB>

                                            </Columns.right>

                                        </Columns>

                                    </Content>

                                    <br />

                                </React.Fragment>
                            }

                            {(file !== undefined && (this.get('customs_shipments_id') === '' || this.get('customs_shipments_id') === undefined)) &&

                                <React.Fragment>

                                    <Content>

                                        <Columns gap="small">
                                        
                                            <Columns.left color={this.validation.fields.get('file') !== undefined ? theme.color[9] : undefined}>
                                            
                                                {this.lang.file}: {file.name !== undefined ? file.name : file}
                                            
                                            </Columns.left>

                                            <Columns.right>

                                                <ButtonB onClick={() => {this.removeFile()}} borderRadius={0} backgroundColor={theme.color[1]}>{this.lang.removeFile}</ButtonB>

                                            </Columns.right>

                                        </Columns>

                                    </Content>

                                    <br />

                                </React.Fragment>
                            }

                            {(this.get('customs_documenttype') === 'FILE' || this.get('customs_documenttype') === 'LATER' || (this.get('customs_shipments_id') !== '' && this.get('customs_shipments_id') !== undefined)) &&

                                <React.Fragment>

                                    <Columns gap="small">

                                        <Columns.left>

                                            <Field type="select" {...fieldProps('deliveryterms')} label={this.lang.deliveryterms} options={this.props.parcel.getOptionfields('terms', undefined, this.get('consignee_countries_id'))} />

                                        </Columns.left>

                                        <Columns.right>

                                            <Field {...this.fieldProps('invoice_vatnumber')} label={this.lang.invoice_vatnumber} maxLength="20" info={this.lang.invoice_vatnumber_help} />

                                        </Columns.right>

                                    </Columns>

                                    <Checkbox2 name="skip_rows" value={true} checked={bool(this.get('skip_rows'))} onChange={this.change} label={this.lang.skip_rows} info={this.lang.skip_rows_info} />

                                    {this.get('skip_rows') !== true &&

                                        <React.Fragment>

                                            <Columns gap="small">

                                                <Columns.left>

                                                    <Columns gap="small">

                                                        <Columns.left>
                                                    
                                                            <Field type="text" {...fieldProps('invoice_number_text')} label={this.lang.invoice_number} />

                                                        </Columns.left>

                                                        <Columns.right>

                                                            <Field type="text" {...fieldProps('invoice_date')} label={this.lang.invoice_date} />

                                                        </Columns.right>

                                                    </Columns>

                                                </Columns.left>

                                                <Columns.right>

                                                    <Columns gap="small">

                                                        <Columns.left>
                                                       
                                                            <Field type="text" {...fieldProps('customs_value')} label={this.lang.customsValue} maxLength="7" />

                                                        </Columns.left>

                                                        <Columns.right>
                                                        
                                                            <Field type="select" {...fieldProps('customs_currency')} label={this.lang.customsCurrency} options={this.props.parcel.getOptionfields('currencies')} />
                                                        
                                                        </Columns.right>

                                                    </Columns> 
                                                
                                                </Columns.right>

                                            </Columns>

                                            <Multifields

                                                name="invoice_contents"
                                                value={invoice_contents}
                                                error={this.error('invoice_contents')}
                                                validation={this.validation.multiCustoms}
                                                
                                                fields={[

                                                    {name: 'product_code', label: this.lang.product_code + ':', width: '10em', description: this.lang.product_code_descripition},
                                                    {name: 'amount', label: this.lang.amount2 + ':', width: '7em', maxlength: 6, description: this.lang.amount2_description},
                                                    {name: 'tariff_name', label: this.lang.tariff_name + ':', width: '9em', maxlength: 10, description: this.lang.tariff_name_description},
                                                    {name: 'supplementalqty', label: this.lang.supplementalqty + ':', width: '11em', description: this.lang.supplementalqty_description, callback: this.setSupplementalqtyDisabled, disabled: true},
                                                    {name: 'unit', label: this.lang.unit + ':', description: this.lang.unit_help, width: '7em', maxlength: 50, options: this.unitOptions, disabled: true, callback: this.setCustomsUnit, default: ''},
                                                    {name: 'netweight', label: this.lang.netweight + ':', width: '7em', maxlength: 50, description: this.lang.netweight_description},
                                                    {name: 'description', label: this.lang.description + ':', maxlength: 255, description: this.lang.description_description},
                                                    {name: 'unit_price', label: this.lang.unit_price + ':', description: this.lang.unit_price_help, width: '10em', maxlength: 7, remainderof: ['amount', 'total_price']},
                                                    {name: 'total_price', label: this.lang.total_price + ':', description: this.lang.total_price_help, width: '7em', maxlength: 7, sumof: ['amount', 'unit_price']},
                                                    {name: 'countries_id', label: this.lang.country_of_origin + ':', description: this.lang.country_of_origin_help, width: '7em', options: this.props.countries.optionsWithCode(), default: '658b3f9b-0146-4404-88ed-eafd4c0affac'}
                                                ]}

                                                breakpoint="1100px"
                                                onChange={this.change}
                                            />

                                        </React.Fragment>
                                    }

                                </React.Fragment>
                            }

                        </React.Fragment>
                    }

                    {((this.fromGLS === true || this.props.parcel.form.get('type') !== this.props.parcel.types.euroBusinessFreight) && this.get('fill_measurements') === true) &&

                        <React.Fragment>

                            <Pagebreak>{this.lang.get_price_title}</Pagebreak>

                            {this.estimatedPrice !== false &&

                                <Columns gap="small" marginBottom={theme.size[1]}>

                                    <Columns.left>

                                        <table>

                                            <tbody>
                                        
                                                {this.estimatedPrice.rows.map((v, k) =>

                                                    <tr key={k}>

                                                        <td style={{paddingRight: theme.size[1]}}>{v[this.matchLang()]}:</td>
                                                        <td style={{paddingRight: theme.size[1]}}>{v.value.replace('.', ',')} €</td>

                                                    </tr>
                                                )}

                                            </tbody>
                                        
                                        </table>
                                    
                                    </Columns.left>

                                    <Columns.right>{this.estimatedPrice.message[this.matchLang()]}</Columns.right>

                                </Columns>
                            }

                            <Columns gap="small" marginBottom={theme.size[1]}>

                                <Columns.left>{this.estimatedPrice !== false && <Small>{this.lang.vat_not_included}</Small>}</Columns.left>

                                <Columns.right>

                                        <Columns gap="small">

                                            <Columns.left>{this.estimatedPrice !== false && <Small>{this.lang.only_estimation}</Small>}</Columns.left>

                                            <Columns.right>

                                                <ButtonB onClick={this.getPrice} style={{marginBottom: theme.size[1]}}>{this.lang.get_price}</ButtonB>

                                            </Columns.right>

                                        </Columns>
                                
                                </Columns.right>

                            </Columns>

                        </React.Fragment>
                    }

                    <Columns gap="small">

                        <Columns.left><Button onClick={this.previous}>{this.lang.previous}</Button></Columns.left>
                        <Columns.right><Button onClick={() => this.save()}>{this.lang.save}</Button></Columns.right>

                    </Columns> 
                                   
                </div>

            </React.Fragment>
        );
    }
}

export default ParcelMeasurements;
