import React from 'react';
import Header from 'components/smart/Header';
import moment from 'moment';
import Button from 'components/gls-dumb/Button';
import Title from 'components/gls-dumb/Title';
import Field from 'components/gls-dumb/Field';
import theme from 'theme';
import styled from 'styled-components';
import {observer, inject} from 'mobx-react';
import {observable, action} from 'mobx';
import {arrayFromMap} from 'Helpers';
import DateTimePicker from 'components/gls-dumb/DateTimePicker';
import {Buttons} from 'components/gls-dumb/Buttons';
import ModalAlerts from 'components/gls-dumb/ModalAlerts';
import Center from 'components/gls-dumb/Center';
import Quicklink from 'components/gls-dumb/Quicklink';
import ParcelsTable from 'components/gls-dumb/ParcelsTable';
import Section from 'components/gls-dumb/Section';

const  breakpoint = '800px'; // Global breakpoint (exception)

const Ellipsis = styled.div`

    ${props => `

        max-width: 10em;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    `}
`;

const Wrapper = styled.div`

    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;

    & > :first-child {

        width: 100%;
    }

    @media (min-width: ${breakpoint}){

        flex-wrap: nowrap;
    }
`;

const Timespan = styled.div`

    display: flex;
    align-items: center;
    padding: ${props => props.theme.size[1]} 0 ${props => props.theme.size[1]} 0;

    @media (min-width: ${breakpoint}){

        padding: 0;
        margin-bottom: ${props => props.theme.size[1]};
    }
`;

const Separator = styled.div`

    padding-left: ${props => props.theme.size[1]};
    padding-right: ${props => props.theme.size[1]};
`;

const Filters = styled.div`

    display: flex;
    flex-wrap: wrap;

    & > * {

        width: 100%;
    }

    & > :first-child {

        padding-bottom: ${props => props.theme.size[1]};
    }

    @media (min-width: ${breakpoint}){

        flex-wrap: nowrap;
        padding-bottom: 0;
        padding-right: ${props => props.theme.size[1]};

        & > * {

            width: auto;
        }

        & > :first-child {

            padding-bottom: 0;
        }
    }
`;

const Filter = styled.div`

    display: flex;

    ${props => ` 

        & > :first-child {

            padding-right: ${props.theme.size[1]};
        }
        
        @media (min-width: ${breakpoint}){
        
            margin-right: ${props.theme.size[1]}
        }
    `}
`;

const QuickLinks = styled.div`

    ${props => `

        margin-top: ${props.theme.size[1]}

        @media (min-width: ${breakpoint}){
       
            margin-top: 0;
            margin-bottom: ${props.theme.size[1]}
        }
    `}
`;

const Link = styled.a`

    text-decoration: underline;
    cursor: pointer;
`;

@inject('parcel')
@inject('senders')
@inject('consignees')
@inject('ui')
@inject('pickuppoints')
@inject('countries')
@inject('import')
@inject('load')

@observer class Parcels extends React.Component {

    lang = this.props.ui.lang.shipments;

    @observable form = new Map();
    @observable parcels = new Map(this.props.parcel.data);
    @observable pickerIsOpen = false;
    @observable pickerField = false;
    @observable actionsIsOpen = false;
    @observable messages = [];

    constructor(props){

        super(props);

        this.setOptionfields();
	this.props.ui.isCancel = false;  

        this.fieldProps = {

            backgroundColor: theme.color[7],
            marginBottom: 0,
            labelPosition: 'left',
            onChange: this.change
        }


        this.props.ui.selectedParcels.clear(); // Clear selected parcerls (if returning from order page)

        if(this.props.ui.actions.useDefaultFilter === false){

            this.fetchOldParcels(true); // parcel.fetchAll fetches only current month and rest is loaded after page is showed
        }

        else {
        
            this.setDefaultFilters();
        }
    }

    setDefaultFilters = () => {

        if(this.props.ui.memory.parcels !== null && this.props.ui.backFrom.overview === true){

            this.props.ui.backFrom.overview = false;

            this.props.ui.memory.parcels.forEach((value, key) => {

                this.form.set(key, value);
            });
        }

        else if(this.props.ui.actions.useDefaultFilter === true){
    
            this.form.set('filter', 'status');
            this.form.set('search', this.lang.READY);
            this.props.ui.actions.useDefaultFilter = false;
            this.fetchOldParcels(false);
            this.saveFormToUIMemory();
        }
    };

    componentDidUpdate = () => {

        if(this.form.get('filter') !== 'status' || this.form.get('search') !== this.lang.READY){
 
            this.setDefaultFilters();
        }
    }

    loading = 0;

    fetchOldParcels = hide => {

        if(hide === true){

            this.props.ui.hideLoading = true;
        }

        const background = [];

        for(let i = 1; i <= 5; i++){

            this.props.ui.backgroundLoading++;

            const year = moment().subtract(i, 'months').format('YYYY');
            const month = moment().subtract(i, 'months').format('M');

            background.push(this.props.parcel.fetchByMonth(year, month, 'shipments').then(() => {

                this.props.ui.backgroundLoading--;
 
                if(this.props.ui.backgroundLoading === 0){
                
                    this.props.ui.hideLoading = false;
                }
            }));
        }

        Promise.all(background).then(() => {

            this.submit();
        });
    }

    useCurrentMonth = () => {

        this.form.set('time_from', moment().startOf('month').format('DD.MM.YYYY'));
        this.form.set('time_to', moment().endOf('month').format('DD.MM.YYYY'));

        this.submit();
    }

    setOptionfields(){

        this.filters = [

            {value: '', option: this.lang.all},
            {value: 'parcelnumber', option: this.lang.parcelnumber},
            {value: 'status', option: this.lang.status},
            {value: 'consignee_name1', option: this.lang.consignee},
            {value: 'type', option: this.lang.type},
            {value: 'ref', option: this.lang.ref}
        ];
    }

    open = id => {

        const params = {id: id};

        this.props.load.route('/parcel/:id/view.html', params, url => {
            
            this.props.history.push('/parcel/'+id+'/view.html');
        });
    }

    change = (v) => {

        this.form.set(v.name, v.value);

        if(v.code === 13){

            this.submit();
        }

        this.saveFormToUIMemory();
    }

    saveFormToUIMemory = () => {

        const form = new Map();

        this.form.forEach((value, key) => {

            form.set(key, value);
        });

        this.props.ui.memory.parcels = form;
    }

    @action submit = () => {

        let data = arrayFromMap(this.props.parcel.data);

        // Filter by search term.

        if(this.form.get('search') !== undefined && this.form.get('search') !== ''){

            let fields = [];

            if(this.form.get('filter') !== undefined && this.form.get('filter') !== ''){

                fields = [this.form.get('filter')];
            }

            else {

                // Fields that search term is compared against.

                fields = [
                
                    'parcelnumber',
                    'status',
                    'consignee_name1',
                    'consignee_name2',
                    'type',
                    'ref',
                    'consignee_countries_id',
                    'created_at',
                    'is_printed',
                    'parcels_cnt',
                    'total_weight'
                ];
            }

            data = data.filter(v => {

                let matches = 0;

                fields.forEach(field => {
     
                    const regexp = new RegExp(this.form.get('search'), 'gi');

                    // Field that are translated in render need to match with translated values.

                    if(field === 'parcelnumber'){

                            v.parcels.forEach((v, k) => { 
                            
                             if(v.parcelnumber !== undefined && v.parcelnumber.match(regexp) !== null){

                                matches++;
                            }
                        });
                    }

                    else if(field === 'status'){

                        if(this.lang[v[field]].match(regexp) !== null){

                            matches++;
                        }
                    }

                    else if(field === 'consignee_countries_id'){

                        let country = this.props.countries.nameByCode(v.consignee_countries_id);

                        if(country !== false){

                            if(country.match(regexp) !== null){

                                matches++;
                            }
                        }
                    }

                    else if(field === 'type'){

                        let type = this.props.parcel.typeNames[v.type];

                        if(type.match(regexp) !== null){

                            matches++;
                        }
                    }
                    
                    else if(field === 'created_at'){

                        let created = moment(v.created_at, 'X').format('DD.MM.YYYY');

                        if(created.match(regexp) !== null){

                            matches++;
                        }
                    }

                    else if(field === 'is_printed'){

                        let printed = v.is_printed === false ? this.lang.no : this.lang.yes;

                        if(printed.match(regexp) !== null){

                            matches++;
                        }
                    }
                   
                    else {
 
                        if(v[field] !== null && v[field].toString().match(regexp) !== null){

                            matches++;
                        }
                    }
                });

                if(matches === 0){

                    return false;
                }

                return true;
            });
        }

        // Filter by date range

        let from = this.form.get('time_from');
        let to = this.form.get('time_to');

        if(from !== undefined && from !== '' && to !== undefined && to !== ''){

            from = moment(from.trim() + ' 00:00:00', 'DD.MM.YYYY HH:mm:ss').unix();
            to = moment(to.trim() + '23:59:59', 'DD.MM.YYYY HH:mm:ss').unix();

            data = data.filter(v => {

                if(v.created_at <= from || v.created_at >= to){

                    return false;
                }

                return true;
            });
        }

        // Replace old data with new sorted and filtered data.

        this.parcels.clear();

        data.forEach((v, k) => {
       
            this.parcels.set(v.id, v);
        });
    }

    openPicker = (field) => {

        this.pickerField = field;
        this.pickerIsOpen = true;
    }

    closePicker = () => {

        this.pickerField = false;
        this.pickerIsOpen = false;
    }

    donePicker = (timestamp) => {

        if(this.pickerField !== false){

            const date = moment(timestamp, 'X').format('DD.MM.YYYY');
        
            this.form.set(this.pickerField, date);
        }

        this.closePicker();
    }

    orderFetchForAll = () => {
	this.props.ui.isCancel = false;

        let selected = 0;

        this.parcels.forEach((v, k) => {
        
            if(v.status === 'READY'){

                this.props.ui.selectedParcels.set(v.id, true);

                selected++;
            }
        });

        if(selected === 0){

            this.messages = [this.props.ui.lang.shipements.noReadyShipments];
        }

        else {

            this.orderFetchForSelected();
        }
    }

    orderFetchForSelected = () => {
	this.props.ui.isCancel = false;

        if(this.props.ui.selectedParcels.size === 0){

            this.messages = [this.lang.noShipmentsSelected];
        }

        else {

            const selected = [];

            let errors = false;

            this.props.ui.selectedParcels.forEach((v, k) => {

                if(errors === false){

                    const parcel = this.props.parcel.data.get(k);
                
                    if(parcel.status !== 'READY'){

                        errors = true;

                        this.props.ui.messages = [this.lang.cannotOrderFetch];
                    }

                    else {

                        selected.push(k);
                    }
                }
            });

            if(errors === false){

                this.props.ui.resolve(this.props.pickuppoints.load(), this.orderFetchForSelected, () => {

                    this.props.history.push('/parcels/order-fetch.html');
                });
            }
        }
    }

    cancelSelected = () => {

        if(this.props.ui.selectedParcels.size === 0){

            this.messages = [this.lang.noShipmentsSelected];
        }

        else {

            const selected = [];

            let errors = false;
	    this.props.ui.isCancel = true;

            this.props.ui.selectedParcels.forEach((v, k) => {

                if(errors === false){

                    const parcel = this.props.parcel.data.get(k);

                    if(parcel.status !== 'READY'){

                        errors = true;

                        this.props.ui.messages = [this.lang.cannotCancel];
                    }

                    else {

                        selected.push(k);
                    }
                }
            });

            if(errors === false){

                this.props.ui.resolve(this.props.pickuppoints.load(), this.orderFetchForSelected, () => {

                    this.props.history.push('/parcels/order-fetch.html');
                });
            }
        }
    }

    printSelected = () => {

        if(this.props.ui.selectedParcels.size === 0){

            this.messages = [this.lang.noShipmentsSelected];
        }

        else {

            const selected = [];

            let errors = false;

            this.props.ui.selectedParcels.forEach((v, k) => {

                if(errors === false){

                    const parcel = this.props.parcel.data.get(k);
                
                    if(parcel.is_printed === true){

                        errors = true;

                        this.props.ui.messages = [this.lang.cannotPrint];
                    }

                    else {

                        selected.push(k);
                    }
                }
            });

            if(errors === false){

                this.print(selected);
            }
        }
    }

    @action print = (selected) => {

        // Print parcel cards

        this.props.parcel.printParcelcards(selected, () => {

            // After parcels cards have printed set them printend also in UI.
       
            selected.forEach(v => {
            
                const parcel = this.parcels.get(v);

                parcel.is_printed = true;

                this.parcels.set(v, parcel);
                this.props.parcel.data.set(v, parcel);
            });
        });
    }

    clearMessages = () => {

        this.messages = [];
    }

    printUnprinted = () => {

        const unprinted = [];

        this.parcels.forEach((v, k) => {
       
            if(v.is_printed === false && v.status !== 'DRAFT'){
            
                unprinted.push(v.id);
            }
        });

        if(unprinted.length === 0){

            this.messages = [this.lang.noUnprinted];
        }

        else {

            this.print(unprinted);
        }
    }

    parseParcelnumber = parcels => {
 
        if(parcels.length === 0 || parcels[0] === undefined || parcels[0].parcelnumber === undefined){

            return '-';
        }

        return parcels[0].parcelnumber;
    }

    useCurrentDate = () => {

        const current = moment().format('DD.MM.YYYY');

        this.form.set('time_from', current);
        this.form.set('time_to', current);

        this.submit();
    }

    openImportList = () => {

        const promises = [

            this.props.import.fetchAll(),
            this.props.senders.fetchAll()
        ];

        this.props.ui.resolve(promises, this.openImportList, () => {

            this.props.history.push('/imports/');
        });
    }

    isSelected = id => {

        const selected = this.props.ui.selectedParcels.get(id);

        if(selected === undefined){

            return false;
        }

        return true;
    }

    selectedParcels = v => {

        this.props.ui.selectedParcels = v;
    }

    modifyParcelsCount = count => {

        return count.toString() + ' ' + this.lang.cntUnit;
    }

    modifyTotalWeight = weight => {

        return weight.toString() + ' kg';
    }

    modifyType = type => {

        return this.props.parcel.typeNames[type];
    }

    modifyConsigneeName = name => {

        return <Ellipsis>{name}</Ellipsis>;
    }

    modifyConsigneeCountry = id => {

        return this.props.countries.nameByCode(id);
    }

    modifyIsPrinted = bool => {
        
        return bool === false ? this.lang.no : this.lang.yes;
    }

    modifyStatus = status => {

        return this.lang[status];
    }

    modifyDate = timestamp => {

        return moment(timestamp, 'X').format('DD.MM.YYYY');
    }

    resetBenchmark = () => {

        this.resetTime = Date.now();
    }

    benchmark = () => {

        let difference = Date.now() - this.resetTime;

        console.log('Benchmark: ' + difference + ' ms, Items: ' + this.parcels.size);
    }

    @action reset = () => {

        this.form.set('time_from', '');
        this.form.set('time_to', '');
        this.form.set('filter', '');
        this.form.set('search', '');
        this.submit();
    }

    render(){

        this.resetBenchmark();

        /*

        const buttonProps = {
        
            width: 'auto',
            borderRadius: 0,
            backgroundColor: theme.color[1]
        };

        */

        const data = Array.from(this.parcels.values()).map(v => {

            const data = {};

            data.id = v.id;
            data.parcels = this.parseParcelnumber(v.parcels);
            data.parcels_cnt = this.modifyParcelsCount(v.parcels_cnt);
            data.total_weight = this.modifyTotalWeight(v.total_weight);
            data.type = this.modifyType(v.type);
            data.consignee_name1 = v.consignee_name1;
            data.consignee_countries_id = this.modifyConsigneeCountry(v.consignee_countries_id);
            data.is_printed = this.modifyIsPrinted(v.is_printed);
            data.status = v.status;
            data.created_at = v.created_at;

            return data;
        });
 
        return (

            <React.Fragment>

                <DateTimePicker isOpen={this.pickerIsOpen} onCancel={this.closePicker} onDone={this.donePicker} />
                <ModalAlerts messages={this.messages} ok={this.clearMessages} />
            
                <Header />

                <Section color="sub">

                    <Title breakpoint={breakpoint}>

                        <Quicklink title={this.lang.shipmentsTitle} value="export" options={[
                       
                            {value: 'export', option: this.lang.quick_export},
                            {value: 'import', option: this.lang.quick_import, callback: this.openImportList}
                        ]} />

                        <Wrapper>

                            <Center>

                                <div> 

                                    <QuickLinks>
                                    
                                        {this.lang.quicklinks}: <Link onClick={this.useCurrentDate}>{this.lang.today}</Link> | <Link onClick={this.useCurrentMonth}>{this.lang.currentMonth}</Link>
                                        
                                    </QuickLinks>

                                    <Timespan>

                                            <Field
                                                
                                                type="calendar"
                                                name="time_from"
                                                value={this.form.get('time_from')}
                                                label={this.lang.timespan}
                                                {...this.fieldProps}
                                                datePicker={() => this.openPicker('time_from')}
                                            />

                                            <Separator>-</Separator>

                                            <Field
                                            
                                                type="calendar"
                                                name="time_to"
                                                value={this.form.get('time_to')}
                                                {...this.fieldProps}
                                                datePicker={() => this.openPicker('time_to')}
                                            />

                                    </Timespan>

                                    <Filters> 

                                        <Filter>

                                            <Field type="select" name="filter" value={this.form.get('filter')} label={this.lang.searchFromField} {...this.fieldProps} options={this.filters} />
                                            <Field type="text" name="search" value={this.form.get('search')} label={this.lang.searchterm} {...this.fieldProps} />

                                        </Filter>

                                        <Buttons style={{marginBottom: 0}}>

                                            <Button onClick={this.submit} width="auto">{this.lang.search}</Button>
                                            <Button onClick={this.reset} width="auto">{this.lang.reset}</Button>

                                        </Buttons>
                                        
                                    </Filters>     

                                </div>

                            </Center> 

                        </Wrapper>
                        
                    </Title>

                </Section>
            
                <div className="main">                    

                    <Buttons breakpoint="1050px">
                        
                        <Button onClick={this.orderFetchForAll}>{this.lang.orderFetchForAll}</Button>
                        <Button onClick={this.orderFetchForSelected}>{this.lang.orderFetchForSelected}</Button>
                        <Button onClick={this.cancelSelected}>{this.lang.cancelSelected}</Button>
                        <Button onClick={this.printUnprinted}>{this.lang.printUnprinted}</Button>
                        <Button onClick={this.printSelected}>{this.lang.printSelected}</Button>

                    </Buttons>

                    <ParcelsTable

                        headers={{

                           parcels: this.lang.parcelnumber,
                           parcels_cnt: this.lang.amount,
                           total_weight: this.lang.weight,
                           type: this.lang.type,
                           consignee_name1: this.lang.consignee,
                           consignee_countries_id: this.lang.country,
                           is_printed: this.lang.printed,
                           status: this.lang.status,
                           created_at: this.lang.date
                        }}

                        modifiers={{

                            consignee_name1: this.modifyConsigneeName,
                            status: this.modifyStatus,
                            created_at: this.modifyDate
                        }}

                        data={data}
                        benchmark={false}
                        pagination={100}
                        defaultSort="created_at"
                        defaultOrder="desc"
                        select={this.selectedParcels}
                        lang={{open: this.lang.open}}
                        open={this.open}
                        breakpoint="1050px"
                    />
                
                </div>

            </React.Fragment>
        );
    }
}

export default Parcels;
