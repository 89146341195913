import React from 'react';
import styled from 'styled-components';
import Button from 'components/gls-dumb/Button';

const Container = styled.div`

    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
`;

const Item = styled.div`

    padding: ${props => props.theme.size[1]};
    background: #fff;
    border-radius: 0.5em;
`;

const Message = styled.div`

    padding: ${props => props.theme.size[1]};
`;

class ModalAlerts extends React.Component {

    render(){

        if(this.props.messages === undefined || this.props.messages.length === 0){

            return null;
        }

        else {

            return (
            
                 <Container>
                
                    <Item>
                    
                        <Message>{this.props.messages.map((v, k) => <div key={k} dangerouslySetInnerHTML={{__html: v}}></div>)}</Message>
                        <Button onClick={this.props.ok}>{this.props.text}</Button>
                        
                    </Item>
                    
                </Container>
            );
        }
    }

    static defaultProps = {

        text: 'OK'
    }
}

export default ModalAlerts;
