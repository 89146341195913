import React from 'react';
import sort from 'fast-sort';
import styled from 'styled-components';
import Order from 'components/gls-dumb/Order';
import moment from 'moment';

const Table = styled.table`

    ${props => `

        width: 100%;
        border: 1px solid ${props.theme.color[4]};

        @media (min-width: ${props.theme.breakpoint}){

            border: 2px solid ${props.theme.color[4]};
        }

        @media (max-width: ${props.theme.breakpoint}){

            & thead {

                display: none;
            }

            &, & tbody, & tr, & td {

                display: block;
            }
        }
    `}
`;

const Title = styled.td`

    ${props => `

        padding: ${props.theme.size[1]};
        background: ${props.theme.color[4]};
    `}
`;

const Row = styled.tr`

    ${props => `

        & > td {

            border-bottom: 1px solid ${props.theme.color[4]};

            @media (min-width: ${props.theme.breakpoint}){

                border-bottom: 2px solid ${props.theme.color[4]};
            }
        }
    `}
`;

const Data = styled.td`

    ${props => `

        padding: ${props.theme.size[1]};

        @media (max-width: ${props.theme.breakpoint}){

            text-align: right;

            &:before {

                float: left;
                content: '${props.title}:';
            }
        }
    `}
`;

const Pagination = styled.div`

    ${props => `
    
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${props.theme.color[4]};
        padding: ${props.theme.size[1]};
    `}
`;

const ChangePage = styled.div`

    ${props => `

        display: flex;
        align-items: center;
        justify-content: center;
        width: ${props.theme.inputSize};
        height: ${props.theme.inputSize};
        background-color: ${props.theme.color[1]};
        color: ${props.theme.color[7]};
        cursor: pointer;

        &:hover {

            background-color: ${props.theme.color[3]};
        }
    `}
`;

const Sort = styled.div`

    cursor: pointer;
`;

const CurrentPage = styled.span`

    ${props => `
   
        margin-left: ${props.theme.size[1]};
        margin-right: ${props.theme.size[1]};
    `}
`;

class LightTable extends React.Component {

    constructor(props){

        super(props);

        const defaultOrder = this.props.defaultOrder !== undefined ? this.props.defaultOrder : Object.keys(this.props.headers)[0];

        this.state = {

            page: 0,
            sort: defaultOrder,
            order: 'asc'
        }
    }

    resetBenchmark = () => {

        this.resetTime = Date.now();
    }

    benchmark = () => {

        let difference = Date.now() - this.resetTime;

        console.log('Benchmark: ' + difference + ' ms, Items: ' + this.props.data.size);
    }

    sortBy = key => {

        const order = this.state.sort !== key ? 'asc' : this.state.order === 'asc' ? 'desc' : 'asc';

        this.setState({

            sort: key,
            order: order 
        });
    }

    sort = (data, field, order) => {

        const options = {

            comparer: new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' }).compare
        };

        if(order === 'asc'){

            options.asc = v => v[field];
        }

        else if(order === 'desc'){

            options.desc = v => v[field];
        }

        return sort(data).by(options);
    }

    pagination = data => {

        const pages = this.props.pagination;

        if(pages === false){

            return data;
        }

        const from = this.state.page * pages;
        const to = from + pages;

        data = data.filter((v, k) => {

            if(k > from & k < to){

                return true;
            }

            return false;
        });

        return data;
    }

    // Arrange data in same order than headers.

    arrange = (values) => {

        values = new Map(values);

        const arranged = [];

        for(let key in this.props.headers){

            arranged.push([key, values.get(key)]);
        }

        return arranged;
    }

    changePage = page => {

        this.setState({

            page: this.state.page + page
        });

        console.log('Change page: ', this.state.page);
    }

    get total(){

        return Math.ceil(this.props.data.size / this.props.pagination);
    }

    render(){

        this.resetBenchmark();
        let data;

        if('map' in this.props.data){

            data = this.props.data;
        }

        else if('values' in this.props.data){

            data = Array.from(this.props.data.values());
        }

        data = this.sort(data, this.state.sort, this.state.order);
        data = this.pagination(data);

        let headers = Array.from(new Map(Object.entries(this.props.headers)));
        let keys = [];

        return (

            <React.Fragment>

                <Table>

                    <thead>

                        <tr>

                            {headers.map((header) => {

                                keys.push(header[0]);

                                return (

                                    <Title key={header[0]}>

                                        <Sort onClick={() => this.sortBy(header[0])}>

                                            {this.state.sort === header[0] && <Order type={this.state.order} />}
                                            {header[1]}:

                                        </Sort>

                                    </Title>
                                );
                            })}

                        </tr>

                    </thead>

                    <tbody>

                        {data.map((v, i) => {

                            let values = Array.from(new Map(Object.entries(v))).filter(v => {

                                // Filter out fields that are not included in headers.

                                if(keys.indexOf(v[0]) === -1){

                                    return false;
                                }

                                return true;
                            });

                            // Use id as key if it is set.

                            const key = v.id !== undefined ? v.id : i;

                            return (

                                <Row key={key}>

                                    {this.arrange(values).map((v) => {

                                        if(v[0] === 'consignee_countries_id'){

                                            v[1] = this.props.countries.get(v[1]);
                                            v[1] = v[1].name;
                                        }

                                        if(v[0] === 'date'){

                                            v[1] = moment(v[1], 'X').format('DD.MM.YYYY');
                                        }

                                        return <Data key={v[0]} title={this.props.headers[v[0]]}>{v[1]}</Data>;
                                    })}
                               
                                </Row>
                            );
                        })}

                    </tbody>

                    {this.props.benchmark === true && [0].map(() => this.benchmark())}

                </Table>

                {(this.props.pagination !== false && this.props.data.size > this.props.pagination) &&

                    <Pagination>

                        {this.state.page > 0 && <ChangePage onClick={() => this.changePage(-1)}><i className="fas fa-caret-left" /></ChangePage>}
                        <CurrentPage>{this.state.page + 1} / {this.total}</CurrentPage>
                        {this.state.page + 1 < this.total && <ChangePage onClick={() => this.changePage(1)}><i className="fas fa-caret-right" /></ChangePage>}

                    </Pagination>
                }

            </React.Fragment>
        );
    }
}

LightTable.defaultProps = {

    benchmark: false,
    pagination: false
}

export default LightTable;
