import React from 'react';
import {observable} from 'mobx';
import {bool} from 'Helpers';

/*

When extending from this class set store:

    this.store = this.props.store; // Injected store
*/

class Component extends React.Component {

    @observable messages = [];

    submitted = false; // If true validate on every change.

    go = url => {

        this.props.history.push(url);
    }

    // Redirect

    replace = url => {

        this.props.history.replace(url);
    }

    // Set form item in store.

    set = (k, v) => {

        this.store.form.set(k, v);
    }

    // Delete form item from store.

    unset = k => {
    
        this.store.form.delete(k);
    }
    
    // Get form item from store.

    get = k => {

        return this.store.form.get(k);
    }

    // Minimal change method (override if not enough)

    change = v => {

        this.set(v.name, v.value);

        if(this.submitted === true){

            this.store.validate(this.props.history.location.pathname);
        }
    }

    // Clear messages shown by  ModalAlerts component.

    clearMessages = () => {

        this.messages = [];
    }

    // Quick props for Field component.

    fieldProps = (field) => {

        return {

            name: field,
            value: this.get(field),
            error: this.store.validation.get(field) === undefined ? false : true,
            onChange: this.change
        };
    }

    // Quick props for Checkbox component.

    checkboxProps = (checkbox) => {

        return {

            name: checkbox,
            checked: bool(this.get(checkbox)),
            onChange: this.change
        }
    }
}

export default Component;
