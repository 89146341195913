import React from 'react';
import Component from 'Component';
import {Buttons} from 'components/gls-dumb/Buttons';
import ButtonB from 'components/gls-dumb/Button/Alternative';
import {inject} from 'mobx-react';
import {withRouter} from 'react-router-dom';
import api from 'api';

@withRouter
@inject('ui')
@inject('import')
@inject('attachment')

class ImportDraft extends Component {

    lang = this.props.ui.lang.importDraft;

    back = () => {

        this.props.ui.customConfirm = {
        
            isOpen: true,
            message: this.lang.confirm_cancel,

            cancelHandler: this.props.ui.clearCustomConfirm,

            okHandler: () => {

                this.props.ui.clearCustomConfirm();
                this.props.history.push('/home/');
            },

            cancelText: this.lang.cancel_text,
            okText: this.lang.ok_text
        };
    }

    save = () => {

        // Resolver is at store!

        const page = (this.props.match.path === '/import/details.html' || this.props.match.path === '/import/:id/details.html') ? 'details' : 'measurements';

        this.props.import.save('DRAFT', page, this.props.match.params.id, (consignees, r) => { 

            if(this.props.import.form.get('customs_documenttype') === 'FILE' && this.props.import.form.get('file') !== undefined){

                const url = api.endpoints.user.imports + r.data.id + '/invoice/';

                this.props.ui.resolve(this.props.attachment.upload(this.props.import.form.get('file'), url), () => this.props.attachment.upload(this.props.import.form.get('file'), url), () => {

                    this.ready();
                });
            }

            else {
            
                this.ready();
            }
        });
    }

    ready = () => {

        this.props.ui.messages = [this.lang.saved];
    }

    render(){

        const status = this.props.import.form.get('status');

        return (

            status === undefined || status === 'DRAFT' ?

                <Buttons width="auto" marginBottom="0">
                
                    <ButtonB onClick={this.back}>{this.lang.cancel}</ButtonB>
                    <ButtonB onClick={this.save}>{this.lang.save}</ButtonB>
                
                </Buttons>
            :
                
                <ButtonB onClick={this.back}>{this.lang.cancel}</ButtonB>
        );
    }
}

export default ImportDraft;
