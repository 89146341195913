import Validate from 'Validate';
import ApiStore from 'stores/ApiStore'
import api from 'api.json';
import {observable, computed} from 'mobx';
import currenciesJSON from 'currencies.json';
import {sortObjectByKey, round} from 'Helpers';
import moment from 'moment';

class ParcelStore extends ApiStore {

    @observable form = new Map();
    
    defaultConsignee = false;
    defaultSender = false;
    copy = false; // Copy of selected parcels is stored here (set to false after copy)
    importCopy = false;
    priceEstimationCopy = false;

    types = {
    
        euroBusinessParcel: '10000',
        euroBusinessFreight: '10013',
        globalBusinessParcel: '10015',
        globalExpressParcel: '10010'
    };

    typeNames = {

        10000: 'EuroBusinessParcel',
        10013: 'EuroBusinessFreight',
        10015: 'GlobalBusinessParcel',
        10010: 'GlobalExpressParcel',
        '': '-'
    }

    constructor(stores){

        super(stores);
        this.stores = stores;

    }

    getOptionfields = (field, text, countriesID, mode, shipmentType) => {

        // Lang is not yet set if these would set in constructor.

        const lang = this.stores.ui.lang.shipmentMeasurements;
	if (typeof shipmentType === 'undefined' || shipmentType === null) {
		shipmentType = this.form.get('type');
	}

        if(field === 'terms'){

            const country = countriesID === undefined ? undefined : this.stores.countries.codeById(countriesID);

            const options = [];

            if(text === undefined){
           
                options.push({value: '', option: '- ' + lang.chooseDeliveryterms});
            }

            else if(text !== ''){

                options.push({value: '', option: text});
            }

            [

                {value: 'INCO10', option: lang.inco10},
                {value: 'INCO18', option: lang.inco18},
                {value: 'INCO20', option: lang.inco20},
                {value: 'INCO30', option: lang.inco30},
                {value: 'INCO40', option: lang.inco40},
                {value: 'INCO60', option: lang.inco60}

            ].forEach(v => {
		if(mode === 'import') {
			if(v.value === 'INCO60') {
        	        	options.push(v);
			}
		} else if ( mode !== 'import' && v.value !== 'INCO60') {

			if(shipmentType === '10010' || shipmentType === '10015') {
			    if (v.value === 'INCO40' || v.value === 'INCO10') {
        		        options.push(v);
			    }
			} else if( shipmentType === '10013') {
			    if (v.value === 'INCO20' || v.value === 'INCO10') {
	        	        options.push(v);
			    }
			} else {
	                    if(v.value !== 'INCO18' || (v.value === 'INCO18' && country === 'GB')){
	                        options.push(v);
	                    }
			}
		}
            });

            return options;
        }

        else if(field === 'currencies'){

            const currencies = [{value: '', option: '- ' + lang.chooseCurrency}];
            const sorted = sortObjectByKey(currenciesJSON);
        
            for(let k in sorted){

                currencies.push({value: k, option: sorted[k]});
            }

            return currencies;
        }

        else if(field === 'types'){

            const options = [];

            if(text === undefined){

                options.push({value: '', option: '-' + lang.selectDefaultType});
            }

            else if(text !== ''){

                options.push({value: '', option: text});
            }

            [
           
                {value: this.types.euroBusinessParcel, option: this.typeNames[this.types.euroBusinessParcel]},
                {value: this.types.euroBusinessFreight, option: this.typeNames[this.types.euroBusinessFreight]},
                {value: this.types.globalBusinessParcel, option: this.typeNames[this.types.globalBusinessParcel]},
                {value: this.types.globalExpressParcel, option: this.typeNames[this.types.globalExpressParcel]}

            ].forEach(v => options.push(v));

            return options;
        }

        return false;
    }

    parcelNumbers = [];

    validate = (page, mod) => {

        if(mod === undefined){
            
            mod = 'normal';
        }

        const fields = new Validate(this.form);

        fields.weightDistribution = function(amount, weight, message){

            amount = parseInt(amount, 10);

            if(amount !== undefined && isNaN(amount) === false){

                const distribution = parseFloat(this.value.replace(',', '.')) / amount;

                if(isNaN(distribution) === false && distribution > weight){

                    this.error('weightDistribution', message);
                }
            }

            return this;
        }

        fields.isSearchNameMandatory = function(name, consignees, message){

            if((this.value === undefined || this.value === '') && name !== undefined && name !== ''){

                let found = false;

                consignees.forEach(consignee => {

                    if(consignee.name1 === name){

                        found = true;
                    }
                });

                if(found){

                    this.error('isSearchNameMandatory', message);
                }
            }

            return this;
        }

        fields.isSearchNameAvailable = function(consignees, message){

            if(this.value !== undefined && this.value !== ''){

                let found = false;

                consignees.forEach(consignee => {

                    if(consignee.search === this.value){

                        found = true;
                    }
                });

                if(found){

                    this.error('isSearchNameAvailable', message);
                }
            }

            return this;
        }

        if(page === 'ParcelSend'){

            const lang = this.stores.ui.lang.shipmentSend;
   
            fields.set('senders_id').isFilled(lang.fillSender);
            fields.set('pickuppoints_id').isFilled(lang.fillPickuppoint);
            fields.set('consignee_name1').isFilled(lang.fillName1);
            // fields.set('consignee_name2').isFilled();
            fields.set('consignee_address1').isFilled(lang.fillAddress1);
            // fields.set('consignee_address2').isFilled();

            let isConsigneeEU = false;

            if(this.form.get('consignee_countries_id') !== undefined){

                // Zip code validation by country.
 
                const country = this.stores.countries.findCountry('id', this.form.get('consignee_countries_id'));

                if(typeof country.zipregex === 'string'){

                    fields.set('consignee_postalnumber').regexp(country.zipregex, this.stores.countries.getZiperrortext(country, lang.invalidPostalnumber));
                }

                isConsigneeEU = this.stores.countries.isEU(country, this.form.get('consignee_postalnumber'));
            }

            else {

                fields.set('consignee_postalnumber').isFilled(lang.fillPostalnumber).format('zip', lang.invalidPostalnumber);
            }

            fields.set('consignee_city').isFilled(lang.fillCity);
            fields.set('consignee_countries_id').isFilled(lang.fillCountry);

            const countryCode = this.stores.countries.codeById(this.form.get('consignee_countries_id'));

            if(this.form.get('type') !== this.types.euroBusinessParcel || (countryCode !== false && countryCode === 'FI')){

                if(this.form.get('type') !== this.types.euroBusinessParcel){

                    fields.set('consignee_contact').isFilled(lang.fillContact);
                }

                if(this.form.get('consignee_mobile') === undefined || this.form.get('consignee_mobile') === ''){

                    fields.set('consignee_tel').isFilled(lang.fillConsigneeTel).isTel(lang.invalidTel);
                }

                else if(this.form.get('consignee_tel') !== undefined && this.form.get('consignee_tel') !== ''){

                    fields.set('consignee_tel').isTel(lang.invalidTel);
                }

                if(this.form.get('consignee_tel') === undefined || this.form.get('consignee_tel') === ''){

                    fields.set('consignee_mobile').isFilled(lang.fillConsigneeMobile).isMobile(lang.invalidMobile);
                }

                else if(this.form.get('consignee_mobile') !== undefined && this.form.get('consignee_mobile') !== ''){

                    fields.set('consignee_mobile').isMobile(lang.invalidMobile);
                }
            }

            else {

                if((this.form.get('consignee_tel') !== undefined && this.form.get('consignee_tel') !== '') || (isConsigneeEU === false && this.IsAtLeastOneContactInfoFilled() === false)){

                    fields.set('consignee_tel').isTel(lang.invalidTel);
                }

                if((this.form.get('consignee_mobile') !== undefined && this.form.get('consignee_mobile') !== '') || (isConsigneeEU === false && this.IsAtLeastOneContactInfoFilled() === false)){

                    fields.set('consignee_mobile').isMobile(lang.invalidMobile);
                }
            }

            if(this.form.get('fds') === true || this.form.get('parcelshop') === true ){
            
                fields.set('consignee_email').isFilled(lang.fillEmail).isEmail(lang.invalidEmail);
            }

            else if(isConsigneeEU === false && this.IsAtLeastOneContactInfoFilled() === false){

                fields.set('consignee_email').isFilled(lang.fillEmailNotEU).isEmail(lang.invalidEmail);
            }

            else if(this.form.get('consignee_email') !== undefined && this.form.get('consignee_email') !== ''){

                fields.set('consignee_email').isEmail(lang.invalidEmail);
            }

            if(this.form.get('save_consignee') === true){

                fields.set('consignee_search')
                .isSearchNameMandatory(this.form.get('consignee_name1'), this.stores.consignees.data, lang.searchNameIsMandatory)
                .isSearchNameAvailable(this.stores.consignees.data, lang.SearchNameNotAvailable);
            }

            if(this.form.get('type') === this.types.euroBusinessFreight || (countryCode !== false && countryCode === 'FI') ||  (countryCode !== false && countryCode === 'SE')){

                fields.set('consignee_recipienttype').isFilled(lang.is_filled_recipienttype);
            }

            fields.set('type').isFilled(lang.fillType);
            // fields.set('ref').isFilled();
            // fields.set('description').isFilled();

            if(this.form.get('type') !== this.types.euroBusinessParcel){

                fields.set('contents').isFilled(lang.fillContent);
            }

            fields.set('date').isFilled(lang.fillDate).date(lang.invalidDate);

            if(this.form.get('type') === this.types.globalBusinessParcel || this.form.get('type') === this.types.globalExpressParcel){

                fields.set('parcels_cnt').isFilled(lang.fillParcelsCnt).isInt(lang.invalidParcelsCnt).isGreater(1, lang.greater_parcels_cnt).isLess(25, lang.is_less_parcels_cnt);
            }

            else {
		if(this.form.get('parcelshop')) {
                	fields.set('parcels_cnt').isFilled(lang.fillParcelsCnt).isInt(lang.invalidParcelsCnt).isGreater(1, lang.greater_parcels_cnt).isLess(1, lang.shop_parcels_cnt);
		} else {
	               fields.set('parcels_cnt').isFilled(lang.fillParcelsCnt).isInt(lang.invalidParcelsCnt).isGreater(1, lang.greater_parcels_cnt);
		}
            }
            if(this.form.get('parcelshop')) {
		    if(!this.form.get('parcelshop_id')){
			        fields.set('parcelshop_id').isFilled(lang.selectShop);


	            }
            }


            if(this.form.get('fill_measurements') !== true){

                let maxWeight = 35;
                let message = lang.invalid_weight_distribution_parcel;
		if(this.form.get('parcelshop')) {
			maxWeight = 20;
                	let message = lang.invalid_weight_shop;
		}

                fields.set('total_weight').isFilled(lang.fillTotalWeight).isDecimal(lang.invalidTotalWeight).weightDistribution(this.form.get('parcels_cnt'), maxWeight, message);
            }

            if(this.form.get('preadvice') === true){

                fields.set('preadvice_email').isFilled(lang.fillPreadviceEmail).isMultiEmail(lang.invalidPreadviceEmail);
            }

            return {fields: fields.result()};
        }

        else if(page === 'ParcelMeasurements'){

            const lang = this.stores.ui.lang.shipmentMeasurements;
            const multi = [];

            // If fill measurements is on validate them.

            if(this.form.get('fill_measurements') === true){

                let rows = this.form.get('parcels');

                if(rows === undefined || rows.length === 0){

                    rows = [new Map([
                        
                        ['amount', undefined],
                        ['content', undefined],
                        ['weight', undefined],
                        ['width', undefined],
                        ['height', undefined],
                        ['depth', undefined]
                    ])];
                }

                rows.forEach((data) => {
               
                    const parcels = new Validate(data);

                    parcels.sizeOk = function(parcels, rules, message){

                        let error = false;

                        if(parcels !== undefined && rules !== undefined){

                            let maxTotal = rules.maxTotal;
                            let maxBiggest = rules.maxBiggest;
                            let maxSecondly = rules.maxSecondly;
                            let maxHeight = rules.maxHeight;
                            let maxOther = rules.maxOther;

                            parcels.forEach(v => {

                                if(error === false){

                                    const length = parseInt(v.get('length'), 10);
                                    const height = parseInt(v.get('height'), 10);
                                    const width = parseInt(v.get('width'), 10);

                                    if( ! isNaN(length) && ! isNaN(height) && ! isNaN(width)){
                                    
                                        const measurements = [length, width, height].sort((a, b) => a - b);
                                        const biggest = Math.max(...measurements);
                                        const median = measurements[1];
                                        const smallest = Math.min(...measurements);
                                        const total = biggest + (2 * median) + (2 * smallest);

                                        if(maxTotal !== undefined && total > maxTotal){

                                            error = true;
                                        }

                                        if(maxBiggest !== undefined && biggest > maxBiggest){

                                            error = true;
                                        }

                                        if(maxSecondly !== undefined && median > maxSecondly){

                                            error = true;
                                        }

                                        if(maxHeight !== undefined && height > maxHeight){

                                            error = true;
                                        }

                                        if(maxOther !== undefined){

                                            maxOther = maxOther.sort((a, b) => a - b);
                                            let other = [length, width].sort((a, b) => a - b);

                                            other.forEach((v, k) => {
                                            
                                                if(other[k] > maxOther[k]){

                                                    error = true;
                                                }
                                            });
                                        }
                                    }
                                }
                            });

                            if(error === true){

                                this.error('sizeOk', message);
                            }
                        }

                        return this;
                    }

                    parcels.set('amount').isFilled(lang.fillAmount).isInt(lang.invalidAmount);
                    
                    if(this.form.get('type') !== this.types.euroBusinessParcel){
                    
                        parcels.set('content').isFilled(lang.fillContent);
                    }

                    let rules;

                    if(this.form.get('type') === this.types.euroBusinessParcel){

                        if(this.form.get('parcelshop')) {
				parcels.set('weight').isFilled(lang.fillWeight).isDecimal(lang.invalidWeight).isLess(20, lang.invalid_weight_shop);
			} else {
				parcels.set('weight').isFilled(lang.fillWeight).isDecimal(lang.invalidWeight).isLess(35, lang.too_much_weight);
			}
                        rules = {maxBiggest: 200, maxSecondly: 80, maxTotal: 300};
                    }
                   
                    else if(this.form.get('type') === this.types.euroBusinessFreight){

                        parcels.set('weight').isFilled(lang.fillWeight).isDecimal(lang.invalidWeight).isLess(1500, lang.too_much_weight);
                        rules = {maxHeight: 240, maxOther: [240, 180]};
                    }

                    else if(this.form.get('type') === this.types.globalBusinessParcel || this.form.get('type') === this.types.globalExpressParcel){

                        parcels.set('weight').isFilled(lang.fillWeight).isDecimal(lang.invalidWeight).isLess(30, lang.too_much_weight);
                        rules = {maxBiggest: 270, maxTotal: 300};
                    }

                    // Use dimension rules only if one of the dimension is set.

                    let fill_measurements = false;

                    rows.forEach(v => {
                    
                        if(
                        
                            (v.get('width') !== undefined && v.get('width') !== '') ||
                            (v.get('height') !== undefined && v.get('height') !== '') ||
                            (v.get('length') !== undefined && v.get('length') !== '')
                        ){

                            fill_measurements = true;
                        }
                    });

                    if(this.form.get('type') !== this.types.euroBusinessParcel || fill_measurements === true){

                        parcels.set('width').isFilled(lang.fillWidth).isInt(lang.invalidWidth).sizeOk(this.form.get('parcels'), rules, lang.invalid_size);
                        parcels.set('height').isFilled(lang.fillHeight).isInt(lang.invalidHeight).sizeOk(this.form.get('parcels'), rules, lang.invalid_size);
                        parcels.set('length').isFilled(lang.fillLength).isInt(lang.invalidLength).sizeOk(this.form.get('parcels'), rules, lang.invalid_size);
                    }

                    multi.push(parcels.result());
                });
            }

            if(this.form.get('fill_measurements') === true){

                fields.set('counted_amount').equalTo(this.form.get('parcels_cnt'), lang.countedAmountMatch);

                //if(this.form.get('type') === this.types.globalBusinessParcel){
                        
                    //fields.set('counted_weight').isLess(70, lang.invalid_total_weight);
                    //fields.set('counted_volume').isLess(0.35, lang.invalid_volume);
                //}
            }

            if(this.require.ontoploading === true){

                fields.set('ontoploading').isFilled(lang.fillOntoploading);
            }

            if(mod === 'normal' && this.require.tariff === true){

                fields.isDocumentAllowed = function(type, document, weight, message){

                    weight = parseFloat(weight);

                    if(document === 'DOCUMENT' && (type === '10010' || type === '10015') && weight > 5){
                    
                        this.error('isDocumentAllowed', message);
                    }

                    return this;
                }

                fields.set('customs_documenttype').isFilled(lang.fillCustomsDoctype).isDocumentAllowed(this.form.get('type'), this.form.get('customs_documenttype'), this.form.get('total_weight'), lang.documenttype_not_allowed);

                if(this.form.get('customs_documenttype') === 'FILE' || this.form.get('customs_documenttype') === 'LATER' || this.form.get('customs_documenttype') === 'COMBINE'){

                    fields.set('deliveryterms').isFilled(lang.fillDeliveryterms);

                    if(this.form.get('skip_rows') !== true){

                        fields.set('invoice_number_text').isFilled(lang.invoice_number_is_filled);
                        fields.set('invoice_date').isFilled(lang.invoice_date_is_filled).date(lang.invoice_date_is_date);
                        fields.set('customs_value').isFilled(lang.fillCustomsValue);
                        fields.set('customs_currency').isFilled(lang.fillCustomsCurrency);
                    }
                }

                if(this.form.get('customs_documenttype') === 'FILE'){

                    if(this.form.get('invoices_files_id') === null || (this.form.get('file') !== undefined && this.form.get('invoices_files_id') !== null)){

                        fields.set('file').fileOfType(['pdf', 'xml'], lang.invalidFileType).maxFileSize(10.5, lang.invalidFileSize);
                    }
                }
            }

            // Validate customs information if customs documenttype is file (PDF)

            const multiCustoms = [];

            if(this.form.get('customs_documenttype') === 'FILE' && this.form.get('skip_rows') !== true){

                const rows = this.form.get('invoice_contents');

                rows.forEach(data => {

                    const validator = new Validate(data);

                    validator.isValidCNcode = message => {

                        let found = false;

                        this.stores.cncodes.data.forEach(v => {

                            if(v.cn === validator.value.substring(0, 8)){

                                found = true;
                            }
                        });

                        if(found === false){

                            validator.error('isValidCNcode', message);
                        }

                        return this;
                    }

                    validator.invalidOverallWeight = message => {

                        const totalWeight = round(this.form.get('total_weight'), 2);

                        let customsTotalWeight = 0;

                        rows.forEach(row => {

                            const customsWeight = round(row.get('netweight'), 2);

                            if(isNaN(customsWeight) === false){
                                
                                customsTotalWeight = round(customsWeight + customsTotalWeight, 2);
                            }
                        });

                        if(customsTotalWeight > totalWeight){

                            validator.error('invalidOverallWeight', message);
                        }

                        return this;
                    }

                    validator.set('tariff_name').isFilled(lang.tariff_name_is_filled).isNumeric(lang.tariff_name_is_numeric).isValidCNcode(lang.tariff_name_is_valid_cncode);

                    if(data.get('unit') !== '' && data.get('unit') !== 'ZZZ'){

                        validator.set('supplementalqty').isFilled(lang.supplementalqty_is_filled).isDecimal(lang.supplementalqty_is_decimal);
                    }

                    // validator.set('netweight').isFilled(lang.netweight_is_filled).isDecimal(lang.netweight_is_decimal).invalidOverallWeight(lang.netweight_invalid_overall_weight);
                    validator.set('netweight').isFilled(lang.netweight_is_filled).isDecimal(lang.netweight_is_decimal);
                    validator.set('description').isFilled(lang.description_is_filled);
                    validator.set('unit_price').isDecimal(lang.unit_price_is_decimal);
                    validator.set('total_price').isFilled(lang.total_price_is_filled).isDecimal(lang.total_price_is_decimal);
                    validator.set('countries_id').isFilled(lang.country_of_origin_is_filled);

                    multiCustoms.push(validator.result());
                });
            }

            return {
            
                fields: fields.result(),
                multiFields: multi,
                multiCustoms: multiCustoms
            }
        }

        else if(page === 'ProForma'){

            const lang = this.stores.ui.lang.shipmentProforma;

            if(this.form.get('different_invoice_address') === true){

                fields.set('invoice_name').isFilled(lang.fillInvoiceName);
                // fields.set('invoice_name2').isFilled('Nimi on pakollinen');
                fields.set('invoice_address').isFilled(lang.fillInvoiceAddress);
                // fields.set('invoice_address2').isFilled('Osoite on pakollinen');
                fields.set('invoice_postalnumber').isFilled(lang.fillInvoicePostalnumber);
                fields.set('invoice_city').isFilled(lang.fillInvoiceCity);
                // fields.set('invoice_country').isFilled(lang.fillInvoiceCountry);
                // fields.set('invoice_contact').isFilled('Kontaktihenkilö on pakollinen');
                // fields.set('invoice_tel').isFilled('');
                // fields.set('invoice_mobile').isFilled('');
                // fields.set('invoice_email').isFilled('');
            }

            // fields.set('invoice_vatnumber').isFilled(lang.fillInvoiceVatnumber);
            fields.set('invoice_reason').isFilled(lang.fillInvoiceReason);

            const multi = [];

            let rows = this.form.get('invoice_contents');

            if(rows === undefined){

                rows = [new Map([
                    
                    ['amount', undefined],
                    ['unit', undefined],
                    ['tariff_name', undefined],
                    ['description', undefined],
                    ['unit_price', undefined],
                    ['total_price', undefined]
                ])];
            }

            rows.forEach((data) => {
                
                const content = new Validate(data);

                content.isValidCNcode = message => {

                    let found = false;

                    this.stores.cncodes.data.forEach(v => {

                        if(v.cn === content.value.substring(0, 8)){

                            found = true;
                        }
                    });

                    if(found === false){

                        content.error('isValidCNcode', message);
                    }

                    return this;
                }

                content.invalidOverallWeight = message => {

                    const totalWeight = round(this.form.get('total_weight'), 2);

                    let customsTotalWeight = 0;

                    rows.forEach(row => {

                        const customsWeight = round(row.get('netweight'), 2);

                        if(isNaN(customsWeight) === false){
                            
                            customsTotalWeight = round(customsWeight + customsTotalWeight, 2);
                        }
                    });

                    if(customsTotalWeight > totalWeight){

                        content.error('invalidOverallWeight', message);
                    }

                    return this;
                }

                content.set('tariff_name').isFilled(lang.tariff_name_is_filled).isNumeric(lang.invalidTariffName).isValidCNcode(lang.invalid_cncode);

                if(data.get('unit') !== '' && data.get('unit') !== 'ZZZ'){

                    content.set('supplementalqty').isFilled(lang.supplementalqty_is_filled).isDecimal(lang.supplementalqty_is_decimal);
                }

                // content.set('netweight').isFilled(lang.fillNetweight).isDecimal(lang.invalidNetweight).invalidOverallWeight(lang.invalidOverallWeight);
                content.set('netweight').isFilled(lang.fillNetweight).isDecimal(lang.invalidNetweight);
                content.set('description').isFilled(lang.fillDescription);
                content.set('unit_price').isDecimal(lang.invalidUnitPrice);
                content.set('total_price').isFilled(lang.fillTotalPrice).isDecimal(lang.invalidTotalPrice);
                content.set('countries_id').isFilled(lang.fillCountryOfOrigin);

                multi.push(content.result());
            });

            fields.set('deliveryterms').isFilled(lang.fillDeliveryterms);
            fields.set('customs_value').isFilled(lang.fillCustomsValue);
            fields.set('customs_currency').isFilled(lang.fillCustomsCurrency);

            return {
            
                fields: fields.result(),
                multiFields: multi
            }
        }
    }

    messages = (results) => {

        return Validate.parseMessages(results);
    }

    errorCount = (results) => {

        return Validate.size(results);
    }

    formToData = () => {
    
        const data = this.convertMap(this.form);

        ['parcels', 'invoice_contents'].forEach(v => {
        
            if(data[v] !== undefined){
            
                data[v] = JSON.stringify(data[v].map(v => this.convertMap(v)));
            }           
        });

        return data;
    }

    printParcelcards = (shipments, callback) => {

        const settings = this.stores.settings.object;

        const type = settings.printing_type;

        if(type !== undefined && type !== ''){

            this.stores.auth.temporary().then(r => {

                const footer = settings.print_footer === undefined || settings.print_footer === '' || settings.print_footer === 'false' ? false : true;
                const tab = window.open();

                if(tab !== null){

                    const url = api.endpoints.user.shipmentsPDF.replace('{shipments}', shipments.join(',')).replace('{jwt}', encodeURIComponent(r.data.jwt)).replace('{type}', type).replace('{footer}', footer);

                    tab.location.replace(this.stores.api.prepareUrl(url));

                    if(callback){

                        callback();
                    }
                }
            });
        }
    }

    printProForma = id => {

        const tab = window.open();

        this.stores.auth.temporary().then(r => {

            const url = api.endpoints.user.shipmentsProforma.replace('{shipments}', id).replace('{jwt}', encodeURIComponent(r.data.jwt));

            tab.location.replace(this.stores.api.prepareUrl(url));
        });
    }

    // Add or update if new or old.

    addUpdate = async (id, data) => {

        if(id === undefined){

            return await this.post(data, {url: api.endpoints.user.shipments});
        }

        else {

            return await this.put(data, {url: api.endpoints.user.shipments+id});
        }
    }

    add = (id, callback) => {

        // Slit parcels by amount.

        const parcels = this.form.get('parcels');

        if(parcels !== undefined && parcels[0] !== undefined){

            const splittedParcels = [];
        
            parcels.forEach((v, k) => {

                const item = new Map(v); // Pass as new map otherwise items reference to same item!
                const amount = item.get('amount');
                item.set('amount', 1);
                item.delete('parcelnumber'); // Remove old parcelnumber (STEP ONE)
 
                for(let i = 0; i < amount; i++){

                    splittedParcels.push(item);
                }
            });

            this.form.set('parcels', splittedParcels);
            this.form.set('parcels_cnt', splittedParcels.length.toString());
        }

        // If parcels are not filled by user create them.

        else if(this.form.get('fill_measurements') === undefined) {

            this.makeParcels(this.form.get('parcels_cnt'), this.form.get('total_weight'));
        }

        // If there are already parcelNumbers we use them if there is enough numbers.

        if(this.parcelNumbers.length !== 0){

            const parcels = this.form.get('parcels');

            if(this.parcelNumbers.length >= parcels.length){

                parcels.forEach((v, k) => {
 
                    v.set('parcelnumber', this.parcelNumbers[k]); // Set new parcelnumber (STEP TWO)
                });
            }

            this.form.set('parcels', parcels);
        }

        // If ProForma doesn't have different invoice address use values from consignee.

        if(this.form.get('customs_documenttype') === 'PROFORMA' && this.form.get('different_invoice_address') === undefined){
        
            this.copyFromTo('consignee_name1', 'invoice_name');
            this.copyFromTo('consignee_name2', 'invoice_name2');
            this.copyFromTo('consignee_address1', 'invoice_address');
            this.copyFromTo('consignee_address2', 'invoice_address2');
            this.copyFromTo('consignee_postalnumber', 'invoice_postalnumber');
            this.copyFromTo('consignee_city', 'invoice_city');
            this.copyFromTo('consignee_contact', 'invoice_contact');
            this.copyFromTo('consignee_tel', 'invoice_tel');
            this.copyFromTo('consignee_mobile', 'invoice_mobile');
            this.copyFromTo('consignee_email', 'invoice_email');
        }

        const data = this.formToData();

        /*
        
        If sending a file or combine with another shipments file unset documenttype
        and let api to set it to file after succesfull upload or when reuploading.

        */

        if(
            (data.customs_documenttype === 'FILE' || data.customs_documenttype === 'COMBINE') &&
            (data.invoice_files_id === null || data.invoice_files_id === undefined || data.invoice_files_id === "")
        ){

            delete data.customs_documenttype;
        }

        data.status  = 'READY';

        // First add parcel.

        this.stores.ui.resolve(this.addUpdate(id, data), () => this.add(id, callback), r => {

            // After parcel is added try to save consignee.

            this.stores.ui.resolve(this.saveConsignee(), this.saveConsignee,
            
                resolved => {
            
                    callback(r, true);
                },

                rejected => {

                    // Ignore if user is not added.

                    callback(r, false);
                }
            );
        });
    }

    saveConsignee = () => {

        const promises = [];

        if(this.form.get('save_consignee') === true || this.form.get('edit_consignee') === true){

            const consignee = this.stores.consignees.data.get(this.form.get('selected_consignee'));

            this.stores.consignees.form.clear();
            this.stores.consignees.form.set('name1', this.form.get('consignee_name1'));
            this.stores.consignees.form.set('name2', this.form.get('consignee_name2'));
            this.stores.consignees.form.set('address1', this.form.get('consignee_address1'));
            this.stores.consignees.form.set('address2', this.form.get('consignee_address2'));
            this.stores.consignees.form.set('postalnumber', this.form.get('consignee_postalnumber'));
            this.stores.consignees.form.set('city', this.form.get('consignee_city'));
            this.stores.consignees.form.set('countries_id', this.form.get('consignee_countries_id'));
            this.stores.consignees.form.set('email', this.form.get('consignee_email'));
            this.stores.consignees.form.set('contact', this.form.get('consignee_contact'));
            this.stores.consignees.form.set('tel', this.form.get('consignee_tel'));
            this.stores.consignees.form.set('mobile', this.form.get('consignee_mobile'));
            this.stores.consignees.form.set('recipienttype', this.form.get('consignee_recipienttype'));

            if(consignee !== undefined){

                this.stores.consignees.form.set('businessid', consignee.businessid);
                this.stores.consignees.form.set('description', consignee.description);
            }

            if(this.form.get('consignee_search') !== undefined){

                this.stores.consignees.form.set('search', this.form.get('consignee_search')); // Overwrite if set.
            }

            if(this.form.get('save_consignee') === true){

                promises.push(this.stores.consignees.add()); // Add
            }

            else {

                if(consignee !== undefined){

                    this.stores.consignees.form.set('search', consignee.search);
                }

                promises.push(this.stores.consignees.update(consignee.id)); // Update
            }
        }

        return promises;
    }

    copyFromTo = (from, to) => {
   
        this.form.set(to, this.form.get(from));
    }

    makeParcels = (amount, total_weight) => {

        amount = parseInt(amount, 10);
        const weight = total_weight / amount;
        const parcels = [];

        for(let i = 0; i < amount; i++){

            parcels.push(new Map([
            
                ['amount', 1],
                ['content', ''],
                ['weight', weight],
                ['width', ''],
                ['height', ''],
                ['depth', '']
            ]));
        }

        this.form.set('parcels', parcels);
    }

    remove = async (id) => {

        await this.delete({}, {
        
            url: api.endpoints.user.shipments + id
        });
    }

    saveDraft = async (id) => {

        const data = this.formToData();

        data['status'] = 'DRAFT';

        const metadata = {};

        if(data.save_consignee !== undefined){

            metadata.save_consignee = data.save_consignee;
        }

        if(data.preadvice !== undefined){

            metadata.preadvice = data.preadvice;
        }

        if(data.fill_measurements !== undefined){

            metadata.fill_measurements = data.fill_measurements;
        }

        if(data.edit_consignee !== undefined){

            metadata.edit_consignee = data.edit_consignee
        }

        if(data.selected_consignee !== undefined){

            metadata.selected_consignee = data.selected_consignee;
        }

        if(data.consignee_search !== undefined){

            metadata.consignee_search = data.consignee_search;
        }

        data['metadata'] = JSON.stringify(metadata);

        return this.addUpdate(id, data);
    }

    @observable fetched = [];

    fetchAll = async () => {

        const year = moment().format('YYYY');
        const month = moment().format('M');

        const settings = {
        
            url: api.endpoints.user.shipments + '?year=' + year + '&month=' + month
        };

        let r = await this.get({}, settings);

        this.fetched.push({
        
            year: year,
            month: month
        });

        return r;
    }

    fetchByMonth = async (year, month, mode = null) => {

        const settings = {
	    url: api.endpoints.user.shipments + '?year=' + year + '&month=' + month + (mode !== null ? '&mode=' + mode : ''),
            append: true
        };

        let r = await this.get({}, settings);

        this.fetched.push({
        
            year: year,
            month: month
        });

        return r;
    }

    load = async () => {

        return await this.fetchAll();
    }

    fetchSingle = async params => {

        return this.get({}, {
        
            url: api.endpoints.user.shipments +  params.id
        });
    }

    // This is executed from App.js on reload

    repopulate(id){

        const handlers = {
            
            date: 'unixToDate',
            invoice_date: 'unixToDate',
            parcels: 'jsonToMultifields',
            invoice_contents: 'jsonToMultifields'
        }

        const data = this.data.get(id);

        // After parcel is saved to API all parcels have amount of one after splitting.

        data.parcels.forEach((v, k) => {
       
            v.amount = 1;
        });

        // Save original parcel numbers returned from API for later use.

        const parcels = data.parcels;

        const parcelNumbers = [];

        if(parcels.length !== 0){

            if(parcels[0] !== undefined){
            
                parcels.forEach((v, k) => {

                    if(v.parcelnumber !== undefined){
                
                        parcelNumbers.push(v.parcelnumber);
                    }
                });
            }
        }

        // If ProForma is selected it means it has been attached.

        if(data.customs_documenttype === 'PROFORMA'){
        
            data.attachment = true;
        }

        this.parcelNumbers = parcelNumbers;
        this.form = Validate.repopulate(data, handlers);

        // Repopulate metadata

        if(data.metadata !== undefined){

            for(var k in data.metadata){

                this.form.set(k, data.metadata[k]);
            }
        }
    }

    @computed get require(){

        const required = {};

        if(this.form.get('consignee_countries_id') !== undefined){

            const country = this.stores.countries.findCountry('id', this.form.get('consignee_countries_id')); 
            const postalcode = this.form.get('consignee_postalnumber') === undefined ? '' :this.form.get('consignee_postalnumber');
            const isEuNegative = (country.iseunegate === '' || postalcode.match(new RegExp(country.iseunegate, 'i')) === null) ? false : true;

            let iseu;

            if(isEuNegative === true){

                iseu = country.iseu === true ? false : true;
            }

            else {

                iseu = country.iseu;
            }
 
            required.tariff = iseu === true ? false : true;
        }

        if(this.form.get('type') !== undefined){

            required.ontoploading  = this.form.get('type') === this.types.euroBusinessFreight ? true : false;
        }

        return required;
    }

    loadParcelSend = callback => {

         const promises = [
        
            this.stores.senders.fetchAll(),
            this.stores.consignees.fetchAll(),
            this.stores.settings.load(),
            this.stores.pickuppoints.load()
        ];

        this.stores.ui.resolve(promises, () => this.openParcelSend(callback),
            
            () => {

                this.stores.parcel.form.clear();
                this.stores.parcel.parcelNumbers = [];
                callback();
            }
        );
    }

    patchInvoice = async (from, to) => {

        const data = {
        
            source_shipments_id: from
        };
         
        return await this.patch(data, {url: api.endpoints.user.shipmentsInvoice.replace('{shipmentID}', to)});
    }

   IsAtLeastOneContactInfoFilled = () => {

        let result = false;
        
        const fields = ['consignee_tel', 'consignee_mobile', 'consignee_email'];

        fields.forEach(field => {

            if(this.form.get(field) !== undefined && this.form.get(field) !== ''){

                result = true;
            }
        });

        return result;
    }
}

export default ParcelStore;
