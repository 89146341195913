import ApiStore from 'stores/ApiStore';
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import Form from 'FormGLS';
import currencies from 'currencies.json';
import {sortObjectByKey} from 'Helpers';
import constants from 'constants.js';
import api from 'api.json';
import moment from 'moment';

@observer class ImportStore extends ApiStore {

    @observable form = new Map();
    @observable validation = new Map();
    
    timeWarning = true;
    copyExportParcel = false;
    copyImportParcel = false;
    priceEstimationCopy = false;

    keys = {

        status: 'status',
        shipmenttype: 'type',
        service: 'service',
        from_name1: 'from_name1',
        from_name2: 'from_name2',
        from_address1: 'from_address1',
        from_address2: 'from_address2',
        from_postalnumber: 'from_postalnumber',
        from_city: 'from_city',
        from_countries_id: 'from_countries_id',
        from_contact: 'from_contact',
        from_tel: 'from_tel',
        from_mobile: 'from_mobile',
        from_email: 'from_email',
        from_preadvice: 'from_preadvice',
        from_preadvice_email: 'from_preadvice_email',
        from_recipienttype: 'from_recipienttype',
        to_name1: 'to_name1',
        to_name2: 'to_name2',
        to_address1: 'to_address1',
        to_address2: 'to_address2',
        to_postalnumber: 'to_postalnumber',
        to_city: 'to_city',
        to_countries_id: 'to_countries_id',
        to_contact: 'to_contact',
        to_tel: 'to_tel',
        to_mobile: 'to_mobile',
        to_email: 'to_email',
        to_recipienttype: 'to_recipienttype',
        reference: 'ref',
        info: 'description',
        contents: 'contents',
        date: 'date',
        parcels_cnt: 'parcels_cnt',
        total_weight: 'total_weight',
        addoninsurance: 'insurance',
        parcels: 'parcels',
        deliveryterms: 'deliveryterms',
        customs_value: 'customs_value',
        customs_currency: 'customs_currency',
        customs_documenttype: 'customs_documenttype',
        metadata: 'metadata',
        requester: 'senders_id',
        invoice_files_id: 'invoice_files_id',
        email: 'email',
        ontoploading: 'ontoploading',
        return_to: 'return_to'
    };

    validate(page){

        const form = new Form(this.form);

        form.isValidImportDate = function(message){

            this.values.forEach((value, index) => {

                let error = false;

                const current = moment().format('DD.MM.YYYY');

                // Date cannot be current day.

                if(value === current){

                    error = true;
                }

                // Date cannot be date from the past.

                const currentDayUnix = moment(moment().format('DD.MM.YYYY 00:00'), 'DD.MM.YYYY HH:mm').format('X');

                if(currentDayUnix > moment(value + ' 00:00', 'DD.MM.YYYY HH:mm').format('X')){

                    error = true;
                }

                // Date cannot be weekend.

                const day = moment(value, 'DD.MM.YYYY').day();

                if(day === 0 || day === 6){

                    error = true;
                }

                else {

                    // After 16 a clock order can't be next work day.

                    const currentHour = parseInt(moment().format('HH'), 10);

                    if(currentHour >= 16){

                        const nextDay = moment().add(1, 'days').format('DD.MM.YYYY');

                        if(nextDay === value){

                            error = true;
                        }
                    }
                }

                if(error === true){

                    this.setError(index, 'isValidImportDate', message);
                }
            });

            return this;
        }

        form.weightDistribution = function(amount, message){

            amount = parseInt(amount, 10);

            if(amount !== undefined && isNaN(amount) === false){

                this.values.forEach((value, index) => {

                    const distribution = parseFloat(value.replace(',', '.')) / amount;

                    if(isNaN(distribution) === false && distribution > 35){

                        this.setError(index, 'weightDistribution', message);
                    }
                });
            }

            return this;
        }

        form.pickuppointInFinland = function(pickuppoints, countries, message){

                this.values.forEach((pickuppointsID, index) => {

                    const pickuppoint = pickuppoints.data.get(pickuppointsID);

                    if(pickuppoint !== undefined){
                    
                        const country = countries.codeById(pickuppoint.countries_id);

                        if(country !== 'FI'){

                            this.setError(index, 'pickuppointInFinland', message);
                        }
                    }
                });

            return this;
        }

        form.isServiceAvailable = function(service, countries, message){

            this.values.forEach((countries_id, index) => {

                const country = countries.findCountry('id', countries_id);

                if(country.psr !== 1){

                    this.setError(index, 'isServiceAvailable', message);
                }
            });

            return this;
        }

        form.isSearchNameMandatory = function(name, compare, consignees, message){

            this.values.forEach((value, index) => {

                if((value === undefined || value === '') && name !== undefined && name !== ''){

                    let found = false;

                    consignees.forEach(consignee => {

                        if(consignee.name1 === name){

                            found = true;
                        }
                    });

                    if(name === compare){

                        found = true;
                    }

                    if(found){

                        this.setError(index, 'isSearchNameMandatory', message);
                    }
                }
            })

            return this;
        }

        form.isSearchNameAvailable = function(consignees, compare, message){

            this.values.forEach((value, index) => {

                if(value !== undefined && value !== ''){

                    let found = false;

                    consignees.forEach(consignee => {

                        if(consignee.search === value){

                            found = true;
                        }
                    });

                    if(found){

                        this.setError(index, 'isSearchNameAvailable', message);
                    }
                }
            });

            return this;
        }

        form.isSearchNameUnique = function(compare, message){

            this.values.forEach((value, index) => {

                if(value !== undefined && value !== '' && value === compare){

                    this.setError(index, 'isSearchNameUnique', message);
                }
            });

            return this;
        }

        if(page === 'details'){

            const lang = this.stores.ui.lang.import;

            form.set('from_name1').isFilled(lang.fill_from_name1);
            form.set('from_address1').isFilled(lang.fill_from_address1);
            form.set('from_postalnumber');

            let isFromEU = false;

            if(form.get('from_countries_id') !== undefined){

                const country = this.stores.countries.findCountry('id', form.get('from_countries_id'));

                isFromEU = this.stores.countries.isEU(country, this.form.get('from_postalnumber'));

                if(typeof country.zipregex === 'string'){

                    form.set('from_postalnumber').regexp(country.zipregex, this.stores.countries.getZiperrortext(country, lang.invalid_from_postalnumber));
                }

                if(country.code === 'SE' && (form.get('service') === 'RETURN' || form.get('service') === 'SHIP')){

                    form.set('from_email').isFilled(lang.fill_from_email);
                }

                else if(isFromEU === false && this.IsAtLeastOneFromContactInfoFilled() === false){

                    form.set('from_email').isFilled(lang.fill_from_email);
                }
            }

            form.set('from_city').isFilled(lang.fill_from_city);

            let comparable_countries_id;

            if(form.get('service') === 'SHIP'){

                comparable_countries_id = this.form.get('to_countries_id');
            }

            else if( ! form.unfilled('return_to')){

                const pickuppoint = this.stores.pickuppoints.data.get(this.form.get('return_to'));
    
                comparable_countries_id = pickuppoint.countries_id;
            }

            const from_country_code = this.stores.countries.codeById(form.get('from_countries_id'));

            // From and to countries has to be different expect in Finland

            if(from_country_code !== 'FI'){
                
                form.set('from_countries_id').isFilled(lang.fill_from_country).isDifferent(comparable_countries_id, lang.same_to_country);

                /*

                if(form.get('service') === 'SHOP'){

                    form.set('from_countries_id').isFilled(lang.fill_from_country).isDifferent(comparable_countries_id, lang.same_to_country).isServiceAvailable('SHOP', this.stores.countries, lang.service_not_available);
                }

                else {

                    form.set('from_countries_id').isFilled(lang.fill_from_country).isDifferent(comparable_countries_id, lang.same_to_country);
                }

                */
            }

            if(form.get('service') !== 'SHOP'){

                form.set('from_contact').isFilled(lang.fill_from_contact);
            }

            form.set('from_tel').isTel(lang.invalid_from_tel);
            form.set('from_mobile').isMobile(lang.invalid_from_mobile);

            if(isFromEU === false && this.IsAtLeastOneFromContactInfoFilled() === false){

                form.set('from_tel').isFilled(lang.fill_from_tel);
                form.set('from_mobile').isFilled(lang.fill_from_mobile);
            }

            else if(form.get('service') !== 'SHOP'){

                if(form.unfilled('from_mobile')){

                    form.set('from_tel').isFilled(lang.fill_from_tel);
                }

                if(form.unfilled('from_tel')){

                    form.set('from_mobile').isFilled(lang.fill_from_mobile);
                }
            }

            form.set('from_email').isEmail(lang.invalid_from_email);

            if(form.get('from_save_recipient') === true){

                form.set('from_search')
                .isSearchNameMandatory(this.form.get('from_name1'), this.form.get('to_name1'), this.stores.consignees.data, lang.from_search_name_is_mandatory)
                .isSearchNameAvailable(this.stores.consignees.data, lang.from_search_name_not_available)
                .isSearchNameUnique(this.form.get('to_search'), lang.from_search_name_is_not_unique);
            }

            const fromCountryCode = this.stores.countries.codeById(this.form.get('from_countries_id'));

            if(this.form.get('shipmenttype') === constants.typeCodes.euroBusinessFreight.toString() || (fromCountryCode !== false && fromCountryCode === 'FI')){

                form.set('from_recipienttype').isFilled(lang.is_filled_from_recipienttype);
            }

            if(form.get('service') === 'SHIP'){

                form.set('to_name1').isFilled(lang.fill_to_name1);
                form.set('to_address1').isFilled(lang.fill_to_address1);
                form.set('to_postalnumber');

                if(form.get('to_countries_id') !== undefined){

                    const country = this.stores.countries.findCountry('id', form.get('to_countries_id'));

                    if(typeof country.zipregex === 'string'){

                        form.set('to_postalnumber').regexp(country.zipregex, this.stores.countries.getZiperrortext(country, lang.invalid_to_postalnumber));
                    }
                }

                form.set('to_city').isFilled(lang.fill_to_city);
                form.set('to_countries_id').isFilled(lang.fill_to_country);
                form.set('to_contact').isFilled(lang.fill_to_contact);
                form.set('to_tel').isTel(lang.invalid_to_tel);
                form.set('to_mobile').isMobile(lang.invalid_to_mobile);

                if(form.unfilled('to_mobile')){

                    form.set('to_tel').isFilled(lang.fill_to_tel);
                }

                if(form.unfilled('to_tel')){

                    form.set('to_mobile').isFilled(lang.fill_to_mobile);
                }

                form.set('to_email').isEmail(lang.invalid_to_email);

                if(form.get('to_save_recipient') === true){

                    form.set('to_search')
                        .isSearchNameMandatory(this.form.get('to_name1'), this.form.get('from_name1'), this.stores.consignees.data, lang.to_search_name_is_mandatory)
                        .isSearchNameAvailable(this.stores.consignees.data, lang.to_search_name_not_available)
                        .isSearchNameUnique(this.form.get('from_search'), lang.to_search_name_is_not_unique);
                }

                const toCountryCode = this.stores.countries.codeById(this.form.get('to_countries_id'));

                if(this.form.get('shipmenttype') === constants.typeCodes.euroBusinessFreight.toString() || (toCountryCode !== false && toCountryCode === 'FI')){

                    form.set('to_recipienttype').isFilled(lang.is_filled_to_recipienttype);
                }
            }

            else {

                form.set('return_to').isFilled(lang.fill_return_to).pickuppointInFinland(this.stores.pickuppoints, this.stores.countries, lang.is_finland_return_to);
            }

            form.set('shipmenttype').isFilled(lang.fill_shipmenttype);

            if(form.get('shipmenttype') !== constants.typeCodes.euroBusinessParcel.toString()){

                form.set('contents').isFilled(lang.fill_contents);
            }

            if(form.get('service') !== 'SHOP'){

                form.set('date').isFilled(lang.fill_date).isDate(lang.invalid_date).isValidImportDate(lang.invalidImportDate);
            }

            form.set('parcels_cnt').isFilled(lang.fill_pieces).isInt(lang.invalid_pieces).isGreater(0, lang.nozero_parcels_cnt);

            if(form.get('fill_measurements') !== true){

                if(form.get('service') === 'SHOP'){

                    form.set('total_weight')
                        .isFilled(lang.fill_total_weight)
                        .isDecimal(lang.invalid_total_weight)
                        .isGreater(0, lang.nozero_total_weight)
                        .isLess(20, lang.max_total_weight)
                        .weightDistribution(this.form.get('parcels_cnt'), lang.weightDistributionError);
                }

                else {

                form.set('total_weight')
                    .isFilled(lang.fill_total_weight)
                    .isDecimal(lang.invalid_total_weight)
                    .isGreater(0, lang.nozero_total_weight)
                    .weightDistribution(this.form.get('parcels_cnt'), lang.weightDistributionError);
                }
            }

            if(form.get('from_preadvice') === true){

                form.set('from_preadvice_email').isFilled(lang.fill_from_preadvice_email).isEmail(lang.invalid_from_preadvice_email);
            }
        }

        else if(page === 'measurements'){

            const lang = this.stores.ui.lang.importMeasurements;

            if(this.form.get('fill_measurements') === true){

                form.set('parcels', 'amount').isFilled(lang.fill_amount).isInt(lang.invalid_amount);

                if(this.form.get('shipmenttype') !== constants.typeCodes.euroBusinessParcel.toString()){
                
                    form.set('parcels', 'content').isFilled(lang.fill_content);
                }
                
                form.set('parcels', 'weight')
                    .isFilled(lang.fill_weight)
                    .isDecimal(lang.invalid_weight)
                    .isGreater(0, lang.nozero_weight)

                let rules;

                if(parseInt(form.get('shipmenttype'), 10) === constants.typeCodes.euroBusinessParcel){

                    form.set('parcels', 'weight').isLess(35, lang.too_much_weight);
                    rules = {maxBiggest: 200, maxSecondly: 80, maxTotal: 300};
                }
               
                else if(parseInt(form.get('shipmenttype'), 10) === constants.typeCodes.euroBusinessFreight){

                    form.set('parcels', 'weight').isLess(1500, lang.too_much_weight);
                    rules = {maxHeight: 240, maxOther: [180, 240]};
                }

                form.set('parcels', 'length')
                    .isInt(lang.invalid_length)
                    .isGreater(0, lang.nozero_length)
                    .sizeOk(this.form.get('parcels'), rules, lang.invalid_size);

                form.set('parcels', 'width')
                    .isInt(lang.invalid_width)
                    .isGreater(0, lang.nozero_width)
                    .sizeOk(this.form.get('parcels'), rules, lang.invalid_size);

                form.set('parcels', 'height')
                    .isInt(lang.invalid_height)
                    .isGreater(0, lang.nozero_height)
                    .sizeOk(this.form.get('parcels'), rules, lang.invalid_size);

                if(this.form.get('shipmenttype') !== constants.typeCodes.euroBusinessParcel.toString() || this.fillAllMeasurements(form.get('parcels')) === true){

                    form.set('parcels', 'length').isFilled(lang.fill_length);
                    form.set('parcels', 'width').isFilled(lang.fill_width);
                    form.set('parcels', 'height').isFilled(lang.fill_height);
                }

                form.set('counted_amount').equalTo(this.form.get('parcels_cnt'), lang.match_counted_amount);
            }

            if(this.form.get('shipmenttype') === constants.typeCodes.euroBusinessFreight.toString()){
            
                form.set('ontoploading').isFilled(lang.fill_on_top_loading);
            }

            if(this.requiresTariff === true){

                form.set('customs_documenttype').isFilled(lang.fill_documenttype);

                if(this.form.get('customs_documenttype') === 'FILE' || this.form.get('customs_documenttype') === 'LATER'){

                    form.set('deliveryterms').isFilled(lang.fill_deliveryterms);
                    form.set('customs_value').isFilled(lang.fill_customs_value).isDecimal(lang.invalid_customs_value).isGreater(0, lang.nozero_customs_value);
                    form.set('customs_currency').isFilled(lang.fill_customs_currency);
                    form.set('file').isFile(['pdf'], lang.invalid_file);
                }
            }
        }

        this.validation = form.result();

        if(this.validation.size !== 0){
        
            return false;
        }

        return true;
    }

    // If some of the measurements is filled all have to be filled.

    fillAllMeasurements = (parcels) => {

        let fill = false;

        if(parcels !== undefined){

            parcels.forEach(v => {
            
                if(
                
                    (v.get('width') !== undefined && v.get('width') !== '') ||
                    (v.get('height') !== undefined && v.get('height') !== '') ||
                    (v.get('length') !== undefined && v.get('length') !== '')
                ){

                    fill = true;
                }
            });
        }

        return fill;
    }

    get messages(){

        const messages = Form.parseMessages(this.validation);
        const uniqueMessages = [...new Set(messages)];

        return uniqueMessages;
    }

    get currencies(){

        const lang = this.stores.ui.lang.importMeasurements;
        const options = [{value: '', option: '- ' + lang.choose_currency}];
        const sorted = sortObjectByKey(currencies);
    
        for(let k in sorted){

            options.push({value: k, option: sorted[k]});
        }

        return options;
    }

    get terms(){

        const lang = this.stores.ui.lang.importMeasurements;

        return [

            {value: '', option: '- ' + lang.choose_delivery_terms},
            {value: 'INCO60', option: lang.inco60}
        ];
    }

    get requiresTariff(){

        if(this.form.get('service') === 'SHIP' || this.form.get('service') === 'RETURN'){

            let fromCountry;
            let toCountry;
            let toPostalnumber;

            const from_countries_id = this.form.get('from_countries_id');

            if(from_countries_id !== undefined && from_countries_id !== ''){

                fromCountry = this.stores.countries.findCountry('id', from_countries_id);
            }

            if(this.form.get('service') === 'SHIP'){

                const to_countries_id = this.form.get('to_countries_id');

                if(to_countries_id !== undefined && to_countries_id !== ''){

                    toCountry = this.stores.countries.findCountry('id', to_countries_id);
                }

                toPostalnumber = this.form.get('to_postalnumber');
            }

            else {

                const pickuppoint_id = this.form.get('return_to');
            
                if(pickuppoint_id !== undefined && pickuppoint_id !== ''){

                    const pickuppoint = this.stores.pickuppoints.data.get(pickuppoint_id);
                    toCountry = this.stores.countries.findCountry('id', pickuppoint.countries_id);  
                    toPostalnumber = pickuppoint.postalnumber;
                }
            }

            if(fromCountry === undefined && toCountry === undefined){

                return false;
            }

            if(fromCountry !== undefined && this.isEu(fromCountry, this.form.get('from_postalnumber')) === false){

                return true;
            }

            if(toCountry !== undefined && this.isEu(toCountry, toPostalnumber) === false){

                return true;
            }
        }
    }

    isEu(country, postalnumber){

        if(postalnumber === undefined){

            return country.iseu;
        }

        const isEuNegative = (country.iseunegate === '' || postalnumber.match(new RegExp(country.iseunegate, 'i')) === null) ? false : true;

        let result;

        if(isEuNegative === true){

            result = country.iseu === true ? false : true;
        }

        else {

            result = country.iseu;
        }

        return result;
    }

    get parcels(){

        // When only total count and weight is set create parcels automaticly.

        if((this.form.get('fill_measurements') !== true)){

            return this.createParcels();
        }

        // When parcels are filled by hand they are splitted by amount.

        else {

            return this.splitParcels(this.form.get('parcels'));
        }
    }

    // Split parcels by amount if parcels are manually filled.

    splitParcels = (parcels) => {

        const splittedParcels = [];

        if(parcels === undefined){

            return splittedParcels;
        }
        
        else {
    
            parcels.forEach((v, k) => {

                const amount = v.get('amount') !== undefined && v.get('amount') !== '' ? v.get('amount') : 1;

                for(let i = 0; i < amount; i++){

                    const remap = {

                        parcelnumber: v.get('parcelnumber'),
                        content: v.get('content'),
                        weight: v.get('weight'),
                        width: v.get('width'),
                        height: v.get('height'),
                        length: v.get('length')
                    };

                    splittedParcels.push(remap);
                }
            });
        }

        return splittedParcels;
    }

    // Parcels are created automaticly if they are not filled manually.

    createParcels = () => {

        if(this.form.get('total_weight') !== undefined){

            this.form.set('total_weight', this.form.get('total_weight').toString().replace(',', '.')); 
        }

        const parcelsCnt = parseInt(this.form.get('parcels_cnt'), 10);
        const totalWeight = parseFloat(this.form.get('total_weight'), 10);

        if(isNaN(parcelsCnt) || isNaN(totalWeight)){

            return undefined;
        }

        const parcels = [];
        const weight = totalWeight / parcelsCnt;

        for(let i = 0; i < parcelsCnt; i++){

            parcels.push({
           
                content: this.form.get('contents'),
                weight: weight
            });
        }

        if(parcels.length === 0){

            return undefined;
        }

        return parcels;
    }

    save = async (status, page, id, callback) => {

        if(this.form.get('service') !== 'SHOP'){

            if(this.timeWarning === true && status === 'READY' && parseInt(moment().format('HH'), 10) >= 13){

                this.stores.ui.customConfirm = {

                    isOpen: true,
                    message: this.stores.ui.lang.importStore.time_warning,

                    cancelHandler: () => {
                    
                        this.timeWarning = true;
                        this.stores.ui.clearCustomConfirm()
                    },

                    okHandler: () => {
                    
                        this.timeWarning = false;
                        this.stores.ui.clearCustomConfirm()
                        this.save(status, page, id, callback);
                    },

                    cancelText: this.stores.ui.lang.importStore.cancelSave,
                    okText: this.stores.ui.lang.importStore.confirmSave
                }

                return false;
            }

            else {
            
                this.timeWarning = true;
            }
        }

        const remap = {};

        for(let ui in this.keys){

            const api = this.keys[ui];

            if(api !== undefined){
            
                remap[api] = this.form.get(ui);
            }
        }

        if(this.form.get('service') === 'RETURN' || this.form.get('service') === 'SHOP'){

            const pickuppoint = this.stores.pickuppoints.data.get(this.form.get('return_to'));

            if(pickuppoint !== undefined){

                remap.to_name1 = pickuppoint.name1;
                remap.to_name2 = pickuppoint.name2;
                remap.to_address1 = pickuppoint.address1;
                remap.to_address2 = pickuppoint.address2;
                remap.to_postalnumber = pickuppoint.postalnumber;
                remap.to_city = pickuppoint.city;
                remap.to_countries_id = pickuppoint.countries_id;
                remap.to_contact = this.stores.settings.all.name; // Use logged in name as fallback before we get it from pickuppoint endpoint.
                remap.to_tel = this.stores.settings.all.tel; // Same reason than above.
                remap.to_mobile = pickuppoint.mobile;
                remap.to_email = pickuppoint.email;
            }
        }

        remap.status = status; 
        remap.parcels = this.parcels;

        remap.metadata = {
            
            from_save_recipient: this.form.get('from_save_recipient'),
            from_search: this.form.get('from_search'),
            from_edit_recipient: this.form.get('from_edit_recipient'),
            to_save_recipient: this.form.get('to_save_recipient'),
            to_search: this.form.get('to_search'),
            to_edit_recipient: this.form.get('to_edit_recipient'),
            fill_measurements: this.form.get('fill_measurements'),
            selected_from_recipient: this.form.get('selected_from_recipient'),
            selected_to_recipient: this.form.get('selected_to_recipient'),
            return_to: this.form.get('return_to')
        }

        // Clean second page if saved from first page and it's not DRAFT.

        if(page === 'details' && status !== 'DRAFT'){

            ['ontoploading', 'customs_documenttype', 'file', 'deliveryterms', 'customs_value', 'customs_currency'].forEach(v => delete remap[v]);
        }

        // If customs_documenttype is FILE don't send it to API.

        if(remap.customs_documenttype === 'FILE' && this.form.get('file') !== undefined){

            if(status !== 'READY'){

                delete remap.customs_documenttype;
            }
        }

        if(id !== undefined){

            this.stores.ui.resolve(this.updateImport(remap, id), () => this.updateImport(remap, id), response => {
            
                this.saveConsignees(callback, remap, response);
            });
        }

        else {

            this.stores.ui.resolve(this.addImport(remap), () => this.addImport(remap), response => {
            
                this.saveConsignees(callback, remap, response);

            }, response => {

                // API ERROR (rejected)

                response.errors.forEach(error => {

                    if(error === 'ONLINE_USER_IMPORTS_UNABLE_TO_CONNECT_GLS_API'){

                        this.stores.ui.messages = [this.stores.ui.lang.import.unable_to_connect_gls_api];
                    }

                    else {

                        console.log('Error:', error);
                    }
                });
            });
        }
    }

    addImport = async data => {

        return await this.post(data, {url: api.endpoints.user.imports});
    }

    updateImport = async (data, id) => {

        return await this.put(data, {url: api.endpoints.user.imports + id});
    }

    saveConsignees = (callback, data, response) => {

        const promises = [];

        if(data.status !== 'DRAFT'){

            if(this.form.get('from_save_recipient') === true || this.form.get('from_edit_recipient') === true){

                const consignee = this.stores.consignees.data.get(this.form.get('selected_from_recipient'));

                this.stores.consignees.form.clear();
                this.stores.consignees.form.set('name1', data.from_name1);
                this.stores.consignees.form.set('name2', data.from_name2);
                this.stores.consignees.form.set('address1', data.from_address1);
                this.stores.consignees.form.set('address2', data.from_address2);
                this.stores.consignees.form.set('postalnumber', data.from_postalnumber);
                this.stores.consignees.form.set('city', data.from_city);
                this.stores.consignees.form.set('countries_id', data.from_countries_id);
                this.stores.consignees.form.set('email', data.from_email);
                this.stores.consignees.form.set('contact', data.from_contact);
                this.stores.consignees.form.set('tel', data.from_tel);
                this.stores.consignees.form.set('mobile', data.from_mobile);

                if(consignee !== undefined){

                    this.stores.consignees.form.set('businessid', consignee.businessid);
                    this.stores.consignees.form.set('description', consignee.description);
                }

                if(this.form.get('from_search') !== undefined){

                    this.stores.consignees.form.set('search', this.form.get('from_search')); // Overwrite if set.
                }

                if(this.form.get('from_save_recipient') === true){

                    promises.push(this.stores.consignees.add());
                }

                else {

                    if(consignee !== undefined){

                        this.stores.consignees.form.set('search', consignee.search);
                    }

                    promises.push(this.stores.consignees.update(consignee.id));
                }
            }

            if(this.form.get('to_save_recipient') === true || this.form.get('to_edit_recipient') === true){

                const consignee = this.stores.consignees.data.get(this.form.get('selected_to_recipient'));

                this.stores.consignees.form.clear();
                this.stores.consignees.form.set('name1', data.to_name1);
                this.stores.consignees.form.set('name2', data.to_name2);
                this.stores.consignees.form.set('address1', data.to_address1);
                this.stores.consignees.form.set('address2', data.to_address2);
                this.stores.consignees.form.set('postalnumber', data.to_postalnumber);
                this.stores.consignees.form.set('city', data.to_city);
                this.stores.consignees.form.set('countries_id', data.to_countries_id);
                this.stores.consignees.form.set('email', data.to_email);
                this.stores.consignees.form.set('contact', data.to_contact);
                this.stores.consignees.form.set('tel', data.to_tel);
                this.stores.consignees.form.set('mobile', data.to_mobile);

                if(consignee !== undefined){

                    this.stores.consignees.form.set('businessid', consignee.businessid);
                    this.stores.consignees.form.set('description', consignee.description);
                }

                if(this.form.get('to_search') !== undefined){

                    this.stores.consignees.form.set('search', this.form.get('to_search')); // Overwrite if set.
                }

                if(this.form.get('to_save_recipient') === true){

                    promises.push(this.stores.consignees.add());
                }

                else {

                    if(consignee !== undefined){

                        this.stores.consignees.form.set('search', consignee.search);
                    }

                    promises.push(this.stores.consignees.update(consignee.id));
                }
            }
        }

        this.stores.ui.resolve(promises, () => this.saveConsignees(data, callback),

            resolved => {
            
                this.ready(callback, true, response);
            },

            rejected => {
    
                this.ready(callback, false, response);
            }
        );
    }

    ready = (callback, consignees, response) => {

        if(callback !== undefined){

            callback(consignees, response);
        }
    }

    fetchAll = async () => {

        return await this.get({}, {url: api.endpoints.user.imports});
    }

    fetchSingle = async params => {

        return await this.get({}, {url: api.endpoints.user.imports + params.id});
    }

    load = () => {

        return this.fetchAll();
    }

    remove = async (id) => {

        return await this.delete({}, {
        
            url: api.endpoints.user.imports + id
        });
    }

    repopulate = id => {

        const data = this.data.get(id);
        const remap = {};

        for(let k in data){

            for(let ui in this.keys){

                const  api = this.keys[ui];

                if(k === api){

                    remap[ui] = data[k];
                }
            }
        }

        // After parcel is saved to API all parcels have amount of one after splitting.

        remap.parcels.forEach(v => {
       
            v.amount = 1;
        });

        const metadata = [
       
            'from_save_recipient',
            'from_search',
            'from_edit_recipient',
            'to_save_recipient',
            'to_search',
            'to_edit_recipient',
            'fill_measurements',
            'selected_from_recipient',
            'selected_to_recipient',
            'return_to'
        ];

        metadata.forEach(v => {
        
            if(remap.metadata[v] !== undefined){

                remap[v] = remap.metadata[v];
            }
        });

        delete remap.metadata;

        const handlers = {
           
            date: 'unixToDate',
            parcels: 'jsonToMultifields'
        };

        this.form = Form.repopulate(remap, handlers);

        if(this.form.get('parcels') !== undefined && this.form.get('parcels').length === 0){

            this.form.delete('parcels');
        }
    }

    loadImportDetails  = (callback) => {

        const promises = [
            
            // this.stores.senders.fetchAll(),
            this.stores.consignees.fetchAll(),
            this.stores.pickuppoints.load()
        ];

        this.stores.ui.resolve(promises, () => this.loadImportDetails(callback), () => {

            this.stores.import.form.clear();
            this.stores.import.validation.clear();
            
            callback();
        });
    }

   IsAtLeastOneFromContactInfoFilled = () => {

        let result = false;
        
        const fields = ['from_tel', 'from_mobile', 'from_email'];

        fields.forEach(field => {

            if(this.form.get(field) !== undefined && this.form.get(field) !== ''){

                result = true;
            }
        });

        return result;
    }
}

export default ImportStore;
