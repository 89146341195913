import React from 'react'
import styled from 'styled-components';
import {observer} from 'mobx-react';
import {observable, action} from 'mobx';
import PropTypes from 'prop-types';

const Close = styled.div`

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 4;
`;

const Container = styled.div`

    ${props => `

        position: absolute;
        top: ${props.top}px;
        left: ${props.left}px;
        background: ${props.theme.color[6]};
        max-width: 50em;
        padding: ${props.theme.size[1]};
        z-index: 5;
    `}
`;

@observer class Note extends React.Component {

    @observable position = {
   
        top: 0,
        left: 0
    };

    constructor(props){

        super(props);
        this.wrapperRef = React.createRef();
    }

    @action componentDidUpdate(){

        if(this.props.show === true){

            const prev = this.wrapperRef.current.previousSibling;
            let top;
            let left;

            if(prev.offsetTop !== 0 && prev.offsetLeft !== 0){

                top = prev.offsetTop - this.containerRef.offsetHeight;
                left = prev.offsetLeft + prev.offsetWidth;
            }

            else {
                
                const pos = prev.getBoundingClientRect();
                top = pos.y - this.containerRef.offsetHeight + document.scrollingElement.scrollTop;
                left = pos.x + prev.offsetWidth;
            }

            if(
                this.position.top !== top ||
                this.position.left !== left
            ){

                this.position = {

                    top: top,
                    left: left

                };
            }
        }
    }

    render(){

        if(this.props.show === false){

            return false;
        }

        else {
 
            return (

                <div ref={this.wrapperRef}>
 
                    <Close onClick={() => {this.props.close(false)}}></Close>
                    <Container top={this.position.top} left={this.position.left} innerRef={x => {this.containerRef = x}} dangerouslySetInnerHTML={{__html: this.props.children}}></Container>

                </div>

            );
        }
    }
}

Note.propTypes = {

    /** Action when clicking outside of the note */
    close: PropTypes.func.isRequired,

    /** Toggle note on or off */
    show: PropTypes.bool
}

export default Note;
