import React from 'react';
import styled from 'styled-components';

function Pagebreak(props) {

    const Wrapper = styled.div`

        ${props => `

            height: 2em;
            margin-bottom: ${props.theme.size[1]};
        `}
    `;

    const Outer = styled.div`

        ${props => `

            position: relative;
            top: ${props.theme.size[1]};
            border-bottom: 4px solid ${props.theme.color[1]};
        `}
    `;

    const Inner = styled.div`

        ${props => `

            position: absolute;
            margin: -0.2em 0 0 2em;
            padding: 0 ${props.theme.size[1]} 0 ${props.theme.size[1]};
            background: ${props.theme.white};
            ${props.color !== undefined && `color: ${props.color};`}
        `}
    `;

    return (

        <Wrapper>
    
            <Outer>
        
                <Inner color={props.color}>{props.children}</Inner>

            </Outer>
            
        </Wrapper>
    );
}

export default Pagebreak;
