import React from 'react';
import Button from 'components/gls-dumb/Button';
import theme from 'theme';

const buttonProps = {

    width: 'auto',
    borderRadius: 0,
    backgroundColor: theme.color[1],
    color: theme.color[7]
};

class Alternative extends React.Component {

    render(){

        return <Button {...this.props} {...buttonProps}>{this.props.children}</Button>;
    }
}

export default Alternative;
