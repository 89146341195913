import React from 'react';
import Header from 'components/smart/Header';
import Title from 'components/gls-dumb/Title';
import Content from 'components/gls-dumb/Content';
import Multifields from 'components/gls-dumb/Multifields';
import {inject, observer} from 'mobx-react';
import {computed, action, observable} from 'mobx';
import Button from 'components/gls-dumb/Button';
import Columns from 'components/gls-dumb/Columns';
import Field from 'components/gls-dumb/Field';
import Validate from 'Validate';

@inject('ui')
@inject('countries')
@inject('cncodes')
@inject('customunits')
@inject('parcel')
@inject('load')
@inject('customs')

@observer class CustomsDeclaration extends React.Component {

    lang = this.props.ui.lang.customs_declaration;
    form = new Map();
    submitted = false;

    change = v => {

        this.form.set(v.name, v.value);

        if(this.submitted === true){

            this.validationResults = this.validate();
        }
    }

    @computed get unitOptions(){

        const options = [{value: '', option: '- ' + this.lang.select_unit}];

        this.props.customunits.data.forEach(v => {

            let option;
        
            if(this.props.ui.selectedLang === 'fi'){

                option = v.fiunit;
            }

            else if(this.props.ui.selectedLang === 'en'){

                option = v.enunit;
            }

            options.push({value: v.intrastat, option: option});
        });

        return options;
    }

    @action getUnitByCNcode = cncode => {

        let unit = false;

        if(cncode !== undefined && (cncode.length === 8 || cncode.length === 10)){

            this.props.cncodes.data.forEach(v => {

                if(v.cn === cncode){

                    this.props.customunits.data.forEach(v2 => {

                        if(v.su === v2.intrastat){

                            unit =  v2.intrastat;
                        }
                    });
                }
            });
        }

        return unit;
    }

    setCustomsUnit = row => {

        const cncode = row.get('tariff_name');
        const unit = this.getUnitByCNcode(cncode);

        if(unit === false || unit === 'ZZZ'){

            row.set('unit', '');
        }

        else {

            row.set('unit', unit);
        }
    }

    setAmountDisabled = (row, overrides) => {

        const cncode = row.get('tariff_name');
        const unit = this.getUnitByCNcode(cncode);

        if(unit === false || unit === 'ZZZ'){

            row.set('amount', undefined);
            overrides['amount'] = {disabled: true};
        }

        else {

            overrides['amount'] = {disabled: false};
        }
    }

    validate = () => {

        const form = new Validate(this.form);

        form.set('parcelnumber').isFilled(this.lang.parcelnumber_is_filled);
        form.set('invoice_vatnumber').isFilled(this.lang.invoice_vatnumber_is_filled);
        form.set('invoice_number').isFilled(this.lang.invoice_number_is_filled).isInt(this.lang.invoice_number_is_int);
        form.set('invoice_date').isFilled(this.lang.invoice_date_is_filled).date(this.lang.invoice_date_is_date);
        form.set('deliveryterms').isFilled(this.lang.deliveryterms_is_filled);
        form.set('customs_value').isFilled(this.lang.customs_value_is_filled);
        form.set('customs_currency').isFilled(this.lang.customs_currency_is_filled);

        const formRowsResult = [];

        const rows = this.form.get('invoice_contents') !== undefined ? this.form.get('invoice_contents') : [new Map()];

        /*

        let rows = this.form.get('invoice_contents');

        if(rows === undefined){

            rows = [new Map([

                ['product_code', undefined],
                ['package_amount', undefined],
                ['tariff_name', undefined],
                ['amount', undefined],
                ['unit', undefined],
                ['netweight', undefined],
                ['description', undefined],
                ['unit_price', undefined],
                ['total_price', undefined],
                ['countries_id', undefined]
            ])];
        }

        */

        rows.forEach((data) => {
            
            const formRows = new Validate(data);

            formRows.isValidCNcode = message => {

                let found = false;

                this.props.cncodes.data.forEach(v => {

                    if(v.cn === formRows.value){

                        found = true;
                    }
                });

                if(found === false){

                    formRows.error('isValidCNcode', message);
                }

                return this;
            }

            /*

            formRows.invalidOverallWeight = message => {

                const totalWeight = round(this.form.get('total_weight'), 2);

                let customsTotalWeight = 0;

                rows.forEach(row => {

                    const customsWeight = round(row.get('netweight'), 2);

                    if(isNaN(customsWeight) === false){
                        
                        customsTotalWeight = round(customsWeight + customsTotalWeight, 2);
                    }
                });

                if(customsTotalWeight > totalWeight){

                    formRows.error('invalidOverallWeight', message);
                }

                return this;
            }

            */

            const cncode = data.get('tariff_name');
            const unit = this.getUnitByCNcode(cncode);

            if(unit !== false && unit !== 'ZZZ'){

                formRows.set('amount').isFilled(this.lang.amount_is_filled).isInt(this.lang.amount_is_int);
            }

            formRows.set('netweight').isFilled(this.lang.netweight_is_filled).isDecimal(this.lang.netweight_is_decimal);
            formRows.set('tariff_name').isFilled(this.lang.tariff_name_is_filled).isNumeric(this.lang.tariff_name_is_numeric).isValidCNcode(this.lang.tariff_name_is_valid_cncode);
            formRows.set('description').isFilled(this.lang.description);
            formRows.set('unit_price').isFilled(this.lang.unit_price_is_filled).isDecimal(this.lang.unit_price_is_decimal);
            formRows.set('total_price').isFilled(this.lang.total_price_is_filled).isDecimal(this.lang.total_price_is_decimal);
            formRows.set('countries_id').isFilled(this.lang.countries_id_is_filled);

            formRowsResult.push(formRows.result());
        });

        return {
        
            form: form.result(),
            formRows: formRowsResult
        }
    }

    @observable validationResults = {

        form: new Map(),
        formRows: [new Map()]
    }

    submit = () => {

        this.submitted = true;

        this.validationResults = this.validate();

        if(Validate.size(this.validationResults) !== 0){

            this.props.ui.messages = Validate.parseMessages(this.validationResults);
        }

        else {

            this.request();
        }

    }

    request = () => {

        this.props.load.api(this.props.customs.add(this.form), this.request, r => {

            if(r.status === false){

                this.props.ui.messages = [this.lang.not_saved];
            }

            else if(r.status === true){

                this.props.ui.messages = [this.lang.saved];

                this.props.ui.afterClearMessages = () => {

                    this.props.load.route('/home/', {}, url => {

                        this.props.history.push(url);
                    });
                };
            }
        });
    }

    error = field => {

        return this.validationResults.form.get(field) === undefined ? false : true;
    }

    fieldProps = field => {

        return {

            name: field,
            value: this.form.get(field),
            onChange: this.change,
            error: this.error(field)
        }
    }

    render(){

        return (

            <div className="main">

                <Header />

                <Title>{this.lang.title}</Title>

                <Content>

                    <Columns>

                        <Columns.left>

                            <Field type="text" {...this.fieldProps('parcelnumber')} label={this.lang.parcelnumber} />

                        </Columns.left>

                        <Columns.right>

                            <Columns>

                                <Columns.left>

                                    <Field type="select" {...this.fieldProps('deliveryterms')} label={this.lang.deliveryterms} options={this.props.parcel.getOptionfields('terms')} />

                                </Columns.left>

                                <Columns.right>

                                    <Field {...this.fieldProps('invoice_vatnumber')} label={this.lang.invoice_vatnumber} maxLength="10" info={this.lang.invoice_vatnumber_help} />

                                </Columns.right>

                            </Columns>

                        </Columns.right>

                    </Columns>

                    <Columns>

                        <Columns.left>

                            <Columns gap="small">

                                <Columns.left>
                            
                                    <Field type="text" {...this.fieldProps('invoice_number')} label={this.lang.invoice_number} />

                                </Columns.left>

                                <Columns.right>

                                    <Field type="text" {...this.fieldProps('invoice_date')} label={this.lang.invoice_date} />

                                </Columns.right>

                            </Columns>

                        </Columns.left>

                        <Columns.right>

                            <Columns gap="small">

                                <Columns.left>
                               
                                    <Field type="text" {...this.fieldProps('customs_value')} label={this.lang.customs_value} maxLength="7" />

                                </Columns.left>

                                <Columns.right>
                                
                                    <Field type="select" {...this.fieldProps('customs_currency')} label={this.lang.customs_currency} options={this.props.parcel.getOptionfields('currencies')} />
                                
                                </Columns.right>

                            </Columns> 
                        
                        </Columns.right>

                    </Columns>

                    <Multifields

                        name="invoice_contents"
                        value={undefined}
                        error={[]}
                        validation={this.validationResults.formRows}
                        
                        fields={[

                            {name: 'product_code', label: this.lang.product_code + ':', width: '10em', description: this.lang.product_code_descripition},
                            {name: 'package_amount', label: this.lang.package_amount + ':', width: '11em', description: this.lang.package_amount_description},
                            {name: 'tariff_name', label: this.lang.tariff_name + ':', width: '9em', maxlength: 10},
                            {name: 'amount', label: this.lang.amount + ':', width: '7em', maxlength: 3, callback: this.setAmountDisabled, disabled: true},
                            {name: 'unit', label: this.lang.unit + ':', description: this.lang.unitHelp, width: '7em', maxlength: 50, options: this.unitOptions, disabled: true, callback: this.setCustomsUnit, default: ''},
                            {name: 'netweight', label: this.lang.netweight + ':', width: '7em', maxlength: 50},
                            {name: 'description', label: this.lang.description + ':', maxlength: 255},
                            {name: 'unit_price', label: this.lang.unit_price + ':', description: this.lang.unit_price_help, width: '10em', maxlength: 7, remainderof: ['amount', 'total_price']},
                            {name: 'total_price', label: this.lang.total_price + ':', description: this.lang.total_price_help, width: '7em', maxlength: 7, sumof: ['amount', 'unit_price']},
                            {name: 'countries_id', label: this.lang.countries_id + ':', description: this.lang.countries_id_help, width: '7em', options: this.props.countries.optionsWithCode(), default: '658b3f9b-0146-4404-88ed-eafd4c0affac'}
                        ]}

                        breakpoint="1100px"
                        onChange={this.change}
                    />

                </Content>

                <Button onClick={() => this.submit()}>{this.lang.save}</Button>

            </div>
        );
    }
}

export default CustomsDeclaration;
