import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
`;

class Close extends React.Component {

    render(){

        if(this.props.isOpen === false){

            return null;
        }

        else {

            return (
            
                <Wrapper onClick={this.props.onClick}></Wrapper> 
            );
        }
    }
}

export default Close;
