import React, {Component} from 'react';
import styled from 'styled-components';
import theme from 'theme';

const Container = styled.div(props => `

    background-color: ${props.color};
`);

class Section extends Component {

    render(){

        let color = "#000";

        if(this.props.color !== undefined){

            if(this.props.color === 'main'){

                color = theme.color[1]; // Blue
            }

            if(this.props.color === 'sub'){

                color = theme.color[4]; // Light blue
            }
        }

        return (

            <Container color={color}>

                <div className="main">{this.props.children}</div>

            </Container>
        );
    }
}

export default Section;
