import {isNumeric} from 'Helpers';
import moment from 'moment';

/*

USAGE EXAMPLE OF SINGLE FIELD:

const errors = Validate.parseMessages({result: new Validate(new Map([['email', this.get('preadviceEmail')]])).set('email').isFilled('Sähköposti on pakollinen.').isEmail('Sähköposti on väärässä muodossa.').result()});

console.log(errors); // Array of errormessages.

*/

class Validate {

    constructor(data){

        this.data = data;
        this.collection = new Map(); 
        
        return this;
    }

    set(k){

        this.collection.set(k, {types: new Map()});
        this.key = k;
        this.value = this.data.get(k);

        if(this.value === undefined){

            this.value = '';
        }

        return this;
    }

    isTel = message => {

        const regexp = new RegExp('^[0-9+. ()-]+$');

        if(this.value.match(regexp) === null){

            this.error('isTel', message);
        }

        return this;
    }

    isMobile = message => {

        const regexp = new RegExp('^[0-9+. ()-]+$');

        if(this.value.match(regexp) === null){

            this.error('isMobile', message);
        }

        return this;
    }

    date = message => {
 
        if( ! moment(this.value, 'DD.MM.YYYY', true).isValid()){

            this.error('date', message);
        }

        return this;
    }

    datetime = message => {

        if( ! moment(this.value, 'DD.MM.YYYY HH:mm', true).isValid()){

            this.error('datetime', message);
        }

        return this;
    }

    maxSize = (max, message) => {

        if(this.value.lenght > max){

            this.error('maxSize', message);
        }

        return this;
    }

    minSize = (min, message) => {

        if(this.value.length < min){

            this.error('minSize', message);
        }

        return this;
    }

    regexp = (regexp, message) => {

        if(this.value.match(new RegExp(regexp)) === null){

            this.error('regexp', message);
        }

        return this;
    }

    isFilled = message => {

        if(this.value === ''){

            this.error('isFilled', message);
        }

        return this;
    }

    isGreater = (value, message) => {

        const number = parseInt(this.value, 10);

        if(isNaN(number) || number < value){

            this.error('isGreater', message);
        }

        return this;
    }

    isLess = (value, message) => {

        const number = parseFloat(this.value.toString().replace(',', '.'), 10);

        if(isNaN(number) || number > value){

            this.error('isLess', message);
        }

        return this;
    }

    isNumeric = message => {

        if(isNumeric(this.value) === false){
        
            this.error('isNumeric', message);
        }

        return this;
    }

    isDecimal = message => {

        // Allow comma as seperator (finnish number delimiter)
        
        const chars = this.value.toString().replace(',', '.').split(''); 
        const delimiter = '.';
        const allowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', delimiter];

        let numeric = true;
        let delimiters = 0;

        // Check if array contains anything else than numbers and zero or one delimiter.

        for(let i = 0; i < chars.length; i++){

            if(chars[i] === delimiter){
            
                delimiters++;

                if(delimiters > 1){

                    // Too many delimiters.

                    numeric = false; break;
                }
            }

            if(allowed.indexOf(chars[i]) === -1){
            
                numeric = false; break;
            }
        }

        // If value is not numeric or it has delimiter in wrong position value is not decimal.

        if(numeric === false ||

            (chars[0] === delimiter || chars[chars.length - 1] === delimiter) ||
            (chars.length > 1 && chars[0] === '0' && chars[1] !== delimiter)
        ){

            this.error('isNumeric', message);
        }

        return this;
    }

    isInt = message => {

        const chars = this.value.toString().replace(',', '.').split(''); 
        const allowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

        let isInt = true;

        // If first character is zero string can't contain more than one character.

        if(chars.length >= 2 && chars[0] === "0"){
        
            isInt = false;
        }

        else {

            // Check that all characters are numbers.

            for(let i = 0; i < chars.length; i++){

                if(allowed.indexOf(chars[i]) === -1){
                
                    isInt = false; break;
                }
            }
        }

        if(isInt === false){

            this.error('isInt', message);
        }

        return this;
    }

    format = (v, message) => {

        if(v === 'zip'){
 
            if(isNumeric(this.value) === false || this.value.length !== 5){
            
                this.error('format', message);
            }
        }

        return this;
    }

    zipmask = (mask, message) => {

        mask = mask.trim();
        const numbers = mask.split('');

        if(isNumeric(this.value === false) || this.value.length !== numbers.length){

            this.error('zimpask', message);
        }

        return this;
    }

    // Maxium file size in MB

    maxFileSize = (max, message) => {

        if(this.value.size === undefined || (this.value.size / 1000000) > max){

            this.error('maxFileSize', message);
        }

        return this;
    }

    /*

    File type validation:

    types = ['pdf', 'xml']; // Example of types attribute.

    */

    fileOfType = (types, message) => {

        let match = false;

        if(this.value.name !== undefined){

            const name = this.value.name.split('.');
            const extension = name[name.length - 1].toUpperCase();

            types.forEach(v => {
     
                if(v.toUpperCase() === extension){

                    if(extension === 'PDF' && this.value.type === 'application/pdf'){

                        match = true;
                    }

                    else if(extension === 'XML' && this.value.type === 'application/xml'){

                        match = true;
                    }

                    else if(extension === 'CSV' && this.value.type === 'text/plain'){

                        match = true;
                    }

                    else {

                        match = true;
                    }
                }
            });
        }

        if(match === false){

            this.error('fileOfType', message);
        }

        return this;
    }

    equalTo = (v, message) => {

        if(this.value !== v){

            this.error('equalTo', message);
        }

        return this;
    }

    // Return all filled rows set by MultiFields

    static multi = (rows, callback) => {

        if(rows === undefined){

            return false;
        }

        rows = rows.filter((v, k) => k > 0 ? true : false);

        return callback(rows);
    }

    isEmail = message => {

        const regexp = new RegExp('^[^ ]+@[^ ]+$');

        if(this.value.match(regexp) === null){

            this.error('isEmail', message);
        }

        return this;
    }

    isMultiEmail = message => {

        const isCommaSeparator = this.value.match(/,/g);
        const isSemiColonSeparator = this.value.match(/;/g);

        let emails = [];

        if(isCommaSeparator !== null){
        
            emails = this.value.split(',');
        }
        
        else if(isSemiColonSeparator !== null){

            emails = this.value.split(';');
        }

        else {
        
            emails.push(this.value);
        }

        const regexp = new RegExp('^[^ ]+@[^ ]+$');

        emails.map(email => {

            email = email.trim();

            if(email.match(regexp) === null){

                this.error('isMultiEmail', message);
            }

            return email;
        });

        return this;
    }

    error = (type, message) => {

        const error = this.collection.get(this.key);
        error.types.set(type, message === undefined ? false : message);

        this.collection.set(this.key, {types: error.types});
    }

    // Helper to parse validation result object to array of messages.

    static parseMessages = (results) => {

        const messages = [];

        for(let name in results){

            if(typeof results[name].slice === 'function'){

                // Item is observable or regular array! (validation of MultiFields component)

                const multiMessages = [];

                results[name].forEach(result => {
                
                    result.forEach(error => {
                    
                        multiMessages.push(error.message);
                    });
                });

                multiMessages.filter((v, i, self) => self.indexOf(v) === i).forEach(message => {
                
                    messages.push(message);
                });
            }

            else {
 
                // Item is observable object or regular object

                results[name].forEach(error => {
                
                    messages.push(error.message);
                });
            }
        }

        return messages;
    }

    // Helper to count total amount of errors.

    static size = (results) => {

        let amount = 0;

        const outerCount = (result) => {
           
            result.forEach(innerCount);
        }

        const innerCount = () => {
        
            amount++;
        }; 

        for(let name in results){

            if(typeof results[name].slice === 'function'){

                // Item is observable or regular array! (validation of MultiFields component)

                results[name].forEach(outerCount);
            }

            else {

                // Item is observable object or regular object

                results[name].forEach(innerCount);
            }
        }

        return amount;
    }

    // Returns validation object which contains all errortypes and messages.

    result = () => {

        this.collection.forEach((error, field) => {
 
            if(error.types.size === 0){

                this.collection.delete(field);
            }

            else {

                const types = error.types.values(); // All messages
                const message = types.next().value; // First message

                this.collection.set(field, {types: error.types, message: message});
            }
        });

        return this.collection;
    }

    /*

    // Example of existing data.

    const data = {
    
        example_unix: '1537274810'
        exmaple_multifields: [JSON]
        example_field: 'Test'
    }

    // Mark keys that has value that needs special handling.

    const handlers = {

        example_unix: 'unixToDate',
        example_multifields: 'jsonToMultifields'
    }

    this.form  = Validate.repopulate(data, handlers);

    */

    static repopulate = (data, handlers) => {

        const form = new Map();

        for(let k in data){

            if(data[k] == null){

                continue;
            }

            if(handlers[k] !== undefined){

                // Unix time to date

                if(handlers[k] === 'unixToDate'){
                
                    form.set(k, moment(data[k], 'X').format('DD.MM.YYYY'));
                }

                // JSON string to iterable (for Multifields component).

                else if(handlers[k] === 'jsonToMultifields'){
                    
                    const multifields = [];
                    const json = data[k];

                    json.forEach((fields) => {
                    
                        const map = new Map();
                    
                        for(let k in fields){

                            map.set(k, fields[k]);
                        }

                        multifields.push(map);
                    });

                    form.set(k, multifields);
                }
            }

            // No special handler

            else {

                // Do not mutate data (reference to API data)

                if(isNumeric(data[k])){

                    form.set(k, data[k].toString());
                }

                else {

                    form.set(k, data[k]);
                }
            }
        }

        return form;
    }
}

export default Validate;
